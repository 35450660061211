import CryptoJS from 'crypto-js';
import SHA256 from 'crypto-js/sha256';
import AES from 'crypto-js/aes';
import T from 'i18n-react';
import { store } from '../datastore/store';
import { authBackendURL } from '../config';
import { emailHash } from './registration';
import { notify } from './notifications';
import { fetchUserData } from './backend';

const loginURL = `${authBackendURL}auth`;

export const decryptPersonalData = async (email, password) => {
    // const username_iv = CryptoJS.PBKDF2(password, username + "hoho");
    // const username_passkey = CryptoJS.PBKDF2(password, username);
    const state = store.getState();
    const provider_type = state.authentication.provider_type.type;
    const encryptedUsername = emailHash(email);
    const passHash = SHA256(password).toString();
    const loginResult = await fetch(loginURL, {
        method: 'POST',
        body: JSON.stringify({
            identifier: encryptedUsername,
            password: passHash,
        }),
        headers: {
            'Content-type': 'application/json',
        },
    });

    const { persons } = state.dataStore.graph;

    Object.values(persons).forEach((pers) => {
        const encryptedKeys = [
            'first_name',
            'last_name',
            'email',
            'ssn',
            'dob',
            'address',
            'zipcode',
            'father_name',
            'mother_name',
            'mobile',
            'phone',
            'gender',
        ];
        encryptedKeys.forEach((key) => {
            if (key in pers && pers[key] != null) {
                pers[key] = AES.decrypt(pers[key], password).toString(CryptoJS.enc.Utf8);
            }
        });
        if (pers.first_name === '' && loginResult.ok && provider_type !== 'Promoter') {
            notify({
                warning: true,
                header: T.translate('warnings.password-reset.fix-persons-title'),
                content: T.translate('warnings.password-reset.fix-persons-body'),
            });
            store.dispatch({ type: 'RESET_PERSONS', payload: true });
        }
        store.dispatch({ type: 'SET_MODEL', payload: pers.serialize() });
        // pers.username = AES.decrypt(pers.username, username_passkey, { iv: username_iv }).toString(
        //     CryptoJS.enc.Utf8
        // );
    });
    window.sessionStorage.setItem(
        'persons',
        JSON.stringify({
            data: Object.values(persons).map((p) => {
                const serializedPerson = p.serialize();
                return serializedPerson.data;
            }),
        })
    );

    return true;
};

export const loginAction = async (email, password, dry_run = false) => {
    // const username_iv = CryptoJS.PBKDF2(password, username + "hoho");
    // const username_passkey = CryptoJS.PBKDF2(password, username);
    //
    // const encryptedUsername = AES.encrypt(username, username_passkey, { iv: username_iv }).toString();
    const encryptedUsername = emailHash(email);
    const passHash = SHA256(password).toString();
    const state = store.getState();

    const loginResult = await fetch(loginURL, {
        method: 'POST',
        body: JSON.stringify({
            identifier: encryptedUsername,
            password: passHash,
        }),
        headers: {
            'Content-type': 'application/json',
        },
    });
    if (!loginResult.ok) throw loginResult;
    if (dry_run) return loginResult;
    const authData = await loginResult.json();

    store.dispatch({ type: 'SET_AUTH_DATA', payload: authData });
    try {
        await fetchUserData();
        await decryptPersonalData(email, password);
    } catch (e) {
        const loginResult = await fetch(loginURL, {
            method: 'POST',
            body: JSON.stringify({
                identifier: encryptedUsername,
                password: passHash,
            }),
            headers: {
                'Content-type': 'application/json',
            },
        });

        if (loginResult.ok) {
            store.dispatch({ type: 'RESET_PERSONS', payload: true });
            const provider_type = state.authentication.provider_type.type;
            console.log('FIRST NOTIFICATION WARNING');
            if (provider_type !== 'Promoter') {
                notify({
                    warning: true,
                    header: T.translate('warnings.password-reset.fix-persons-title'),
                    content: T.translate('warnings.password-reset.fix-persons-body'),
                });
            }
        }
    }

    // const state = store.getState();
    // let activePersonId = state.authentication.activePersonId;
    let activePersonId = Object.values(state.dataStore.graph.persons).find((p) => p.default).id;
    if (!activePersonId) {
        activePersonId = state.dataStore.graph.persons[0].id;
    }
    store.dispatch({ type: 'SET_ACTIVE_PERSON', payload: activePersonId });

    return authData;
};
