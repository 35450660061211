import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
// import PressureMetricContainer from "./pressure_metric";
import GeneralMetricContainer from 'Mib/components/metric_panels/general_metric';
import moment from 'moment';
import T from 'i18n-react';
import { withRouter } from 'react-router-dom';

import { openPressuresModal } from 'Mib/actions';
import { subscribeWithLoader } from '../../lib/subscribe';
import { Create, Update } from '../../actions';
import { fetchAction } from '../../actions/backend';
import { generateChartData } from '../../lib/charts';
import { deepMerge } from '../../lib/utils';
import MeasurementFormModal from '../modals/measurement_form_modal';
import { TextInput, DropDownInput, MibButton, CalendarInput, TagRemoteInput } from '../../components/inputs';
import { Pressure } from '../../lib/models';
import { FilteredMetricComponent } from './filtered_metric';
import filterByMeasureSystem from 'Mib/lib/measure_units';

class _PressureContainer extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.open = this.open.bind(this);
        this.dataPropName = 'pressures';
        this.config = {
            descriptor: 'systolic',
            name: T.translate('measurements.singular.pressure'),
            dateDescriptor: 'date',
            valueOffset: 3,
            onGraphPointClicked: this.open,
        };
    }

    _generateChartData(data = {}, props = this.props) {
        const { merge_config = {}, ...chart_settings } = this.config;

        const chart_config = generateChartData(data, chart_settings);
        const chart_config2 = generateChartData(data, { ...chart_settings, descriptor: 'diastolic' });

        chart_config.series[0].name = T.translate('measurements.pressures.systolic');
        chart_config2.series[0].name = T.translate('measurements.pressures.diastolic');

        chart_config.series.push(chart_config2.series[0]);
        typeof merge_config === 'function'
            ? deepMerge(chart_config, merge_config(props))
            : deepMerge(chart_config, merge_config);

        let [min, max] = this.getMinMax(data);
        chart_config.yAxis.min = min;
        chart_config.yAxis.max = max;
        // TODO: fix me
        // chart_config.boost = {enabled: false}
        return chart_config;
    }

    getMinMax(data) {
        let min, max;
        Object.values(data).forEach((p) => {
            if (p.systolic > max || max == null) max = p.systolic;
            if (p.diastolic < min || min == null) min = p.diastolic;
        });
        return [min, max];
    }

    filterData(data = {}, props = this.props, valueAttr = 'result', dateAttr = 'date') {
        const _filtered = super.filterData(data, props, valueAttr, dateAttr);
        let filtered = {};
        Object.values(_filtered)
            .filter((o) => o.systolic || o.diastolic)
            .forEach((i) => (filtered[i.id] = i));
        return filtered;
    }

    render() {
        let { pressures, measures, children, syncing, loading, ...rest } = this.props;

        let { chart_config, pressures: filteredPressures } = this.state;
        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate('measurements.singular.pressure')}
                data={filteredPressures}
                measureUnits={measures}
                chart_config={chart_config}
                dataType="pressures"
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
                chartLimit={30}
            />
        );
    }

    open(id = undefined, type = undefined) {
        let { unitId } = this.state;

        openPressuresModal({ id, type, unitId });
    }
}

const mapStateToProps = (state) => {
    var allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    var pressureMeasureUnits = {};
    var systemMeasureUnits = filterByMeasureSystem(allmeasureUnits);
    systemMeasureUnits &&
        Object.entries(systemMeasureUnits).forEach(([key, unit]) => {
            if (unit.measureUnitType && unit.measureUnitType.name == 'PRESSURE') {
                pressureMeasureUnits[unit.id] = unit;
            }
        });
    return {
        pressures: state.dataStore.graph.pressures,
        measures: pressureMeasureUnits,
        filters: state.filters,
    };
};

const _PressureContainerSub = subscribeWithLoader(_PressureContainer, mapStateToProps, [
    'pressures',
    'measure-units?with=measureUnitType',
]);

export default withRouter(_PressureContainerSub);
