/* eslint-disable no-underscore-dangle */
import React from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import sortOn from 'sort-on';

import { ContentTitle } from 'Mib/components/generic';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import BaseContainer from 'Mib/components/containers/base';
import TimelineEventList from 'Mib/components/generic/timeline/timeline_event_list';
import WithSideMenu from 'Mib/components/containers/with_side_menu';
import ChartEventList from 'Mib/components/generic/chart_event_list';

import { generateChartData, generateConfigSeries } from 'Mib/lib/charts';
import { CreateButton } from 'Mib/components/inputs/buttons';
import { ActivityForm } from 'Mib/routes/activities/activity_form';
import ActivityTypesMenu from 'Mib/routes/activities/activity_types_menu';
import AddTypePopup from 'Mib/components/generic/add_type_popup';
import { FilteredMetricComponent } from 'Mib/components/metric_panels/filtered_metric';

const activityTypeUrls = {
    0: 'other',
    1: 'walking',
    2: 'running',
    3: 'biking',
    4: 'ski',
    5: 'withings',
};

// const iconMap = {
//     "0": "child",
//     "1": "icon-walking",
//     "2": "icon-running",
//     "3": "bicycle",
//     "4": "icon-skiing",
//     "5": "icon-withings",
// };

const iconMap = {
    0: 'mibmono-excersise',
    1: 'mibmono-walking',
    2: 'mibmono-running',
    3: 'mibmono-bicycle',
    4: 'mibmono-skiing',
    5: 'icon-withings',
};

class _ActivitiesPage extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { type_filter: 'all' };
        // this.open = this.open.bind(this);
        this.dataPropName = 'activities';
        this.config = {
            descriptor: 'steps',
            name: T.translate('routes.activities'),
            dateDescriptor: 'start',
            // valueOffset: 10,
            // onGraphPointClicked: this.open,
        };
    }
    componentWillMount() {
        const type = getProperty(this, 'props.match.params.type');
        this._filterData(type);
        super.componentWillMount();
    }
    componentWillReceiveProps(newProps) {
        const oldType = getProperty(this, 'props.match.params.type');
        const newType = getProperty(newProps, 'match.params.type');
        if (newType != oldType) this._filterData(newType);

        const { filters, [this.dataPropName]: data } = newProps;
        const { filters: oldFilters, [this.dataPropName]: oldData } = this.props;
        // Check for updates
        if (
            this.filtersHaveChanged(oldFilters, filters) ||
            this.dataHasChanged(oldData, data) ||
            this.forceRefresh ||
            newType != oldType
        ) {
            // Get the measure unit from the data
            let unitId = this._getMeasureUnitId(data);
            unitId && this.setState({ unitId });

            // Apply filters
            const filteredData = this.filterData(
                data,
                newProps,
                'result',
                'start',
                Object.entries(activityTypeUrls).find((item) => item[1] === newType)
            );
            this.setState({ [this.dataPropName]: filteredData });

            // Generate the chart configuration
            const chart_config = this._generateChartData(filteredData, newProps);
            chart_config.forceRefresh = true;
            this.setState({ chart_config: chart_config });

            // Execute custom callbacks
            if (this.onDataChanged && this.onDataChanged.length) this.onDataChanged.forEach((fn) => fn(filteredData));
            this.forceRefresh = false;
            return filteredData;
        } else {
            return this.state[this.dataPropName];
        }
        this._checkCreateRoute(newProps);
    }

    _generateChartData(data = {}, props = this.props) {
        const { merge_config = {}, ...chart_settings } = this.config;
        const chart_config_steps = generateChartData(data, {
            ...chart_settings,
            descriptor: 'steps',
            name: T.translate('activities.steps'),
        });
        const chart_config_distance = generateChartData(data, {
            ...chart_settings,
            descriptor: 'distance',
            name: T.translate('activities.distance'),
        });
        const chart_config_calories = generateChartData(data, {
            ...chart_settings,
            descriptor: 'calories',
            name: T.translate('activities.calories'),
        });
        const chart_config_velocity = generateChartData(data, {
            ...chart_settings,
            descriptor: 'velocity',
            name: T.translate('activities.velocity'),
        });

        const colors = ['#2185d0', '#a333c8', '#21ba45', '#fbbd08'];
        const chart_config = chart_config_steps;
        chart_config.chart.type = 'column';
        chart_config.colors = colors;
        chart_config.title.text = T.translate('routes.activities');
        chart_config.plotOptions = { column: { groupPadding: 5 } };

        chart_config.series[0].yAxis = 0;
        chart_config_distance.series[0].yAxis = 1;
        chart_config_calories.series[0].yAxis = 2;
        chart_config_velocity.series[0].yAxis = 3;

        chart_config.yAxis.labels = { style: { color: colors[0] } };
        chart_config.yAxis.title = undefined;
        chart_config.yAxis.endOnTick = false;

        chart_config.series.push(chart_config_distance.series[0]);
        chart_config.series.push(chart_config_calories.series[0]);
        chart_config.series.push(chart_config_velocity.series[0]);

        chart_config_distance.yAxis.labels = { style: { color: colors[1] } };
        chart_config_distance.yAxis.title = undefined;
        chart_config_calories.yAxis.labels = { style: { color: colors[2] } };
        chart_config_calories.yAxis.title = undefined;
        chart_config_velocity.yAxis.labels = { style: { color: colors[3] } };
        chart_config_velocity.yAxis.title = undefined;
        chart_config.yAxis = [
            chart_config_steps.yAxis,
            chart_config_distance.yAxis,
            chart_config_calories.yAxis,
            chart_config_velocity.yAxis,
        ];
        return chart_config;
    }

    _filterData(type) {
        let type_id = 'all';
        const typeMap = Object.entries(activityTypeUrls).find((item) => item[1] === type);
        if (!typeMap) {
            type_id = 'all';
        } else {
            type_id = typeMap[0];
        }
        this.setState({
            type_filter: type_id,
            chart_config: { ...this.state.chart_config, forceRefresh: true },
        });
    }

    filterData(data = {}, props = this.props, valueAttr = 'result', dateAttr = 'date', type_filter = ['all', 0]) {
        const _filtered = super.filterData(data, props, valueAttr, dateAttr);
        let filtered = {};
        if (type_filter[0] == 'all') {
            filtered = _filtered;
        } else {
            Object.values(_filtered).forEach((i) => {
                if (i.activityType.id == type_filter[0]) {
                    filtered[i.id] = i;
                }
            });
        }
        return filtered;
    }

    render() {
        const { activities = {}, match, location, history, measureUnits, loading, ...rest } = this.props;
        // const filteredActivities =
        //     this.state.type_filter === "all"
        //         ? Object.entries(activities)
        //         : Object.entries(activities).filter(
        //               ([id, activity]) => activity["activityType"].id === this.state.type_filter
        //           );
        // const data = {};
        // filteredActivities.forEach(([id, act]) => (data[id] = act));

        const filteredActivities =
            this.state.type_filter === 'all'
                ? sortOn(Object.values(activities), '-start')
                : sortOn(Object.values(activities), '-start').filter(
                      (activity) => activity.activityType.id === this.state.type_filter
                  );
        const Menu = (
            <ActivityTypesMenu
                match={match}
                history={history}
                types={activityTypeUrls}
                createActivity={this.createActivity.bind(this)}
            />
        );
        return (
            <WithSideMenu menu={Menu} history={history} match={match}>
                <ContentTitle icon="mib-mono-icon icon-excersise" title={T.translate('measurements.my.my-activities')} />
                <BaseContainer loading={loading}>
                    <ActivityAddPopup open={this.createActivity.bind(this)} activityTypes={this.props.activityTypes} />
                    <ChartEventList title="hello" config={this.state.chart_config} />
                </BaseContainer>
                <BaseContainer loading={loading} style={{ marginTop: '2em' }}>
                    {this.state.type_filter !== 'create' ? (
                        <TimelineEventList
                            sortOn="start"
                            data={filteredActivities}
                            title={this.props.title}
                            timelinePointClick={this.editActivity.bind(this)}
                        />
                    ) : null}
                </BaseContainer>
                <ActivityForm ref={(i) => (this.activityForm = i)} measureUnits={measureUnits} />
            </WithSideMenu>
        );
    }

    createActivity(type) {
        this.activityForm.open(undefined, type);
    }

    editActivity(id) {
        const { activities = {} } = this.props;
        this.activityForm.open(Object.values(activities).find((a) => a.id == id));
    }
}

const mapStateToProps = ({ filters, dataStore, authentication }) => {
    // console.log('hohoh',userSettings);
    const userId = getProperty(authentication, 'id');
    const measureSystemId = getProperty(dataStore, `graph.user-settings.${userId}.measureSystem.id`);

    const allMeasureUnits = getProperty(dataStore, 'graph.measure-units') || {};
    const measureUnits = {};
    Object.entries(allMeasureUnits).forEach(([key, unit]) => {
        if (unit.measureSystem.id == measureSystemId || unit.measureSystem.id == 0) {
            measureUnits[key] = unit;
        }
    });
    return {
        // activityTypes: dataStore["activity-types"] || {},
        activities: getProperty(dataStore, 'graph.activities'),
        activityTypes: getProperty(dataStore, 'graph.activity-types'),
        measureUnits: measureUnits,
        filters: filters,
    };
};

export const ActivitiesPage = subscribeWithLoader(_ActivitiesPage, mapStateToProps, [
    'activity-types?',
    'activities',
    'measure-units?with=measureUnitType',
]);

function ActivityAddPopup({ activityTypes, open }) {
    return (
        <AddTypePopup
            defaultText={T.translate('activities.choose')}
            items={Object.values(activityTypes || {}).map((type) => ({
                key: type.id,
                onClick: () => open(type),
                hoverText: `${T.translate('activities.create')}: ${T.translate(
                    `activities.${activityTypeUrls[type.id]}`
                )}`,
                icon: iconMap[type.id],
            }))}
        />
    );
}
