import { Icon, Menu } from 'semantic-ui-react';
import React, { Component } from 'react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';
import MibIcon from 'Mib/components/generic/Icons';

import { MenuPanel } from 'Mib/components/containers';

export default class ProfileMenu extends Component {
    constructor() {
        super();
        this.items = [
            {
                key: 0,
                as: NavLink,
                to: '/profile/emergency_data/allergies',
                label: (
                    <span>
                        <Icon className="icon-allergy" />
                        <span className="title">{T.translate('routes.health-folder.allergies')}</span>
                    </span>
                ),
            },
            {
                key: 1,
                as: NavLink,
                to: '/profile/emergency_data/medications',
                label: (
                    <span>
                        <Icon className="icon-icon_meds" />
                        {T.translate('user-drugs.user-drugs')}
                    </span>
                ),
            },
        ];
    }

    render() {
        const { history } = this.props;
        const menuTitle = (
            <Menu.Item as={NavLink} to="/profile/emergency_data" exact className="menu-title emergency-title">
                <MibIcon className="mibcolor-emergency" />
                <span className="title">{T.translate('routes.profile.emergency-data')}</span>
            </Menu.Item>
        );

        return <MenuPanel className="red profile-menu mib-menu" inverted title={menuTitle} />;
    }
}
