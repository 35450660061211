import React, { Component } from 'react';
import { Input, Form, Transition, Dropdown, Checkbox } from 'semantic-ui-react';
import classnames from 'classnames';
import T from 'i18n-react';

export class TextInput extends Component {
    constructor() {
        super();
        this.state = {
            value: '',
        };
        this.value = '';
        this.hoho = new Promise((resolve) => {
            this.resolve = resolve;
        });
    }

    render() {
        let { label, value, onChange, className, fieldStyle, required, errors, inline = false, ...other } = this.props;
        const val = 'value' in this.props ? value : this.state.value;
        const hasErrors = Array.isArray(errors) && errors.length;
        label = required ? label + '*' : label;
        return (
            <Form.Field
                inline={inline}
                style={{ position: 'relative', ...fieldStyle }}
                // className="form-input"
                required={required}
            >
                <Input
                    className={classnames('defaultCss', 'material', 'form-input', { error: hasErrors }, className)}
                    value={val}
                    onChange={this.onChange.bind(this)}
                    ref={(i) => (this.input = i)}
                    placeholder={label}
                    {...other}
                />
                <div className={classnames('funky-placeholder', { animePlaceHolder: val != '' && val != null })}>
                    {label}
                </div>
                <Transition.Group animation="slide down">
                    {hasErrors ? errors.map((err) => <p className="input-error-message">{err.message}</p>) : null}
                </Transition.Group>
            </Form.Field>
        );
    }

    onChange(e) {
        if (this.props.replaceComma == true) {
            e.target.value = e.target.value.replace(',', '.');
        }
        this.setState({ value: e.target.value });
        this.value = e.target.value;
        if (this.props.onChange !== undefined) {
            this.props.onChange(e);
        }
    }

    componentDidMount() {
        this.resolve();
    }

    focus() {
        this.input.focus();
    }
}

export class NoteToggleInput extends Component {
    constructor() {
        super();
        this.state = {
            value: '',
        };
        this.value = '';
        this.hoho = new Promise((resolve) => {
            this.resolve = resolve;
        });
    }

    render() {
        let { label, value, onChange, className, fieldStyle, required, errors, inline = false, ...other } = this.props;
        const val = 'value' in this.props ? value : this.state.value;
        label = required ? label + '*' : label;
        return (
            <Form.Field
                inline={inline}
                style={{ position: 'relative', ...fieldStyle }}
                className={classnames('form-input reminderInput', { flagTrue: value })}
                required={required}
            >
                <div style={{ display: 'flex', flex: 1 }}>
                    <span style={{ flex: 1, color: '#2eaadc', fontSize: 'large', fontFamily: 'bold' }}>{label}</span>
                    <label>
                        <Checkbox onChange={this.onChange.bind(this)} toggle checked={value} />
                    </label>
                </div>
            </Form.Field>
        );
    }

    onChange(e, v) {
        this.setState({ value: v.checked });
        this.value = v.checked;
        if (this.props.onChange !== undefined) {
            this.props.onChange({ target: { value: this.value } });
        }
    }

    componentDidMount() {
        this.resolve();
    }

    focus() {
        this.input.focus();
    }
}

export class NoteReminderInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
        this.value = '';

        [this.reminderNumber, this.reminderPeriod] = this.splitInputReminder(props.value);
        this.hoho = new Promise((resolve) => {
            this.resolve = resolve;
        });
    }

    splitInputReminder(reminderValue) {
        let splitReminder, splitedReminderNumber, splitedReminderPeriod;
        if (reminderValue != null) {
            splitReminder = reminderValue.split('|');
            splitedReminderNumber = splitReminder[0];
            splitedReminderPeriod = splitReminder[1];
        }
        return [splitedReminderNumber, splitedReminderPeriod];
    }

    render() {
        let {
            label,
            value,
            onChange,
            fieldName,
            defaultValue,
            className,
            fieldStyle,
            required,
            errors,
            inline = false,
            ...other
        } = this.props;

        const remindPeriods = [
            { key: 0, value: 'm', text: T.translate('notes.minutes') },
            { key: 1, value: 'h', text: T.translate('notes.hours') },
            { key: 2, value: 'd', text: T.translate('notes.days') },
        ];

        let [splitedReminderNumber, splitedReminderPeriod] = this.splitInputReminder(value);
        const hasErrors = Array.isArray(errors) && errors.length;

        return (
            <Form.Field
                inline={inline}
                style={{ position: 'relative', ...fieldStyle }}
                className="form-input reminderInput"
                required={required}
            >
                <div style={{ display: 'flex', flex: 1 }}>
                    <Input
                        style={{ flex: 1 }}
                        className={classnames('defaultCss', 'material', 'form-input', { error: hasErrors }, className)}
                        value={splitedReminderNumber}
                        onChange={this.onInputChange.bind(this)}
                        type="number"
                        ref={(i) => (this.input = i)}
                        {...other}
                    />
                    <span style={{ width: '15%' }} />
                    <Dropdown
                        className={classnames('defaultCss', 'material', { error: hasErrors }, className)}
                        selection
                        onChange={this.onDropDownChange.bind(this)}
                        value={splitedReminderPeriod}
                        options={remindPeriods}
                        ref={(i) => (this.dropdown = i)}
                        {...other}
                    />
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            color: '#2eaadc',
                            fontSize: 'large',
                            fontFamily: 'bold',
                        }}
                    >
                        <span>&nbsp;&nbsp;{T.translate('notes.before')}</span>
                    </span>
                </div>

                <Transition.Group animation="slide down">
                    {hasErrors ? errors.map((err) => <p className="input-error-message">{err.message}</p>) : null}
                </Transition.Group>
            </Form.Field>
        );
    }

    onInputChange(e) {
        this.reminderNumber = e.target.value;
        this.props.onChange({ target: { value: this.reminderNumber + '|' + this.reminderPeriod } });
    }

    onDropDownChange(e, v) {
        this.reminderPeriod = v.value;
        this.props.onChange({ target: { value: this.reminderNumber + '|' + this.reminderPeriod } });
    }

    componentDidMount() {
        this.resolve();
    }

    focus() {
        this.input.focus();
    }
}
