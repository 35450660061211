import React from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { QuickAddPanel } from 'Mib/routes/dashboard/panels/quickadd_panel';
import { getUserMeasureUnit } from './getQuickAddMeasureUnit';

class _TemperatureQuickAdd extends QuickAddPanel {
    constructor(props) {
        super();
        this.state = { value: null, muId: '5' };
        this.link = '/health-folder/temperatures';
        this.title = T.translate('measurements.add.add-temperature');
        this.dataType = 'temperatures';
        this.minimums = {
            5: 33,
            6: 90,
        };
        this.maximums = {
            5: 45,
            6: 113,
        };
        this.steps = {
            5: 0.1,
            6: 1,
        };
    }
}

const mapStateToProps = (state) => {
    const userId = state.dataStore && getProperty(state, 'authentication.id');
    const userSettings = getProperty(state, `dataStore.graph.user-settings.${userId}`);
    const defaultMeasureUnit = userSettings && getUserMeasureUnit(userSettings, 'temperature');

    return {
        data: state.dataStore.graph.temperatures,
        filters: state.filters,
        measureUnitFromSettings: defaultMeasureUnit,
    };
};

export const DashTemperatureQuickAdd = subscribeWithLoader(_TemperatureQuickAdd, mapStateToProps, [
    'temperatures',
    'measure-units?with=measureUnitType',
]);
