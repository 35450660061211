import React, { Component } from 'react';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import { connect } from 'react-redux';
import { store } from 'Mib/datastore/store';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import BaseContainer from 'Mib/components/containers/base';
import { DoubleCharts } from 'Mib/routes/health_indicators/covid/doubleChart';
import { QuestionsContainer } from 'Mib/routes/health_indicators/covid/questionsContainer';

class _CovidIndexPage extends Component {
    constructor() {
        super();
        this.haveAnswers = this.haveAnswers.bind(this);
        this.arriveData = this.arriveData.bind(this);
    }

    openPopUp() {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'CHECKUP-SURVEY',
                survey_id: 'covid19',
            },
        });
    }

    haveAnswers() {
        const currentAnswers =
            this.props.allAnswers &&
            Object.values(this.props.allAnswers).filter(
                (answer) => answer['survey-event-id'] === this.props.currentSurvey.id
            );
        const haveAnswers = currentAnswers.length != 0;
        return haveAnswers;
    }

    arriveData() {
        return this.props.currentSurvey == undefined || this.props.currentSurvey == null ? false : true;
    }

    render() {
        this.arriveData() && !this.haveAnswers() && this.openPopUp();

        return (
            <>
                <DoubleCharts />

                <BaseContainer style={{ marginTop: '10px' }}>
                    <QuestionsContainer />
                </BaseContainer>
            </>
        );
    }
}

const mapStateToProps = ({ dataStore }) => {
    let currentSurvey = getProperty(dataStore.graph, 'person-surveys.covid19');
    let answers = getProperty(dataStore.graph, 'answers');

    return {
        currentSurvey: currentSurvey,
        allAnswers: answers,
    };
};

export const CovidIndexPage = subscribeWithLoader(_CovidIndexPage, mapStateToProps, [
    'person-surveys-by-survey-id/covid19?',
]);

//
// export const CovidIndexPage = connect(mapStateToProps)(_CovidIndexPage)

// export const CovidIndexPage = _CovidIndexPage
