import React, { Component } from 'react';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';

import { connect } from 'react-redux';
import { store } from 'Mib/datastore/store';
import BaseContainer from 'Mib/components/containers/base';
import { CreateButton } from 'Mib/components/inputs/buttons';
import { Message } from 'semantic-ui-react';
import { AnswerTable } from 'Mib/routes/health_indicators/covid/answersTable';

class _AccordionItem extends Component {
    onAddAnswer() {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'SURVEY-QUESTION',
                survey_id: 'covid19',
                question: this.props.question,
                currentSurvey: this.props.currentSurvey,
            },
        });
    }

    render() {
        const { questionHint } = this.props;
        const currentAnswers =
            this.props.allAnswers &&
            this.props.allAnswers.filter(
                (answer) =>
                    answer['survey-event-id'] === this.props.currentSurvey.id &&
                    answer.question_id == this.props.question.question.id
            );
        const haveAnswers = currentAnswers.length != 0;
        return (
            <BaseContainer>
                {questionHint && <Message warning style={{ fontSize: '11px' }} content={questionHint} />}
                <div className="answerTableDiv">
                    {haveAnswers ? (
                        <AnswerTable
                            answers={currentAnswers}
                            questionType={this.props.question.question.type}
                            onAddAnswer={this.onAddAnswer.bind(this)}
                        />
                    ) : (
                        <div className="noAnswerDiv">
                            <Message info content={T.translate('surveys.noAnswerMsg')} />
                            <div>
                                {' '}
                                <CreateButton onClick={this.onAddAnswer.bind(this)} />
                            </div>
                        </div>
                    )}
                </div>
            </BaseContainer>
        );
    }
}

const mapStateToProps = ({ dataStore }) => {
    const answers = getProperty(dataStore.graph, 'answers');
    return {
        allAnswers: answers ? Object.values(answers) : [],
    };
};

export const AccordionItem = connect(mapStateToProps)(_AccordionItem);
