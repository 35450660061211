import React, { Component } from 'react';
import { connect } from 'react-redux';
import nanoid from 'nanoid';
import { getProperty } from 'dot-prop';
import { store } from 'Mib/datastore/store';
import Listeners from './listeners';

class _ChannelControllerClass extends Component {
    constructor() {
        super();
        // All registered listeners, as {channel: {topic: [listener, ...], ...}, ...}
        this.listeners = {};
        // A list of message_ids that have been processed
        this.proccessed_messages = [];
        this.init();
        this.running_instances = 0;
    }

    init() {
        // this.unsubscribe = store.subscribe(this._onMessage.bind(this));
        // ;debugger
        // // Manually proccess existing messages
        // this._onMessage();
        Listeners.forEach(({ channel, topic, listener }) => this.registerListener(channel, topic, listener));
    }

    componentWillReceiveProps(newProps) {
        let { message_queue } = newProps;
        this._onMessage(false, message_queue);
    }

    render() {
        return null;
    }
    /*
    Registers a callback for messages in <channel> for <topic>
    callbacks will be called, passing the message as an argument.
    If any of the callbacks returns true, the message is retained in the queue,
    else it is removed when all listener callbacks return.

    The return value of this function can be used in unregisterListener
    */
    registerListener(channel, topic, callback) {
        const listener_id = nanoid();

        const channelListeners = getProperty(this.listeners, channel, {});
        const topicListeners = getProperty(channelListeners, topic, []);

        this.listeners[channel] = {
            ...channelListeners,
            [topic]: [...topicListeners, { id: listener_id, channel, topic, callback }],
        };
        const force_read_history = true;
        this._onMessage(force_read_history);
        return listener_id;
    }

    /*
    Remove a registered listener
    */
    unregisterListener(listener_id) {
        const listener = this._getListenerById(listener_id);
        if (listener == null) {
            console.warn('Message listener not found: ', listener_id);
            return false;
        }

        const { channel, topic } = listener;

        let topicListeners = getProperty(this.listeners, `${channel}.${topic}`, []);
        topicListeners = topicListeners.filter((l) => l.id !== listener_id);
        this.listeners[channel][topic] = topicListeners;
        return true;
    }

    /*
     * Retreives a listener by its id
     */
    _getListenerById(listener_id) {
        let target;
        Object.values(this.listeners).forEach((topics) =>
            topics.forEach((listener) => {
                if (listener.id === listener_id) {
                    target = listener;
                }
            })
        );
        return target;
    }

    /*
     * Get all messages existing in the redux store that have not been processed
     * and call registered hancdlers. If no handlers have been registered for a
     * specific topic, messages are left in the queue.
     */
    _onMessage(force_read_history = false, message_queue = []) {
        // ;debugger
        // const state = store.getState();
        // const { message_queue } = state;
        const unread_messages =
            message_queue !== undefined
                ? force_read_history
                    ? message_queue
                    : message_queue.filter((msg) => !this.proccessed_messages.includes(msg.id))
                : [];
        // const unread_messages = message_queue
        let shouldKeep = [];

        unread_messages.forEach((msg) => {
            const { channel, topic } = msg;
            this.proccessed_messages.push(msg.id);
            const listeners = getProperty(this.listeners, `${channel}.${topic}`, []);
            if (listeners.length) {
                listeners.forEach((l) => {
                    l.callback(msg) && shouldKeep.push(msg.id);
                });
            } else {
                shouldKeep.push(msg.id);
            }
        });

        unread_messages.forEach(
            (msg) => !shouldKeep.includes(msg.id) && store.dispatch({ type: 'POP_SOCKET_MESSAGE', message_id: msg.id })
        );
    }
}
function mapStateToProps(store) {
    return {
        message_queue: getProperty(store, 'message_queue'),
    };
}

export const ChannelController = connect(mapStateToProps)(_ChannelControllerClass);

export const ChannelReducer = (store = [], action) => {
    switch (action.type) {
        case 'ADD_SOCKET_DATA':
            const dataMeta = {
                id: nanoid(),
                time_received: Date.now(),
                read: false,
                handled: false,
                dataFor: action.dataFor,
                extra_data: {},
            };
            return store.concat([{ ...dataMeta, ...action.payload }]);

        case 'ADD_SOCKET_ANSWER':
            const answerMeta = {
                id: nanoid(),
                time_received: Date.now(),
                read: false,
                handled: false,
                answerFor: action.answerFor,
                extra_data: {},
            };
            return store.concat([{ ...answerMeta, ...action.payload }]);

        case 'ADD_SOCKET_QUESTION':
            const questionMeta = {
                id: nanoid(),
                time_received: Date.now(),
                read: false,
                handled: false,
                questionFor: action.questionFor,
                extra_data: {},
            };
            return store.concat([{ ...questionMeta, ...action.payload }]);
        case 'ADD_SOCKET_MESSAGE':
            // Metadata that accompany each message
            const messageMeta = {
                id: nanoid(),
                time_received: Date.now(),
                read: false,
                handled: false,
                messageFor: action.messageFor,
                extra_data: {},
            };
            return store.concat([{ ...messageMeta, ...action.message }]);
        case 'POP_SOCKET_MESSAGE': {
            const { message_id } = action;
            return store.filter((msg) => msg.id !== message_id);
        }
        case 'MARK_READ': {
            const { message_id, read = true } = action;
            return store.map((msg) => (msg.id === message_id ? { ...message, read: read } : messge));
        }
        default:
            return store;
    }
};
