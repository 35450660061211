import { JsonApiDataStoreModel } from '../vendor/jsonapi-datastore';

/*
 * Serializes a JsonApiDatastoreModel and appends the ``name`` attribute
 * to all tags it contains
 */
export const serializeWithTags = (obj) => {
    // Save the names of the tags
    const tag_names = {};
    if (obj.tags != null) {
        obj.tags.forEach((tag) => {
            if (tag.id == null) tag.id = `_temp_${Math.random()}`;
            tag_names[tag.id] = tag.tag;
        });
    }
    // Serialize the object
    if (typeof obj.serialize !== 'function') {
        const temp = new JsonApiDataStoreModel();
        obj.serialize = temp.serialize;
    }
    const serialized = obj.serialize();
    // Apply the name attribute for the tags on the serialized object
    if (
        serialized.length != 0 &&
        serialized.data.hasOwnProperty('relationships') &&
        serialized.data.relationships.hasOwnProperty('tags')
    ) {
        serialized.data.relationships.tags.data.forEach((tag) => {
            tag.attributes = { tag: tag_names[tag.id] };
            if (tag.id.startsWith('_temp')) {
                delete tag.id;
            }
        });
    }

    if (
        serialized.length != 0 &&
        serialized.data.hasOwnProperty('relationships') &&
        serialized.data.relationships.hasOwnProperty('symptoms')
    ) {
        const serializedSymptoms = obj.symptoms; // obj.symptoms.map((id,symptomId) => {
        //                                  return {'id':id}
        //                               });
        serialized.data.relationships.symptoms = { data: serializedSymptoms };
    }

    return serialized;
};

/**
 * Return the backend end-point for this model
 */
export const modelnameToEndpoint = (modelName) => {
    const mapping = {
        'measure-unit': 'measure-units',
        category: 'categories',
        weights: 'weights',
        allergy: 'allergies',
        tags: 'tags',
        'user-drug': 'user-drugs',
        pressures: 'pressures',
        'person-allergy': 'person-allergies',
        'person-vaccine': 'person-vaccines',
        'person-allergies': 'person-allergies',
        'person-drug': 'person-drugs',
        'person-drug-usages': 'person-drug-usages',
        temperatures: 'temperatures',
        heights: 'heights',
        admission: 'admissions',
        discharge: 'discharges',
        note: 'person-notes',
        activity: 'activities',
        oximetries: 'oximetries',
        documents: 'documents',
        contacts: 'contacts',
        heads: 'head-circumferences',
        'lab-exam-events': 'lab-exam-events',
        providerPermissions: 'provider-permissions',
    };
    return modelName in mapping ? mapping[modelName] : modelName;
};

export const getMeta = (object) => {
    const model = object._type;
    const endPoint = modelnameToEndpoint(model);
    return [model, endPoint];
};
