import { store } from "../datastore/store";

export const logoutAction = async () => {
    store.dispatch({type: 'CLEAR_AUTH_DATA'})
    store.dispatch({type: 'CLEAR_PERSONAL_DATA', purge: true})
    store.dispatch({type: 'CLEAR_SYNC_CACHE'})
    store.dispatch({type: 'CLEAR_LIVE_DATA'})
    window.sessionStorage.removeItem('authData')
    window.sessionStorage.removeItem('persons')
    return true
}
