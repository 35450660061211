/* eslint-disable import/prefer-default-export */
import { Menu } from 'semantic-ui-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import T from 'i18n-react';

import MibIcon from 'Mib/components/generic/Icons';
import { MenuPanel } from 'Mib/components/containers';

function Title() {
    return (
        <Menu.Item as={NavLink} to="/profile/persons/" exact className="menu-title persons-list-title">
            <MibIcon className="mibcolor-person" /> <span className="title">{T.translate('persons.basic-data')}</span>
        </Menu.Item>
    );
}

export function PersonsList({ history }) {
    return (
        <MenuPanel
            className="blue profile-menu mib-menu"
            inverted
            title={<Title />}
            // footer={
            //     <Menu.Item as={NavLink} to="/profile/persons/create" className="menu-footer">
            //         <Icon name="add circle" /> <span className="title">{T.translate('persons.create-person')}</span>
            //     </Menu.Item>
            // }
        />
    );
}
