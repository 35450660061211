import React, { Component } from 'react';
import { Icon, TransitionablePortal, Dimmer, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import classnames from 'classnames';
import T from 'i18n-react';
import sortOn from 'sort-on';
import { v4 as uuidv4 } from 'uuid';

// import { subscribeWithLoader } from 'Mib/lib/subscribe';
import BaseContainer from 'Mib/components/containers/base';

require('./info_modal.scss');

const GET_CONTENTS = (mode, props) => {
    const lang = localStorage.getItem('lang') || 'el';
    switch (mode) {
        case 'WTH':
            return {
                title: T.translate('measurements.wth.title'),
                children: renderWTH(props),
            };
        case 'BMI':
        default:
            return {
                title: T.translate('measurements.bmi.title'),
                children: renderBMI(props),
            };
    }
};
/**
 Parse strings like " > 5" or " 2-3" and determine if `value` is within the interval
 It returns an array of indices indicating which intervals of the `ranks` array
 match.
 If the parameter `chooseColumn` is passed, only matches on the specified column will be returned
* */

const calculateCurrenRank = (ranks, chooseColumn, value) => {
    // console.log('chooseColumn',chooseColumn);
    if (!ranks.length || value == null) return [];
    let matches = [];
    ranks.forEach((row, row_index) => {
        row.forEach((range, col_index) => {
            let check;
            if (range.match(/[<>]/)) {
                const check_string = `${value}${range}`;
                check = eval(check_string);
            } else if (range.match(/\s*\d+\s*-\s*\d+\s*/)) {
                let [min, max] = range.split('-');
                min = Number(min);
                max = Number(max);
                check = value > min && value < max;
            }
            if (check && chooseColumn === col_index) {
                matches.push(`${row_index}-${col_index}`);
            }
        });
    });
    return matches;
};

const renderBMI = ({ lastWTH = {}, lastBMI = {}, gender }) => {
    const Rows = [
        ['< 17.5', '< 17.5'],
        ['17.5 - 19.1', '17.5 - 20.7'],
        ['19.1 - 25.8', '20.7 - 26.4'],
        ['25.9 - 27.3', '26.5 - 27.8'],
        [' 27.4 - 32.3', '27.9 - 31.1'],
        [' 35 - 40', '35 - 40 '],
        [' 40 - 50', '40 - 50 '],
        ['> 50', '> 50'],
    ];
    const gender_column = gender == '1' ? 1 : gender == '2' ? 0 : null;
    const highlighted_rows = calculateCurrenRank(Rows, gender_column, getProperty(lastBMI, 'result'));
    return (
        <>
            <div className="info-modal-text">{T.translate('measurements.bmi.details.before-table')}</div>
            <h3>{lastBMI && `${T.translate('measurements.wth.your-wth')}${lastBMI.result}`}</h3>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '19px 0' }}>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {T.translate('measurements.bmi.details.table.for-women')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>{T.translate('measurements.bmi.details.table.for-men')}</Table.HeaderCell>
                            <Table.HeaderCell>
                                {T.translate('measurements.bmi.details.table.ranks.title')}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {Rows.map((row, index) => (
                            <Table.Row key={uuidv4()}>
                                <Table.Cell
                                    lassName={classnames({ highlight: highlighted_rows.includes(`${index}-0`) })}
                                >
                                    {row[0]}
                                </Table.Cell>
                                <Table.Cell
                                    className={classnames({ highlight: highlighted_rows.includes(`${index}-1`) })}
                                >
                                    {row[1]}
                                </Table.Cell>
                                <Table.Cell>
                                    {T.translate(`measurements.bmi.details.table.ranks.${index + 1}`)}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            <div className="info-modal-text">{T.translate('measurements.bmi.details.after-table')}</div>
        </>
    );
};

const renderWTH = ({ lastWTH, lastBMI, gender }) => {
    const Rows = [
        ['<= 0.34', '<= 0.34', '<= 0.34'],
        ['0.46 - 0.51', '0.43 - 0.52', '0.42 - 0.48'],
        ['0.52 - 0.63', '0.53 - 0.57', '0.49 - 0.53'],
        ['>= 0.64', '0.58 - 0.62', '0.54 - 0.57'],
        ['', '>= 0.63', '>= 0.58'],
    ];
    const gender_column = gender == '1' ? 1 : gender == '2' ? 2 : null;
    const highlighted_rows = calculateCurrenRank(Rows, gender_column, getProperty(lastWTH, 'result'));
    return [
        <div className="info-modal-text">{T.translate('measurements.wth.details.before-table')}</div>,
        <h3>{lastWTH && `${T.translate('measurements.wth.your-wth')}${lastWTH.result}`}</h3>,
        <div style={{ display: 'flex', justifyContent: 'center', margin: '19px 0' }}>
            <Table>
                <Table.Header>
                    <Table.Row key={uuidv4()}>
                        <Table.HeaderCell>{T.translate('measurements.wth.details.table.children')}</Table.HeaderCell>
                        <Table.HeaderCell>{T.translate('measurements.wth.details.table.for-men')}</Table.HeaderCell>
                        <Table.HeaderCell>{T.translate('measurements.wth.details.table.for-women')}</Table.HeaderCell>
                        <Table.HeaderCell>
                            {T.translate('measurements.wth.details.table.categorization')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>{T.translate('measurements.wth.details.table.examples')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Rows.map((row, index) => (
                        <Table.Row key={uuidv4()}>
                            <Table.Cell className={classnames({ highlight: highlighted_rows.includes(`${index}-0`) })}>
                                {row[0]}
                            </Table.Cell>
                            <Table.Cell className={classnames({ highlight: highlighted_rows.includes(`${index}-1`) })}>
                                {row[1]}
                            </Table.Cell>
                            <Table.Cell className={classnames({ highlight: highlighted_rows.includes(`${index}-2`) })}>
                                {row[2]}
                            </Table.Cell>
                            <Table.Cell>
                                {T.translate(`measurements.wth.details.table.ranks.cat_${index + 1}`)}
                            </Table.Cell>
                            <Table.Cell>
                                {T.translate(`measurements.wth.details.table.ranks.ex_${index + 1}`)}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>,
        <div className="info-modal-text">{T.translate('measurements.wth.details.after-table')}</div>,
    ];
};

class _InfoModal extends Component {
    constructor() {
        super();
        this.state = { open: false, mode: 'BMI' };
    }

    open(mode) {
        this.setState({ open: true, mode: mode });
    }

    close() {
        this.setState({ open: false });
    }

    render() {
        const { open, mode } = this.state;
        const { lastWTH, lastBMI, gender } = this.props;

        const { title, children } = GET_CONTENTS(mode, {
            lastWTH,
            lastBMI,
            gender,
        });
        return (
            <>
                <TransitionablePortal
                    closeOnDocumentClick
                    onOpen={() => this.setState({ open: true })}
                    onClose={() => this.setState({ open: false })}
                    open={open}
                >
                    <BaseContainer className="mib-modal modal">
                        <div className="mib-modal-title">
                            {title}
                            <Icon name="x" className="close-button" onClick={this.close.bind(this)} />
                        </div>
                        {children}
                    </BaseContainer>
                </TransitionablePortal>
                <Dimmer active={open} onClickOutside={() => this.setState({ open: false })} page />
            </>
        );
    }
}

const mapStateToProps = ({ dataStore, authentication }) => {
    const wths = getProperty(dataStore, 'graph.wths', {});
    const sortedWTH = sortOn(Object.values(wths), '-date');
    const lastWTH = sortedWTH.length ? sortedWTH[0] : null;

    const bmis = getProperty(dataStore, 'graph.bmis', {});
    const sortedBMI = sortOn(Object.values(bmis), '-date');
    const lastBMI = sortedBMI.length ? sortedBMI[0] : null;

    const activePersonId = getProperty(authentication, 'activePersonId');
    const gender = getProperty(dataStore, `graph.persons.${activePersonId}.gender`);

    return {
        lastWTH,
        lastBMI,
        gender,
    };
};

export const InfoModal = connect(mapStateToProps, null, null, { forwardRef: true })(_InfoModal); /* [
export const InfoModal = subscribeWithLoader(_InfoModal, mapStateToProps, [
  "wths",
  "bmis"
], true);
*/
