import { getProperty } from 'dot-prop';
import { store } from 'Mib/datastore/store';

const filterByMeasureSystem = (allMeasureUnits = {}) => {
    const userId = getProperty(store.getState().authentication, 'id');

    const measureSystemId = getProperty(store.getState().dataStore, `graph.user-settings.${userId}.measureSystem.id`);

    const measureUnits = {};

    Object.entries(allMeasureUnits).forEach(([key, unit]) => {
        if (unit.measureSystem && (unit.measureSystem.id == measureSystemId || unit.measureSystem.id == 0)) {
            measureUnits[key] = unit;
        }
    });
    return measureUnits;
};

export default filterByMeasureSystem;
