import React from 'react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

import { MenuPanel } from 'Mib/components/containers';

function ProvidersMenu({ match, history, types = {} }) {
    const menuItems = [
        {
            key: 0,
            as: NavLink,
            to: '/connections/access-provision',
            label: T.translate('providers.access-provision'),
        },
        {
            key: 1,
            as: NavLink,
            to: `/connections/health-providers`,
            label: T.translate('providers.health-providers'),
        },
        {
            key: 2,
            as: NavLink,
            to: '/connections/lab-connections',
            label: T.translate('providers.connect-to-medical-lab'),
        },
    ];

    return (
        <MenuPanel
            title={
                <Menu.Item className="menu-title" as={NavLink} to="/connections/health-providers" exact>
                    <Icon className="doctor" />
                    {T.translate('routes.providers')}
                </Menu.Item>
            }
            // className="bg-blue"
            className="mib-menu yellow providers-menu"
            items={menuItems}
        />
    );
}

export default ProvidersMenu;
