import React, { Component } from 'react';
import { Icon, Header, Segment, Popup } from 'semantic-ui-react';
import T from 'i18n-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import BaseContainer from './base';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { getProperty } from 'dot-prop';

require('./quick_info_panels.scss');

class _QuickInfoPanel2 extends Component {
    constructor() {
        super();
    }

    render() {
        let { title, footer, iconName, date, value, altText, loading, backgroundColor, target } = this.props;
        const quickInfoCircle = {
            cursor: 'pointer',
            width: '120',
            height: '75',
            backgroundColor: backgroundColor,
            color: 'white',
        };

        loading = loading === undefined ? value === undefined && !altText : loading;
        return (
            <BaseContainer className="quick-info-container" loading={loading}>
                <h3 className="quick-info-title">{title}</h3>
                <div className="quick-info-subtitle">{footer}</div>
                {value != null ? (
                    <Segment
                        onClick={() => this.onClickInfo(target)}
                        style={quickInfoCircle}
                        circular
                        className="quick-info-circle"
                    >
                        <Header style={{ color: 'white' }} as="h3">
                            {value}
                        </Header>
                    </Segment>
                ) : (
                    <Popup
                        trigger={
                            <Segment
                                onClick={() => this.onClickInfo(target)}
                                style={quickInfoCircle}
                                circular
                                className="quick-info-circle"
                            >
                                <Header style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
                                    <Icon style={{ margin: 0 }} name="help" />
                                </Header>
                            </Segment>
                        }
                        content={T.translate('no-data')}
                        size="large"
                    />
                )}
                {iconName !== undefined ? (
                    <div className="quick-info-icon">
                        <Icon style={{ marginBottom: '5px' }} name={iconName} size="big" />{' '}
                    </div>
                ) : null}
                <div className="quick-info-subtitle">{date}</div>
            </BaseContainer>
        );
    }

    onClickInfo(target) {
        this.props.history.push(target);
    }
}

const FromMeasurement = ({ measurement, editAction, onClick, loading, title, ...rest }) => {
    let displayedValue = '?';
    if (measurement != null) {
        if (getProperty(measurement, 'result') != null) {
            displayedValue = `${getProperty(measurement, 'result', '?')} ${getProperty(
                measurement,
                'measureUnit.abbreviation'
            )}`;
        }
        if (getProperty(measurement, 'systolic') != null) {
            displayedValue = `${getProperty(measurement, 'systolic', '?')}/${getProperty(
                measurement,
                'diastolic',
                '?'
            )} ${getProperty(measurement, 'measureUnit.abbreviation')}`;
        }
    }
    title = title ? title : measurement ? T.translate(`measurements.singular.${measurement._type}`) : '';
    return (
        <QuickInfoPanel2
            title={title}
            value={displayedValue}
            loading={loading}
            subTitle={measurement ? moment(getProperty(measurement, 'date')).format('L') : ''}
            {...rest}
        />
    );
};

_QuickInfoPanel2.FromMeasurement = FromMeasurement;

export const QuickInfoPanel2 = withRouter(_QuickInfoPanel2);
