import React, { Component } from "react";
import Dropzone from "react-dropzone";
import T from "i18n-react";
import { Icon,Transition, Progress } from "semantic-ui-react";
import classnames from "classnames";
import { humanFileSize } from "../../lib/conversions";

export class DocumentInput extends React.Component {
    constructor() {
        super();
        this.state = {
            accept: "",
            files: [],
            dropzoneActive: false,
        };
    }

    onDragEnter() {
        this.setState({
            dropzoneActive: true,
        });
    }

    onDragLeave() {
        this.setState({
            dropzoneActive: false,
        });
    }

    onDrop(files) {
        const {onChange} = this.props;
        this.setState({
            files,
            dropzoneActive: false,
        });
        if (onChange){
            onChange(files)
        }
    }

    // _renderThumbnail(file){
    //     switch(file.type){

    //     }
    // }

    render() {
        let {errors,value, fileUploading, edit} = this.props;
        const { accept, files, dropzoneActive } = this.state;
        console.log('edit at file upload' , edit)
        console.log('props at file upload' , this.props)
        const overlayStyle = {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            padding: "2.5em 0",
            background: "rgba(0,0,0,0.5)",
            textAlign: "center",
            color: "#fff",
        };
         const hasErrors = Array.isArray(errors) && errors.length;
        return (
            <div className="mib-fileupload">
                <Dropzone
                    className="mib-fileupload-input"
                    // style={{ position: "relative", border: '3px dashed rgba(0, 0, 0, 0.5)' }}
                    // accept={accept}
                    onDrop={this.onDrop.bind(this)}
                    onDragEnter={this.onDragEnter.bind(this)}
                    onDragLeave={this.onDragLeave.bind(this)}
                >
                    {dropzoneActive && <div style={overlayStyle}>Drop files...</div>}
                    {edit ? <p>{T.translate("meta.edit-drop-files-here")}</p> : <p>{T.translate("meta.drop-files-here")}</p>}
                </Dropzone>
                { files.length ? <h2 className="mib-fileupload-files-title">{T.translate("selected-files")}</h2> : value }
                <ul className="mib-fileupload-file-list">
                    {files.map(f => (
                        <li className="mib-fileupload-file-item">
                          {f.type.indexOf("image") == 0 ? (<img src={f.preview} height="100" />) : (<Icon name="file outline" style={{fontSize:'70px'}} />)}
                            <span className="file-name">{f.name}</span>
                            <span className="file-size">{humanFileSize(f.size)}</span>
                            <div className="file-remove-icon" onClick={()=>this.removeFile(f)}><Icon name="trash"/></div>
                        </li>
                    ))}
                </ul>
                { fileUploading && <Progress size='tiny' color='blue' percent={100} active/> }
                <Transition.Group animation="slide down">
                    {hasErrors ? errors.map(err => <p className="input-error-message">{err.message}</p>) : null}
                </Transition.Group>
            </div>
        );
    }

    removeFile(file){
        const {files} = this.state;
        this.setState({files: files.filter( f => f.name != file.name && f.lastModified != file.lastModified)})
    }
}
