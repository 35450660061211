import { Icon, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import React from 'react';
import T from 'i18n-react';

import { MenuPanel } from 'Mib/components/containers';

const ConnectionsMenu = ({ match, history }) => {
    const menuItems = [
        {
            key: 0,
            as: NavLink,
            to: `/connections/devices_apps/withings`,
            label: 'Withings',
            label: (
                <span>
                    <Icon className="icon-withings" />
                    Withings
                </span>
            ),
        },
        {
            key: 1,
            as: NavLink,
            to: '/connections/devices_apps/med4u',
            label: (
                <span>
                    <Icon className="plus circular" />
                    med4U
                </span>
            ),
        },
    ];

    return (
        <MenuPanel
            title={
                <Menu.Item className="menu-title" as={NavLink} to="/connections/devices_apps" exact>
                    <Icon className="share alternate" />
                    {T.translate('connections.connections')}
                </Menu.Item>
            }
            // className="bg-blue"
            className="mib-menu teal connections-menu"
            items={menuItems}
        />
    );
};

export default ConnectionsMenu;
