import React, { Component } from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import { v4 as uuidv4 } from 'uuid';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { ContentTitle } from 'Mib/components/generic/content_title';
import { PanelDropdownInput } from 'Mib/components/inputs/panel_inputs';
import { shouldRenderFeature } from 'Mib/lib/feature_toggle';

import { labIcons } from './lab-icons';

function PanelTitle({ icon, name }) {
    return (
        <span>
            <span
                className={icon}
                style={{ paddingRight: '0.5em', color: '#2eaadc', fontSize: '1.6em', verticalAlign: 'middle' }}
            />
            <span style={{ verticalAlign: 'middle' }}>{name}</span>
        </span>
    );
}

class LabExamIndexPage extends Component {
    getLatestLabExamEvent(labId) {
        const { labExamEvents = {} } = this.props;
        const events = Object.values(labExamEvents).filter((l) => l.labExam.id === labId);
        return events.length ? events[0] : null;
    }

    render() {
        const { labGroups = {}, labs = {} } = this.props;

        // FEATURE TOGGLE
        const showLabCategories = shouldRenderFeature('lab_categories') ? 'block' : 'none';

        // Find the labs that don't belong in a category
        const inGroup = [];
        Object.values(labGroups).forEach((lg) => lg.labExams.forEach((lab) => inGroup.push(lab.id)));
        const groups = {
            ...labGroups,
            other: {
                name: T.translate('other'),
                labExams: Object.values(labs).filter((lab) => !inGroup.includes(lab.id)),
            },
        };

        return (
            <div className="content-container">
                {Object.values(groups).map((group) => {
                    if (!group.labExams.length) {
                        return null;
                    }
                    const icon = group.icon ? `mib-lab ${group.icon}` : 'mib-lab miblab-body category-icon';
                    let labExamsWithEvent = [];
                    labExamsWithEvent = group.labExams
                        .map((labExam) => {
                            const icon = getProperty(labIcons, labExam.id, 'icon question circle outline');
                            const latestEvent = this.getLatestLabExamEvent(labExam.id);
                            return latestEvent ? { event: latestEvent, icon: icon, name: labExam.name } : null;
                        })
                        .filter((n) => n !== null);
                    if (labExamsWithEvent.length === 0) {
                        return null;
                    }

                    return (
                        <div key={uuidv4()}>
                            <ContentTitle
                                title={group.name}
                                icon={icon}
                                style={{
                                    color: '#2eaadc',
                                    borderBottom: '1px solid black',
                                    width: '100%',
                                    display: showLabCategories,
                                }}
                            />
                            <div className="person-panel-form-container">
                                {labExamsWithEvent.map((lab) => {
                                    const { icon, name, event } = lab;

                                    return (
                                        <div key={uuidv4()}>
                                            {event ? (
                                                <PanelDropdownInput
                                                    value={1}
                                                    radiusLarge={110}
                                                    label={<PanelTitle icon={icon} name={name} />}
                                                    options={[
                                                        {
                                                            key: 1,
                                                            text: `${event.result} ${event.measureUnit.abbreviation}`,
                                                            value: 1,
                                                        },
                                                    ]}
                                                    lockable
                                                    hideLockButton
                                                />
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        labs: state.dataStore.graph['lab-exams'],
        labGroups: state.dataStore.graph['lab-groups'],
        labExamEvents: state.dataStore.graph['lab-exam-events'],
    };
};

export default subscribeWithLoader(LabExamIndexPage, mapStateToProps, [
    'lab-exam-events',
    'lab-exams?',
    'lab-groups?with=labExams',
]);
