import React from 'react';
import classnames from 'classnames';
import { Segment, Message, Icon, Transition } from 'semantic-ui-react';
import T from 'i18n-react';

function DashboardSettings({ active = false, toggle }) {
    return (
        <Transition visible={active} unmountOnHide animation="fade left">
            <Segment className={classnames('dash-settings-container', 'active')}>
                <Icon name="angle double right" onClick={toggle} style={{ cursor: 'pointer' }} />
                <Message info>
                    <Icon name="info circle" />
                    {T.translate('dashboard.widgets.help')}
                </Message>
                <DraggableComponent
                    text={T.translate('dashboard.widgets.weight-graph')}
                    Component="DashWeightChart"
                    color="green"
                    iconName="icon-scale"
                    type="graph"
                />
                <DraggableComponent
                    text={T.translate('dashboard.widgets.height-graph')}
                    Component="DashHeightChart"
                    color="blue"
                    iconName="icon-height"
                    type="graph"
                />
                <DraggableComponent
                    text={T.translate('dashboard.widgets.pressure-graph')}
                    Component="DashPressureChart"
                    color="olive"
                    iconName="icon-heartbeat"
                    type="graph"
                />
                <DraggableComponent
                    text={T.translate('dashboard.widgets.oximetry-graph')}
                    Component="DashOximetryChart"
                    color="orange"
                    iconName="icon-oxygen"
                    type="graph"
                />
                <DraggableComponent
                    text={T.translate('dashboard.widgets.temperature-graph')}
                    Component="DashTemperatureChart"
                    color="purple"
                    iconName="icon-thermometer"
                    type="graph"
                />
                <DraggableComponent
                    text={T.translate('dashboard.widgets.weight-quickadd')}
                    Component="DashWeightQuickAdd"
                    color="green"
                    iconName="icon-scale"
                    type="quickadd"
                />
                <DraggableComponent
                    text={T.translate('dashboard.widgets.height-quickadd')}
                    Component="DashHeightQuickAdd"
                    color="blue"
                    iconName="icon-height"
                    type="quickadd"
                />
                <DraggableComponent
                    text={T.translate('dashboard.widgets.temperature-quickadd')}
                    Component="DashTemperatureQuickAdd"
                    color="purple"
                    iconName="icon-thermometer"
                    type="quickadd"
                />
                <DraggableComponent
                    text={T.translate('dashboard.widgets.pressure-quickadd')}
                    Component="DashPressureQuickAdd"
                    color="olive"
                    iconName="icon-heartbeat"
                    type="quickadd"
                />
            </Segment>
        </Transition>
    );
}

function DraggableComponent({ text, Component, color, iconName, type }) {
    return (
        <Segment
            color={color}
            draggable="true"
            className="draggable-widget"
            onDragStart={(e) => {
                e.dataTransfer.setData('component', Component);
            }}
        >
            {type == 'graph' ? (
                <Icon size="large" name="area graph" style={{ marginRight: '1.6em', float: 'left' }} color={color} />
            ) : (
                <Icon.Group size="large" style={{ marginRight: '1.6em' }}>
                    <Icon name="add square" style={{ float: 'left' }} color={color} />
                    <Icon name="lightning" style={{ color: '#f2711c', fontSize: '0.6em' }} corner />
                </Icon.Group>
            )}
            {text}
            {iconName && <Icon className={iconName} style={{ float: 'right' }} />}
        </Segment>
    );
}

export default DashboardSettings;
