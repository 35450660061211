import React, { Component, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { getProperty } from 'dot-prop';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import TagPanel from 'Mib/components/containers/tag_panel';
import WithSideMenu from 'Mib/components/containers/with_side_menu';
import PersonDocuments from 'Mib/components/metric_panels/person_document';

const DocumentMenu = React.lazy(() => import('./documents_menu'));

class _DocumentsPage extends Component {
    componentDidMount() {
        this.redirect();
    }

    redirect() {
        const { type } = this.props.match.params;
        const { history } = this.props;
        if (!type) {
            history.replace('/documents/person_documents');
        }
    }

    render() {
        const { match, history, loading, tagFilters, syncing } = this.props;
        const childProps = { tagFilters, loading, syncing };
        return (
            <Suspense fallback="loading...">
                <WithSideMenu history={history} match={match} menuItems={[DocumentMenu, TagPanel]}>
                    <Route path={`${match.url}/person_documents`} render={() => <PersonDocuments {...childProps} />} />
                </WithSideMenu>
            </Suspense>
        );
    }
}

const mapStateToProps = ({ authentication: { activePersonId }, dataStore, userSettings, appState }) => {
    const personSpecificSettings = getProperty(dataStore.graph, `person-settings.${activePersonId}`) || {};

    return {
        personSettings: personSpecificSettings,

        tagFilters: appState.tagFilters,
    };
};

export const DocumentPage = subscribeWithLoader(_DocumentsPage, mapStateToProps, ['person-settings', 'person-info']);
