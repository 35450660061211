import React, { Component } from 'react';

import { Icon, Menu } from 'semantic-ui-react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';

import { store } from 'Mib/datastore/store';
import { MenuPanel } from 'Mib/components/containers';

export default class TutorialsMenu extends Component {
    constructor(props) {
        super();
        this.generateMenuItems(props);
    }

    onOpenCheckUp() {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'CHECKUP-SURVEY',
                survey_id: 1,
            },
        });
    }

    onOpenTutorial() {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'CHECKUP-SURVEY',
                survey_id: 3,
            },
        });
    }

    generateMenuItems(props) {
        const { match } = props;
        const url = match.url;

        this.menuItems = [
            {
                key: 0,
                as: 'div',
                className: 'mib-menu-item_as-div',
                onClick: this.onOpenCheckUp,
                label: (
                    <>
                        <img
                            style={{ width: '9%', marginRight: '7px', cursor: 'pointer' }}
                            src="/assets/img/connections/check_list.png"
                        />
                        <span>{T.translate('CheckUp')}</span>
                    </>
                ),
            },
            {
                key: 2,
                as: NavLink,
                className: 'mib-menu-item_as-div',
                to: `${url}/covid19`,
                label: (
                    <>
                        <img
                            style={{ width: '9%', marginRight: '7px', cursor: 'pointer' }}
                            src="/assets/img/connections/covidGray.svg"
                        />
                        <span>{T.translate('Covid-19')}</span>
                    </>
                ),
            },
            {
                key: 1,
                as: 'div',
                className: 'mib-menu-item_as-div',
                onClick: this.onOpenTutorial,
                label: (
                    <>
                        <img
                            style={{ width: '9%', marginRight: '7px', cursor: 'pointer' }}
                            src="/assets/img/connections/wizard2.png"
                        />
                        <span>{T.translate('Wizard')}</span>
                    </>
                ),
            },
        ];
    }

    render() {
        // const { history } = this.props;
        const menuTitle = (
            <Menu.Item className="menu-title" as={NavLink} to="/health-indicators/tutorials" exact>
                <Icon className="magic" />
                {T.translate('routes.health-indicators.checkup-menu-title')}
            </Menu.Item>
        );

        return <MenuPanel className="blue profile-menu mib-menu" inverted title={menuTitle} items={this.menuItems} />;
    }
}
