import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import { TransitionablePortal, Icon, Dimmer } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import { fetchAction } from 'Mib/actions/backend';
import { MibButton } from 'Mib/components/inputs';
import { store } from 'Mib/datastore/store';
import { JsonApiDataStore, JsonApiDataStoreModel } from 'Mib/vendor/jsonapi-datastore';
import { serializeWithTags } from 'Mib/lib/json-api';
import { relToResource } from 'Mib/lib/conversions';
import { Fetch } from 'Mib/lib/fetch';
import * as models from 'Mib/lib/models';
import * as modals from 'Mib/actions/modals';
import { mapEntityToFrontEndModels, mapEntityToBackendModels } from 'Mib/lib/conversions';
import CloseSurveyMsg from 'Mib/components/medi/surveys/close_survey_msg';
import BaseContainer from 'Mib/components/containers/base';

import './surveys.scss';

const FullMedi = React.lazy(() => import('./full_medi'));
const TwoPanelSurvey = React.lazy(() => import('./two_panels'));

const mapModals = (entity) => {
    const entityMappings = {
        drugs: 'openDrugsModal',
        allergies: 'openAllergyModal',
    };
    return entityMappings[entity] || entity;
};

const getCurrentGroupIndex = (questionGroups, currentQuestionGroupPosition) => {
    return questionGroups && questionGroups.findIndex((qg) => qg.position === currentQuestionGroupPosition);
};

class SurveyContainer extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            currentQuestionGroupPosition: 0,
            survey_id: null,
            currentSurvey: {},
            answers: [],
            showIntro: true,
        };
        this.closeAndSave = this.closeAndSave.bind(this);
        this.onSurveyChange = this.onSurveyChange.bind(this);
        this.goToGroup = this.goToGroup.bind(this);
        this.saveSurvey = this.saveSurvey.bind(this);
        this.nextGroup = this.nextGroup.bind(this);
        this.prevGroup = this.prevGroup.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.shouldFetchData = false;
    }

    // componentDidMount() {}

    async componentWillReceiveProps(newProps) {
        // const newState = {};
        // const {currentSurvey } = oldState;
        let { survey_id, currentsurvey } = this.state;
        const { signals = [], signalType, signalProccessed } = newProps;
        const relevantSignals = signals.filter((s) => s.signalType === signalType);
        // const answers = [];

        if (relevantSignals.length) {
            const signal = relevantSignals[0];
            const { survey_id } = signal;
            signalProccessed(signal);
            this.shouldFetchData = true;
            this.setState({ isOpen: true, survey_id, signal });
            // this,setState({newState});
        }

        if (this.shouldFetchData && survey_id) {
            this.shouldFetchData = false;
            const answers = [];
            const state = store.getState();
            const activePersonId = getProperty(state, 'authentication.activePersonId');

            // signalProccessed(prevState.signal);
            const repliedSurvey = await Fetch(
                `persons/${activePersonId}/person-surveys-by-survey-id/${survey_id}/`,
                {},
                'api'
            );

            const apiStore = new JsonApiDataStore();
            const currentSurvey = apiStore.sync(repliedSurvey);

            const firstGroup = currentSurvey.survey.surveyQuestions.reduce(
                (total, q) => (q.questionGroup.position < total ? q.questionGroup.position : total),
                9999
            );

            const { options } = currentSurvey.survey;
            // this is where the user's previous answers are added to the surveys
            if (options.show_last) {
                Object.values(getProperty(currentSurvey, `answers`)).forEach((val) => {
                    if (val.question.type === 4) {
                        const store = new JsonApiDataStore();
                        if (!(val.value instanceof JsonApiDataStoreModel)) {
                            const model = store.sync(val.value);
                            val.value = model;
                        }
                    }
                    answers[val.question_id] = val;
                });
            }
            // if(val.question.type == 5)
            // {
            //           let store = new JsonApiDataStore();
            //           if(!(val.value.model instanceof JsonApiDataStoreModel) && (val.value.model!=null))
            //           {
            //              let  model = store.sync(val.value);
            //              val.value.model = model;
            //           }

            // }

            this.setState({
                isOpen: true,
                currentSurvey,
                survey_id: null,
                showIntro: true,
                answers,
                currentQuestionGroupPosition: firstGroup,
            });
        }
    }

    /* *
     * Parse the survey's questions and question groups
     * and split them into pages
     * */
    splitSurveyToPages(survey) {}

    calcSurveyProgress() {
        const { currentSurvey, currentQuestionGroupPosition, answers } = this.state;

        const { questionGroups } = currentSurvey.survey;
        // console.log('PRoPS', this.props);
        // console.log('QUESTION GROUPS ', questionGroups);
        return questionGroups.map((qg, index) => {
            const currentIndex = getCurrentGroupIndex(questionGroups, currentQuestionGroupPosition);

            if (currentQuestionGroupPosition == qg.position) return { active: true };
            if (index > currentIndex) return {};
            if (
                currentSurvey.survey.surveyQuestions.some(
                    (q) => q.questionGroup.position === qg.position && answers[q.question.id] == null
                )
            ) {
                return { incomplete: true };
            }
            return { complete: true };
        });
    }

    open() {
        this.setState({ isOpen: true });
    }

    close() {
        this.handleResetSurvey();
    }

    handleResetSurvey() {
        this.setState({
            isOpen: false,
            currentQuestionGroupPosition: 0,
            survey_id: null,
            currentSurvey: {},
            answers: [],
            showIntro: true,
        });
    }

    async closeAndSave(confirm = true) {
        this.setState({
            isOpen: false,
        });
        let confirmation = confirm ? this.closeSurveyMsg.open() : Promise.resolve();
        try {
            await confirmation;
        } catch (e) {
            this.handleResetSurvey();
            return false;
        }
        await this.saveSurvey();
        this.handleResetSurvey();
    }

    async saveSurvey() {
        const answersPayload = [];
        const entities = [];

        console.log('answers  in save normal Survey', this.state.answers);
        const { answers } = this.state;
        Object.values(answers).map(async (answer) => {
            if (answer) {
                if (answer.question.entity) {
                    entities.push(mapEntityToBackendModels(answer.question.entity));
                }
                const clonedAnswer = cloneDeep(answer);
                switch (clonedAnswer.question.type) {
                    case 4:
                        clonedAnswer.value = serializeWithTags(clonedAnswer.value);
                        break;
                    case 5:
                        if (clonedAnswer.value.model && clonedAnswer.value.id === 1) {
                            const { description } = clonedAnswer.question;
                            let { entity } = clonedAnswer.question;
                            let modelAttributes = description;
                            modelAttributes = Object.values(modelAttributes);
                            entity = mapEntityToFrontEndModels(entity);

                            const model = models[entity];

                            clonedAnswer.value.model = entity.includes('asynced')
                                ? await model(...modelAttributes)
                                : model(...modelAttributes);
                        }
                        clonedAnswer.value.model =
                            clonedAnswer.value.id === 1
                                ? (clonedAnswer.value.model = serializeWithTags(clonedAnswer.value.model))
                                : null;
                        break;
                    default:
                        break;
                }
                clonedAnswer.date = new Date();
                answersPayload.push(serializeWithTags(clonedAnswer));
            }
        });
        // for (const answer of this.state.answers) {
        //     if (answer) {
        //         if (answer.question.entity) {
        //             entities.push(mapEntityToBackendModels(answer.question.entity));
        //         }
        //         switch (answer.question.type) {
        //             case 4:
        //                 answer.value = serializeWithTags(answer.value);
        //                 break;
        //             case 5:
        //                 if (answer.value.model && answer.value.id == 1) {
        //                     let { description, entity } = answer.question;
        //                     let modelAttributes = description;
        //                     modelAttributes = Object.values(modelAttributes);
        //                     entity = mapEntityToFrontEndModels(entity);

        //                     const model = models[entity];

        //                     answer.value.model = entity.includes('asynced')
        //                         ? await model(...modelAttributes)
        //                         : model(...modelAttributes);
        //                 }
        //                 answer.value.model =
        //                     answer.value.id == 1 ? (answer.value.model = serializeWithTags(answer.value.model)) : null;
        //                 break;
        //         }
        //         answer.date = new Date();
        //         answersPayload.push(serializeWithTags(answer));
        //     }
        // }
        // if (answerspayload.length == 0){
        //     this.close(false)
        //     return false
        // }

        // answer&&(answer.value=answer.question.type==4?serializeWithTags(answer.value):answer.value); answersPayload.push(serializeWithTags(answer)) } )
        const { activePersonId } = this.props;
        let personSurvey = await Fetch(
            `persons/${activePersonId}/person-answers`,
            { method: 'POST', body: JSON.stringify({ data: answersPayload }) },
            'api'
        )
            .then((result) => {
                store.dispatch({ type: 'SET_DATA', payload: result });
            })
            .catch(async (e) => {
                if (e.status == 422) {
                    throw await e.json();
                }
            });

        entities.forEach((entity) => {
            fetchAction(entity, { force: true, replace: true });
        });

        // this.close(false)
    }

    nextGroup() {
        const { currentQuestionGroupPosition, currentSurvey, showIntro } = this.state;
        const { questionGroups } = currentSurvey.survey; //TODO

        const currentIndex = getCurrentGroupIndex(questionGroups, currentQuestionGroupPosition);
        if (showIntro) {
            this.setState({
                showIntro: false,
            });
            return 0;
        } else if (questionGroups.length > currentIndex + 1) {
            this.setState({
                currentQuestionGroupPosition: questionGroups[currentIndex + 1].position,
                showIntro: false,
            });
        }
    }

    prevGroup() {
        const { currentQuestionGroupPosition, currentSurvey } = this.state;
        const { questionGroups } = currentSurvey.survey; //TODO
        const currentIndex = getCurrentGroupIndex(questionGroups, currentQuestionGroupPosition);
        if (currentIndex > 0) {
            this.setState({
                currentQuestionGroupPosition: questionGroups[currentIndex - 1].position,
            });
        } else {
            this.setState({
                showIntro: true,
            });
        }
    }

    goToGroup(groupIndex) {
        const { questionGroups } = this.state.currentsurvey.survey; //TODO
        if (groupIndex in questionGroups) {
            this.setState({
                currentQuestionGroupPosition: questionGroups[groupIndex].position,
            });
        }
    }

    async onSurveyChange(question, answer, evnt, field = 'result', dropDownVal = null) {
        const question_id = question.id;

        const { dataStore } = store.getState();
        const { answers } = this.state;
        // debugger;
        const clonedAnswers = cloneDeep(answers);

        switch (question.type) {
            case 2:
                answer.value.id = evnt;
                break;
            case 4:
                const valuesOfTextInputsAndDropDowns = dropDownVal ? dropDownVal.value : evnt.target.value;
                if (answer.value._relationships.includes(field)) {
                    const relation = getProperty(
                        dataStore,
                        `graph.${relToResource(field)}.${valuesOfTextInputsAndDropDowns}`
                    );
                    answer.value.setRelationship(field, relation);
                } else {
                    answer.value.setAttribute(field, evnt.target.value);
                }
                break;
            case 5:
                let { entity } = question;

                if (evnt === 1) {
                    answer.value = { id: evnt, model: entity };
                } else answer.value = { id: evnt };
                break;

            case 6:
                entity = question.entity;
                entity = mapModals(entity);
                const modal = modals[entity];
                modal();

                break;
            case 7:
                debugger;
                answer.value = {"value":evnt};
                break;
              

            case 8:
                if (field === 'remarks') {
                    answer.remarks = evnt.target.value;
                } else {
                    answer.value = evnt;
                }

                break;
            default:
                break;
            case 9:
                answer.value = {"value_date":moment(evnt.value)};
        }
        clonedAnswers[question_id] = answer;
        this.setState({ answers: clonedAnswers });
    }

    render() {
        const { isOpen, currentSurvey, currentQuestionGroupPosition, answers, showIntro } = this.state;
        const { activePersonId } = this.props;

        const questionGroups = getProperty(currentSurvey, 'survey.questionGroups') || [];
        const { name } = currentSurvey;
        const surveyQuestions = getProperty(currentSurvey, 'survey.surveyQuestions');
        let questions = [];
        if (Object.values(currentSurvey).length)
            questions = surveyQuestions.filter((q) => q.questionGroup.position === currentQuestionGroupPosition);

        const currentIndex = getCurrentGroupIndex(questionGroups, currentQuestionGroupPosition);

        const isFirst = currentIndex === 0;
        const isLast = currentIndex === questionGroups.length - 1;

        const { introText, description } = getProperty(currentSurvey, 'survey', {});

        let errorWithQuestions = false;
        // questions.map((q) => (errorWithQuestions = q.question.text === '' || q.question.predefinedAnswers === null));

        return (
            <Suspense fallback="loading...">
                <TransitionablePortal onOpen={this.open} open={isOpen}>
                    <BaseContainer
                        className="mib-modal modal survey-container"
                        style={{
                            top: '40px',
                            overflowY: 'auto',
                            maxHeight: 'calc(100vh - 100px)',
                        }}
                    >
                        <div className="mib-modal-title" style={{ background: 'none' }}>
                            {name}
                            <Icon
                                name="x"
                                className="close-button"
                                onClick={this.closeAndSave}
                                style={{ color: 'black' }}
                            />
                        </div>

                        {!!showIntro && (
                            <FullMedi
                                goNext={this.nextGroup}
                                saveSurvey={this.saveSurvey}
                                close={this.close}
                                introText={introText}
                                description={description}
                            />
                        )}

                        {!showIntro && errorWithQuestions && (
                            <div style={{ display: 'grid', justifyContent: 'center' }}>
                                <h2>{T.translate('surveys.no-questions')}</h2>
                                <MibButton
                                    key="1"
                                    onClick={this.close}
                                    label={<span style={{ width: '100%' }}>{T.translate('surveys.close-guide')}</span>}
                                    className="teal"
                                />
                            </div>
                        )}

                        {!showIntro && !errorWithQuestions && (
                            <>
                                <TwoPanelSurvey
                                    questions={questions}
                                    progress={this.calcSurveyProgress()}
                                    onChange={this.onSurveyChange.bind(this)}
                                    answers={answers}
                                    goToGroup={this.goToGroup}
                                    currentSurvey={currentSurvey}
                                />
                                <SurveyControls
                                    isFirst={isFirst}
                                    isLast={isLast}
                                    nextGroup={this.nextGroup}
                                    prevGroup={this.prevGroup}
                                    saveSurvey={this.closeAndSave.bind(this, false)}
                                />
                            </>
                        )}
                    </BaseContainer>
                </TransitionablePortal>

                <Dimmer active={isOpen} onClickOutside={this.closeAndSave} page />

                <CloseSurveyMsg
                    ref={(closeSurveyMsg) => (this.closeSurveyMsg = closeSurveyMsg)}
                    answers={answers}
                    activePersonId={activePersonId}
                    onSaveSurvey={this.saveSurvey}
                />
            </Suspense>
        );
    }
}

const mapStateToProps = ({ authentication: { activePersonId }, dataStore, appSignals: { signals } }) => ({
    surveys: getProperty(dataStore, 'graph.person-surveys', {}),
    questionGroups: Object.values(getProperty(dataStore, 'graph.questionGroups', {})).sort(
        (a, b) => a.position > b.position
    ),
    signals: signals,
    activePersonId: activePersonId,
});

const mapDispatchToProps = (dispatch) => ({
    signalProccessed: ({ signalType }) => dispatch({ type: 'SIGNAL_PROCCESSED', payload: { signalType } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyContainer);

function SurveyControls({ prevGroup, nextGroup, isFirst = false, isLast = false, saveSurvey }) {
    return (
        <div className="survey-controls-container">
            <MibButton
                key="3"
                onClick={prevGroup}
                label={
                    <span style={{ width: '100%' }}>
                        <Icon name="caret left" style={{ float: 'left' }} />
                        {T.translate('surveys.Previous')}
                    </span>
                }
                type="info"
            />
            {isLast ? (
                <MibButton
                    key="1"
                    onClick={saveSurvey}
                    label={<span style={{ width: '100%' }}>{T.translate('surveys.End')}</span>}
                    className="teal"
                />
            ) : (
                <MibButton
                    key="2"
                    onClick={nextGroup}
                    label={
                        <span style={{ width: '100%' }}>
                            {T.translate('surveys.Next')}
                            <Icon name="caret right" style={{ float: 'right' }} />
                        </span>
                    }
                    type="info"
                />
            )}
        </div>
    );
}
