import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import React, { Component, Suspense } from 'react';
import T from 'i18n-react';

import BaseContainer from 'Mib/components/containers/base';

import * as _Charts from './panels';

const SketchPicker = React.lazy(() => import('react-color'));
// const style = { position: 'absolute', right: '13px', top: '1em', zIndex: 100, color: '#C0C0C0' };
// const settingsStyle = { position: 'absolute', right: '13px', top: '1em', zIndex: 100, color: '#C0C0C0' };
// const colorStyle = { position: 'absolute', left: '13px', top: '1em', zIndex: 100, color: '#C0C0C0' };

export default class DashPanelContainer extends Component {
    constructor(props) {
        super();
        this.state = { ...props.config };
        this.options = [
            { key: 1, text: T.translate('measurements.my.my-height-graph'), value: 'DashHeightChart' },
            { key: 2, text: T.translate('measurements.my.my-weight-graph'), value: 'DashWeightChart' },
            { key: 3, text: T.translate('measurements.my.my-pressure-graph'), value: 'DashPressureChart' },
            { key: 4, text: T.translate('measurements.my.my-oximetry-graph'), value: 'DashOximetryChart' },
            { key: 5, text: T.translate('measurements.my.my-temperature-graph'), value: 'DashTemperatureChart' },
        ];
    }

    componentWillReceiveProps(newProps) {
        this.setState({ panelComponent: newProps.config.panelComponent });
    }

    componentWillMount() {
        this.setState({ panelComponent: this.props.config.panelComponent });
    }

    // handleChange = (e, obj) => ( console.log(obj)) //this.setState({ value })}
    handleChange(e, { value }) {
        if (value == 'options') {
            this.props.toggleSettings();
            return;
        }
        this.setState({ ...this.state, panelComponent: value });
        this.props.save({ ...this.state, panelComponent: value });
    }

    hoverOn() {
        this.setState({ dropdown: true });
    }

    hoverOff() {
        this.setState({ dropdown: false });
    }

    hoverPickerOn() {
        this.setState({ picker: true });
    }

    hoverPickerOff() {
        this.setState({ picker: false });
    }

    handleChangeComplete(color) {
        this.setState({ ...this.state, lineColor: color.hex });
        this.props.save({ ...this.state, lineColor: color.hex });
    }

    render() {
        let { value, panelComponent, lineColor } = this.state;
        let { toggleSettings, save, config } = this.props;
        const DashComponent = _Charts[panelComponent] || BaseContainer;
        return (
            <Suspense fallback={null}>
                <>
                    <Menu style={{ position: 'absolute', right: 0, top: '3px', margin: 0 }} borderless>
                        <Dropdown
                            item
                            icon="wrench"
                            simple
                            style={{ zIndex: 9, right: 0, color: 'rgba(0,0,0,0.5)' }}
                            onChange={this.handleChange.bind(this)}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={toggleSettings}>
                                    <Icon name="setting" />
                                    {T.translate('dashboard.settings')}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item>
                                    <Icon name="dropdown" />
                                    {T.translate('dashboard.choose-widget')}
                                    <Dropdown.Menu options={this.options}>
                                        {this.options.map((opt) => (
                                            <Dropdown.Item key={opt.key} onClick={(e) => this.handleChange(e, opt)}>
                                                {opt.text}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    {T.translate('dashboard.color')}

                                    <SketchPicker
                                        submodule="TwitterPicker"
                                        triangle="hide"
                                        color={lineColor}
                                        colors={[
                                            '#00b1aa',
                                            '#f6b908',
                                            '#b4cb17',
                                            '#da2727',
                                            '#a032c4',
                                            '#2082cc',
                                            '#6435c9',
                                            '#21ba45',
                                        ]}
                                        onChangeComplete={this.handleChangeComplete.bind(this)}
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => save({}, true)}>
                                    {T.translate('dashboard.reset-settings')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu>
                    <DashComponent {...this.props} color={config.lineColor} />
                </>
            </Suspense>
        );
    }
}
