import React, { Component } from 'react';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { MibButton } from 'Mib/components/inputs';
import BaseContainer from 'Mib/components/containers/base';
import { getWithings, getConnections, syncWithings, notify } from 'Mib/actions';

class WithingsConnectionPanel extends Component {
    constructor() {
        super();
        this.state = { loading: false };
    }

    componentWillMount() {
        const { location, match, history } = this.props;
        const isWithingsCallback = match.params.action === 'withings-callback';
        if (isWithingsCallback) {
            this.setState({ loading: true });

            // Extract the search params from the location
            const paramsArray = location.search.split(/[\?&]/);
            const params = {};
            paramsArray.shift();
            paramsArray.forEach((p) => (params[p.split('=')[0]] = p.split('=')[1]));

            // Change the path back to normal
            // const basePath = `/${match.url.split("/")[1]}`;
            getWithings(params).then((res) => {
                this.setState({ loading: false });
                history.replace({ pathname: match.url });
                getConnections();
            });
        }
    }
    render() {
        const { isConnected, loadingConnections } = this.props;
        // const isConnected = true;
        const Button = isConnected ? (
            <MibButton
                loading={this.state.loading || loadingConnections}
                onClick={this.syncWithings.bind(this)}
                type="warning"
                label={
                    <span style={{ display: 'flex' }}>
                        <Icon name="cloud download" />
                        {T.translate('connections.synchronize')}
                    </span>
                }
            />
        ) : (
            <MibButton
                loading={this.state.loading || loadingConnections}
                onClick={this.enableWithings.bind(this)}
                type="success"
                label={T.translate('connections.activate')}
            />
        );
        return (
            <BaseContainer className="connection-segment" color="blue">
                <div className="connection-container-inner">
                    <img
                        src="/assets/img/connections/withings_logo.png"
                        alt="withings logo"
                        className="logo-image"
                        style={{ width: '30%', padding: '2%' }}
                    />
                    <div className="connection-description">{T.translate('connections.withings')}</div>
                    <div className="connection-controls">{Button}</div>
                </div>
            </BaseContainer>
        );
    }

    async enableWithings(e) {
        getWithings();
    }

    async syncWithings(e) {
        this.setState({ loading: true });
        syncWithings()
            .then((res) => {
                if ('url' in res) {
                    notify({
                        info: true,
                        header: T.translate('connections.must-authorize.title'),
                        content: 'connections.must-authorize.content',
                    });
                    setTimeout(() => window.open(res.url, '_blank'), 1500);
                } else {
                    notify({ success: true, header: T.translate('connections.sync-successfull'), content: '' });
                }
                this.setState({ loading: false });
            })
            .catch((err) => {
                notify({
                    error: true,
                    header: T.translate('connections.sync-failed'),
                    content: T.translate('connections.sync-failed-details'),
                });
                this.setState({ loading: false });
            });
    }
}

const mapStateToProps = ({ dataStore: { graph } }) => ({
    isConnected: Object.values(getProperty(graph, 'devices') || {}).some((dev) => dev.name === 'Withings'),
});

export default connect(mapStateToProps)(WithingsConnectionPanel);
