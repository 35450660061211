import React, { Component,Fragment } from  "react";
import { ProviderCard } from "./providerCard";
import { PermissionsCard } from "./permissionsCard";
import {Message} from "semantic-ui-react";
import  T from "i18n-react";

const OnSuccessBind = ({bindCode}) => {
	return(<Message success floating
			 		header={`${T.translate('providers.successDoctorBindTitle')} : ${bindCode}`}
					list={[
                        T.translate('providers.successDoctorBindContent'),
                        T.translate('providers.successDoctorBindPermission'),
                    ]}
			/>)

}

export  default class ProviderPermissionsCard extends Component{

	constructor(props){
		super();
		this.state = {providerToggle: true,
			permissionsToggle: false,
			visible: true,
			provider: props.provider,
			addProviderFunctionality: props.addProviderFunctionality=== undefined ? false : props.addProviderFunctionality }
	}

	toggle(){
		this.setState({providerToggle: !this.state.providerToggle})
	}
	onBindCode(bindCode){
		this.setState({provider: {...this.state.provider, bindCode:bindCode}, success: true, addProviderFunctionality: false })
		this.toggle()
        setTimeout( () => this.setState({success:false}) , 13000);

    }
	toggleVisibility(){
		this.setState({visible: !this.state.visible})
	}




	render(){
		const {success, addProviderFunctionality} = this.state;
		// const addProviderFunctionality = this.props.addProviderFunctionality === undefined ? false : this.props.addProviderFunctionality;
		return(
		<React.Fragment>
		      {/* <div className="right">
                    <Popup
                        trigger={
                            <Radio
                                toggle
                                checked={this.state.providerToggle}
                                onChange={(e, v) => this.setState({ providerToggle: v.checked })}
                            />
                        }
                        content={T.translate("providerpermission.show")}
                    />
                </div>*/}

            {success && <OnSuccessBind bindCode={this.state.provider.bindCode}/>}



			{this.state.providerToggle ? this.state.visible &&

				<ProviderCard close={this.toggleVisibility.bind(this)}
								onProviderBind={this.onBindCode.bind(this)}
								onPermissionsEdit={this.toggle.bind(this)}
								addProviderFunctionality={addProviderFunctionality}
								actionButtons={this.props.actionButtons}
								type = {this.props.type}
								provider={this.props.provider} />
				:
				<PermissionsCard  toggleProviderPermissions={this.toggle.bind(this)}
								  permissions={this.props.provider.permissions}
								  bindCode={this.state.provider.bindCode||null}
								  providerGuid={this.state.provider.id||null}
								  readOnly={this.props.readOnly||false}
				/>

								}
		</React.Fragment>
		)

	}
}
