import React, { Component } from 'react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import classnames from 'classnames';

import { MenuPanel } from 'Mib/components/containers';

class HealthFolderMenu extends Component {
    constructor(props) {
        super();
        this.state = {
            visible: 0,
        };
        this.menuItems = [];
        this.fuzzyOptions = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ['label'],
        };
        this.generateMenuItems(props);
    }

    componentWillReceiveProps(newProps) {
        this.generateMenuItems(newProps);
    }

    generateCategoryTitle(group, key, expanded = false) {
        // FEATURE TOGGLE

        const icon = group.icon ? `mib-lab ${group.icon}` : 'mib-lab miblab-body';
        return {
            key: key,
            label: (
                <>
                    <span className={`${icon} category-icon`} />
                    <span style={{ flex: 1 }}>{group.name}</span> <Icon name="angle down" />
                </>
            ),
            onClick: this.toggleCategoryExpanded.bind(this, group.id),
            as: 'div',
            className: classnames('lab-panel-category', { expanded: expanded }),
        };
    }

    generateMenuItems(props) {
        const { match } = props;
        let { url } = match;
        if (match.url.endsWith('/')) url = url.substr(0, match.url.length - 1);

        this.menuItems = [
            {
                path: '/weights',
                key: 1,
                label: (
                    <>
                        <span className="mib-lab icon icon-scale category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('measurements.singular.weight')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/weights`,
                as: NavLink,
                // icon: 'icon icon-scale',
            },
            {
                path: '/heights',
                key: 2,
                // label: T.translate('measurements.singular.height'),
                label: (
                    <>
                        <span className="mib-lab icon icon-height category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('measurements.singular.height')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/heights`,
                as: NavLink,
                // icon: 'icon icon-height',
            },

            {
                path: '/pressures',
                key: 3,
                // label: T.translate('measurements.singular.pressure'),
                label: (
                    <>
                        <span className="mib-lab icon icon-pressure category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('measurements.singular.pressure')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/pressures`,
                as: NavLink,
                // icon: 'icon icon-pressure',
            },

            {
                path: '/pulses',
                key: 4,
                // label: T.translate('measurements.plural.pulses'),
                label: (
                    <>
                        <span className="mib-lab icon icon-heartbeat category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('measurements.plural.pulses')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/pulses`,
                as: NavLink,
                // icon: 'icon icon-heartbeat',
            },

            {
                path: '/oximetries',
                key: 5,
                // label: T.translate('measurements.singular.oximetry'),
                label: (
                    <>
                        <span className="mib-lab icon icon-oxygen category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('measurements.singular.oximetry')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/oximetries`,
                as: NavLink,
                // icon: 'icon icon-oxygen',
            },
            {
                path: '/temperatures',
                key: 6,
                // label: T.translate('measurements.singular.temperature'),
                label: (
                    <>
                        <span className="mib-lab icon icon-thermometer category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('measurements.singular.temperature')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/temperatures`,
                as: NavLink,
                // icon: 'icon icon-thermometer',
            },
            {
                path: '/waists',
                key: 111,
                // label: T.translate('measurements.singular.waist'),
                label: (
                    <>
                        <span className="mib-lab icon icon-waist category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('measurements.singular.waist')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/waists`,
                as: NavLink,
                // icon: 'icon-waist',
            },
            {
                path: '/heads',
                key: 7,
                // label: T.translate('measurements.singular.head'),
                label: (
                    <>
                        <span className="mib-lab icon icon-waist category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('measurements.singular.head')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/heads`,
                as: NavLink,
                // icon: 'icon-waist',
            },
        ];
    }

    isActive(match, location) {
        const measurement = location.pathname
            .split('/')
            .filter((i) => i != '')
            .pop();
        return measurement == 'health-folder' || this.menuItems.map((i) => i.path.substring(1)).includes(measurement);
    }

    render() {
        // return <MenuPanel title="Metr;hseiw" color="blue" items={menuItems} />;
        const menuTitle = (
            <Menu.Item
                as={NavLink}
                to="/health-folder/"
                isActive={this.isActive.bind(this)}
                className="menu-title emergency-title"
            >
                <Icon className="icon-heartbeat" />
                <span className="title">{T.translate('measurements.measurements')}</span>
                <Icon name="angle down" />
            </Menu.Item>
        );
        return (
            <MenuPanel
                className="blue mib-menu lab-exams-menu"
                inverted
                title={menuTitle}
                items={this.menuItems}
                collapse
                allCaps
            />
        );
    }
}
export default HealthFolderMenu;
