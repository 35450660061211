/* eslint-disable import/prefer-default-export */
import { createStore, combineReducers } from 'redux';

import { ChannelReducer } from 'Mib/lib/websockets/channels';
import { JsonApiDataStore } from '../vendor/jsonapi-datastore';

import {
    LoginReducer,
    DataStoreReducer,
    AppStateReducer,
    SignalsReducer,
    UserSettingsReducer,
    FiltersReducer,
    BillingReducer,
    NotificationMessageReducer,
    MediMessageReducer,
    LiveDataReducer,
    SenseReportReducer,
} from './reducers';

// Load the auth token
let authData = window.sessionStorage.getItem('authData');
let persons = window.sessionStorage.getItem('persons');
let permissions = window.sessionStorage.getItem('permissions');
authData = authData ? JSON.parse(authData) : {};
persons = persons ? JSON.parse(persons) : {};
permissions = permissions ? JSON.parse(permissions) : {};

const initialStore = new JsonApiDataStore();
initialStore.syncWithMeta(persons);
initialStore.syncWithMeta(permissions);

const lang = localStorage.getItem('lang') || 'el';
const initialState = {
    authentication: authData,
    dataStore: initialStore,
    appState: { syncing: [], lastSync: {}, messages: [], tagFilters: [], lang: lang },
    appSignals: { signals: [] },
};

export const store = createStore(
    combineReducers({
        authentication: LoginReducer,
        dataStore: DataStoreReducer,
        appState: AppStateReducer,
        appSignals: SignalsReducer,
        userSettings: UserSettingsReducer,
        filters: FiltersReducer,
        // socketDataStore: ChannelReducer,
        message_queue: ChannelReducer,
        medi_messages: MediMessageReducer,
        features: BillingReducer,
        notification_messages: NotificationMessageReducer,
        live_data: LiveDataReducer,
        'sense-report': SenseReportReducer,
    }),
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
