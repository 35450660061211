import React, { Component, Suspense } from 'react';
import BaseContainer from 'Mib/components/containers/base';

import { getProperty } from 'dot-prop';

const ReactHighcharts = React.lazy(() => import('react-highcharts'));

export default class ReactHighChartsManaged extends Component {
    constructor() {
        super();
        this.lastData = [];
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        const { limit } = this.props;
        if (limit > 0 && this.chartRef.current !== null) {
            setTimeout(() => this.autoZoomChart(limit), 10);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (getProperty(nextProps, 'config.forceRefresh')) {
            nextProps.config.forceRefresh = false;
            return true;
        }
        const nextData = getProperty(nextProps, 'config.series.0.data') || [];

        const todaysData = this.lastData;
        const ret = !nextData.every(({ x, y }, index) => {
            return x === getProperty(todaysData, `${index}.x`) && y === getProperty(todaysData, `${index}.y`);
        });

        if (ret) this.lastData = nextData;
        return ret;
    }

    autoZoomChart(days) {
        const d = new Date();
        // console.log('this.chartRef', this.chartRef);
        // console.log('this.chartRef.current', this.chartRef.current);
        const c = this.chartRef.current.getChart();
        // const c = this.chartRef.current.Highcharts.getChart();
        c.xAxis[0].setExtremes(
            Date.UTC(d.getFullYear(), d.getMonth(), d.getDate() - days),
            Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
        );
        c.showResetZoom();
    }

    // TODO: Should we zoom on every update?

    // componentDidUpdate(){
    //     const {limit} = this.props;
    //     if (limit > 0){
    //         this.autoZoomChart(limit)
    //     }
    // }

    render() {
        return (
            <Suspense fallback={<BaseContainer style={{ height: '200px' }} loading />}>
                <ReactHighcharts config={this.props.config} ref={this.chartRef} />
            </Suspense>
        );
    }
}
