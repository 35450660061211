/* eslint-disable import/prefer-default-export */
import { store } from '../datastore/store';
import { backendURL, authBackendURL } from '../config';
import { getProperty } from 'dot-prop';
import history from './history';
import { logoutAction } from 'Mib/actions/logout';
import { AuthenticationException } from './exceptions';

export const Fetch = async (url, opts = {}, domain, isFile = false) => {
    const baseURL = domain === 'auth' ? authBackendURL : backendURL;

    const state = store.getState();
    const { lang } = state.appState;
    const access_token = getProperty(state, 'authentication.access_token');
    const activePersonId = getProperty(state, 'authentication.activePersonId');

    if (!access_token) {
        await logoutAction();
        history.location.pathname !== '/login' && history.push({ pathname: '/login' });
        throw new AuthenticationException('Auth failed. You should be redirecting to the login page.');
    }

    let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${access_token}`,
        'X-UnitSystem': 1,
        'X-locale': lang,
    };

    if (isFile) {
        delete headers['Content-Type'];
        delete headers.Accept;
    }

    const options = { headers: headers, ...opts };
    url = `${baseURL}${url}`;
    const result = await fetch(url, options);

    if (!result.ok) {
        return handleError(result);
    }
    if (result.statusText == 'No Content') {
        return await result;
    }

    return await result.json();
};

const handleError = async (result) => {
    switch (result.status) {
        case 401:
            throw result;
        case 499: // Token expired
            history.location.pathname !== '/login' && history.push({ pathname: '/login' });
            throw new AuthenticationException('Auth failed. You should be redirecting to the login page.');
        case 422:
        case 413:
            // await result.json();
            throw result;
        default:
            throw await result;
    }
};
