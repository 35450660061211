import React, { Component, Fragment as F } from "react";
import T from 'i18n-react'
import { Route } from "react-router-dom";
import {Message} from 'semantic-ui-react'

export default class MetricInfoPanel extends Component {
  render() {
    return (
      <F>
        <Route
          path="/health-indicators/bmi"
          render={() => (
            <Message
              icon="info"
              info
              header={T.translate("measurements.bmi.title")}
              content={T.translate("measurements.bmi.info")}
              onClick={this.props.openBMIInfo}
            />
          )}
        />
        <Route
          path="/health-indicators/waist_to_height"
          render={() => (
            <Message
              icon="info"
              info
              header={T.translate("measurements.wth.title")}
              content={T.translate("measurements.wth.info")}
              onClick={this.props.openWTHInfo}
            />
          )}
        />
          <Route
              path="/health-indicators/examsByAge"
              render={() => (
                  <Message
                      icon="info"
                      info
                      header={T.translate("routes.health-indicators.examsByAge")}
                      content={T.translate("measurements.examsByAge.info")}
                  />
              )}
          />
      </F>
    );
  }
}
