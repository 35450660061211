import React, {Component} from "react"

require("./loginAndRegisterTemplateCss.scss")
export default class LoginAndRegisterTemplate extends Component{
    constructor(){
        super();
    }

    render(){
        return(
            <div className="generalTemplate">
                <div className="columnLeft">
                   <img className="mibIcon" src="/assets/img/logo_mib_BIG.png"/>
                    <span style={{paddingTop: "3%"}}>  {this.props.children} </span>
                    <img className="espaIcon" src="/assets/img/sense_mib.png"/>
                </div>
                <div className="columnRight">
                    <img className="familyIcon" src="/assets/img/login_family.jpg" />
                </div>


            </div>
        )
    }
}