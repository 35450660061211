import React, { Component, Suspense } from 'react';

import { Form, Input, Icon } from 'semantic-ui-react';
import T from 'i18n-react';
import classnames from 'classnames';
import moment from 'moment';

import { TextInput } from 'Mib/components/inputs';
import { isValidDate } from './calendarTools';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = React.lazy(() => import('react-datepicker'));

export class CalendarInput extends Component {
    constructor() {
        super();
        this.state = {
            startDate: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRaw = this.handleChangeRaw.bind(this);
        this.value = new Date();
    }

    handleChange = (date, e) => {
        this.setState({
            startDate: date,
        });
        this.value = date;
        const hoho = date != null ? { value: date } : null; // props. onChange function  always expects a value property
        // let hoho = date != null ? Object.assign({}, { value: date.format() }) : null; //props. onChange function  always expects a value property
        // console.log('insidesimplehandlechange', date);
        if (this.props.onChange) this.props.onChange(e, hoho);
    };

    handleChangeRaw = (e) => {
        let date = e.target.value;
        date = moment(date, 'dd/MM/yyyy HH:mm');
        // date = new Date(date);
        this.handleChange(date, e);
    };

    render() {
        let {
            label,
            required,
            value,
            className,
            onChange,
            inline = false,
            errors,
            time,
            maxDate,
            ...other
        } = this.props;

        const { startDate } = this.state;

        let val = new Date(value);
        if (!isValidDate(val)) val = null;
        if (value === undefined) val = null;

        label = required ? `${label}*` : label;
        const hasErrors = Array.isArray(errors) && errors.length;
        const timeProps = time
            ? {
                  showTimeSelect: true,
                  timeFormat: 'HH:mm',
                  timeIntervals: 10,
                  dateFormat: 'dd/MM/yyyy HH:mm',
                  timeCaption: T.translate('time'),
              }
            : {};
        const maxDateProps = maxDate ? new Date() : '';

        return (
            <Suspense fallback={TextInput}>
                <Form.Field style={{ position: 'relative' }} inline={inline}>
                    <label>
                        <Input
                            className={classnames(
                                'defaultCss',
                                'material',
                                'date-picker',
                                { error: hasErrors },
                                className
                            )}
                            icon
                        >
                            <DatePicker
                                selected={val || startDate}
                                onChange={this.handleChange}
                                onChangeRaw={this.handleChangeRaw}
                                dateFormat="dd/MM/yyyy"
                                maxDate={maxDateProps}
                                placeholderText={label}
                                {...timeProps}
                                {...other}
                            />
                            <Icon classnames="focusedIcon" name="calendar" />
                        </Input>
                    </label>
                    <div
                        className={classnames('funky-placeholder', {
                            animePlaceHolderDatePicker: value != undefined && value != '',
                        })}
                    >
                        {label}
                    </div>
                    {hasErrors ? errors.map((err) => <p className="input-error-message">{err.message}</p>) : null}
                </Form.Field>
            </Suspense>
        );
    }
}

export default CalendarInput;
