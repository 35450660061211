import React, { Component, Suspense } from 'react';
import { getProperty } from 'dot-prop';
import { Route } from 'react-router-dom';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import Bmi from 'Mib/components/metric_panels/bmi';
import Wth from 'Mib/components/metric_panels/wth';
import TagPanel from 'Mib/components/containers/tag_panel';
import WithSideMenu from 'Mib/components/containers/with_side_menu';
import HealthIndicatorMenu from 'Mib/routes/health_indicators/health_indicators_menu';
import TutorialsMenu from 'Mib/routes/health_indicators/tutorials_menu';
import MetricInfoPanel from 'Mib/components/generic/metric_info_panel';
import TutorialsPage from 'Mib/routes/health_indicators/tutorials_index';
import { InfoModal } from 'Mib/routes/health_indicators/info_modal';
import { CovidIndexPage } from 'Mib/routes/health_indicators/covid/covidIndexPage';
import ExamsByAgeTable from '../examsByAge/examByAgeTable';

// import SurveyQuestionContainer from 'Mib/routes/health_indicators/surveyQuestionContainer';
const SurveyQuestionContainer = React.lazy(() => import('Mib/routes/health_indicators/surveyQuestionContainer'));

class _HealthIndicatorsPage extends Component {
    componentDidMount() {
        this.redirect();
    }

    redirect(props = this.props) {
        const { type } = this.props.match.params;
        const { history } = this.props;
        if (!type) {
            history.replace('/health-indicators/bmi');
        }
    }

    openBMIInfo() {
        this.formModal.open('BMI');
    }

    openWTHInfo() {
        this.formModal.open('WTH');
    }

    render() {
        const { match, history, loading, tagFilters, syncing } = this.props;
        const childProps = { tagFilters, loading, syncing };
        return (
            <WithSideMenu
                history={history}
                match={match}
                menuItems={[
                    HealthIndicatorMenu,
                    TutorialsMenu,
                    TagPanel,
                    (props) => (
                        <MetricInfoPanel
                            {...props}
                            openBMIInfo={() => this.openBMIInfo}
                            openWTHInfo={() => this.openWTHInfo()}
                        />
                    ),
                ]}
            >
                <Route path={`${match.url}/bmi`} render={() => <Bmi {...childProps} />} />
                <Route path={`${match.url}/waist_to_height`} render={() => <Wth {...childProps} />} />

                <Route path={`${match.url}/examsByAge`} render={() => <ExamsByAgeTable {...childProps} />} />

                <Route path={`${match.url}/covid19`} component={CovidIndexPage} />

                <Suspense fallback={null}>
                    <SurveyQuestionContainer signalType="SURVEY-QUESTION" />
                </Suspense>

                <Route path={`${match.url}/tutorials`} component={TutorialsPage} />
                <InfoModal ref={(i) => (this.formModal = i)} />
            </WithSideMenu>
        );
    }
}

const mapStateToProps = ({ authentication: { activePersonId }, dataStore, userSettings, appState }) => {
    const personSpecificSettings = getProperty(dataStore.graph, `person-settings.${activePersonId}`) || {};
    // let personSurveys = getProperty(dataStore.graph, 'person-surveys') || [];
    // let covid19 = Object.values(personSurveys).find( s => s.survey.name ==="Covid19");

    return {
        personSettings: personSpecificSettings,
        tagFilters: appState.tagFilters,
    };
};

export const HealthIndicatorsPage = subscribeWithLoader(_HealthIndicatorsPage, mapStateToProps, [
    'person-settings',
    'person-info',
]);
