import React, { Component, useRef } from 'react';

import { Link } from 'react-router-dom';
import { Segment, Message, Transition, Flag } from 'semantic-ui-react';
import { connect } from 'react-redux';
import T from 'i18n-react';

import { store } from 'Mib/datastore/store';
import { loginAction, syncAllAction } from 'Mib/actions';
import { setLocale } from 'Mib/lib/utils';
import { TextInput, MibButton } from 'Mib/components/inputs';
import { CreateRegistrationData, registrationAction } from 'Mib/actions/registration';
import LoginAndRegisterTemplate from '../loginAndRegisterTemplate/loginAndRegisterTemplate';

require('./login.scss');

export * from './password_reset';

function SelectLocale({ reload }) {
    return (
        <div style={{ display: 'inline-flex', justifyContent: 'space-around', padding: '.92857143em 1.14285714em' }}>
            <Flag
                style={{ cursor: 'pointer' }}
                name="gr"
                size="large"
                onClick={() => {
                    setLocale('el');
                    reload();
                }}
            />
            <Flag
                style={{ cursor: 'pointer' }}
                name="gb"
                onClick={() => {
                    setLocale('en');
                    reload();
                }}
            />
        </div>
    );
}

class _LoginPage extends Component {
    constructor() {
        super();
        this.state = { loading: false, error: false, register: false, username: '', password: '', force: false };
    }

    componentDidMount() {
        if (this.unInput) this.unInput.focus();
    }

    async login() {
        this.setState({ loading: true, error: false });
        const { username, password } = this.state;
        try {
            await loginAction(username, password);
        } catch (e) {
            if (e.status === 401) {
                this.setState({ error: true });
            }
            this.setState({ error: true, loading: false });
            this.setState({ password: '' });
            this.pwInput.focus();
            return;
        }
        this.setState({ loading: false });
        syncAllAction(true);
        const { search } = this.props.location;
        const match = search.match(/\?next=([^&\?]*)/);

        if (this.props.personsRedirect) {
            this.props.history.push({ pathname: `/profile/persons/${this.props.authData.activePersonId}` });
        } else if (match != null) {
            this.props.history.push({ pathname: match[1] });
        } else {
            this.props.history.push({ pathname: '/dashboard' });
        }

        store.dispatch({ type: 'RESET_PERSONS', payload: false });
    }

    render() {
        return (
            <LoginAndRegisterTemplate>
                <div className="login-panel-container">
                    {/* <div> */}
                    <SelectLocale reload={() => this.setState({ reload: !this.state.reload })} />
                    <Segment className="login-panel">
                        <div className="login-title">{T.translate('meta.login.connect')}</div>
                        <TextInput
                            type="text"
                            className="login-input"
                            ref={(i) => (this.unInput = i)}
                            value={this.state.username}
                            onChange={(e) => this.setState({ username: e.target.value })}
                            placeholder={T.translate('meta.login.email')}
                        />
                        <TextInput
                            type="password"
                            className="login-input"
                            ref={(i) => (this.pwInput = i)}
                            value={this.state.password}
                            onChange={(e) => this.setState({ password: e.target.value })}
                            placeholder={T.translate('meta.login.password')}
                            onKeyPress={({ key }) => key == 'Enter' && this.login()}
                        />
                        <MibButton
                            fluid
                            type="info"
                            className="login-button"
                            loading={this.state.loading}
                            disabled={this.state.loading}
                            onClick={this.login.bind(this)}
                            label={T.translate('meta.login.log-in')}
                        />
                        <Transition.Group animation="drop" duration={500}>
                            {this.state.error ? (
                                <Message negative style={{ marginTop: '10px' }}>
                                    <Message.Header>{T.translate('meta.login.can-not-connect')}</Message.Header>
                                    <p>{T.translate('meta.login.wrong-password')}</p>
                                </Message>
                            ) : null}
                        </Transition.Group>
                        <div className="login-footer">
                            {T.translate('meta.login.forgot-password')}&nbsp;
                            <Link to="/password-reset">{T.translate('meta.login.here')}</Link>
                        </div>
                    </Segment>
                    <MibButton
                        color="teal"
                        className="register-button"
                        label={T.translate('meta.login.register')}
                        onClick={() => this.props.history.push({ pathname: '/registration' })}
                    />
                    {this.state.register ? <RegisterComponent /> : null}
                </div>
            </LoginAndRegisterTemplate>
        );
    }
}

function RegisterComponent() {
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    function register() {
        const values = {
            first_name: firstNameRef.current.value,
            last_name: lastNameRef.current.value,
            email: emailRef.current.value,
            username: usernameRef.current.value,
            password: passwordRef.current.value,
        };
        const data = CreateRegistrationData(values);
        registrationAction(data);
    }

    return (
        <Segment className="login-panel">
            <input name="first_name" ref={firstNameRef} placeholder="first_name" type="text" />
            <input name="last_name" ref={lastNameRef} placeholder="last_name" type="text" />
            <input name="email" ref={emailRef} placeholder="email" type="email" />
            <input name="username" ref={usernameRef} placeholder="username" type="text" />
            <input name="password" ref={passwordRef} placeholder="password" type="password" />
            <button onClick={register} type="button">
                Submit
            </button>
        </Segment>
    );
}

const mapStateToProps = ({ authentication, appState }) => ({
    authData: authentication,
    personsRedirect: appState.resetPersons,
});

export const LoginPage = connect(mapStateToProps)(_LoginPage);
