import React, { Component, Suspense } from 'react';

import { Form, Input } from 'semantic-ui-react';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = React.lazy(() => import('react-datepicker'));

export class RangeCalendarInput extends Component {
    constructor({ endDate }) {
        super();
        this.state = {
            startDate: null,
            endDate: endDate,
        };
    }

    onStartChanged = (date, e) => {
        this.setState({ startDate: date });
        const retValue = date != null ? { value: date, moment: date } : null; // props. onChange function  always expects a value property
        if (this.props.onStartChanged) this.props.onStartChanged(e, retValue);
    };

    onEndChanged = (date, e) => {
        this.setState({ endDate: date });
        const retValue = date != null ? { value: date, moment: date } : null; // props. onChange function  always expects a value property

        // let retValue = date != null ? Object.assign({}, { value: date.format(), moment: date }) : null; //props. onChange function  always expects a value property
        if (this.props.onEndChanged) this.props.onEndChanged(e, retValue);
    };

    render() {
        const { fromLabel, toLabel, startDate, endDate, inline = false, onChange, ...other } = this.props;

        return (
            <Suspense fallback={null}>
                <Form.Field inline={inline}>
                    <label>
                        <span className="InputLabel">{fromLabel}</span>
                    </label>
                    <Input className="defaultCss">
                        <DatePicker
                            selected={this.state.startDate || startDate}
                            selectsStart
                            startDate={this.state.startDate || startDate}
                            endDate={this.state.endDate}
                            onChange={this.onStartChanged}
                            dateFormat="dd/MM/yyyy"
                            {...other}
                        />
                    </Input>
                    <span style={{ display: 'inline-block', width: '10px' }} />
                    <label>
                        <span className="InputLabel">{toLabel}</span>
                    </label>
                    <Input className="defaultCss">
                        <DatePicker
                            selected={this.state.endDate || endDate}
                            selectsEnd
                            startDate={this.state.startDate || startDate}
                            endDate={this.state.endDate}
                            onChange={this.onEndChanged}
                            dateFormat="dd/MM/yyyy"
                            {...other}
                        />
                    </Input>
                </Form.Field>
            </Suspense>
        );
    }
}

export default RangeCalendarInput;
