import React, { Component } from 'react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import classnames from 'classnames';

import { MenuPanel } from 'Mib/components/containers';

class MedicationsMenu extends Component {
    constructor(props) {
        super();
        this.state = {
            visible: 0,
        };
        this.menuItems = [];
        this.fuzzyOptions = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ['label'],
        };
        this.generateMenuItems(props);
    }

    componentDidUpdate() {
        this.generateMenuItems(this.props);
    }

    generateMenuItems(props) {
        const { match } = props;
        let { url } = match;
        if (match.url.endsWith('/')) url = url.substr(0, match.url.length - 1);

        this.menuItems = [
            {
                path: '/medicine/medications',
                key: 1,
                label: (
                    <>
                        <span className="mib-lab icon icon-scale category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>
                            {T.translate('user-drugs.user-drugs')}
                        </span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/medicine/medications`,
                as: NavLink,
            },
            {
                path: '/medicine/single-drug-use',
                key: 2,
                label: (
                    <>
                        <span className="mib-lab icon icon-icon_meds category-icon" />
                        <span style={{ flex: 1, textTransform: 'uppercase' }}>{T.translate('user-drugs.drug-use')}</span>
                    </>
                ),
                className: classnames('lab-panel-category', { expanded: false }),
                to: `${url}/medicine/single-drug-use`,
                as: NavLink,
            },
        ];
    }

    render() {
        const menuTitle = (
            <Menu.Item as={NavLink} to="/health-folder/medicine/" className="menu-title emergency-title">
                <Icon className="icon-icon_meds" />
                <span className="title">{T.translate('user-drugs.drugs')}</span>
                <Icon name="angle down" />
            </Menu.Item>
        );
        return (
            <MenuPanel
                className="green profile-menu mib-menu"
                inverted
                title={menuTitle}
                items={this.menuItems}
                collapse
                allCaps
            />
        );
    }
}
export default MedicationsMenu;
