import { getProperty } from 'dot-prop';

import { store } from '../datastore/store';
import { Fetch } from '../lib/fetch';

export const getWithings = async (data) => {
    const state = store.getState();
    const activePersonId = getProperty(state, 'authentication.activePersonId');
    const url = `persons/${activePersonId}/enable-withings`;
    const opts = { method: 'POST' };
    if (data) opts.body = JSON.stringify(data);
    try {
        const res = await Fetch(url, opts, 'auth');
        return res;
    } catch (e) {
        if (e.status === 307) {
            const jsonData = await e.json();
            window.open(jsonData.url, '_blank');
            return jsonData;
        }
        throw e;
    }
};

/* *
 * Returns the active connections
 * */
export const getConnections = async () => {
    const state = store.getState();
    const activePersonId = getProperty(state, 'authentication.activePersonId');
    const url = `persons/${activePersonId}/available-devices`;
    const res = await Fetch(url, {}, 'auth');
    res.data.forEach((dev) => (dev.type = 'devices'));
    store.dispatch({ type: 'SET_DATA', payload: res });
    return res;
};

export const syncWithings = async () => {
    const state = store.getState();
    const activePersonId = getProperty(state, 'authentication.activePersonId');
    const url = `persons/${activePersonId}/sync-withings`;
    const res = await Fetch(url, { method: 'POST' }, 'api');
    return res;
};
