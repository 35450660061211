import { startPersonsServers } from "Mib/actions/backend";
import {PhoenixSocketClient} from 'Mib/lib/websockets/websockets';


export function SocketConnect(authData, lang) {
    startPersonsServers()
    const { activePersonId, access_token, id } = authData
    const client = new PhoenixSocketClient()
    client.init( activePersonId, id, access_token, lang )
    window.sockClient = client
}


export function SocketDisconnect() {
    window.sockClient.channels["person"].leave()
    window.sockClient.personSock.disconnect()
}