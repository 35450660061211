import React from 'react';
import { connect } from 'react-redux';
import ShouldDisplayFeature from './shouldDisplayFeature';

class WithPaymentC extends React.Component {
    render() {
        const { feature_id, ...rest } = this.props;
        const canViewFeature = ShouldDisplayFeature(feature_id);
        return this.props.children({ ...rest, canViewFeature: canViewFeature, feature_id: feature_id });
    }
}

const mapStateToProps = (state) => {
    return {
        features: state.features,
    };
};

export const WithPayment = connect(mapStateToProps)(WithPaymentC);
