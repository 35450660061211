import React, { useEffect, useRef, useState } from 'react';

import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import { Message } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Socket } from 'Mib/vendor/phoenix';
import { store } from 'Mib/datastore/store';
import { socketSenseReport } from 'Mib//lib/websockets/listeners'

import { ContentTitle } from '../../../components/generic/content_title';

const filterSenseDataXY = (arr) => {
    const data = [];

    arr.map((x) => {
        // const date = new Date(x.timestamp * 1000);

        data.push([new Date(x.timestamp * 1000).getTime(), x.value]);
    });

    return data;
};


const partial = (func, ...args) => (...rest) => func(...args, ...rest);


function SenseReportPanel({ clearFilters }) {


    const chartRef = useRef(null);



    function useLiveData(chart){

        const series = chart.series[0],
        shift = series.data.length > 20
        const point = filterSenseDataXY(senseReportData)
        chart.series[0].addPoint(point, true, shift);
   
    }

 


    const senseReportData = useSelector((state) => getProperty(state['sense-report']));
    // const socketData = useSelector((state) => getProperty(state['message_queue']));

    const chart = chartRef.current?.chart
    // const [senseReportData, setSenseReportData] = useState([])
    const [fuck, setFuck] = useState(dummyData)
    const [chartOptions, setChartOptions] = useState({
        title: {
            text: T.translate('sense.chartTitle'),
        },
        yAxis: {
            allowDecimals: true,
            scrollbar: {
                enabled: true,
                showFull: false,
            },
        },
        xAxis: {
            // title: { text: 'mpampis' },
            type: 'datetime',
            labels: {
                enabled: true,
                format: '{value:%H:%M:%S}',
            },
        },
        // chart: {
        //     events: {
        //         load: partial(useLiveData, chart)
        //     }
        // },
        series: [{ cropThreshold: 999999, name: T.translate('sense.measurements'), data: [] }],
    });

    const [chartSeriesData, setChartSeriesData] = useState([]);

    
    // useEffect( ()=>{

    //     const sockMessage = socketData[socketData.length-1]
    //     // store.dispatch({
    //     //     type: 'POP_SOCKET_MESSAGE',
    //     //     message_id: socket_data.id 
    //     // })
    //     sockMessage&&socketSenseReport(sockMessage) 
        
    // }, [socketData])


    // useEffect(() => {
    //     const timeZoneOffset = new Date().getTimezoneOffset();
    //     const chart = chartRef.current?.chart
    //     let socket = new WebSocket("ws://localhost:8000")
    //     // useLiveData = partial(useLiveData,chart)
    //     socket.onopen = (()=>socket.send("intialize"))

    //     socket.onmessage = ((event) => {
    //         console.log(event.data)
    //         store.dispatch({
    //             type: 'ADD_SOCKET_MESSAGE',
    //             message: { person: "person", payload: {message: event.data}, topic: "sense-report" },
    //         });
    //         let hoho = JSON.parse(event.data)
    //         // store.subscribe()
    //         // store.dispatch({type: "SOCKET_TEST" , payload: hoho})
 
    //         // socketSenseReport(hoho)

             
    //     })

    //     socket.onclose = function(event) {
    //         if (event.wasClean) {
    //           console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
    //         } else {
    //           // e.g. server process killed or network down
    //           // event.code is usually 1006 in this case
    //           console.log('[close] Connection died');
    //         }
    //       };
          
    //       socket.onerror = function(error) {
    //         console.log(`[error]`);
    //       };


    //     Highcharts.setOptions({
      
    //         time: {
    //             timezoneOffset: timeZoneOffset,
    //             // timezoneOffset: 2 * 60,
    //         },
    //     });

    //     return () => {
    //         socket.close()
    //     }

        
    // }, []);



    useEffect(() => {
        // if (chartOptions.series[0].data.length) {
        if (chartSeriesData.length) {
            const chart = chartRef.current?.chart;
            if (chart) {
                // Get index of last point
                const lastPoint = chart.series[0].data.length - 1;

                // if (chart) {
                //     chart.reflow();
                // }
                if (chart.series[0].data[lastPoint]) {
                    const d = new Date(chart.series[0].data[lastPoint].category);
                    d.setSeconds(d.getSeconds() - 15);
                    // d.setMinutes(d.getMinutes() - 1);

                    chart.xAxis[0].setExtremes(
                        d.getTime(), // min
                        chart.series[0].data[lastPoint].category
                    ); // max
                }
            }
        }
    }, [chartSeriesData]);

    useEffect(() => {
        // const interval = setInterval(() => {
            // const hoho = dummyData.pop()
            // setSenseReportData( (prevData) => { return [ ...prevData, hoho] } ) 
            // // setSenseReportData(hoho)
            // dummyData.push(hoho)
            const chart = chartRef.current?.chart;
            if (chart && senseReportData.length>0) {
                // Add new random point

                // useLiveData(chart)
                const series = chart.series[0],
                shift = series.data.length > 20

                // const newPoint = filterSenseDataXY(senseReportData)[0]
                // let newPoint = senseReportData.shift()
                let newPoint = senseReportData[senseReportData.length-1]
                newPoint = filterSenseDataXY([newPoint])[0]
                // setSenseReportData(senseReportData)
                if (newPoint)
                    // chart.series[0].addPoint(newPoint, true, shift);
                // const newPoint = [new Date().getTime(), Math.random() * (100.0 - 10.0) + 10];
                    chart.series[0].addPoint(newPoint);
                // chart.series[0].addPoint(newPoint, false, true);
                // chart.series[0].addPoint(newPoint, false);
                // chart.redraw();

      
                setChartSeriesData((prevState) => {
                    return [...prevState, newPoint];
                });
            }
        // }, 100);
        // return () => clearInterval(interval);
    }, [senseReportData]);



    // useEffect(()=>{

    //     const hoho = dummyData.shift()
    //     // setFuck((prevData) => {
    //     //     prevData.shift()
    //     //     return prevData
    //     // } )
    //     setSenseReportData((prevData) => [...prevData, hoho])
    //     console.log(dummyData)
    //     // dummyData.push(hoho)
        

    // }, [dummyData.length])

    return (
        <div>
            <ContentTitle
                icon="mib-mono-icon icon-excersise"
                title={`${T.translate('sense.title')} - ${T.translate('sense.measurementBoard')}`}
            />
            {/* {senseReportData && senseReportData.length ? ( */}
            {dummyData && dummyData.length ? (
                <div>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />
                </div>
            ) : (
                <Message
                    icon="info circle"
                    header={T.translate('sense.noMeasurements')}
                    onClick={clearFilters}
                    // content={T.translate("measurements.clear-filters-prompt")}
                    info
                    color="violet"
                    style={{ marginBottom: '180px', marginTop: '30px', cursor: 'pointer' }}
                />
            )}
        </div>
    );
}

export default SenseReportPanel;

let dummyData = [
    {
        value: 40.95,
        timestamp: 1658150731.037378,
    },
    {
        value: 48.27,
        timestamp: 1658150731.1388538,
    },
    {
        value: 25.38,
        timestamp: 1658150731.242929,
    },
    {
        value: 97.41,
        timestamp: 1658150731.344409,
    },
    {
        value: 90.77,
        timestamp: 1658150731.448724,
    },
    {
        value: 48.29,
        timestamp: 1658150731.5530329,
    },
    {
        value: 17.2,
        timestamp: 1658150731.6532218,
    },
    {
        value: 58.85,
        timestamp: 1658150731.756011,
    },
    {
        value: 29.69,
        timestamp: 1658150731.860186,
    },
    {
        value: 98.55,
        timestamp: 1658150731.9615219,
    },
    {
        value: 89.29,
        timestamp: 1658150732.063416,
    },
    {
        value: 38.12,
        timestamp: 1658150732.1670809,
    },
    {
        value: 80.78,
        timestamp: 1658150732.2694101,
    },
    {
        value: 38.95,
        timestamp: 1658150732.3697338,
    },
    {
        value: 67.29,
        timestamp: 1658150732.47361,
    },
    {
        value: 48.65,
        timestamp: 1658150732.573884,
    },
    {
        value: 72.81,
        timestamp: 1658150732.6777449,
    },
    {
        value: 42.98,
        timestamp: 1658150732.779764,
    },
    {
        value: 56.07,
        timestamp: 1658150732.8837929,
    },
    {
        value: 43.32,
        timestamp: 1658150732.985921,
    },
    {
        value: 57.88,
        timestamp: 1658150733.0904698,
    },
    {
        value: 57.96,
        timestamp: 1658150733.194654,
    },
    {
        value: 54.06,
        timestamp: 1658150733.2987468,
    },
    {
        value: 53.61,
        timestamp: 1658150733.402147,
    },
    {
        value: 85.02,
        timestamp: 1658150733.506361,
    },
    {
        value: 62.26,
        timestamp: 1658150733.611233,
    },
    {
        value: 91.16,
        timestamp: 1658150733.7150662,
    },
    {
        value: 86.33,
        timestamp: 1658150733.819822,
    },
    {
        value: 67.3,
        timestamp: 1658150733.921122,
    },
    {
        value: 53.71,
        timestamp: 1658150734.025679,
    },
    {
        value: 29.75,
        timestamp: 1658150734.12776,
    },
    {
        value: 19.47,
        timestamp: 1658150734.232272,
    },
    {
        value: 54.48,
        timestamp: 1658150734.336501,
    },
    {
        value: 31.88,
        timestamp: 1658150734.4399638,
    },
    {
        value: 50.58,
        timestamp: 1658150734.540475,
    },
    {
        value: 42.75,
        timestamp: 1658150734.640696,
    },
    {
        value: 13.43,
        timestamp: 1658150734.743954,
    },
    {
        value: 85.86,
        timestamp: 1658150734.848777,
    },
    {
        value: 38.76,
        timestamp: 1658150734.9532008,
    },
    {
        value: 18.93,
        timestamp: 1658150735.0545878,
    },
    {
        value: 48.53,
        timestamp: 1658150735.1555111,
    },
    {
        value: 43.27,
        timestamp: 1658150735.256263,
    },
    {
        value: 24.52,
        timestamp: 1658150735.3612869,
    },
    {
        value: 24.51,
        timestamp: 1658150735.4638999,
    },
    {
        value: 57.86,
        timestamp: 1658150735.56822,
    },
    {
        value: 40.26,
        timestamp: 1658150735.669906,
    },
    {
        value: 24.45,
        timestamp: 1658150735.770536,
    },
    {
        value: 44.98,
        timestamp: 1658150735.872077,
    },
    {
        value: 39.39,
        timestamp: 1658150735.975373,
    },
    {
        value: 26.11,
        timestamp: 1658150736.0777931,
    },
    {
        value: 56.93,
        timestamp: 1658150736.178473,
    },
    {
        value: 55.71,
        timestamp: 1658150736.280163,
    },
    {
        value: 47.04,
        timestamp: 1658150736.3843358,
    },
    {
        value: 80.64,
        timestamp: 1658150736.4892428,
    },
    {
        value: 42.21,
        timestamp: 1658150736.5919318,
    },
    {
        value: 49.25,
        timestamp: 1658150736.6923468,
    },
    {
        value: 83.85,
        timestamp: 1658150736.792642,
    },
    {
        value: 34.44,
        timestamp: 1658150736.893055,
    },
    {
        value: 79.9,
        timestamp: 1658150736.9971042,
    },
    {
        value: 86.71,
        timestamp: 1658150737.1003819,
    },
    {
        value: 99.45,
        timestamp: 1658150737.2033339,
    },
    {
        value: 39.05,
        timestamp: 1658150737.3036091,
    },
    {
        value: 94.04,
        timestamp: 1658150737.4067478,
    },
    {
        value: 94.18,
        timestamp: 1658150737.511324,
    },
    {
        value: 60.25,
        timestamp: 1658150737.61615,
    },
    {
        value: 80.62,
        timestamp: 1658150737.717973,
    },
    {
        value: 54.4,
        timestamp: 1658150737.818218,
    },
    {
        value: 91.64,
        timestamp: 1658150737.920515,
    },
    {
        value: 79.9,
        timestamp: 1658150738.0238972,
    },
    {
        value: 67.89,
        timestamp: 1658150738.127848,
    },
    {
        value: 50.33,
        timestamp: 1658150738.2319849,
    },
    {
        value: 56.81,
        timestamp: 1658150738.3367512,
    },
    {
        value: 19.51,
        timestamp: 1658150738.436928,
    },
    {
        value: 59.73,
        timestamp: 1658150738.5384169,
    },
    {
        value: 55.06,
        timestamp: 1658150738.638751,
    },
    {
        value: 75.42,
        timestamp: 1658150738.742283,
    },
    {
        value: 54.39,
        timestamp: 1658150738.843078,
    },
    {
        value: 96.36,
        timestamp: 1658150738.946781,
    },
    {
        value: 51.61,
        timestamp: 1658150739.051363,
    },
    {
        value: 43,
        timestamp: 1658150739.153435,
    },
    {
        value: 78.31,
        timestamp: 1658150739.2551522,
    },
    {
        value: 57.46,
        timestamp: 1658150739.3576052,
    },
    {
        value: 62.88,
        timestamp: 1658150739.461399,
    },
    {
        value: 37.63,
        timestamp: 1658150739.5619452,
    },
    {
        value: 68.85,
        timestamp: 1658150739.662123,
    },
    {
        value: 94.57,
        timestamp: 1658150739.7672682,
    },
    {
        value: 19.23,
        timestamp: 1658150739.870081,
    },
    {
        value: 17.37,
        timestamp: 1658150739.971044,
    },
    {
        value: 39.32,
        timestamp: 1658150740.0725179,
    },
    {
        value: 76.2,
        timestamp: 1658150740.1727371,
    },
    {
        value: 19.78,
        timestamp: 1658150740.277874,
    },
    {
        value: 81.59,
        timestamp: 1658150740.3795002,
    },
    {
        value: 42.41,
        timestamp: 1658150740.479716,
    },
    {
        value: 44.28,
        timestamp: 1658150740.584873,
    },
    {
        value: 60.37,
        timestamp: 1658150740.686798,
    },
    {
        value: 18.71,
        timestamp: 1658150740.788917,
    },
    {
        value: 75.6,
        timestamp: 1658150740.892339,
    },
    {
        value: 80.21,
        timestamp: 1658150740.9962702,
    },
    {
        value: 60.08,
        timestamp: 1658150741.100559,
    },
];
