import React from 'react';
import T from 'i18n-react';

import BaseContainer from 'Mib/components/containers/base';

// eslint-disable-next-line import/prefer-default-export
export function EmptyDashPanel({ toggleSettings }) {
    return (
        <BaseContainer className="dash-chart-container empty">
            <span className="plus" onClick={toggleSettings}>
                +
            </span>
            <span className="info">{T.translate('dashboard.empty-info')}</span>
        </BaseContainer>
    );
}
