import { Icon, Menu, Radio } from 'semantic-ui-react';
import React, { Component } from 'react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';
require('./interactive_mode.scss');

import { MenuPanel } from 'Mib/components/containers';

export default class InteractiveModeMenu extends Component {
    constructor(props) {
        super();
        this.state = {
            checkBoxes: {
                covidEnabled: true,
            },
        };

        this.generateMenuItems = this.generateMenuItems.bind(this);
        this.generateMenuItems(props, this.state.checkBoxes);
    }

    toggleClicked(toggleOf, e, v) {
        this.setState({ checkBoxes: { [toggleOf]: v.checked } });
        this.generateMenuItems(this.props, { ...this.state.checkBoxes, [toggleOf]: v.checked });
    }

    generateMenuItems(props, checkboxes) {
        const { match } = props;
        this.menuItems = [
            {
                key: 1,
                as: NavLink,
                to: match.url + '/covid',
                label: (
                    <span className="socket-menu-item">
                        <span>
                            <span className="miblab-aimopetalia mib-lab lab-exam-icon" />
                            {T.translate('routes.interactive-mode.covid')}
                        </span>
                        <Radio
                            onClick={this.toggleClicked.bind(this, 'covidEnabled')}
                            checked={checkboxes.covidEnabled}
                            toggle
                        />
                    </span>
                ),
            },
        ];
    }

    // <span className="mib-mono-icon mibmono-surgeon"/>

    render() {
        const { history, match } = this.props;

        const menuTitle = (
            <Menu.Item className="menu-title" as={NavLink} to={`${match.url}`} exact>
                <Icon className="icon-allergy" />
                {T.translate('routes.interactive-mode.menu-title')}
            </Menu.Item>
        );

        return <MenuPanel className="blue profile-menu mib-menu" inverted title={menuTitle} items={this.menuItems} />;
    }
}
