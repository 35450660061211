import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import { TextInput, MibButton } from '../inputs';
import ReactHighchartsManaged from './react_high_charts_managed';
import ModelForm from '../forms/model_form';
import { Measurement } from '../../lib/models';
import { Update } from '../../actions';

export default class ChartEventList extends Component {
    constructor() {
        super();
        this.state = {
            hasEntered: false,
            model: {},
            loading: false,
            config: { loading: null },
            pointX: 0,
            pointY: 0,
            display: 'none',
        };
    }

    onMouseLeave() {
        this.state.hasEntered ? this.setState({ display: 'none' }) : this.setState({ display: 'inline' });
    }

    save() {
        this.setState({ loading: true });

        Update(this.state.model).then((res) => {
            this.setState({ loading: false });
            this.close();
        });
    }

    render() {
        const formItems = [{ Element: TextInput, label: 'measurements.result', fieldName: 'result' }];
        const { pointX, pointY, display, model } = this.state;
        const { config } = this.props;
        return (
            <>
                <ReactHighchartsManaged config={config} ref="chart" />
                <Segment
                    onMouseEnter={() => this.setState({ hasEntered: true })}
                    onMouseLeave={() => this.onMouseLeave()}
                    style={{
                        position: 'absolute',
                        left: pointX,
                        top: pointY - 100,
                        display: display,
                    }}
                >
                    <ModelForm
                        style={{ paddingRight: '20%' }}
                        model={model}
                        validator={Measurement.Validator}
                        onChange={(model) => this.setState({ model: model })}
                        items={formItems}
                    />
                    <MibButton label="save" type="success" onClick={() => this.save()} />
                </Segment>
            </>
        );
    }
}
