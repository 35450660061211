import React from 'react';

import GeneralMetricContainer from './general_metric';
import { store } from '../../datastore/store';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { FilteredMetricComponent } from './filtered_metric';

class _GluccoseContainer extends FilteredMetricComponent {
    constructor(props) {
        super(props);
        this.state = { chart_config: {} };

        this.open = this.open.bind(this);
        const labName = (props.labExam && props.labExam.name) || '';
        this.dataPropName = 'personLabs';
        this.config = {
            descriptor: 'result',
            name: labName,
            dateDescriptor: 'date',
            valueOffset: 1,
            onGraphPointClicked: this.open,
            merge_config: ((props, conf) => ({
                series: [
                    {
                        ...conf.series[0],
                        name: (props.labExam && props.labExam.name) || '',
                        point: {
                            events: {
                                click: (event) => this.open(event.point.id, event.point.type, 1), // props.graphPointClick(event.point.id, event.point.type,props.labId),
                            },
                        },
                    },
                ],
            })).bind(this),
        };
    }

    render() {
        let { personLabs, measures, labExam, tagFilters, children, ...rest } = this.props;
        // var { labId } = this.props.match.params;
        var labId = this.props.labExam.id;
        // var labId = 1;

        let { chart_config, personLabs: filteredLabs } = this.state;

        var labName = (labExam && labExam.name) || '';
        return (
            <GeneralMetricContainer
                {...rest}
                title="my lab exams"
                data={filteredLabs}
                measureUnits={measures}
                dataType="lab-exam-events"
                chart_config={chart_config}
                name={labName}
                labId={labId}
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
            />
        );
    }

    open(id = undefined, type = undefined, lab_type = null) {
        const { unitId } = this.state;
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'MEASUREMENT_MODAL',
                id,
                type,
                unitId,
                lab_type,
            },
        });
    }
}

const mapStateToProps = (state, props) => {
    // var { labId } = props.match.params;
    var allLabExams = state.dataStore.graph['lab-exam-events'];
    var gluccExamEvents = {};
    allLabExams &&
        Object.entries(allLabExams).forEach(([key, labExamEvent]) => {
            if (labExamEvent.labExam && labExamEvent.labExam.code == 'GLU') {
                gluccExamEvents[labExamEvent.id] = labExamEvent;
            }
        });

    var allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    var labMeasureUnits = {};
    allmeasureUnits &&
        Object.entries(allmeasureUnits).forEach(([key, unit]) => {
            if (unit.measureUnitType && unit.measureUnitType.name == 'LAB') {
                labMeasureUnits[unit.id] = unit;
            }
        });

    // var lab = state.dataStore.graph["lab-exams"] && state.dataStore.graph["lab-exams"][labId];

    // console.log('obj values',Object.values(gluccExamEvents))//[0].labExam;
    var lab = gluccExamEvents && Object.values(gluccExamEvents).length > 0 && Object.values(gluccExamEvents)[0].labExam;
    return {
        personLabs: gluccExamEvents,
        measures: labMeasureUnits,
        labExam: lab,
        filters: state.filters,
    };
};

// const _personLabContainerSub = subscribe(_personLabContainer, ['lab-exams', 'measure-units'])

// export default connect(mapStateToProps)(_GluccoseContainer);
export default subscribeWithLoader(_GluccoseContainer, mapStateToProps, [
    'lab-exam-events',
    'measure-units?with=measureUnitType',
]);
