import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import GeneralMetricContainer from 'Mib/components/metric_panels/general_metric';
import { openMeasurementModal } from 'Mib/actions';
import { subscribe } from '../../lib/subscribe';
import { withRouter } from 'react-router-dom';

import T from 'i18n-react';
import { Create, Update } from '../../actions';
import { fetchAction } from '../../actions/backend';
import moment from 'moment';

import { FilteredMetricComponent } from './filtered_metric';

class _OximetryContainer extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.open = this.open.bind(this);
        this.dataPropName = 'oximetries';
        this.config = {
            descriptor: 'result',
            name: T.translate('measurements.singular.oximetry'),
            dateDescriptor: 'date',
            valueOffset: 10,
            onGraphPointClicked: this.open,
        };
    }

    render() {
        let { oximetries, children, ...rest } = this.props;

        let { chart_config, oximetries: filteredOximetries } = this.state;
        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate('measurements.singular.oximetry')}
                name="oximetry"
                data={filteredOximetries}
                chart_config={chart_config}
                dataType="oximetries"
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
            />
        );
    }

    open(id = undefined, type = undefined) {
        const { unitId } = this.state;
        openMeasurementModal({ id, type, unitId });
    }
}

const mapStateToProps = (state) => {
    return {
        oximetries: state.dataStore.graph.oximetries,
        filters: state.filters,
    };
};

const _OximetryContainerSub = subscribe(_OximetryContainer, ['oximetries', 'measure-units?with=measureUnitType']);

export default withRouter(connect(mapStateToProps)(_OximetryContainerSub));
