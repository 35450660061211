import React, { Component } from 'react';
import T from 'i18n-react';

import { UserVaccine } from 'Mib/lib/models';
import {
    TextAreaInput,
    CalendarInput,
    DropDownInput,
    MibButton,
    RadioButtonGroup,
    RemoteInput,
    TagRemoteInput,
} from 'Mib/components/inputs';
import { FormModal } from 'Mib/components/modals';
import ModelForm from 'Mib/components/forms/model_form';
import { Create, Update, Delete } from 'Mib/actions';
import { ConvertOptionsWithKey } from 'Mib/lib/convertOptionsWithKey';
import { store } from 'Mib/datastore/store';
import { relToResource, convertTags } from 'Mib/lib/conversions';
import { getProperty } from 'dot-prop';
import revalidator from 'revalidator';

const onFormChanged = (data, e, v) => {
    const value = v ? v.value : e.target.value;
    const { model, onChange, validator, onValidate } = data;
    const dataStore = store.getState().dataStore;

    let relationship = getProperty(dataStore, `graph.vaccines.${value}`);
    if (relationship != null) {
        model.setRelationship('vaccine', relationship);
        if (!model._relationships.includes('vaccine')) {
            model._relationships.push('vaccine');
        }
    } else {
        console.warn(`No relationship was found for model ${model._type} and field ${field}`);
    }

    model['vaccine_name'] = '';
    onChange(model);
    let valid = revalidator.validate(model, validator);
    // console.log('thew validator is ',model,validator,valid)
    let errors = valid.errors.map((err) => ({ property: err.property, message: err.message }));
    // this.setState({ errors: errors });
    onValidate && onValidate(errors);
};

const onSearchChanged = (data, e, v) => {
    const value = v.searchQuery;
    const { model, onChange, validator, onValidate } = data;
    const dataStore = store.getState().dataStore;
    model['vaccine_name'] = value;

    const index = model._relationships.indexOf('vaccine');

    if (index !== -1) {
        model._relationships.splice(index, 1);
    }
    model['vaccine'] = '';
    onChange(model);
    let valid = revalidator.validate(model, validator);
    // console.log('thew validator is ',model,validator,valid)
    let errors = valid.errors.map((err) => ({ property: err.property, message: err.message }));
    // this.setState({ errors: errors });
    onValidate && onValidate(errors);
};

const getElementValue = (model, childProps) => {
    // let val = model['vaccine_name'] ? model['vaccine_name'] : model['vaccine']['id'];

    if (model['vaccine_name']) {
        let key = Math.max.apply(
            Math,
            childProps.options.map(function (o) {
                return o.key;
            })
        );
        childProps.options.push({ key: key + 1, text: model['vaccine_name'], value: key + 1 });
        return key + 1;
    } else {
        return model && model['vaccine'] && model['vaccine']['id'];
    }
};

export class VaccineForm extends Component {
    constructor() {
        super();
        this.state = { model: {}, loading: false, vaccinesWithKey: {} };
    }

    render() {
        let { action } = this.state;
        let vaccinesWithKey = this.state.vaccinesWithKey;
        const formItems = [
            {
                Element: DropDownInput,
                fieldName: 'vaccine',
                getElementValue: getElementValue,
                onChange: onFormChanged,
                onMySearchChange: onSearchChanged,
                options: vaccinesWithKey,
                search: true,
                label: 'vaccines.vaccine',
                attrib: 'id',
                multiple: false,
            },
            {
                Element: CalendarInput,
                label: 'measurements.date',
                fieldName: 'date',
                time: false,
                showMonthDropdown: true,
                showYearDropdown: true,
            },

            { Element: TagRemoteInput, label: 'measurements.tags', fieldName: 'tags', attrib: 'tag', multiple: true },
            { Element: TextAreaInput, label: 'measurements.comments', fieldName: 'comments' },

            // {Element: TextAreaInput, fieldName: "allergy", label: "comments"}
        ];

        return (
            <FormModal ref={(i) => (this.modal = i)} title={T.translate('vaccines.create')} loading={this.state.loading}>
                <ModelForm
                    style={{ padding: '10%' }}
                    model={this.state.model}
                    validator={UserVaccine.Validator}
                    onChange={(model) => this.setState({ model: model })}
                    items={formItems}
                    onValidate={(errors) => this.setState({ errors })}
                    errors={this.state.errors}
                />
                <div className="mib-modal-footer controls">
                    {action === 'edit' && (
                        <MibButton
                            label={T.translate('buttons.delete')}
                            type="danger"
                            onClick={this.delete.bind(this)}
                        />
                    )}
                    <MibButton label={T.translate('buttons.close')} type="warning" onClick={this.close.bind(this)} />
                    <MibButton label={T.translate('buttons.save')} type="success" onClick={this.save.bind(this)} />
                </div>
            </FormModal>
        );
    }
    open(vaccine) {
        const model = UserVaccine({});
        vaccine && Object.assign(model, vaccine);
        let vaccines = this.props.vaccines;
        let vaccinesWithKey = ConvertOptionsWithKey(vaccines);
        this.setState({
            model: model,
            action: vaccine === undefined ? 'create' : 'edit',
            vaccinesWithKey: vaccinesWithKey,
        });
        this.modal.open();
    }
    close() {
        this.setState({ model: {}, errors: [], action: null, loading: false });
        // setTimeout(function(){this.modal.close()},30000);
        this.modal.close();
    }
    save() {
        this.setState({ loading: true });

        if (this.state.action === 'edit') {
            Update(this.state.model)
                .then((res) => {
                    this.setState({ loading: false });
                    this.close();
                })
                .catch((e) => {
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        } else {
            Create(this.state.model)
                .then((res) => {
                    this.setState({ loading: false });
                    this.close();
                })
                .catch((e) => {
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        }
    }
    delete() {
        Delete(this.state.model).then((res) => {
            this.setState({ loading: false });
            this.close();
        });
    }
}
