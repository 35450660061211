import React from 'react';
import T from 'i18n-react';
import { Button, Icon } from 'semantic-ui-react';

export function MibButton({ type, label, style, ...rest }) {
    // Before white #FFFFFF button have gray color #E9EAEB
    const typeMap = {
        basic: { backgroundColor: '#75828A', color: '#FFFFFF' },
        success: { backgroundColor: '#388E3C', color: '#FFFFFF' },
        danger: { backgroundColor: '#D32F2F', color: '#FFFFFF' },
        info: { backgroundColor: '#039EDF', color: '#FFFFFF' },
        warning: { backgroundColor: '#FFAB00', color: '#FFFFFF' },
    };

    const typeProp = typeMap[type] || {};
    const styleProp = { minWidth: '110px', ...style, ...typeProp };

    return (
        <Button style={styleProp} {...rest}>
            {label}
        </Button>
    );
}

export function CreateButton({ onClick, label }) {
    return (
        <div className="create-button" onClick={onClick}>
            <Icon name="plus square outline" />
            <span>{label || T.translate('create')}</span>
        </div>
    );
}
