import React, { Component } from 'react';
import { getProperty } from 'dot-prop';
import { Feature_Toggles as Feature_Toggles_Config } from 'Mib/config';
import { store } from 'Mib/datastore/store';

const Feature_Toggles = {
    ...Feature_Toggles_Config,
    tutorial: Feature_Toggles_Config.tutorial && onlyGreekLang,
    checkup: Feature_Toggles_Config.checkup && onlyGreekLang,
};

export const withFeatureToggle = (Component, feature_name) => {
    class Wrapped extends React.Component {
        render() {
            return (
                <FeatureToggle feature_name={feature_name}>
                    <Component />
                </FeatureToggle>
            );
        }
    }
    return Wrapped;
};

// Wrap a component
export class FeatureToggle extends React.Component {
    render() {
        const { children, feature_name } = this.props;
        return shouldRenderFeature(feature_name) ? <React.Fragment>{children}</React.Fragment> : null;
    }
}

export const shouldRenderFeature = (feature_name) => {
    const val =
        typeof Feature_Toggles[feature_name] == 'function'
            ? Feature_Toggles[feature_name](feature_name)
            : Feature_Toggles[feature_name];
    return Boolean(val);
};

/***
 * Utils
 ***/
const getLang = () => {
    const state = store.getState();
    return getProperty(state, 'appState.lang');
};

function onlyGreekLang() {
    return getLang() == 'el';
}
