import React, { Component } from "react";
import T from "i18n-react";
import dotProp from "dot-prop";
import {Route} from "react-router-dom";
import WithSideMenu from "Mib/components/containers/with_side_menu";
import InteractiveModeMenu from "./interactive_mode_menu";
import CovidIndexPage from "./covid";
import {GeneralInteractiveDash} from "./generalInteractiveDash";
require("./interactive_mode.scss");

export class InteractiveModeIndexPage extends Component{


    render() {

        let { personSettings, match, history, tagFilters, loading, syncing, ...rest } = this.props;
        let menuItems = [InteractiveModeMenu]
        return (
            <div className="content-container">
                <WithSideMenu
                    match={match}
                    history={history}
                    menuItems={menuItems}
                >
                    <Route exact path={`${match.url}/`} component={GeneralInteractiveDash} />
                    <Route exact path={`${match.url}/covid`} component={CovidIndexPage} />

                </WithSideMenu>
            </div>
        )
    }
}

