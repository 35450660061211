import React, { Component } from "react";
import classnames from "classnames";

require("./select.scss");

let OFFSET = "1.2em";

export default class QuickAddSelect extends Component {
    constructor() {
        super();
        this.state = { open: false };
    }

    render() {
        const { open } = this.state;
        const { item, value, style, itemStyle, disabled } = this.props;

        const activeItem = item || {
            text: "",
            key: 189237678
        };

        return (
            <div
                className={classnames("mib-select-container", { disabled: disabled })}
                style={style}
                onWheel={this.onWheel}
            >
                {this._renderCenterItem(activeItem)}

            </div>
        );
    }



    _renderCenterItem(item) {
        const { itemStyle } = this.props;
        return this.props.renderCenterItem ? (
            this.props.renderCenterItem(
                item,
                this.onCenterItemClicked.bind(this, item.value)
            )
        ) : (
            <div
                className="mib-select-item active"
                onClick={this.onCenterItemClicked.bind(this, item.value)}
                style={itemStyle}
            >
                {item.text}
            </div>
        );
    }



    onItemClicked(index) {
        this.props.onChange(index);
        this.toggle(false);
    }

    onCenterItemClicked(activeIndex) {
        const { disabled } = this.props;
        if (disabled) return null;
        if (this.state.open) {
            this.onItemClicked(activeIndex);
        } else {
            this.toggle(true);
        }
    }

    focus(){
        return this.toggle()
    }

    toggle(state) {
        this.setState({ open: state != undefined ? state : !this.state.open });
    }


}
