import { getProperty } from 'dot-prop';
import T from 'i18n-react';

import { loginAction, decryptPersonalData } from './login';
import { CreateRegistrationData } from './registration';
import { notify } from './notifications';
import { dataSyncTimeout } from '../config';
import { store } from '../datastore/store';
import { Fetch } from '../lib/fetch';
import { serializeWithTags, getMeta } from '../lib/json-api';

import { startBackendSyncAction, stopBackendSyncAction } from './app_state';
import { JsonApiDataStoreModel } from '../vendor/jsonapi-datastore';
import { fetchFeatures } from '../billing/actions';
import moment from 'moment';

const staticEndpoints = [
    'measure-units',
    'user-settings',
    'categories',
    'allergies',
    'activity-types',
    'blood-types',
    'lab-exams',
    'lab-groups',
    'drugs',
    'doctor-settings',
    'persons',
    'vaccines',
];

const authEndpoints = ['contacts', 'providers', 'person-providers', 'doctors', 'doctor-settings'];

const dontThrottle = ['bmis', 'wths'];

export const fetchAction = async (dataType, opts = {}) => {
    let {
        force = false, // Ignore throttling, request will always be executed
        url = undefined, // If you manually provide a url, the next two parameters will be ignored
        query = '?orderBy=date&sortedBy=desc', // Append this query after the request path
        force_static = false, // Don't prepend /persons/<activePersonId>/ to the request
        force_auth = false,
        replace = false, // Make the call to the auth backend
    } = opts;
    const state = store.getState();
    const dataTypeClean = dataType.split(/[\s,\?]/)[0];

    if (!force && (shouldThrottle(dataTypeClean) || state.appState.syncing.includes(dataTypeClean))) {
        console.log(`Throttling ${dataType}`);
        return;
    }

    startBackendSyncAction(dataType);

    const activePersonId = getProperty(state, 'authentication.activePersonId');

    if (!url) {
        if (dataType.includes('?')) {
            let datatypes = dataType.split(/[\s,\?]/);
            dataType = datatypes[0];
            query = `?${datatypes[1]}`;
        }
        url =
            staticEndpoints.includes(dataType) || force_static
                ? `${dataType}${query}`
                : `persons/${activePersonId}/${dataType}${query}`;
    }

    let domain = authEndpoints.includes(dataTypeClean) || force_auth ? 'auth' : 'api';

    const data = await Fetch(url, {}, domain).catch((e) => {
        e.name !== 'AuthenticationException' && console.log('Error while syncing: ', e);
        stopBackendSyncAction(dataType);
    });
    store.dispatch({ type: 'SET_DATA', payload: data, replace: replace });
    stopBackendSyncAction(dataType);
    return data;
};

export const startPersonsServers = async () => {
    const state = store.getState();
    const activePersonId = getProperty(state, 'authentication.activePersonId');
    const data = await Fetch(`medi/${activePersonId}/start-reminder`, { method: 'POST' }, 'auth');
    console.log('sever starting....data', data);
};

const fetchAction_bak = async (dataType, force = false, url, query = '?orderBy=date&sortedBy=desc') => {
    if (shouldThrottle(dataType)) {
        return `${dataType} Throttled`;
    }

    startBackendSyncAction(dataType);

    const state = store.getState();
    const activePersonId = getProperty(state, 'authentication.activePersonId');
    if (!url && dataType) {
        let datatypes = dataType.split(/[\s,\?]/);
        var dataType = datatypes[0];
        var dataParams = datatypes[1];
        url = staticEndpoints.includes(dataType)
            ? `${dataType}?`
            : `persons/${activePersonId}/${dataType}?orderBy=date&sortedBy=desc`;
        url = dataParams ? url + `&${dataParams}` : url;
    } else {
        let urlDatatypes = url.split(/[\s,\?]/);
        var urlDataType = urlDatatypes[0];
        var urlDataParams = urlDatatypes[1];
        if (dataType == null) {
            dataType = urlDataType;
        }

        url =
            // url ||
            staticEndpoints.includes(urlDataType)
                ? `${urlDataType}?${urlDataParams}`
                : `persons/${activePersonId}/${urlDataType}?${urlDataParams}`;
    }

    // url = url || (staticEndpoints.includes(dataType) ? `${dataType}` : `persons/${activePersonId}/${dataType}${query}`);
    // url = dataParams ? url + `?${dataParams}` : url;
    let domain = authEndpoints.includes(dataType) ? 'auth' : 'api';

    const data = await Fetch(url, {}, domain).catch((e) => {
        e.name !== 'AuthenticationException' && console.log('Error while syncing: ', e);
        stopBackendSyncAction(dataType);
    });
    // TODO new action
    store.dispatch({ type: 'SET_DATA', payload: data });
    stopBackendSyncAction(dataType);
    return data;
};

export const syncAllAction = async (force = false) => {
    startBackendSyncAction('__all__');
    fetchFeatures();
    Promise.all([
        fetchAction('measure-units', { query: '?with=measureUnitType;measureSystem', force }),
        fetchAction('categories', { force, query: '' }),
        fetchAction('blood-types', { force, query: '' }),
        fetchAction('activity-types', { force, query: '' }),
        fetchAction('lab-exams', { force, query: '' }),
        fetchAction('user-settings', { force, query: '' }),
        fetchAction('promoters', { url: 'promoters', force_auth: true }),
    ])
        .then((res) => {
            stopBackendSyncAction('__all__');
            return res;
        })
        .catch((err) => {
            stopBackendSyncAction('__all__');
            throw err;
        });
};

export const fetchUserData = async (password, username) => {
    startBackendSyncAction('user-data');
    const persons = await Fetch(`persons`, {}, 'auth');

    // console.log('in fetch user data');
    // Get user-info
    persons.data.forEach((pers) =>
        fetchAction(`person-info/${pers.id}`, { url: `persons/${pers.id}/person-info`, domain: 'api' })
    );
    persons.data.forEach((pers) =>
        fetchAction(`person-settings/${pers.id}`, { url: `persons/${pers.id}/person-settings`, domain: 'api' })
    );
    store.dispatch({ type: 'SET_DATA', payload: persons });
    stopBackendSyncAction('user-data');
    return persons;
};

export const Create = async (data, domain = 'api', unitId = null) => {
    let [model, endpoint] = getMeta(data);
    const payload = serializeWithTags(data);
    const state = store.getState();
    const activePersonId = getProperty(state, 'authentication.activePersonId');
    const url =
        unitId != null
            ? `persons/${activePersonId}/${endpoint}?unit=${unitId}`
            : `persons/${activePersonId}/${endpoint}`;

    return Fetch(url, { method: 'POST', body: JSON.stringify(payload) }, domain)
        .then((results) => {
            console.log('results', results);
            store.dispatch({ type: 'SET_DATA', payload: results });
            notify({
                success: true,
                header: T.translate('success.create.header'),
                content: T.translate('success.create.content'),
            });
        })
        .catch(async (e) => {
            if (e.status == 422) {
                throw await e.json();
            }
            notify({
                error: true,
                header: T.translate('errors.create.header'),
                content: T.translate('errors.create.content'),
            });
        });
};

export const Update = async (data, domain = 'api', unitId = null) => {
    let [model, endpoint] = getMeta(data);
    const payload = serializeWithTags(data);
    const state = store.getState();
    const activePersonId = getProperty(state, 'authentication.activePersonId');
    let url = staticEndpoints.includes(endpoint)
        ? `${endpoint}/${payload.data.id}`
        : `persons/${activePersonId}/${endpoint}/${payload.data.id}`;
    url = unitId != null ? `${url}?unit=${unitId}` : url;
    return Fetch(
        url,
        {
            method: 'PATCH',
            body: JSON.stringify(payload),
        },
        domain
    )
        .then((results) => {
            store.dispatch({ type: 'SET_DATA', payload: results });
            notify({
                success: true,
                header: T.translate('success.update.header'),
                content: T.translate('success.update.content'),
            });
        })
        .catch(async (e) => {
            if (e.status == 422) {
                throw await e.json();
            }
            notify({
                error: true,
                header: T.translate('errors.update.header'),
                content: T.translate('errors.update.content'),
            });
        });
};

export const Delete = async (data, domain = 'api') => {
    let [model, endpoint] = getMeta(data);
    const payload = serializeWithTags(data);
    const state = store.getState();
    const activePersonId = getProperty(state, 'authentication.activePersonId');

    console.log('ACTIONS DELETE DATA', data);
    return Fetch(`persons/${activePersonId}/${endpoint}/${payload.data.id}`, { method: 'DELETE' }, domain)
        .then((results) => {
            store.dispatch({ type: 'DELETE_ITEM', payload: data });
            notify({
                success: true,
                header: T.translate('success.delete.header'),
                content: T.translate('success.delete.content'),
            });
        })
        .catch((e) => {
            if (e.status == 422) {
                throw e;
            }
            notify({
                error: true,
                header: T.translate('errors.delete.header'),
                content: T.translate('errors.delete.content'),
            });
        });
};

export const PersonUpdateAction = async (params, redirect = false) => {
    // Check the username and password
    const { id, username, password } = params;
    const isLoginValid = await loginAction(username, password, true);
    if (!isLoginValid.ok) {
        // handle 401
    }
    const personalDataNames = [
        'first_name',
        'last_name',
        'email',
        'ssn',
        'father_name',
        'mother_name',
        'dob',
        'mobile',
        'phone',
        'zipcode',
        'address',
        'username',
        'password',
        'gender',
    ];

    const personalData = {};
    personalDataNames.forEach((item) => {
        if (item in params) personalData[item] = params[item];
        if (item == 'gender' && params[item]) personalData[item] = params[item].toString();

        // if(item=='sex'){
        //     let sexString = params[item] == 1 ? T.translate('male') : params[item] == 2 ? T.translate('female') : null;
        //     personalData[item] = sexString;
        // }
    });

    const personInfoNames = ['blood-type', 'dominant_hand'];
    const personInfo = {};
    const pImodel = new JsonApiDataStoreModel('person-info');

    personInfoNames.forEach((item) => {
        if (item in params) {
            if (item === 'blood-type') {
                const bdmodel = new JsonApiDataStoreModel('blood-types');
                // bdmodel.setAttributes('type','blood-type')
                bdmodel.setAttribute('id', params[item] ? params[item] : null);
                pImodel.setRelationship('bloodType', bdmodel);
                // return;
            }
            if (item === 'dominant_hand') {
                pImodel.setAttribute('dominantHand', params[item] ? params[item] : null);
            }
        }
    });

    const payloadtosend = serializeWithTags(pImodel);

    // personInfoNames.forEach(item => {
    //     if (item in params) personInfo[item] = params[item];
    // });

    const encryptedData = CreateRegistrationData(personalData, true);
    const yearOfBirth = moment(personalData.dob).year();
    const mergedEncrypt = { ...encryptedData, i: yearOfBirth, g: personalData.gender };
    if (id) {
        try {
            const res = await Fetch(
                `persons/${id}`,
                { method: 'PATCH', body: JSON.stringify({ data: { attributes: mergedEncrypt } }) },
                'auth'
            )
                .then((data) => {
                    notify({
                        success: true,
                        header: T.translate('success.update.header'),
                        content: T.translate('success.update.content'),
                    });
                })
                .catch(() => {});
            const res2 = await Fetch(`persons/${id}/person-info`, {
                method: 'PATCH',
                body: JSON.stringify(payloadtosend),
            });
        } catch (e) {}
    } else {
        // Create
        try {
            const res = await Fetch(
                `persons/`,
                { method: 'POST', body: JSON.stringify({ data: { attributes: encryptedData } }) },
                'auth'
            )
                .then(() => {
                    notify({
                        success: true,
                        header: T.translate('success.create.header'),
                        content: T.translate('meta.registration.title'),
                    });
                })
                .catch(() => {});
            const res2 = await Fetch(`persons/${res.data.id}/person-info`, {
                method: 'PATCH',
                body: JSON.stringify({ data: { attributes: personInfo } }),
            });
        } catch (e) {}
    }

    fetchUserData().then(() => decryptPersonalData(username, password));
};

const shouldThrottle = (dataType) => {
    if (dontThrottle.includes(dataType)) {
        return false;
    }
    const state = store.getState();
    const { lastSync } = state.appState;

    if (!dataType in lastSync) return false;

    const timestamp = lastSync[dataType];
    const now = new Date().valueOf();
    return now - timestamp < dataSyncTimeout;
};
