import React, { Component } from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import sortOn from 'sort-on';
import moment from 'moment';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Icon } from 'semantic-ui-react';

import BaseContainer from 'Mib/components/containers/base';
import { MibButton } from 'Mib/components/inputs/buttons';
import { Pressure } from 'Mib/lib/models';
import { Create } from 'Mib/actions/backend';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import Select from 'Mib/components/inputs/select';

import { MeasurementInfoPanel } from './measurements_info';
import { getUserMeasureUnit } from './getQuickAddMeasureUnit';

export class _DashPressureQuickAdd extends Component {
    constructor(props) {
        super();
        this.state = { value: [8, 13], muId: '13' };
        this.link = '/health-folder/pressures';
        this.title = T.translate('measurements.add.add-pressure');
        this.dataType = 'pressures';
        this.minimums = {
            12: 50,
            13: 5,
        };
        this.maximums = {
            12: 200,
            13: 20,
        };
        this.steps = {
            12: 1,
            13: 0.1,
        };
        this.marks = {
            12: {
                50: '50',
                60: '60',
                70: '70',
                80: '80',
                90: '90',
                100: '100',
                110: '110',
                120: '120',
                130: '130',
                140: '140',
                150: '150',
                160: '160',
                170: '170',
                180: '180',
                190: '190',
                200: '200',
            },
            13: {
                5: '5',
                6: '6',
                7: '7',
                8: '8',
                9: '9',
                10: '10',
                11: '11',
                12: '12',
                13: '13',
                14: '14',
                15: '15',
                16: '16',
                17: '17',
                18: '18',
                19: '19',
                20: '20',
            },
        };
    }

    save() {
        let { value, muId } = this.state;
        if (value == null) {
            let { data } = this.props;
            const lastMeasurement = data && sortOn(Object.values(data), '-date')[0];
            value = lastMeasurement.result;
        }
        const model = Pressure(value[1], value[0], null, Number(muId));
        Create(model);
    }

    render() {
        let { chart_config } = this.state;
        let { data = {}, loading, color, measureUnitFromSettings } = this.props;
        const filteredPressures = {};
        Object.values(data).forEach((p) => {
            if (p.systolic != 0 && p.systolic != null) filteredPressures[p.id] = p;
        });
        const lastMeasurement = filteredPressures && sortOn(Object.values(filteredPressures), '-date')[0];
        const measureDate = moment(getProperty(lastMeasurement, 'date')).format('L');
        const measureTime = moment(getProperty(lastMeasurement, 'date')).format('HH:mm');
        const measureUnitAbbrev = getProperty(lastMeasurement, 'measureUnit.abbreviation');
        const value = lastMeasurement
            ? `${lastMeasurement.diastolic} / ${lastMeasurement.systolic}  ${measureUnitAbbrev}`
            : undefined;

        const selectedMeasureUnit = [
            measureUnitFromSettings
                ? {
                      value: measureUnitFromSettings.id,
                      text: measureUnitFromSettings.abbreviation,
                      key: measureUnitFromSettings.id,
                  }
                : { value: '', text: '', key: '' },
        ];
        return (
            <>
                <MeasurementInfoPanel
                    style={{ borderTopColor: this.props.color, borderTopWidth: '2px' }}
                    title={this.title}
                    date={measureDate}
                    time={measureTime}
                    value={value}
                    altText={T.translate('no-data')}
                    loading={loading}
                    color={this.props.color}
                    link={this.link}
                />
                <BaseContainer
                    // loading={loading}
                    className="dash-chart-container"
                    style={{
                        height: 'calc(200px + 1rem)',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <h3 style={{ color: 'rgba(0,0,0, 0.49)' }}>{T.translate('quick-add')}</h3>
                            </div>
                            <span
                                style={{
                                    flex: 2,
                                    textAlign: 'center',
                                    color: color,
                                    fontSize: '20pt',
                                    fontWeight: 'bold',
                                }}
                            >{`${this.state.value[0]} / ${this.state.value[1]}`}</span>
                            <div style={{ flex: 1 }}>
                                {/* <Dropdown
                                placeholder="units"
                                options={measureUnitOptions}
                                selection
                                onChange={(e, v) => this.setState({ muId: v.value })}
                                // onChange={console.log}
                                value={this.state.muId}
                                style={{ marginLeft: '1em', minWidth: "30px", color: color || 'black'}}
                            /> */}
                                <Select
                                    value={this.state.muId}
                                    items={selectedMeasureUnit}
                                    style={{ marginLeft: '1.5em' }}
                                    itemStyle={{ color: this.props.color }}
                                    onChange={(val) => {
                                        const { value, muId } = this.state;
                                        if (val != muId) {
                                            const newVal =
                                                val == '12' ? value.map((i) => i * 10) : value.map((i) => i / 10);
                                            this.setState({ muId: val, value: newVal });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ width: '90%', margin: 'auto' }}>
                            <Slider
                                range
                                className="pressure-slider"
                                value={this.state.value || getProperty(lastMeasurement, 'result') || [0, 0]}
                                value={this.state.value}
                                min={this.minimums ? this.minimums[this.state.muId] : 0}
                                max={this.maximums[this.state.muId]}
                                allowCross={false}
                                pushable={true}
                                marks={this.marks[this.state.muId]}
                                trackStyle={[{ backgroundColor: color }]}
                                activeDotStyle={{ borderColor: color }}
                                handleStyle={[{ color: color }, { color: color }]}
                                step={0.1}
                                // step={this.steps[this.state.muId]}
                                // bgColor="#ccc"
                                // fgColor={color || "#b5cc18"}
                                onChange={(value) => this.setState({ value })}
                            />
                        </div>
                        <MibButton
                            label={
                                <>
                                    <Icon name="add" />
                                    {T.translate('create')}
                                </>
                            }
                            style={{ backgroundColor: '#b5cc18', color: 'white' }}
                            onClick={this.save.bind(this)}
                        />
                    </div>
                </BaseContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const userId = state.dataStore && getProperty(state, 'authentication.id');
    const userSettings = getProperty(state, `dataStore.graph.user-settings.${userId}`);
    const defaultMeasureUnit = userSettings && getUserMeasureUnit(userSettings, 'pressure');

    return {
        data: state.dataStore.graph.pressures,
        filters: state.filters,
        measureUnitFromSettings: defaultMeasureUnit,
    };
};

export const DashPressureQuickAdd = subscribeWithLoader(_DashPressureQuickAdd, mapStateToProps, [
    'pressures',
    'measure-units?with=measureUnitType',
]);
