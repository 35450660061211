import React from 'react';
import T from 'i18n-react';
import { withRouter } from 'react-router-dom';

import GeneralMetricContainer from 'Mib/components/metric_panels/general_metric';
import { openMeasurementModal } from 'Mib/actions';
import filterByMeasureSystem from 'Mib/lib/measure_units';
import { FilteredMetricComponent } from './filtered_metric';
import { subscribeWithLoader } from '../../lib/subscribe';

class _HeightContainer extends FilteredMetricComponent {
    constructor() {
        super();

        this.state = { chart_config: {}, unitId: null };

        this.open = this.open.bind(this);
        this.dataPropName = 'heights';
        this.config = {
            descriptor: 'result',
            name: T.translate('measurements.singular.height'),
            dateDescriptor: 'date',
            valueOffset: 1,
            onGraphPointClicked: this.open,
        };
    }

    open(id = undefined, type = undefined, labId) {
        const { unitId } = this.state;
        openMeasurementModal({ id, type, unitId });
    }

    render() {
        let { heights, measures, children, ...rest } = this.props;
        let { chart_config, heights: filteredHeights } = this.state;

        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate('measurements.singular.height')}
                dataType="heights"
                measureUnits={measures}
                chart_config={chart_config}
                data={filteredHeights}
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    const heightMeasureUnits = {};
    const systemMeasureUnits = filterByMeasureSystem(allmeasureUnits);

    if (systemMeasureUnits)
        Object.entries(systemMeasureUnits).forEach(([key, unit]) => {
            if (unit.measureUnitType && unit.measureUnitType.name == 'HEIGHT') heightMeasureUnits[unit.id] = unit;
        });

    return {
        heights: state.dataStore.graph.heights,
        measures: heightMeasureUnits,
        filters: state.filters,
    };
};

const _HeightContainerSub = subscribeWithLoader(_HeightContainer, mapStateToProps, [
    'heights',
    'measure-units?with=measureUnitType',
]);

export default withRouter(_HeightContainerSub);
