// export condst backendURL = 'https://betaapi.medinfobook.gr/'

let localConf = {};
try {
    localConf = require('./config.local.js');
} catch (e) {}

// export const backendURL = localConf.backendURL || 'http://api-test.medinfobook.gr/' ; //'http://api.medinfobook.gr/'
// export const authBackendURL =  localConf.authBackendURL || 'http://auth-test.medinfobook.gr/' ;//'http://auth.medinfobook.gr/'

export const backendURL = localConf.backendURL || 'https://api.medinfobook.gr/';
export const authBackendURL = localConf.authBackendURL || 'https://auth.medinfobook.gr/';
export const websocketUrl = localConf.websocketURL || 'ws://api.medinfobook.gr';

export const dataSyncTimeout = 1000 * 60 * 1;

export const Feature_Toggles = {
    tutorial: true,
    checkup: true,
    lab_categories: true,
    ...(localConf.Feature_Toggles || {}),
};
