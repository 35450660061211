/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Icon, TransitionablePortal, Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import moment from 'moment';
import revalidator from 'revalidator';

import { store } from 'Mib/datastore/store';
import { notify } from 'Mib/actions/notifications';
import filterByMeasureSystem from 'Mib/lib/measure_units';
import { FormModal } from 'Mib/components/modals';
import { Fetch } from 'Mib/lib/fetch';

import BaseContainer from '../containers/base';
import {
    TextInput,
    TextAreaInput,
    DropDownInput,
    MibButton,
    CalendarInput,
    DocumentInput,
    TagRemoteInput,
    RemoteInput,
} from '../../components/inputs';
import { Create, Update, Delete } from '../../actions';

import ModelForm from '../../components/forms/model_form';

import {
    Measurement,
    Pressure,
    LabTest,
    Document,
    Note,
    PersonDrug,
    UserAllergy,
    Drug,
    Allergy,
    PersonSingleUseDrug,
} from '../../lib/models';

require('./form_modal.scss');

const DrugRemoteInput = RemoteInput({
    modelName: 'drugs',
    endPoint: 'drugs',
    Model: Drug,
});
const AllergyRemoteInput = RemoteInput({
    modelName: 'allergies',
    endPoint: 'allergies',
    Model: Allergy,
});

const MUTypesMap = {
    weights: 'WEIGHT',
    heights: 'HEIGHT',
    temperatures: 'TEMPERATURE',
    pressures: 'PRESSURE',
    pulses: 'PRESSURE',
    oximetries: 'OXIMETRY',
    'lab-exams': 'LAB',
    'lab-exam-events': 'LAB',
    waists: 'HEIGHT',
    heads: 'HEIGHT',
};

class _GlobalModal extends Component {
    constructor() {
        super();
        this.state = { open: false };
    }

    static getDerivedStateFromProps(newProps, oldState) {
        const { currentSignal } = oldState;
        const { signals = [], signalType, signalProccessed } = newProps;
        const relevantSignals = signals.filter((s) => s.signalType == signalType);

        if (currentSignal) return null;
        if (!relevantSignals.length) return null;

        const signal = relevantSignals[0];
        const { title, content } = signal;
        signalProccessed(signal);
        return { open: true, currentSignal: signal, title, content };
    }

    open(contact) {
        this.setState({ open: true });
    }

    close() {
        this.setState({ open: false, currentSignal: null });
    }

    render() {
        const { loading } = this.props;
        const { title, content, open } = this.state;
        return (
            <>
                <TransitionablePortal
                    closeOnDocumentClick={true}
                    onOpen={this.open.bind(this)}
                    onClose={this.close.bind(this)}
                    open={open}
                >
                    <BaseContainer className="mib-modal modal" loading={loading}>
                        <div className="mib-modal-title">
                            {title}
                            <Icon name="x" className="close-button" onClick={this.close.bind(this)} />
                        </div>
                        {content}
                    </BaseContainer>
                </TransitionablePortal>
                <Dimmer active={open} onClickOutside={this.close.bind(this)} page />
            </>
        );
    }
}

const mapStateToProps = ({ appSignals: { signals }, authentication: { id }, dataStore: { graph } }) => ({
    signals: signals,
    userSettings: getProperty(graph, `user-settings.${id}`),
});

const mapDispatchToProps = (dispatch) => ({
    signalProccessed: ({ signalType }) => dispatch({ type: 'SIGNAL_PROCCESSED', payload: { signalType } }),
});

export const GlobalModal = connect(mapStateToProps, mapDispatchToProps)(_GlobalModal);

class _GlobalMFModal extends Component {
    constructor() {
        super();
        // this.open = this.open.bind(this);
        this.state = { model: {}, loading: false };
        Measurement.Validator = {
            properties: {
                result: {
                    required: true,
                    pattern: /^[0-9\.]+$/,
                    minLength: 1,
                    messages: {
                        pattern: T.translate('errors.must-be-number'),
                        minLength: T.translate('errors.not-blank'),
                        required: T.translate('errors.required'),
                    },
                },
                date: {
                    required: true,
                    conform: (value) => moment(value).isValid() && value != null,
                    messages: {
                        conform: T.translate('errors.required'),
                    },
                },
                measureUnit: {
                    required: true,
                    minLength: 1,
                    messages: {
                        required: T.translate('errors.required'),
                    },
                },
            },
        };
    }

    // open(id = undefined, type = undefined, lab_type = null) {
    //     const model = id
    //         ? this.props[type][id].makeDeepCopy()
    //         : type == "documents"
    //             ? Document()
    //             : type == "pressures"
    //                 ? Pressure(null, null, null, 3)
    //                 : type == "lab-exam-events" ? LabTest(null, lab_type) : Measurement(type, null, 1);
    //
    //     this.setState({ model: model, action: id == null ? "create" : "edit" });
    //     this.modal.open();
    // }

    getDefaultUnitId(type) {
        const { userSettings } = this.props;
        switch (type) {
            case 'waists':
            case 'heights':
                return getProperty(userSettings, 'heightUnit.id');
            case 'weights':
                return getProperty(userSettings, 'weightUnit.id');
            case 'pressures':
                return getProperty(userSettings, 'pressureUnit.id');
            case 'temperatures':
                return getProperty(userSettings, 'temperatureUnit.id');
            case 'oximetries':
                return getProperty(userSettings, 'oximetryUnit.id');
            case 'lab-exam-events':
                return getProperty(userSettings, 'labUnit.id');
        }
    }

    close() {
        this.setState({
            model: {},
            errors: [],
            action: null,
            loading: false,
            id: undefined,
            type: undefined,
            lab_type: undefined,
            measures: undefined,
            formItems: undefined,
            open: false,
        });
        this.modal.close();
    }

    delete() {
        Delete(this.state.model).then((res) => {
            this.setState({ loading: false });
            this.close();
        });
    }

    save() {
        // If already saving, don't do it again
        if (this.saving) return null;
        this.saving = true;
        let { errors } = this.state;

        let valid = revalidator.validate(this.state.model, this.state.model.Validator);
        // console.log('thew validator is ',model,validator,valid)
        errors = valid.errors.map((err) => ({
            property: err.property,
            message: err.message,
        }));
        this.setState({ errors: errors });
        // this.state.errors && this.state.errors(errors);
        if (Array.isArray(errors) && errors.length) {
            this.saving = false;
            return;
        }
        this.setState({ loading: true });
        let unitId = getProperty(this.state, 'model.measureUnit.id');

        if (this.state.action === 'edit') {
            console.log('this state model on save edit', this.state.model);
            Update(this.state.model, 'api', unitId)
                .then((res) => {
                    this.setState({ loading: false });
                    this.close();
                    this.saving = false;
                })
                .catch((e) => {
                    this.saving = false;
                    let error_list = e.errors || [];
                    let errors = error_list.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    try {
                        this.setState({ errors });
                    } catch (e) {}
                });

            console.log('after', this.state.model);
        } else {
            Create(this.state.model, 'api', unitId)
                .then((res) => {
                    this.saving = false;
                    this.setState({ loading: false });
                    this.close();
                    store.dispatch({
                        type: 'SEND_SIGNAL',
                        payload: {
                            signalType: 'ENTITY_CREATED',
                            entity: this.state.type,
                        },
                    });
                })
                .catch((e) => {
                    this.saving = false;
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        }
    }

    assignValidator(model, type, lab_type = null) {
        switch (type) {
            case 'documents':
                model.Validator = Document().Validator;

                break;
            case 'pressures':
                model.Validator = Pressure(null, null, null, 3).Validator;
                break;
            case 'pulses':
                model.Validator = Pressure(null, null, null, 3).PulsesValidator;
                break;
            case 'lab-exam-events':
                model.Validator = LabTest(null, lab_type).Validator;
                break;
            case 'person-notes':
                model.Validator = Note({}).Validator;
                break;
            case 'person-drugs':
                model.Validator = PersonDrug.Validator;
                break;
            case 'person-drug-usages':
                model.Validator = PersonSingleUseDrug.Validator;
                break;
            case 'person-allergies':
                model.Validator = UserAllergy.Validator;

                break;
            default:
                model.Validator = Measurement(type, null, 1).Validator;
        }
    }

    componentWillReceiveProps(newProps) {
        const state = store.getState();
        const { open } = this.state;
        const { signals = [], signalType, signalProccessed } = newProps;
        const relevantSignals = signals.filter((s) => s.signalType == signalType);

        // if (open) return null;
        if (!relevantSignals.length) return null;

        const signal = relevantSignals[0];
        const { id, type, lab_type, formItems, modalTitle, showTime, skipUnits = false } = signal;
        let { unitId } = signal;

        console.log('THIS IS THE SIGNAL IN GLOBAL MODAL', signal);

        const mu = getProperty(state, `dataStore.graph.measure-units.${signal.unitId}`);
        let measures = [];

        measures = Object.values(getProperty(state, `dataStore.graph.measure-units`) || {}).filter((mt) => {
            if (signal.lab_type) {
                let labExamp = getProperty(state, `dataStore.graph.lab-exams.${signal.lab_type}`);
                let muTypeId = labExamp.measure_unit_type_id;
                return mt.measureUnitType.id == muTypeId;
            } else {
                return mt.measureUnitType.name == MUTypesMap[type];
            }
        });

        const measuresInSystem = filterByMeasureSystem(measures);
        // TODO TEST

        let model;
        console.log('ib modal', type, lab_type, id);
        if (id) {
            model = getProperty(state, `dataStore.graph.${type == 'pulses' ? 'pressures' : type}.${id}`).makeDeepCopy();
        } else {
            switch (type) {
                case 'person-drugs':
                    model = PersonDrug();
                    break;
                case 'person-drug-usages':
                    model = PersonSingleUseDrug();
                    break;
                case 'person-allergies':
                    model = UserAllergy();
                    break;
                case 'documents':
                    model = Document();
                    break;
                case 'pressures':
                    model = Pressure(null, null, null, 3);
                    break;
                case 'pulses':
                    model = Pressure(null, null, null, 3);
                    model.Validator = model.PulsesValidator;
                    break;
                case 'lab-exam-events':
                    model = LabTest(null, lab_type);
                    break;
                case 'person-notes':
                    model = Note({});
                    break;
                default:
                    model = Measurement(type, null, 1);
            }
        }

        if (!model.Validator || this.shouldForceValidator) {
            this.assignValidator(model, type, lab_type);
        }

        if (!skipUnits) {
            unitId = unitId != null || unitId != undefined ? unitId : this.getDefaultUnitId(type);
            let measureUnit = store.getState().dataStore.find('measure-units', unitId);
            model.setRelationship('measureUnit', measureUnit);
        }

        this.setState({
            model: model,
            action: id == null ? 'create' : 'edit',
            id,
            type,
            lab_type,
            measures: measuresInSystem,
            formItems,
            unitId,
            modalTitle,
            showTime,
            open: true,
        });
        signalProccessed(signal);
        this.modal.open();
    }

    render() {
        let { formItems, showTime, measures, modalTitle, action } = this.state;
        const measureUnitOptions = Object.values(measures || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.abbreviation,
        }));

        if (!formItems) {
            formItems = [
                {
                    Element: TextInput,
                    label: 'measurements.result',
                    fieldName: 'result',
                    replaceComma: true,
                },
                {
                    Element: CalendarInput,
                    label: 'measurements.date',
                    fieldName: 'date',
                    time: showTime,
                    showMonthDropdown: true,
                    showYearDropdown: true,
                },
                {
                    Element: DropDownInput,
                    label: 'measurements.measure-unit',
                    fieldName: 'measureUnit',
                    options: measureUnitOptions,
                    attrib: 'id',
                    search: true,
                },
                {
                    Element: TagRemoteInput,
                    label: 'measurements.tags',
                    fieldName: 'tags',
                    multiple: true,
                    attrib: 'tag',
                },
                {
                    Element: TextAreaInput,
                    label: 'measurements.comments',
                    fieldName: 'comments',
                },
            ];
        }

        return (
            <FormModal
                ref={(i) => (this.modal = i)}
                title={
                    this.state.action &&
                    T.translate(`measurements.${this.state.action}`) +
                        ' ' +
                        (modalTitle || T.translate(`measurements.genitive.${this.state.model._type}`))
                }
                loading={this.state.loading}
            >
                <ModelForm
                    model={this.state.model}
                    validator={this.state.model.Validator}
                    onChange={(model) => this.setState({ model: model })}
                    onValidate={(errors) => this.setState({ errors })}
                    items={formItems}
                    errors={this.state.errors}
                />
                <div className="mib-modal-footer controls">
                    {action === 'edit' && (
                        <MibButton
                            label={T.translate('buttons.delete')}
                            type="danger"
                            onClick={this.delete.bind(this)}
                        />
                    )}
                    <MibButton label={T.translate('buttons.close')} type="warning" onClick={this.close.bind(this)} />
                    <MibButton label={T.translate('buttons.save')} type="success" onClick={this.save.bind(this)} />
                </div>
            </FormModal>
        );
    }
}

export const GlobalMFModal = connect(mapStateToProps, mapDispatchToProps)(_GlobalMFModal);

class _GlobalLabExamDocModal extends _GlobalMFModal {
    constructor() {
        super();
        this.shouldForceValidator = true;
    }

    assignValidator(model, type, lab_type = null) {
        model.Validator = { ...Measurement(type, null, 1).Validator };
        delete model.Validator.properties.result;
        let fileArrayName = model.documents.length != 0 ? [model.documents[0].file] : [];
        // let fileArrayName  =  [];
        console.log('model length', model.documents.length);
        model.setAttribute('files', fileArrayName);

        console.log('model at assignValidator', model);
    }

    getDefaultUnitId(type) {
        return 0;
    }
    async onSelectFile(props, files) {
        this.setState({ fileUploading: true });
        const { model, onChange, validator, onValidate } = props;
        const file = files[0];
        const state = store.getState();
        const activePersonId = getProperty(state, 'authentication.activePersonId');
        let url = `persons/${activePersonId}/upload-documents`;
        const data = new FormData();
        data.append('documents', file);

        let fileData;
        try {
            fileData = await Fetch(url, { method: 'POST', body: data }, 'api', true);
        } catch (e) {
            // No more space
            if (e.status == 488) {
                notify({
                    error: true,
                    header: T.translate('document-modal.no-space-error-title'),
                    content: T.translate('document-modal.no-space-error-text'),
                });
            } else {
                notify({
                    error: true,
                    header: T.translate('document-modal.upload-error-title'),
                    content: T.translate('document-modal.upload-error-text', { error_code: e.status }),
                });
            }
        }
        this.setState({ fileUploading: false });
        let first_element = await fileData[0];
        model.files = [await first_element['name']];
        model.documents = [await first_element['name']];
        console.log('on upload ', model.file, model.documents);
        onChange(model);
        if (model.file) {
            onValidate && onValidate([]);
        }
    }

    delete() {
        if (confirm(T.translate('confirm-delete'))) {
            Delete(this.state.model).then((res) => {
                this.setState({ loading: false });
                this.close();
            });
        }
    }

    render() {
        let { showTime, modalTitle, action, fileUploading } = this.state;
        let edit = this.state.action == 'edit' ? true : false;

        let formItems = [
            {
                Element: TextInput,
                label: 'measurements.comments',
                fieldName: 'comments',
            },
            {
                Element: DocumentInput,
                fieldName: 'file',
                onChange: this.onSelectFile.bind(this),
                fileUploading: fileUploading,
                edit: edit,
            },

            {
                Element: CalendarInput,
                label: 'measurements.date',
                fieldName: 'date',
            },
            {
                Element: TagRemoteInput,
                label: 'measurements.tags',
                fieldName: 'tags',
                multiple: true,
                attrib: 'tag',
            },
        ];

        return (
            <FormModal
                ref={(i) => (this.modal = i)}
                title={
                    this.state.action &&
                    T.translate(`measurements.${this.state.action}`) +
                        ' ' +
                        (modalTitle || T.translate(`measurements.genitive.${this.state.model._type}`))
                }
                loading={this.state.loading}
            >
                <ModelForm
                    model={this.state.model}
                    validator={this.state.model.Validator}
                    onChange={(model) => this.setState({ model: model })}
                    onValidate={(errors) => this.setState({ errors })}
                    items={formItems}
                    errors={this.state.errors}
                    editDoc={true}
                />
                <div className="mib-modal-footer controls">
                    {action === 'edit' && (
                        <MibButton
                            label={T.translate('buttons.delete')}
                            type="danger"
                            onClick={this.delete.bind(this)}
                        />
                    )}
                    <MibButton label={T.translate('buttons.close')} type="warning" onClick={this.close.bind(this)} />
                    <MibButton
                        label={T.translate('buttons.save')}
                        type="success"
                        disabled={fileUploading}
                        loading={fileUploading}
                        onClick={this.save.bind(this)}
                    />
                </div>
            </FormModal>
        );
    }
}

class _GlobalDocModal extends _GlobalMFModal {
    constructor() {
        super();
    }

    async onSelectFile(props, files) {
        this.setState({ fileUploading: true });
        const { model, onChange, validator, onValidate } = props;
        const file = files[0];
        const state = store.getState();
        const activePersonId = getProperty(state, 'authentication.activePersonId');
        let url = `persons/${activePersonId}/upload-documents`;
        const data = new FormData();
        data.append('documents', file);
        let fileData;
        try {
            fileData = await Fetch(url, { method: 'POST', body: data }, 'api', true);
        } catch (e) {
            // No more space
            if (e.status == 488) {
                notify({
                    error: true,
                    header: T.translate('document-modal.no-space-error-title'),
                    content: T.translate('document-modal.no-space-error-text'),
                });
            } else {
                notify({
                    error: true,
                    header: T.translate('document-modal.upload-error-title'),
                    content: T.translate('document-modal.upload-error-text', { error_code: e.status }),
                });
            }
        }
        this.setState({ fileUploading: false });
        let first_element = await fileData[0];
        model.file = await first_element['name'];
        onChange(model);
        if (model.file) {
            onValidate && onValidate([]);
        }
    }

    delete() {
        if (confirm(T.translate('confirm-delete'))) {
            Delete(this.state.model).then((res) => {
                this.setState({ loading: false });
                this.close();
            });
        }
    }

    render() {
        let { showTime, measures, modalTitle, action, fileUploading } = this.state;
        let { categories } = this.props;
        const categoriesOptions = Object.values(categories || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        let formItems = [
            {
                Element: TextInput,
                label: 'measurements.doc-title',
                fieldName: 'title',
            },
            {
                Element: DocumentInput,
                fieldName: 'file',
                onChange: this.onSelectFile.bind(this),
                fileUploading: fileUploading,
            },
            {
                Element: DropDownInput,
                label: 'categories',
                fieldName: 'category',
                options: categoriesOptions,
                attrib: 'id',
            },
            {
                Element: CalendarInput,
                label: 'measurements.date',
                fieldName: 'date',
            },
            {
                Element: TagRemoteInput,
                label: 'measurements.tags',
                fieldName: 'tags',
                multiple: true,
                attrib: 'tag',
            },
        ];

        const measureUnitOptions = [];

        return (
            <FormModal
                ref={(i) => (this.modal = i)}
                title={
                    this.state.action &&
                    T.translate(`measurements.${this.state.action}`) +
                        ' ' +
                        (modalTitle || T.translate(`measurements.genitive.${this.state.model._type}`))
                }
                loading={this.state.loading}
            >
                <ModelForm
                    model={this.state.model}
                    validator={this.state.model.Validator}
                    onChange={(model) => this.setState({ model: model })}
                    onValidate={(errors) => this.setState({ errors })}
                    items={formItems}
                    errors={this.state.errors}
                />
                <div className="mib-modal-footer controls">
                    {action === 'edit' && (
                        <MibButton
                            label={T.translate('buttons.delete')}
                            type="danger"
                            onClick={this.delete.bind(this)}
                        />
                    )}
                    <MibButton label={T.translate('buttons.close')} type="warning" onClick={this.close.bind(this)} />
                    <MibButton
                        label={T.translate('buttons.save')}
                        type="success"
                        disabled={fileUploading}
                        loading={fileUploading}
                        onClick={this.save.bind(this)}
                    />
                </div>
            </FormModal>
        );
    }
}

const mapStateToPropsForDocs = ({ appSignals: { signals }, authentication: { id }, dataStore: { graph } }) => ({
    categories: graph.categories,
    signals: signals,
    userSettings: getProperty(graph, `user-settings.${id}`),
});

export const GlobalDocModal = connect(mapStateToPropsForDocs, mapDispatchToProps)(_GlobalDocModal);

export const GlobalLabExamDocModal = connect(mapStateToPropsForDocs, mapDispatchToProps)(_GlobalLabExamDocModal);
