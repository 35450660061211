import React from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import sortOn from 'sort-on';
import moment from 'moment';

import { SimpleMetricComponent } from 'Mib/components/metric_panels/filtered_metric';
import ReactHighChartsManaged from 'Mib/components/generic/react_high_charts_managed';
import BaseContainer from 'Mib/components/containers/base';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { store } from 'Mib/datastore/store';
import { MeasurementInfoPanel } from './measurements_info';
import NoDataMessage from './no_data_message';

class _DashHeightChart extends SimpleMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.dataPropName = 'heights';
        this.config = {
            descriptor: 'result',
            name: T.translate('measurements.singular.height'),
            dateDescriptor: 'date',
            onGraphPointClicked: this.open.bind(this),
            valueOffset: 0,
            merge_config: (props, cfg) => ({
                chart: {
                    height: 200,
                },
                series: [
                    {
                        ...cfg.series[0],
                        showInLegend: false,
                        color: props.color,
                    },
                ],
                title: { floating: true, text: '' },
                xAxis: {
                    title: {
                        text: '',
                    },
                },
            }),
        };
    }

    componentWillReceiveProps(newProps) {
        this.forceRefresh = newProps.color != this.props.color;
        super.componentWillReceiveProps(newProps);
    }

    render() {
        let { chart_config } = this.state;
        let { heights, loading, measures, color } = this.props;
        const lastMeasurement = heights && sortOn(Object.values(heights), '-date')[0];
        const measureDate = moment(getProperty(lastMeasurement, 'date')).format('L');
        const measureUnitAbbrev = getProperty(lastMeasurement, 'measureUnit.abbreviation');
        const value = lastMeasurement ? lastMeasurement.result + measureUnitAbbrev : undefined;
        return (
            <>
                <MeasurementInfoPanel
                    style={{ borderTopColor: color, borderTopWidth: '2px' }}
                    title={T.translate('measurements.my.my-height-graph')}
                    date={measureDate}
                    value={value}
                    altText={T.translate('no-data')}
                    loading={loading}
                    color={this.props.color}
                    link="/health-folder/heights"
                />
                <BaseContainer loading={loading} className="dash-chart-container">
                    {loading ? (
                        <div style={{ width: '100%', height: '200px' }} />
                    ) : heights ? (
                        <ReactHighChartsManaged config={chart_config} />
                    ) : (
                        <NoDataMessage create={() => this.open(null, 'heights')} />
                    )}
                </BaseContainer>
            </>
        );
    }

    open(id = undefined, type = undefined) {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'MEASUREMENT_MODAL',
                id: id,
                type: type,
            },
        });
    }
}

const mapStateToProps = (state) => {
    const allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    const weightMeasureUnits = {};

    if (allmeasureUnits)
        Object.entries(allmeasureUnits).forEach(([key, unit]) => {
            // console.log('measureUnitType',unit)
            if (unit.measureUnitType && unit.measureUnitType.name == 'HEIGHT') {
                weightMeasureUnits[unit.id] = unit;
            }
        });

    return {
        heights: state.dataStore.graph.heights,
        measures: weightMeasureUnits,
        filters: state.filters,
    };
};

export const DashHeightChart = subscribeWithLoader(_DashHeightChart, mapStateToProps, ['heights']);

// export default connect(mapStateToProps)(_DashHeightChartSub);
