import React, { useEffect, useState } from 'react';

import { Dropdown } from 'semantic-ui-react';

import { fetchAction } from '../../actions/backend';

function MeasureUnitSelect({ unitId, measureUnits, resourceType, onCreate, configUpdateUnitFunc }) {
    const [localUnitId, setLocalUnitId] = useState(null);

    useEffect(() => {
        if (measureUnits.length) {
            if (unitId) {
                setLocalUnitId(unitId);
            } else {
                setLocalUnitId(measureUnits[0].value);
            }
        }
    }, [measureUnits, unitId]);

    const changeUnits = (e, obj) => {
        const unitId = obj.value;

        fetchAction(resourceType, { query: `?unit=${unitId}`, force: true }).then(() => {
            configUpdateUnitFunc(unitId);
            // testFunction.myfunc = onCreate.bindArgs(unitId);
        });
    };

    return (
        <Dropdown
            placeholder="units"
            options={measureUnits}
            selection
            onChange={changeUnits}
            value={localUnitId}
            style={{ minWidth: '0' }}
        />
    );
}

export default MeasureUnitSelect;
