import React, { Component } from 'react';
import { Icon, Image, Segment, Label, Message, Transition } from 'semantic-ui-react';
import { SearchInput } from '../../../components/inputs/form_inputs';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import moment from 'moment';
import { Fetch } from '../../../lib/fetch';
import { store } from '../../../datastore/store';

import { subscribeWithLoader } from '../../../lib/subscribe';
import { ProviderCard } from './providerCard';
import { PermissionsCard } from './permissionsCard';
import ProviderPermissionsCard from './provider_permissions_card';

class _ConnectMedicalLabSteps extends Component {
    constructor() {
        super();
        this.state = { refCode: '', notFind: false, done: false, permissionCard: false };
    }

    render() {
        return (
            <div>
                <Segment className="providerSegmentSize">
                    {/*<Label as='a' color='blue' ribbon>{moment().format('L, H:mm')}</Label>*/}
                    <div className="searchBarContainer">
                        <div className="searchBarPosition">
                            <SearchInput
                                onKeyDown={(e) => {
                                    if (e.key == 'Enter') this.connectProvider();
                                }}
                                iconName="check"
                                placeholder={T.translate('providers.placeholder')}
                                onChange={(e, v) => this.setState({ refCode: e.target.value })}
                                onClick={this.connectProvider.bind(this)}
                            />
                        </div>
                    </div>

                    <div style={{ paddingTop: '15px' }}>
                        {this.state.notFind ? (
                            <Message
                                icon="remove circle"
                                header={T.translate('providers.connectMedicalLabFailTitle')}
                                content={
                                    this.state.errors.length
                                        ? this.state.errors.map((err) => <div>{err.detail}</div>)
                                        : T.translate('providers.connectMedicalLabFail')
                                }
                                negative
                            />
                        ) : this.state.done ? (
                            <Message
                                icon="checkmark circle"
                                header={T.translate('providers.connectMedicalLabSuccessTitle')}
                                content={T.translate('providers.connectMedicalLabSuccess')}
                                positive
                            />
                        ) : (
                            <Message
                                icon="info circle"
                                header={T.translate('providers.connectMedicalLabInfoTitle')}
                                content={T.translate('providers.connectMedicalLabInfo')}
                                info
                            />
                        )}
                    </div>
                </Segment>

                {this.props.personProviders &&
                    this.props.personProviders.map((item, index) => (
                        <ProviderPermissionsCard provider={item} readOnly={true} />
                    ))}
            </div>
        );
    }

    onPermissionsEdit() {
        this.setState({ permissionCard: true });
    }

    onSavePermissions() {
        this.setState({ permissionCard: false });
    }

    async allConects() {
        const state = store.getState();
        const activePersonId = getProperty(state, 'authentication.activePersonId');
        let allConnects = async () =>
            await Fetch(`persons/${activePersonId}/providers`, { method: 'GET' }, 'auth')
                .catch((e) => {})
                .then();
    }

    async connectProvider() {
        const state = store.getState();
        const activePersonId = getProperty(state, 'authentication.activePersonId');
        let catchErrors = [];
        let query = this.state.refCode.trim();
        let doctor = await Fetch(`persons/${activePersonId}/bind-provider/${query}`, { method: 'POST' }, 'auth')
            .catch(async (e) => {
                if (e.status == 404) {
                    catchErrors = [{ detail: T.translate('providers.connectMedicalLabFail') }];
                } else {
                    try {
                        const { errors } = await e.json();
                        catchErrors = errors;
                    } catch (e) {
                        catchErrors = [{ detail: T.translate('errors.unknown') }]; //TODO roll bar
                    }
                }

                this.setState({ notFind: true, done: false, errors: catchErrors });
            })
            .then(this.setState({ done: true, notFind: false, errors: [] }));
    }
}

const mapStateToProps = ({ dataStore }) => {
    const allPersonProviders = getProperty(dataStore, 'graph.person-providers');
    const personProviders =
        allPersonProviders &&
        Object.values(allPersonProviders).filter((p) => {
            return p.providerType.type == 'Organisation';
        });

    return {
        personProviders: personProviders,
    };
};

export const ConnectMedicalLabSteps = subscribeWithLoader(_ConnectMedicalLabSteps, mapStateToProps, [
    'person-providers?type=2',
]);
