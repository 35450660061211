import { withRouter } from 'react-router-dom';
import React from 'react';
import T from 'i18n-react';

import { FilteredMetricComponent } from './filtered_metric';
import { subscribeWithLoader } from '../../lib/subscribe';
import GeneralMetricContainer from 'Mib/components/metric_panels/general_metric';
import MeasurementFormModal from '../modals/measurement_form_modal';
import { openMeasurementModal } from 'Mib/actions';
import filterByMeasureSystem from 'Mib/lib/measure_units';

class _WeightContainer extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.open = this.open.bind(this);
        this.dataPropName = 'weights';
        this.config = {
            descriptor: 'result',
            name: T.translate('measurements.singular.weight'),
            dateDescriptor: 'date',
            // valueOffset: 10,
            onGraphPointClicked: this.open,
            merge_config: ((props, conf) => ({
                yAxis: {
                    plotLines: [
                        {
                            color: 'rgba(184,233,134,1)',
                            dashStyle: 'dash',
                            width: 2,
                            value: props.weightTarget,
                            label: {
                                align: 'right',
                                style: {
                                    fontStyle: 'italic',
                                    color: 'rgba(184,233,134,1)',
                                },
                                text: T.translate('persons.weightGoal'),
                                x: -10,
                            },
                            zIndex: 3,
                        },
                    ],
                    min: props.weightTarget ? Math.min(props.weightTarget, conf.yAxis.min) : conf.yAxis.min,
                    max: props.weightTarget ? Math.max(props.weightTarget, conf.yAxis.max) : conf.yAxis.max,
                },
            })).bind(this),
        };
        this.onDataChanged = [];
    }

    componentWillReceiveProps(newProps) {
        const { weightTarget } = newProps;
        const { weightTarget: oldWeightTarget } = this.props;
        // const oldWeightTarget = this.props.weightTarget;
        if (weightTarget != oldWeightTarget) {
            this.forceRefresh = true;
        }
        super.componentWillReceiveProps(newProps);
    }

    render() {
        let { weights, weightTarget, measures, tagFilters, children, ...rest } = this.props;
        let { chart_config, weights: filteredWeights } = this.state;

        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate('measurements.singular.weight')}
                measureUnits={measures}
                chart_config={chart_config}
                data={filteredWeights}
                dataType="weights"
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
            />
        );
    }

    open(id, type) {
        const { unitId } = this.state;
        openMeasurementModal({ id, type, unitId });
    }
}

const mapStateToProps = (state) => {
    var allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    var weightMeasureUnits = {};
    var systemMeasureUnits = filterByMeasureSystem(allmeasureUnits);
    systemMeasureUnits &&
        Object.entries(systemMeasureUnits).forEach(([key, unit]) => {
            if (unit.measureUnitType && unit.measureUnitType.name == 'WEIGHT') {
                weightMeasureUnits[unit.id] = unit;
            }
        });
    return {
        weights: state.dataStore.graph.weights,
        measures: weightMeasureUnits,
        weightTarget: state.dataStore.graph.meta && state.dataStore.graph.meta.weightTarget,
        filters: state.filters,
    };
};

export default withRouter(
    subscribeWithLoader(_WeightContainer, mapStateToProps, ['weights', 'measure-units?with=measureUnitType'])
);

// export default connect(mapStateToProps)(_WeightContainerSub);
