/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import T from 'i18n-react';

import BaseContainer from '../containers/base';
// import { Radio } from 'semantic-ui-react';
import { CreateButton } from '../inputs/buttons';
import { AdvancedRangeCalendar } from '../inputs/calendars/advancedRangeCalendarInput';

import SelectMeasureUnit from '../generic/measure_unit_select';

export function GeneralMetricControls({
    onCreate,
    configUpdateUnitFunc,
    measureUnitOptions,
    resourceType,
    dateFrom,
    dateTo,
    onStartChanged,
    onEndChanged,
    tagFilters,
    toggleView,
    unitId,
}) {
    const [measuringUnit, setMeasuringUnit] = useState([]);

    useEffect(() => {
        if (Object.keys(measureUnitOptions).length) setMeasuringUnit(measureUnitOptions);
    }, [measureUnitOptions]);

    // console.log('unitId', unitId);

    return (
        <BaseContainer className="metric-controls">
            {onCreate != null ? <CreateButton onClick={onCreate} /> : null}
            {Object.keys(measuringUnit).length !== 0 ? (
                <SelectMeasureUnit
                    configUpdateUnitFunc={configUpdateUnitFunc}
                    onCreate={onCreate}
                    resourceType={resourceType}
                    measureUnits={measuringUnit}
                    unitId={unitId}
                />
            ) : null}
            <AdvancedRangeCalendar
                startDate={dateFrom}
                endDate={dateTo}
                onStartChanged={onStartChanged}
                onEndChanged={onEndChanged}
                fromLabel={T.translate('meta.from')}
                toLabel={T.translate('meta.to')}
                tagFilters={tagFilters}
            />
            {/* <Radio toggle onChange={toggleView} /> */}
        </BaseContainer>
    );
}
