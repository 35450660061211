import React, { Component } from 'react';
import { TextInput, DropDownInput, MibButton, CalendarInput, TagRemoteInput } from '../../components/inputs';
import T from 'i18n-react';
import { Create, Update, Delete } from '../../actions';

import ModelForm from '../../components/forms/model_form';
import { FormModal } from 'Mib/components/modals';
import { Measurement, Pressure, LabTest, Document } from '../../lib/models';

export default class MeasurementFormModal extends Component {
    constructor() {
        super();
        this.open = this.open.bind(this);
        this.state = { model: {}, loading: false };
    }

    render() {
        let { formItems, showTime } = this.props;
        const measureUnitOptions = Object.values(this.props.measures || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        if (!formItems) {
            formItems = [
                { Element: TextInput, label: 'measurements.result', fieldName: 'result', replaceComma: true },
                { Element: CalendarInput, label: 'measurements.date', fieldName: 'date', time: showTime },
                {
                    Element: DropDownInput,
                    label: 'measurements.measure-unit',
                    fieldName: 'measureUnit',
                    options: measureUnitOptions,
                    attrib: 'id',
                },
                { Element: TextInput, label: 'measurements.comments', fieldName: 'comments' },
                {
                    Element: TagRemoteInput,
                    label: 'measurements.tags',
                    fieldName: 'tags',
                    multiple: true,
                    attrib: 'tag',
                },
            ];
        }

        return (
            <FormModal
                ref={(i) => (this.modal = i)}
                title={
                    this.state.action &&
                    T.translate(`measurements.${this.state.action}`) +
                        ' ' +
                        T.translate(`measurements.genitive.${this.state.model._type}`)
                }
                loading={this.state.loading}
            >
                <ModelForm
                    model={this.state.model}
                    validator={Measurement.Validator}
                    onChange={(model) => this.setState({ model: model })}
                    items={formItems}
                />
                <div className="mib-modal-footer controls">
                    <MibButton label={T.translate('buttons.close')} type="danger" onClick={this.close.bind(this)} />
                    <MibButton label={T.translate('buttons.save')} type="success" onClick={this.save.bind(this)} />
                </div>
            </FormModal>
        );
    }

    open(id = undefined, type = undefined, lab_type = null) {
        const model = id
            ? this.props[type][id].makeDeepCopy()
            : type == 'documents'
            ? Document()
            : type == 'pressures'
            ? Pressure(null, null, null, 3)
            : type == 'lab-exam-events'
            ? LabTest(null, lab_type)
            : Measurement(type, null, 1);

        this.setState({ model: model, action: id == null ? 'create' : 'edit' });
        this.modal.open();
    }
    close() {
        this.setState({ model: {}, errors: [], action: null, loading: false });
        this.modal.close();
    }
    delete() {
        Delete(this.state.model).then((res) => {
            this.setState({ loading: false });
            this.close();
        });
    }
    save() {
        this.setState({ loading: true });
        if (this.state.action === 'edit') {
            Update(this.state.model, 'api', this.props.unitId).then((res) => {
                this.setState({ loading: false });
                this.close();
            });
        } else {
            Create(this.state.model, 'api', this.props.unitId).then((res) => {
                this.setState({ loading: false });
                this.close();
            });
        }
    }
}
