import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import classnames from 'classnames';
import { store } from '../../datastore/store';
import { JsonApiDataStoreModel } from 'Mib/vendor/jsonapi-datastore';
import { serializeWithTags } from 'Mib/lib/json-api';
import { Fetch } from '../../lib/fetch';
import { getProperty } from 'dot-prop';

export class TagRemoteInput extends Component {
    constructor() {
        super();
        this.state = {
            isFetching: false,
            multiple: true,
            search: true,
            searchQuery: null,
            value: null,
            options: [],
            added_options: this.getExistingItems(),
        };

        this.hoho = new Promise((resolve) => {
            this.resolve = resolve;
        });
    }

    componentDidMount() {
        this.resolve();
    }
    focus() {
        // this.dropdown.searchRef.focus();
    }
    getExistingItems() {
        const state = store.getState();
        const existingTags = Object.values(getProperty(state, 'dataStore.graph.tags') || {}).map((tag) => ({
            key: tag.id,
            text: tag.tag,
            value: tag.tag,
        }));
        return existingTags;
    }

    componentWillMount() {
        this.setState({ value: this.props.value });
    }
    componentWillReceiveProps(newProps) {
        if (newProps.value != this.state.value) {
            this.setState({ value: newProps.value });
        }
    }

    handleChange(e, item) {
        let newItem;
        let { value, options } = item;
        if (value === undefined) return null;
        const extra_opts = this.state.added_options;
        if (Array.isArray(value)) {
            value.forEach((v) => {
                if (extra_opts.every((ex) => ex.value !== v)) {
                    newItem = options.find((i) => i.value == v);
                    if (newItem) {
                        extra_opts.push(newItem);
                    }
                }
            });
        } else {
            if (extra_opts.every((ex) => ex.value !== item.value)) {
                newItem = options.find((i) => i.value == item.value);
                if (newItem) {
                    extra_opts.push(newItem);
                }
            }
        }

        extra_opts.forEach((itm) => {
            let tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('tag', itm.value);
            tagObj.setAttribute('id', itm.key);
            const payload = serializeWithTags(tagObj);
            store.dispatch({ type: 'SET_DATA', payload: payload });
        });

        this.setState({ added_options: extra_opts, value: value });
        this.props.onChange(e, item);
    }

    async handleSearchChange(e, { searchQuery }) {
        const state = store.getState();
        const activePersonId = getProperty(state, 'authentication.activePersonId');
        let url = `persons/${activePersonId}/tags?search=tag:${searchQuery}`;
        const data = await Fetch(url).catch((e) => {
            console.log('Error while syncing: ', e);
        });

        let options = data.data.map((elem) => ({ key: elem.id, text: elem.attributes.tag, value: elem.attributes.tag }));
        this.setState({ options: options });
    }

    render() {
        let {
            label,
            onChange,
            onAddItem,
            placeholder,
            isFetching,
            className,
            fieldStyle,
            multiple = false,
            inline = false,
            allowAdditions = true,
            ...other
        } = this.props;
        let { options, added_options, value } = this.state;
        const addItemHandler = onAddItem || this.onAddItem.bind(this);
        return (
            <Form.Field inline={inline} style={{ position: 'relative', ...fieldStyle }} classnames="form-input">
                {/*<span className="InputLabel">{label}</span>*/}
                <Dropdown
                    ref={(i) => (this.dropdown = i)}
                    className={classnames('defaultCss', 'material', className)}
                    value={value}
                    placeholder={placeholder || label}
                    selection
                    search
                    icon={{ name: 'search', circular: true, link: true }}
                    multiple={multiple}
                    allowAdditions={allowAdditions}
                    onAddItem={addItemHandler}
                    onChange={this.handleChange.bind(this)}
                    options={this._getUnique(options.concat(added_options))}
                    onSearchChange={this.handleSearchChange.bind(this)}
                    {...other}
                />

                <div
                    className={classnames('funky-placeholder', {
                        // animePlaceHolderDatePicker: value != undefined && value != "",
                        animePlaceHolderDatePicker: true,
                    })}
                >
                    {label}
                </div>
            </Form.Field>
        );
    }
    _getUnique(items) {
        let existingValues = [];
        return items.filter((item) => {
            if (!existingValues.includes(item.key)) {
                existingValues.push(item.key);
                return true;
            }
            return false;
        });
    }
    onAddItem(e, item) {
        const extra_opts = this.state.added_options;
        extra_opts.push({ key: `_temp_${Math.random()}`, text: item.value, value: item.value });
        this.setState({ added_options: extra_opts });
    }
}

export const RemoteInput = ({ modelName, endPoint, Model, labelField = 'name' }) => {
    return class RemoteInput extends TagRemoteInput {
        getExistingItems() {
            const state = store.getState();
            const existing = Object.values(getProperty(state, `dataStore.graph.${modelName}`) || {}).map((item) => ({
                key: item.id,
                text: item[labelField],
                value: item.id,
                item: item /*Dont know what these are for*/,
            }));
            return existing;
        }
        async handleSearchChange(e, { searchQuery }) {
            // if (searchQuery.length < 3) return false;
            let url = `${endPoint}?search=${labelField}:${searchQuery}&limit=36`;
            const data = await Fetch(url).catch((e) => {
                console.log('Error while syncing: ', e);
            });

            let options = data.data.map((elem) => ({
                key: elem.id,
                text: elem.attributes[labelField],
                value: elem.id,
                item: Model({ id: elem.id, ...elem.attributes }) /*Dont know what these are for*/,
            }));
            this.setState({ options: options });
        }
    };
};
// export class SymptomRemoteInput extends TagRemoteInput {
//     getExistingItems() {
//         const state = store.getState();
//         const existing = Object.values(getProperty(state, "dataStore.graph.symptoms") || {}).map(item => ({
//             key: item.id,
//             text: item.name,
//             value: item,
//         }));
//         return existing;
//     }
//     async handleSearchChange(e, { searchQuery }) {
//         let url = `symptoms?search=name:${searchQuery}`;
//         const data = await Fetch(url).catch(e => {
//             console.log("Error while syncing: ", e);
//         });
//         let options = data.data.map(elem => ({
//             key: elem.id,
//             text: elem.attributes.name,
//             value: Symptom({ id: elem.id, ...elem.attributes }),
//         }));
//         this.setState({ options: options });
//     }
// }
