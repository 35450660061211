import {Fetch} from "./fetch";



export const SendUnencryptedData = async (personId, i , g) =>{

    console.log('SendUnencryptedData props', personId , i , g)

    let res = await Fetch(
        `persons/${personId}/unencrypted`,
        { method: "PUT", body: JSON.stringify({ data: { attributes: {i : i, g: g} } }) },
        "auth"
    )
        .then(() => {
          console.log('Send unencrypted update')
        })
        .catch((e) => {console.log( ' Unencrypted send error', e)});


}