import React, { Component } from 'react';
import { Grid, Dimmer } from 'semantic-ui-react';
import MediaQuery from 'react-responsive';

import SideMenu from 'Mib/components/generic/side_menu';

export default class WithSideMenu extends Component {
    constructor() {
        super();
        this.state = {
            visible: false,
        };
    }

    render() {
        const { menu, menuItems, history, match, children } = this.props;
        const Menu = menu || menuItems.map((Item, key) => <Item history={history} match={match} key={key} />);
        return (
            <div className="content-container">
                <Grid>
                    <SideMenu history={history} onToggle={(v) => this.setState({ visible: v })}>
                        {Menu}
                    </SideMenu>
                    <MediaQuery minWidth={1200}>
                        {(moreThan1200) => (
                            <>
                                <Grid.Column width={moreThan1200 ? 12 : 16}>{children}</Grid.Column>
                                {moreThan1200 || <Dimmer active={this.state.visible} style={{ zIndex: 898 }} />}
                            </>
                        )}
                    </MediaQuery>
                </Grid>
            </div>
        );
    }
}
