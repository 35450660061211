import React, { Component, Suspense } from 'react';
import { Grid, Dimmer, Header, Icon } from 'semantic-ui-react';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { startBackendSyncAction, stopBackendSyncAction } from 'Mib/actions/app_state';
import BaseContainer from 'Mib/components/containers/base';
import { serializeWithTags } from 'Mib/lib/json-api';
import { Fetch } from 'Mib/lib/fetch';
import { store } from 'Mib/datastore/store';
import MediaQuery from 'react-responsive';

// import DashMedi from "./medi";
// import { DashWeightQuickAdd } from 'Mib/routes/dashboard/panels/weight_quickadd';
// import { DashHeightChart } from 'Mib/routes/dashboard/panels/height_graph';
// import { DashPressureChart } from 'Mib/routes/dashboard/panels/pressure_graph';
import DashPanelContainer from 'Mib/routes/dashboard/dash_panel_container';
import DashboardSettings from 'Mib/routes/dashboard/dashboard_settings';

// import DashboardCalendar from "./dash_calendar";
const Medi = React.lazy(() => import('./medi'));
const DashCalendar = React.lazy(() => import('../../../src/routes/dashboard/dash_calendar'));

require('./dashboard.scss');

class _DashboardPage extends Component {
    constructor() {
        super();
        this.defaultConfig = {
            0: { panelComponent: 'DashWeightQuickAdd', lineColor: '#00B1AA', x: 0 },
            1: { panelComponent: 'DashHeightChart', lineColor: '#2082CC', x: 1 },
            2: { panelComponent: 'DashPressureChart', lineColor: '#DA2727', x: 2 },
            3: { panelComponent: 'EmptyDashPanel', lineColor: '#F6B908', x: 3 },
            4: { medi: true, x: 12 },
            5: { panelComponent: 'EmptyDashPanel', lineColor: '#A032C4', x: 5 },
        };
        this.state = {
            dashConfig: this.defaultConfig,
            dimmers: {},
        };
    }

    componentWillMount() {
        let dashConf = this.props.personSetting && this.props.personSetting.dashboard;

        if (dashConf != '' && dashConf != undefined) {
            dashConf = JSON.parse(dashConf.replace(/\\/g, ''));
            this.setState({ dashConfig: { ...this.state.dashConfig, ...dashConf } });
        }
    }

    componentWillReceiveProps(newProps) {
        let dashConf = newProps.personSetting && newProps.personSetting.dashboard;
        if (dashConf != '' && dashConf != undefined) {
            dashConf = JSON.parse(dashConf.replace(/\\/g, ''));

            this.setState({ dashConfig: { ...this.state.dashConfig, ...dashConf } });
            // this.setState({dashConfig: {...this.defaultConfig}})
        }
    }

    async save(panelConf = {}, resetSettings = false) {
        let dashConfig = resetSettings ? this.defaultConfig : this.state.dashConfig;

        if (!(Object.keys(panelConf).length === 0 && panelConf.constructor === Object)) {
            dashConfig[`${panelConf.x}`] = panelConf;
        }

        let stringed = JSON.stringify(dashConfig).replace(/"/g, '\\"');
        let personSettings = this.props.personSetting.makeDeepCopy();
        personSettings.setAttribute('dashboard', stringed);
        let measureUnit = store.getState().dataStore.find('measure-units', 1);
        personSettings.setRelationship('weightUnit', measureUnit);

        const payloadtosend = serializeWithTags(personSettings);

        startBackendSyncAction('person-settings');
        console.log('payloadtosend', payloadtosend);
        const data = await Fetch(
            `persons/${this.props.personId}/person-settings`,
            { method: 'PATCH', body: JSON.stringify(payloadtosend) },
            'api'
        ).catch((e) => {
            console.log('Error while syncing: ', e);
            stopBackendSyncAction('person-settings');
        });
        console.log('savinnngng');
        store.dispatch({ type: 'SET_DATA', payload: data });
        stopBackendSyncAction('person-settings');
    }

    _onHTMLDragOver(e) {
        e.preventDefault();
        return false;
    }

    _onHTMLDrop(index, e) {
        e.preventDefault();
        const component = e.dataTransfer.getData('component');
        this.setState({ dimmers: {} });
        if (component == null || component == '') return;
        const { dashConfig } = this.state;
        dashConfig[index].panelComponent = component;
        this.setState({ dashConfig, dimmers: {} });
        this.save(dashConfig[index]);
    }

    _onHTMLEnter(id, e) {
        this.setState({ dimmers: { ...this.state.dimmers, [id]: true } });
    }

    _onHTMLExit(id, e) {
        this.setState({ dimmers: { ...this.state.dimmers, [id]: false } });
    }

    render() {
        const { settingsVisible } = this.state;
        const { promoters = {} } = this.props;
        const med4U_logo = Object.values(promoters).some((p) => p.name == 'Med4u');
        //TODO MULTIPLE PROMOTERS LOGO
        const promoter = Object.values(promoters)[0];

        return (
            <Suspense fallback={<BaseContainer loading style={{ height: '200px' }} />}>
                <div className="dashboard-page-container" style={{ width: '100%', display: 'flex', padding: '5pt' }}>
                    <DashboardSettings
                        active={settingsVisible}
                        toggle={() => this.setState({ settingsVisible: !settingsVisible })}
                    />
                    <div className="dashboard-page-left" style={{ width: '75%' }}>
                        <MediaQuery maxWidth={900}>
                            {(max900) => (
                                <MediaQuery maxWidth={1400}>
                                    {(max1400) => (
                                        <Grid columns={max900 ? 1 : max1400 ? 2 : 3} padded={true}>
                                            {Object.values(this.state.dashConfig).map((item) =>
                                                item.medi ? (
                                                    <Grid.Column key={item.x}>
                                                        <Medi />
                                                    </Grid.Column>
                                                ) : (
                                                    <Grid.Column style={{ padding: '3pt' }} key={item.x}>
                                                        <div
                                                            key={item.x}
                                                            onDragOver={this._onHTMLDragOver.bind(this)}
                                                            onDrop={this._onHTMLDrop.bind(this, Number(item.x))}
                                                            onDragEnter={this._onHTMLEnter.bind(this, item.x)}
                                                            onDragExit={this._onHTMLExit.bind(this, item.x)}
                                                        >
                                                            <Dimmer active={this.state.dimmers[item.x]}>
                                                                <Header as="h2" icon inverted>
                                                                    <Icon name="exchange" />
                                                                    {T.translate('dashboard.exchange')}
                                                                </Header>
                                                            </Dimmer>
                                                            <DashPanelContainer
                                                                save={this.save.bind(this)}
                                                                config={this.state.dashConfig[Number(item.x)]}
                                                                color={item.lineColor}
                                                                key={item.x}
                                                                toggleSettings={() =>
                                                                    this.setState({ settingsVisible: !settingsVisible })
                                                                }
                                                            />
                                                        </div>
                                                    </Grid.Column>
                                                )
                                            )}
                                        </Grid>
                                    )}
                                </MediaQuery>
                            )}
                        </MediaQuery>
                    </div>
                    <div style={{ width: '25%', display: 'flex', flexDirection: 'column' }}>
                        {!promoter ? (
                            <BaseContainer>{T.translate('meta.beta-info', { br: <br /> })}</BaseContainer>
                        ) : (
                            Object.values(promoters).map((promoter) => (
                                <BaseContainer containerStyle={{ textAlign: 'center' }}>
                                    <a target="_blank" href={promoter.website}>
                                        <img
                                            src={`/assets/img/connections/${promoter.logo}.png`}
                                            alt={`${promoter.name} logo`}
                                            className="connection-image"
                                            style={{ width: '100%' }}
                                        />
                                    </a>
                                </BaseContainer>
                            ))
                        )}
                        <DashCalendar />

                        {/* <div className='halfMediContainer'> */}
                            <img className='halfMediImg' style={{paddingTop: "50px"}} src="/assets/img/sense_mib.png"/>
                        {/* </div> */}
                    </div>
                </div>
            </Suspense>
        );
    }
}

const mapStateToProps = (state) => {
    let activePersonId = getProperty(state, 'authentication.activePersonId');
    let personSetting = getProperty(state, `dataStore.graph.person-settings.${activePersonId}`);
    let promoters = getProperty(state, `dataStore.graph.promoters`);

    return {
        personSetting: personSetting,
        personId: activePersonId,
        promoters: promoters,
    };
};

export const DashboardPage = subscribeWithLoader(_DashboardPage, mapStateToProps, ['person-settings']);
