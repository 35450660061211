import React, { Component } from "react";
import T from "i18n-react";
import dotProp from "dot-prop";
import { Icon, Segment, Label, Table, Grid, Button } from "semantic-ui-react";
import { MibButton } from "../../../components/inputs/buttons";
import { ProviderInfoTable } from "./providerInfoTable";
import { Fetch } from "Mib/lib/fetch";
import { connect } from "react-redux";
import { notify } from "Mib/actions/notifications";
import {fetchAction} from "Mib/actions/backend";

export class _ProviderCard extends Component {
  constructor() {
    super();
    this.copyBindCode = this.copyBindCode.bind(this);
  }

  async addProviderAccess() {
    let payload = {
      data: { attributes: { temp_provider_name: this.props.provider.name } }
    };

    let tokenAccess = await Fetch(
      `persons/${this.props.personId}/person-providers`,
      { method: "POST", body: JSON.stringify(payload) },
      "auth"
    ).then(results => {
      this.props.onProviderBind(results["bind-code"]);
    });
  }

  showBindCode() {
    notify({
      success: true,
      header: T.translate("providers.doctorbindcodedescription"),
      content:
        T.translate("providers.access-token") + this.props.provider.bindCode
    });
    // body...
  }

async onProviderDelete(){
  console.log('imhere',this.props.provider.type)
  if(this.props.type == "pending")
  {
    let answer = await Fetch(
      `persons/${this.props.personId}/person-providers-pending/${this.props.provider.bindCode}`,
      { method: "DELETE" },
      "auth"
    ).then(results => {
          notify({
            success: true,
            header: T.translate("providers.pending"),
            content:
              T.translate("providers.pending-deleted") 
          });
          fetchAction('person-providers',{query:'?all&type=3',force:true,replace:true})
    });
  }
}

  render() {
    const { provider, type } = this.props;

    return (
      <Segment className="providerSegmentSize">
        {type && (
          <Label as="a" color="blue" ribbon>
            {T.translate(`providers.types.${type}`)}
          </Label>
        )}

        {this.props.addProviderFunctionality ? (
          <Button
            style={{
              position: "absolute",
              right: "0",
              top: "3px",
              backgroundColor: "#4CAF50"
            }}
            onClick={this.addProviderAccess.bind(this)}
            animated="fade"
            color="green"
            icon
            labelPosition="left"
            size="small"
          >
            <Icon name="doctor" /> {T.translate("providers.addDoctor")}
          </Button>
        ) : null}

        {this.props.actionButtons ? (
          <div style={{ position: "absolute", right: "5", top: "10" }}>
            {this.props.onPermissionsEdit ? (
              <Button
                onClick={this.props.onPermissionsEdit}
                color="olive"
                icon
                size="small"
              >
                <Icon name="configure" />
              </Button>
            ) : null}
            {/*<Button onClick={this.props.close} color="red" icon size="small">*/}
              {/*<Icon name="trash outline" />*/}
            {/*</Button>*/}
            {this.props.type=="pending"?(
              <Button
                onClick={this.onProviderDelete.bind(this)}
                color="red"
                icon
                size="small"
              >
                <Icon name="delete" />
              </Button>
              ):null}
          </div>
        ) : null}

        <div className="providerContainer">
          <div className="providerMapDiv">
            <Icon style={{ padding: "0.5%" }} name="doctor" size="massive" />
          </div>

          <div style={{ flex: 1, padding: "0.5%" }}>
            <ProviderInfoTable
              name={provider.name}
              address={provider.address}
              website={provider.website}
              email={provider.email}
            />
          </div>

          <div className="providerPhonesDiv">
            <div style={{ paddingBottom: "10px" }}>
              <span>
                <Icon className="phone circular" size="large" />
                <span style={{ paddingLeft: "5%" }}>
                  {provider.phone
                    ? provider.phone
                    : T.translate("providers.notFound")}{" "}
                </span>
              </span>
            </div>
            <span>
              <Icon className="mobile alternate circular" size="large" />
              <span style={{ paddingLeft: "5%" }}>
                {provider.mobile
                  ? provider.mobile
                  : T.translate("providers.notFound")}{" "}
              </span>
            </span>
          </div>
        </div>
        {type == "pending" && (
          <Button
            style={{
              position: "absolute",
              bottom: "3",
              backgroundColor: "#2eaadc",
              color: "white",
              left: "50%",
              transform: "translateX(-50%)"
            }}
            animated="vertical"
            onClick={this.copyBindCode}
          >
            <Button.Content style={{ fontSize: "20px" }} hidden>
              <div style={{ display: "flex" }}>
                <textarea
                  ref={i => (this.bindCodeArea = i)}
                  style={{
                    flex: 1,
                    background: "inherit",
                    border: "none",
                    resize: "none",
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  {this.props.provider.bindCode}
                </textarea>
                <Icon name="copy" />
              </div>
            </Button.Content>
            <Button.Content visible>
              <div style={{ fontSize: "13px", fontWeight: "bolt" }}>
                <Icon style={{ color: "white" }} name="key" />
                {T.translate("providers.doctorbindcodedescription")}{" "}
              </div>
            </Button.Content>
          </Button>
        )}

        <Label attached="bottom right">{provider.city}</Label>
      </Segment>
    );
  }

  copyBindCode() {
    this.bindCodeArea.focus();
    this.bindCodeArea.select();
    var successful = document.execCommand("copy");
    if (successful) {
      notify({
        success: true,
        header: T.translate("providers.copy-success.header"),
        content: T.translate("providers.copy-success.content")
      });
    }
    this.bindCodeArea.blur();
  }
}

const mapStateToProps = ({ authentication: { activePersonId } }) => {
  return {
    personId: activePersonId
  };
};

export const ProviderCard = connect(mapStateToProps)(_ProviderCard);
