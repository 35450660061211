import React, { Component } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import T from 'i18n-react';
import moment from 'moment';

import BaseContainer from './base';
import { getProperty } from 'dot-prop';

require('./quick_info_panels.scss');

export const QuickInfoPanel = ({
    title,
    footer,
    iconName,
    date,
    subTitle,
    value,
    editAction,
    onClick,
    altText,
    loading,
}) => {
    loading = loading === undefined ? value === undefined && !altText : loading;
    return (
        <BaseContainer className="quick-info-container" loading={loading} onClick={onClick}>
            <h3 className="quick-info-title">{title}</h3>
            <div className="quick-info-subtitle">{footer}</div>
            {value !== null ? (
                <div className="quick-info-value">{value}</div>
            ) : (
                <Message
                    icon="info circle"
                    header={T.translate('no-data')}
                    info
                    style={{ marginBottom: '18px', marginTop: '3px', cursor: 'pointer' }}
                />
            )}
            {iconName !== undefined ? (
                <div className="quick-info-icon">
                    <Icon style={{ marginBottom: '5px' }} name={iconName} size="big" />{' '}
                </div>
            ) : null}
            <div className="quick-info-subtitle">{date}</div>
        </BaseContainer>
    );
};

const FromMeasurement = ({ measurement, editAction, onClick, loading, title, ...rest }) => {
    const displayedValue =
        measurement !== undefined
            ? `${getProperty(measurement, 'result')} ${getProperty(measurement, 'measureUnit.abbreviation')}`
            : undefined;
    title = measurement ? T.translate(`measurements.singular.${measurement._type}`) : title || '';
    return (
        <QuickInfoPanel
            title={title}
            value={displayedValue}
            loading={loading}
            subTitle={measurement ? moment(getProperty(measurement, 'date')).format('L') : ''}
            {...rest}
        />
    );
};

QuickInfoPanel.FromMeasurement = FromMeasurement;
