import React, { Component } from 'react';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import { connect } from 'react-redux';
import { Message, Transition, Icon } from 'semantic-ui-react';

import { notify } from 'Mib/actions';
import { Fetch } from 'Mib/lib/fetch';
import BaseContainer from 'Mib/components/containers/base';
import { MibButton, TextInput } from 'Mib/components/inputs';

class Med4UConnectionPanel extends Component {
    constructor() {
        super();
        this.state = { detailsOpen: false, promoCode: '' };
    }

    onSubmitButtonClicked(e) {
        const { promoCode, detailsOpen } = this.state;
        if (detailsOpen && this.isCodeValid()) {
            Fetch(
                '/users/promoters',
                { method: 'POST', body: JSON.stringify({ promoter_id: 1, promoter_code: promoCode }) },
                'auth'
            )
                .then((res) => {
                    this.props.setPromoter(res);
                    notify({
                        success: true,
                        header: T.translate('success.create.header'),
                        content: T.translate('success.med4u'),
                    });
                })
                .catch(() =>
                    notify({
                        error: true,
                        header: T.translate('errors.create.header'),
                        content: T.translate('errors.med4u'),
                    })
                );
        } else {
            this.setState({ detailsOpen: !detailsOpen });
        }
    }

    onPromoCodeChanged(e) {
        this.setState({ promoCode: e.target.value.toUpperCase() });
    }

    isCodeValid() {
        return this.state.promoCode.length != 0;
    }

    render() {
        const { detailsOpen, promoCode } = this.state;
        const canSubmit = detailsOpen && this.isCodeValid();
        const hasMed4U = Object.values(this.props.promoters || {}).some((p) => p.name == 'Med4u');
        return (
            <BaseContainer className="connection-segment med4u" color="red">
                <div className="connection-container-inner">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex' }}>
                            <img
                                src="/assets/img/connections/med4u.png"
                                // src="/assets/img/connections/Med4U-logo.png"
                                alt="med4u logo"
                                className="connection-image"
                                style={{ width: '30%', padding: '2%' }}
                            />
                            <div className="connection-description">{T.translate('connections.med4u.description')}</div>
                        </div>
                        <Transition.Group>
                            {detailsOpen && (
                                <div>
                                    <div className="connection-content-expanded">
                                        <label className="med4u-registration-label" htmlFor="med4u-code">
                                            {T.translate('connections.med4u.input-label')}:
                                        </label>
                                        <TextInput
                                            placeholder="CODE"
                                            onChange={this.onPromoCodeChanged.bind(this)}
                                            value={promoCode}
                                        />
                                    </div>
                                </div>
                            )}
                        </Transition.Group>
                    </div>
                    <div className="connection-controls">
                        {hasMed4U ? (
                            <MibButton
                                type="warning"
                                label={
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        <Icon name="check" />
                                        {T.translate('connections.active')}{' '}
                                    </span>
                                }
                            />
                        ) : (
                            <MibButton
                                type="success"
                                label={
                                    canSubmit ? (
                                        <span style={{ display: 'flex' }}>
                                            {T.translate('meta.submit')}
                                            <Icon name="caret right" style={{ float: 'right' }} />
                                        </span>
                                    ) : (
                                        T.translate('connections.activate')
                                    )
                                }
                                onClick={this.onSubmitButtonClicked.bind(this)}
                            />
                        )}
                    </div>
                </div>
                <Transition.Group>
                    {detailsOpen && (
                        <div>
                            <Message
                                info
                                header={T.translate('connections.med4u.info-title')}
                                content={T.translate('connections.med4u.info-body')}
                                icon="info"
                            />
                        </div>
                    )}
                </Transition.Group>
            </BaseContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    promoters: getProperty(state, 'dataStore.graph.promoters'),
});

const mapDispatchToProps = (dispatch) => ({
    setPromoter: (data) => dispatch({ type: 'SET_DATA', payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Med4UConnectionPanel);
