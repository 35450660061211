import React, { Component } from 'react';
import T from 'i18n-react';

import moment from 'moment';
import sortOn from 'sort-on';

import { ContentTitle } from 'Mib/components/generic';
import BaseContainer from '../containers/base';

import { subscribeWithLoader } from '../../lib/subscribe';
import { openMeasurementModal, openPulsesModal, openPressuresModal } from 'Mib/actions';
import TimelineEventList from '../generic/timeline/timeline_event_list';
import AddTypePopup from 'Mib/components/generic/add_type_popup';

class _AllMeasurements extends Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
    }

    filterData(data = {}, props = this.props, valueAttr = 'result', dateAttr = 'date') {
        const {
            filters: { dateFrom, dateTo, tags },
        } = props;
        const filtered = {};
        return data.filter((o) => {
            let res =
                (dateFrom == null || moment(o[dateAttr]) >= dateFrom) &&
                (dateTo == null || moment(o[dateAttr]) <= dateTo);
            if (tags && tags.length) {
                res &= o.tags.some((tag) => tags.some((t) => t.id === tag.id));
            }
            return res;
        });
        // .forEach(o => (filtered[o.id] = o));
        return filtered;
    }

    open(id = undefined, type = undefined, lab_type = null) {
        switch (type) {
            case 'pressures':
                openPressuresModal({ type, id });
                break;
            case 'pulses':
                openPulsesModal({ type, id });
                break;
            default:
                openMeasurementModal({ type, id });
        }
    }

    render() {
        const { allmeasurements, children, loading, ...rest } = this.props;
        const filterMeasurements = this.filterData(allmeasurements);
        return (
            <>
                <ContentTitle title={T.translate('measurements.my.my-measurements')} />
                <BaseContainer loading={loading}>
                    <GenericAddPopup />
                    <TimelineEventList
                        sortOn="date"
                        data={filterMeasurements}
                        timelinePointClick={this.open}
                        typeInTags={false}
                    />
                </BaseContainer>
            </>
        );
    }
}

const mapStateToProps = ({ dataStore, filters }, props) => {
    const { weights, heights, pressures, oximetries, waists, temperatures } = dataStore.graph;

    const allMeasures = { ...weights, ...heights, ...pressures, ...oximetries, ...waists, ...temperatures };
    const hoho = [];
    const lala =
        allMeasures &&
        Object.entries(allMeasures).forEach(([key, unit]) => {
            hoho.push(unit);
        });
    const naana = sortOn(hoho, '-date');
    return {
        allmeasurements: naana,
        filters: filters,
    };
};

export default subscribeWithLoader(_AllMeasurements, mapStateToProps, [
    'weights',
    'heights',
    'pressures',
    'temperatures',
    'oximetries',
    'waists',
]);

function GenericAddPopup() {
    return (
        <AddTypePopup
            defaultText={T.translate('measurements.choose')}
            items={[
                {
                    key: 1,
                    onClick: () => openMeasurementModal({ type: 'weights' }),
                    hoverText: `${T.translate('create')} ${T.translate('measurements.genitive.weights')}`,
                    icon: 'icon-scale',
                },
                {
                    key: 2,
                    onClick: () => openMeasurementModal({ type: 'heights' }),
                    hoverText: `${T.translate('create')} ${T.translate('measurements.genitive.heights')}`,
                    icon: 'icon-height',
                },
                {
                    key: 3,
                    onClick: () => openPressuresModal({ type: 'pressures' }),
                    hoverText: `${T.translate('create')} ${T.translate('measurements.genitive.pressures')}`,
                    icon: 'icon-pressure',
                },
                {
                    key: 4,
                    onClick: () => openPulsesModal({ type: 'pulses' }),
                    hoverText: `${T.translate('create')} ${T.translate('measurements.genitive.pulses')}`,
                    icon: 'icon-heartbeat',
                },
                {
                    key: 5,
                    onClick: () => openMeasurementModal({ type: 'oximetries' }),
                    hoverText: `${T.translate('create')} ${T.translate('measurements.genitive.oximetries')}`,
                    icon: 'icon-oxygen',
                },
                {
                    key: 6,
                    onClick: () => openMeasurementModal({ type: 'temperatures' }),
                    hoverText: `${T.translate('create')} ${T.translate('measurements.genitive.temperatures')}`,
                    icon: 'icon-thermometer',
                },

                {
                    key: 7,
                    onClick: () => openMeasurementModal({ type: 'waists' }),
                    hoverText: `${T.translate('create')} ${T.translate('measurements.genitive.waists')}`,
                    icon: 'icon-waist',
                },
            ]}
        />
    );
}
