import React, { Component } from "react";
import { Popup, Icon } from "semantic-ui-react";

require("./fab.scss");

const Fab = ({ icon, title, onClick, x = 0, y = 0, background = "red", color = "white", style = {} }) => {
    const noPopup = title != null ? {} : {open:false}
    return (
    <Popup
        trigger={
            <div
                className="fab"
                onClick={onClick}
                style={{ ...style, top: y, left: x, background: background, color: color }}
            >
                <Icon name={icon} size="large" />
            </div>
        }
        content={title}
        {...noPopup}
    />
)};

export default Fab;
