import React, { Component } from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Menu, Icon, Radio, Popup } from 'semantic-ui-react';
// import history from '../../../lib/history';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { MenuPanel } from 'Mib/components/containers';

class _SingleUseMedicinePanel extends Component {
    constructor(props) {
        super();
        const { defaultShowAll, showExtraInfo } = props;
        this.state = { showAll: defaultShowAll || false };
    }

    filterData(data = {}, props = this.props, valueAttr = 'result', dateAttr = 'date') {
        const {
            filters: { tags },
        } = props;
        const filtered = data.filter((o) => {
            let res = true;

            if (tags && tags.length && o.tags) {
                res = o.tags.some((tag) => tags.some((t) => t.id === tag.id));
            }
            return res;
        });
        return filtered;
    }

    isDrugActive(drug) {
        return drug.end === null || moment(drug.end) >= moment();
    }

    renderItemLabel(drug) {
        const date = `${moment(drug.start).format('L')}${drug.end ? ` - ${moment(drug.end).format('L')}` : ''}`;
        if (this.props.showExtraInfo)
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '2em', flex: 1 }}>
                    <span>{drug.drug ? drug.drug.name : drug.drug_name}</span>
                    <span>{date}</span>
                </div>
            );
        if (drug.drug) {
            return drug.drug.name;
        }
        return drug.drug_name;

        // return this.props.showExtraInfo ? (
        //     <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '2em', flex: 1 }}>
        //         <span>{drug.drug ? drug.drug.name : drug.drug_name}</span>
        //         <span>{date}</span>
        //     </div>
        // ) : drug.drug ? (
        //     drug.drug.name
        // ) : (
        //     drug.drug_name
        // );
    }

    renderHeader() {
        const titleLabel = this.state.showAll
            ? `${T.translate('user-drugs.all')} ${T.translate('user-drugs.drugs-usages')}`
            : `${T.translate('user-drugs.active')} ${T.translate('user-drugs.drugs-usages')}`;
        const title = {
            label: titleLabel,
            onClick: (_) => history.push('/user-drugs'),
            icon: 'icon-icon_meds',
        };
        return (
            <Menu.Item onClick={title.onClick} className="menu-title">
                <Icon name={title.icon} style={{ fontSize: '1.3em', marginLeft: 0 }} />
                <span className="title">{title.label}</span>
            </Menu.Item>
        );
    }

    render() {
        let { drugs = {}, history, openMedicineForm, loading, ...rest } = this.props;
        const { showAll } = this.state;
        drugs = showAll
            ? Object.values(drugs).sort((a, b) => moment(a.start) < moment(b.start))
            : Object.values(drugs)
                  .sort((a, b) => moment(a.start) < moment(b.start))
                  .filter(this.isDrugActive);

        console.log('single drugs', drugs);
        const itemsIfEmpty = [
            {
                key: 0,
                label: (
                    <Menu.Item onClick={() => openMedicineForm()} icon="plus square outline">
                        {T.translate('medications.no-data')}
                    </Menu.Item>
                ),
            },
        ];
        const filteredDrugs = this.filterData(drugs);
        const items = filteredDrugs.map((drug) => ({
            label: this.renderItemLabel(drug),
            key: drug.id,
            onClick: () => openMedicineForm(drug),
            icon: this.isDrugActive(drug) ? null : 'time',
            style: this.isDrugActive(drug) ? { display: 'flex' } : { display: 'flex', color: 'gray' },
        }));

        return (
            <MenuPanel
                className="green"
                title={this.renderHeader()}
                loading={loading}
                footer={{
                    label: T.translate('user-drugs.create-new-single-use'),
                    onClick: (_) => history.push('/user-drugs/new'),
                    onClick: () => openMedicineForm(),
                    icon: 'add circle',
                }}
                items={items.length || loading ? items : itemsIfEmpty}
                {...rest}
            />
        );
    }
}

const mapStateToProps = ({ dataStore, filters }) => {
    return {
        drugs: getProperty(dataStore, 'graph.person-drug-usages'),
        filters: filters,
    };
};

export const SingleUseMedicinePanel = withRouter(
    subscribeWithLoader(_SingleUseMedicinePanel, mapStateToProps, ['person-drug-usages'])
);
