/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';

import moment from 'moment';
import { Icon, Table } from 'semantic-ui-react';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import { v4 as uuidv4 } from 'uuid';

import { CreateButton } from 'Mib/components/inputs/buttons';
import { store } from 'Mib/datastore/store';
import { Fetch } from 'Mib/lib/fetch';
import { notify } from 'Mib/actions/notifications';

export class AnswerTable extends Component {
    async onDeleteAnswer(answer_id) {
        const state = store.getState();
        const activePersonId = getProperty(state, 'authentication.activePersonId');
        const { answers } = this.props;
        const answerForDel = answers.find((ans) => ans.id === answer_id);
        try {
            await Fetch(`persons/${activePersonId}/person-answers/${answer_id}`, { method: 'DELETE' }, 'api')
                .then((success) => {
                    store.dispatch({ type: 'DELETE_ITEM', payload: answerForDel });
                    notify({
                        success: true,
                        header: T.translate('success.delete.header'),
                        content: T.translate('success.delete.content'),
                    });
                })
                .catch((e) => {
                    if (e.status === 422) {
                        throw e;
                    }
                    notify({
                        error: true,
                        header: T.translate('errors.delete.header'),
                        content: T.translate('errors.delete.content'),
                    });
                });
        } catch (e) {
            alert(`something go wrong : ${e}`);
        }
    }

    render() {
        const { onAddAnswer, answers, questionType } = this.props;
        return (
            <Table celled padded>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center" singleLine>
                            {T.translate('surveys.posted')}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" singleLine>
                            {T.translate('surveys.answer')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>{T.translate('surveys.remarks')}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {answers.map((answer) => {
                        const answerByType =
                            questionType === 4 ? answer.value.data.attributes.result : answer.value.text;
                        return (
                            <Table.Row key={uuidv4()}>
                                <Table.Cell width="2" textAlign="center">
                                    {moment(answer.date).format('L')}
                                </Table.Cell>
                                <Table.Cell width="2" textAlign="center">
                                    {answerByType}
                                </Table.Cell>
                                <Table.Cell>{answer.remarks}</Table.Cell>
                                <Table.Cell width="1">
                                    <Icon
                                        style={{ cursor: 'pointer' }}
                                        color="red"
                                        name="trash alternate outline"
                                        size="big"
                                        onClick={this.onDeleteAnswer.bind(this, answer.id)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>

                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan="4">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {' '}
                                <CreateButton onClick={onAddAnswer} />
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    }
}
