import React, { Component } from "react";
import T from "i18n-react";
import dotProp from "dot-prop";
import {MediWithMsg} from "../../components/medi/mediWithMsg";



export class GeneralInteractiveDash extends Component{

    render() {
        return (
            <div className="content-container">

                        <MediWithMsg
                            mediMsg = "Eimai edw gia na se voithiso na parakolouthisis tin poreia sou"
                        />
            </div>
        )
    }
}

