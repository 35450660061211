import moment from 'moment';
import T from 'i18n-react';

import { fetchAction } from 'Mib/actions/backend';
import { store } from '../datastore/store';
import { JsonApiDataStoreModel } from '../vendor/jsonapi-datastore';

export const Contact = ({ firstName, lastName, email, phone1, phone2, priority, comments }) => {
    const model = new JsonApiDataStoreModel('contacts');
    model.setAttribute('first_name', firstName);
    model.setAttribute('last_name', lastName);
    model.setAttribute('email', email);
    model.setAttribute('phone1', phone1);
    model.setAttribute('phone2', phone2);
    model.setAttribute('priority', priority);
    model.setAttribute('comments', comments);

    return model;
};

Contact.validator = {
    properties: {
        first_name: {
            required: true,
            type: 'string',
            minLength: 3,
        },
        last_name: {
            required: true,
            type: 'string',
            minLength: 3,
        },
        email: {
            type: 'string',
            format: 'email',
            required: false,
        },
    },
};

export const Measurement = (type, value, measureUnit, date = moment(), tags = [], comments) => {
    // Convert measure unit
    if (typeof measureUnit == 'number') measureUnit = store.getState().dataStore.find('measure-units', measureUnit);

    // Convert tags
    tags = tags.map((tag) => {
        if (typeof tag == 'string') {
            tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('name', tag);
            return tagObj;
        }
        return tag;
    });

    // Create the model
    const model = new JsonApiDataStoreModel(type);

    model.setAttribute('date', date.format()); // date.toISOString());
    model.setAttribute('comments', comments);
    model.setAttribute('result', value);
    model.setRelationship('measureUnit', measureUnit);
    model.setRelationship('category', {});
    model.setRelationship('tags', tags);

    if (type == 'temperature') model.setAttribute('mode', 0);
    model.Validator = {
        properties: {
            result: {
                required: true,
                pattern: /^[0-9\.]+$/,
                conform: (value) => value != null,
                minLength: 1,
                messages: {
                    pattern: T.translate('errors.must-be-number'),
                    minLength: T.translate('errors.not-blank'),
                    required: T.translate('errors.required'),
                },
            },
            date: {
                required: true,
                conform: (value) => moment(value).isValid() && value != null,
                messages: {
                    conform: T.translate('errors.required'),
                },
            },
            measureUnit: {
                required: true,
                minLength: 1,
                messages: {
                    required: T.translate('errors.required'),
                },
            },
        },
    };

    return model;
};

Measurement.convertTags = (tags = []) => {
    tags = tags.map((tag) => {
        if (typeof tag == 'string') {
            tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('name', tag);
            return tagObj;
        }
        return tag;
    });
};

Measurement.Validator = {
    properties: {
        result: {
            required: true,
            pattern: /^[0-9\.]+$/,
            minLength: 1,
            messages: {
                pattern: T.translate('errors.must-be-number'),
                minLength: T.translate('errors.not-blank'),
                required: T.translate('errors.required'),
            },
        },
        date: {
            required: true,
            conform: (value) => moment(value).isValid() && value != null,
            messages: {
                conform: T.translate('errors.required'),
            },
        },
    },
};

export const Answer = (value, question, survey_event_id, date, comments) => {
    const answer = new JsonApiDataStoreModel('Answer');

    answer.setAttribute('survey-event-id', survey_event_id);
    answer.setRelationship('question', question);
    answer.setAttribute('value', value);
    answer.setAttribute('date', date);
    answer.setAttribute('remarks', comments);
    return answer;
};

export const LabTest = (value, type, measureUnit, date = moment(), tags = []) => {
    const labtest = Measurement('lab-exam-events', null, measureUnit, date, tags);
    delete labtest.value;
    labtest.setAttribute('result-n', value);

    const lab = new JsonApiDataStoreModel('lab-exams');
    lab.setAttribute('id', type);
    labtest.setRelationship('labExam', lab);

    return labtest;
};

LabTest.Validator = {
    // properties: {
    //     [""]:{
    //         required: true,
    //         type: "string",
    //         minLength: 1
    //     },
    // }
};

export const Pressure = (systolic, diastolic, pulses, measureUnit, date = moment(), tags = []) => {
    const pressure = Measurement('pressures', 0, measureUnit, date, tags);
    delete pressure.result;
    pressure.setAttribute('systolic', systolic);
    pressure.setAttribute('diastolic', diastolic);
    pressure.setAttribute('pulses', pulses);
    pressure.Validator = {
        properties: {
            date: {
                required: true,
                conform: (item) => moment(item).isValid(),
            },
            systolic: {
                required: true,
                pattern: /^[0-9\.]+$/,
                minLength: 1,
                messages: {
                    pattern: T.translate('errors.must-be-number'),
                    minLength: T.translate('errors.not-blank'),
                    required: T.translate('errors.required'),
                },
            },
            diastolic: {
                required: true,
                pattern: /^[0-9\.]+$/,
                minLength: 1,
                messages: {
                    pattern: T.translate('errors.must-be-number'),
                    minLength: T.translate('errors.not-blank'),
                    required: T.translate('errors.required'),
                },
            },
        },
    };
    pressure.PulsesValidator = {
        properties: {
            date: {
                required: true,
                conform: (item) => moment(item).isValid(),
            },
            pulses: {
                required: true,
                pattern: /^[0-9\.]+$/,
                minLength: 1,
                messages: {
                    pattern: T.translate('errors.must-be-number'),
                    minLength: T.translate('errors.not-blank'),
                    required: T.translate('errors.required'),
                },
            },
        },
    };
    return pressure;
};

Pressure.Validator = {
    properties: {
        date: {
            required: true,
            format: 'date',
        },
        systolic: {
            required: true,
            pattern: /^[0-9\.]+$/,
            minLength: 1,
            messages: {
                pattern: T.translate('errors.must-be-number'),
                minLength: T.translate('errors.not-blank'),
                required: T.translate('errors.required'),
            },
        },
        diastolic: {
            required: true,
            pattern: /^[0-9\.]+$/,
            minLength: 1,
            messages: {
                pattern: T.translate('errors.must-be-number'),
                minLength: T.translate('errors.not-blank'),
                required: T.translate('errors.required'),
            },
        },
    },
};

export const Document = (filename, title, category, date = moment(), tags = []) => {
    tags = tags.map((tag) => {
        if (typeof tag == 'string') {
            tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('name', tag);
            return tagObj;
        }
        return tag;
    });

    const document = new JsonApiDataStoreModel('documents');
    document.setAttribute('date', date);
    document.setAttribute('file', filename);
    document.setAttribute('title', title);
    document.setRelationship('category', {});
    document.setRelationship('tags', tags);
    // todo: Καρφωτή κατηγορία
    // if (!category) category = 2;
    document.category.id = category;
    document.Validator = {
        properties: {
            title: {
                required: true,
                type: 'string',
                minLength: 1,
            },
            category: {
                required: true,
                minLength: 1,
                conform: (value) => value.id != null,
                messages: {
                    required: T.translate('errors.required'),
                    conform: T.translate('errors.required'),
                },
            },
        },
    };
    return document;
};

export const answer = (value, text) => {
    const model = new JsonApiDataStoreModel('answer');
    model.setAttribute('value', value);
    model.setAttribute('text', text);

    return model;
};

export const UserAllergy = (allergy, symptoms = {}, date = moment(), tags = [], source = 0) => {
    // Convert tags
    tags = tags.map((tag) => {
        if (typeof tag == 'string') {
            tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('name', tag);
            return tagObj;
        } else {
            return tag;
        }
    });
    // Create the model

    const model = new JsonApiDataStoreModel('person-allergy');
    model.setAttribute('start', date.format());
    model.setAttribute('end', '');
    model.setAttribute('allergy_level', '');
    model.setAttribute('source', source);
    model.setAttribute('comments', '');
    model.setRelationship('tags', tags);
    model.setRelationship('allergy', allergy);
    model.setRelationship('symptoms', {});
    return model;
};

export const asyncedUserAllergy = async (allergy, symptoms = {}, date = moment(), tags = [], source = 0) => {
    if (typeof allergy == 'number') {
        const allergyData = await fetchAction('allergies', { url: `allergies/${allergy}`, force: true });

        allergy = new JsonApiDataStoreModel('allergy', allergy);
        allergy.setAttribute('name', allergyData.data.attributes.name);
        allergy.setAttribute('formal_name', allergyData.data.attributes.formal_name);
    }

    return UserAllergy(allergy, symptoms, date, tags, source);
};

UserAllergy.Validator = {
    properties: {
        allergy: {
            required: true,
            // type: "string",
        },
        start: {
            required: true,
            // format: 'date',
            conform: (item) => moment(item).isValid(),
        },
    },
};

export const UserVaccine = (vaccine, date = moment(), tags = []) => {
    // Convert tags
    tags = tags.map((tag) => {
        if (typeof tag == 'string') {
            tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('name', tag);
            return tagObj;
        }
        return tag;
    });
    // Create the model
    const model = new JsonApiDataStoreModel('person-vaccine');

    model.setAttribute('comments', '');
    model.setAttribute('vaccine_name', '');
    model.setAttribute('date', date.format()); // date.toISOString());
    model.setRelationship('tags', tags);
    model.setRelationship('vaccine', vaccine);

    return model;
};

UserVaccine.Validator = {
    properties: {
        vaccine: {
            required: true,
        },
    },
};

export const PersonDrug = (drug, symptoms = {}, date = moment(), tags = []) => {
    // Convert tags
    tags = tags.map((tag) => {
        if (typeof tag == 'string') {
            tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('name', tag);
            return tagObj;
        }
        return tag;
    });
    // Create the model
    const model = new JsonApiDataStoreModel('person-drugs');
    model.setAttribute('start', date.format());
    model.setAttribute('end', '');
    model.setAttribute('comments', '');
    model.setAttribute('drug_name', '');
    model.setAttribute('source', null);
    model.setAttribute('dose', null);
    model.setAttribute('daily_dosage', null);
    model.setRelationship('category', {});
    model.setRelationship('drug', {});
    model.setRelationship('tags', tags);

    return model;
};

PersonDrug.Validator = {
    properties: {
        drug: {
            required: true,
        },
        start: {
            required: true,
            conform: (item) => moment(item).isValid(),
        },
    },
};

export const Allergy = ({ id, name, code }) => {
    const model = new JsonApiDataStoreModel('allergies', id);
    model.setAttribute('name', name);
    model.setAttribute('code', code);
    return model;
};

export const Drug = ({ id, formal_name, name, code }) => {
    const model = new JsonApiDataStoreModel('drugs', id);
    model.setAttribute('formal_name', formal_name);
    model.setAttribute('name', name);
    model.setAttribute('code', code);
    return model;
};

export const Permission = () => {
    const model = new JsonApiDataStoreModel('permission');

    model.setAttribute('start', null);
    model.setAttribute('end', null);
    model.setAttribute('category_id', null);
    model.setAttribute('type', null);
};
export const Provider = (name = null) => {
    const model = new JsonApiDataStoreModel('provider');
    model.setAttribute('name', name);
    model.setAttribute('address', null);
    model.setAttribute('website', null);
    model.setAttribute('email', null);
    model.setAttribute('phone', null);
    model.setAttribute('mobile', null);
    model.setAttribute('city', null);
    return model;
};

/**
 * Model for Activities
 * Parameters are:
 *      type: (string|int) id of the activity type, 0-4
 *      steps: (int) the number of steps,
 *      distance: (float) the distance in km,
 *      calories: (int) the calories in kcal,
 *      velocity: (float) the speed in km/h,
 *      [startDate]: moment() Default: now,
 *      [endDate]: moment() Default: now,
 *      [tags]: an array of tags. Each can be an object, if it came from storage, or a string if it should be created
 */
export const Activity = ({
    type,
    steps,
    distance,
    calories,
    velocity,
    startDate = moment(),
    endDate = moment(),
    tags = [],
}) => {
    // const { dataStore } = store.getState();
    // const distanceUnit = dataStore.find("measure-units", 6);
    // const caloriesUnit = dataStore.find("measure-units", 9);
    // const velocityUnit = dataStore.find("measure-units", 7);
    const activityType = new JsonApiDataStoreModel('activity-type', type);

    // Convert tags
    tags = tags.map((tag) => {
        if (typeof tag == 'string') {
            tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('name', tag);
            return tagObj;
        }
        return tag;
    });

    // Create the model
    const model = new JsonApiDataStoreModel('activity');
    model.setAttribute('start', startDate.format());
    model.setAttribute('end', endDate.format());
    model.setAttribute('steps', steps);
    model.setAttribute('velocity', velocity);
    model.setAttribute('distance', distance);
    model.setAttribute('calories', calories);

    model.setRelationship('distanceUnit', null);
    model.setRelationship('calorieUnit', null);
    model.setRelationship('velocityUnit', null);
    // model.setRelationship("distanceUnit", distanceUnit);
    // model.setRelationship("caloriesUnit", caloriesUnit);
    // model.setRelationship("velocityUnit", velocityUnit);
    model.setRelationship('tags', tags);
    model.setRelationship('activityType', activityType);

    return model;
};

Activity.Validator = {
    properties: {
        start: {
            required: true,
            conform: (value) => moment(value).isValid() && value != null,
            messages: {
                conform: T.translate('errors.required'),
            },
        },
        activityType: {
            required: true,
            conform: (value) => value.id != null,
            messages: {
                conform: T.translate('errors.required'),
            },
        },
    },
};

export const Symptom = ({ id, code, name, formal_name }) => {
    const model = new JsonApiDataStoreModel('symptoms', id);
    model.setAttribute('code', code);
    model.setAttribute('name', name);
    model.setAttribute('formal_name', formal_name);
    return model;
};

export const Note = ({ note, comments, start, end, reminder_flag = false, reminder }) => {
    const model = new JsonApiDataStoreModel('person-notes');
    model.setAttribute('note', note);
    model.setAttribute('reminder_flag', reminder_flag);
    model.setAttribute('reminder', reminder);
    model.setAttribute('comments', comments);
    model.setAttribute('date', start);
    model.setRelationship('category', { id: 1 });
    // model.setAttribute("end", end);

    model.Validator = {
        properties: {
            note: {
                required: true,
            },
        },
    };
    return model;
};

export const PersonSingleUseDrug = (drug, symptoms = {}, date = moment(), tags = []) => {
    // Convert tags
    tags = tags.map((tag) => {
        if (typeof tag == 'string') {
            tagObj = new JsonApiDataStoreModel('tags');
            tagObj.setAttribute('name', tag);
            return tagObj;
        }
        return tag;
    });
    // Create the model
    const model = new JsonApiDataStoreModel('person-drug-usages');
    model.setAttribute('drug_dose_datetime', date.format());
    model.setAttribute('comments', '');
    model.setAttribute('drug_name', '');
    model.setAttribute('dose', null);
    model.setRelationship('category', {});
    model.setRelationship('drug', {});
    model.setRelationship('tags', tags);

    return model;
};

PersonSingleUseDrug.Validator = {
    properties: {
        drug: {
            required: true,
        },
        drug_dose_datetime: {
            required: true,
            conform: (item) => moment(item).isValid(),
        },
    },
};
