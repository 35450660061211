import React, { Component, Fragment as F } from "react";
import T from "i18n-react";
import { SearchInput, TextAreaInput, TextInput } from "Mib/components/inputs/form_inputs";
import { Icon } from "semantic-ui-react";

import BaseContainer from "Mib/components/containers/base";
import moment from "moment";

export default class ProviderRow extends Component {
    constructor() {
        super();
        this.state = {
            index: 0,
            codeShowing: false,
        };

        this.prov = {
            name: "",
            "provider-speciality": {
                "provider-category": "",
                name: "",
            },
            address: "",
            phone1: "",
        };
        this.details = {
            categories: [],
        };
    }

    render() {
        const perm = this.props.provider;
        this.prov = perm.provider;
        if (!this.prov) {
            this.prov = {
                name: this.props.provider.name,
                "provider-speciality": {
                    "provider-category": "",
                    name: T.translate("providers.noRegisterProv"),
                },
                address: "",
                phone1: "",
            };
        }

        this.details = {};
        let from, to;
        if (perm["start-date"]) from = moment(perm["start-date"]);
        if (perm["end-date"]) to = moment(perm["end-date"]);
        if (perm.type == 2) {
            this.details.status = T.translate("providers.permanent");
        } else if (from && to) {
            this.details.status =
                T.translate("from") + ": " + from.format("L") + "   " + T.translate("to") + ": " + to.format("L");
        } else {
            this.details.status = T.translate("providers.temporary");
        }
        this.details.categories = perm["permission-details"]&&perm["permission-details"].map(cat => cat.name);
        const prov = this.prov;

        return this.renderControls();
    }

    renderControls() {
        const prov = this.props.provider;
        if (this.props.type == "active") {
            return <BaseContainer>{prov.name}</BaseContainer>;
        } else if (this.props.type == "inactive") {
            return <BaseContainer>Inactive</BaseContainer>;
        } else if (this.props.type == "pending") {
            return (
                <BaseContainer>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4>
                                <Icon name="doctor" />
                                {prov.name}
                            </h4>
                            <span style={{}}>
                                <Icon className="editButton" name="write" size="large" />
                                <Icon className="deleteButton" name="trash outline" size="large" />
                            </span>
                        </div>

                        <div>{prov["provider-speciality"] && prov["provider-speciality"].name}</div>
                        <div>{prov.address}</div>
                        <div>
                            {prov.phone1 +
                                (prov.phone2 ? " - " + prov.phone2 : "") +
                                (prov.mobile ? " - " + prov.mobile : "")}
                        </div>
                        <div>{this.details.status}</div>
                        <div>{T.translate("providers.categories") + ": " + this.details&&this.details.categories&&this.details.categories.join(", ")}</div>
                        <div className="provider-controls">{/*{this.renderControls()}*/}</div>
                    </div>
                </BaseContainer>
            );
        } else {
            return null;
        }
    }

    showCode() {
        this.setState({ codeShowing: !this.state.codeShowing });
    }
}
