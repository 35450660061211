import React, { Component, Fragment as F } from 'react';
import { connect } from 'react-redux';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import { Tab, Accordion, Icon, Segment } from 'semantic-ui-react';
import HealthProvidersActive from './health_providers_active';
import HealthProvidersInactive from './health_providers_inactive';
import HealthProvidersPending from './health_providers_pending';
import moment from 'moment';
import { PermissionsCard } from './permissionsCard';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { WithPayment } from '../../../billing/withPaymentWrapper';
import BillingDimmer from '../../../billing/dimmer';

class _HealthProviders extends Component {
    constructor() {
        super();
        this.state = {
            toggled: true,
            activeProviders: [],
            inactiveProviders: [],
            pendingProviders: [],
            textFilter: '',
            activeIndex: 2,
        };
    }

    componentWillReceiveProps(newProps) {
        this.filterProviders(newProps.providers);
    }

    componentDidMount() {
        this.filterProviders(this.props.providers);
    }

    filterProviders(allproviders, filter = this.state.textFilter) {
        const providers =
            allproviders &&
            Object.values(allproviders).filter((p) => {
                return p.providerType.type == 'Doctor';
            });
        this.setState({
            activeProviders: providers && providers.filter(this.filterActive),
            inactiveProviders: providers && providers.filter(this.filterInactive),
            pendingProviders: providers && providers.filter(this.filterPending),
        });
    }

    filterActive(provider) {
        const lolo = provider.permissions.length
            ? Object.values(provider.permissions).every((item) => {
                  return (
                      moment(item['start']) <= moment.now() &&
                      (moment(item['end']) >= moment.now() || item['end'] == null) &&
                      item['bind-code'] === null
                  );
              })
            : false;

        return lolo;
    }

    filterInactive(provider) {
        return provider.permissions
            ? Object.values(provider.permissions).every(
                  (item) =>
                      moment(item['end']) != null && moment(item['end']) <= moment.now() && item['bind-code'] === null
              )
            : false;
    }

    filterPending(provider) {
        const pending = provider.permissions
            ? Object.values(provider.permissions).every((item) => {
                  return item['bind-code'] !== null;
              })
            : false;

        return pending;
    }

    render() {
        return (
            <div>
                <WithPayment feature_id="premium.connections.doctors">
                    {(props) => (
                        <F>
                            {!props.canViewFeature ? (
                                <BillingDimmer
                                    mediMsg={`${T.translate('billing.you-are-basic')}`}
                                    feature_id={props.feature_id}
                                />
                            ) : null}
                            <label className="titleLabel"> {T.translate('providers.health-providers')}</label>
                            <Segment>
                                <Accordion
                                    defaultActiveIndex={[0, 1, 2]}
                                    exclusive={false}
                                    fluid
                                    styled
                                    panels={[
                                        {
                                            key: 0,
                                            title: T.translate('providers.active'),
                                            content: {
                                                content: (
                                                    <HealthProvidersActive
                                                        activeProviders={this.state.activeProviders}
                                                    />
                                                ),
                                            },
                                        },
                                        {
                                            key: 1,
                                            title: T.translate('providers.inactive'),
                                            content: {
                                                content: (
                                                    <HealthProvidersInactive
                                                        inactiveProviders={this.state.inactiveProviders}
                                                    />
                                                ),
                                            },
                                        },
                                        {
                                            key: 2,
                                            title: T.translate('providers.pending'),
                                            content: {
                                                content: (
                                                    <HealthProvidersPending
                                                        pendingProviders={this.state.pendingProviders}
                                                    />
                                                ),
                                            },
                                        },
                                    ]}
                                />
                            </Segment>
                        </F>
                    )}
                </WithPayment>
            </div>
        );
    }
    handleClick(e, titleProps) {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    }
}

const mapStateToProps = ({ dataStore }) => {
    return {
        providers: getProperty(dataStore, 'graph.person-providers'),
    };
};
export default subscribeWithLoader(_HealthProviders, mapStateToProps, ['doctors']);
