import React, { Component } from "react";
import T from "i18n-react";
import dotProp from "dot-prop";
import { Icon, Segment,Label,Table,Grid} from "semantic-ui-react";




export class ProviderInfoTable extends Component{
    constructor(){
        super();
    }



    render(){
        const {name,address,website,email} = this.props
        return(
            <Grid.Column>
                <Table>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{T.translate('providers.name')}:</Table.Cell>
                            <Table.Cell>{name}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{T.translate('providers.address')}:</Table.Cell>
                            <Table.Cell>{address}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{T.translate('providers.site')}:</Table.Cell>
                            <Table.Cell>{website}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{T.translate('providers.email')}:</Table.Cell>
                            <Table.Cell>{email}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Grid.Column>
        )
    }

}