import React, { Component } from 'react';
import { connect } from 'react-redux';

import { store } from '../../datastore/store';
import { Create, Update } from '../../actions';
import TimelineEventList from '../generic/timeline/timeline_event_list';
import BaseContainer from '../containers/base';
import { GeneralMetricControls } from './general_metric_controls';
import { getProperty } from 'dot-prop';

class _personLabWithDoc extends Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        const labName = (props.labExam && props.labExam.name) || '';
        this.state = {
            loading: false,
            dateFrom: null,
            dateTo: null,
        };
        this.dataPropName = 'personLabs';
    }

    render() {
        console.log('person labs ', this.props.personLabs);
        let { configUpdateUnitFunc, unitId, ...rest } = this.props;
        let { dateFrom, dateTo, loading } = this.state;
        let { labExam, tagFilters, personLabs } = this.props;
        var { labId } = this.props.match.params;
        const measureUnitOptions = {};

        var labName = (labExam && labExam.name) || '';

        let data = Object.values(personLabs);

        let dataType = 'lab-exam-events';
        let name = labName;
        let graphPointClick = this.open;
        let forceEventType = 'lab-exam-document';

        let createFunc = graphPointClick != undefined ? (args) => graphPointClick(null, dataType, labId) : null;

        return (
            <React.Fragment>
                <GeneralMetricControls
                    onCreate={createFunc || null}
                    unitId={unitId}
                    measureUnitOptions={measureUnitOptions}
                    configUpdateUnitFunc={configUpdateUnitFunc}
                    resourceType={dataType}
                    startDate={dateFrom}
                    endDate={dateTo}
                    onStartChanged={(e, d) => this.setState({ dateFrom: d ? d.moment : null })}
                    onEndChanged={(e, d) => this.setState({ dateTo: d ? d.moment : null })}
                    tagFilters={tagFilters}
                    // toggleView={this.toggleChange}
                />
                <BaseContainer title={labName} loading={loading} style={{ marginTop: '10px' }}>
                    <TimelineEventList
                        data={data}
                        sortOn="date"
                        title={this.props.title}
                        timelinePointClick={this.open || null}
                        forceEventType={forceEventType}
                    />
                </BaseContainer>
            </React.Fragment>
        );
    }

    open(id = undefined, type = undefined, lab_type = null) {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'IN_VIVO_MODAL',
                id,
                type,
                lab_type,
            },
        });
    }
}

const mapStateToProps = (state, props) => {
    var { labId } = props.match.params;

    var allLabExams = state.dataStore.graph['lab-exam-events'];
    var labExamEvents = {};
    allLabExams &&
        Object.entries(allLabExams).forEach(([key, labExamEvent]) => {
            if (labExamEvent.labExam && labExamEvent.labExam.id == labId) {
                labExamEvents[labExamEvent.id] = labExamEvent;
            }
        });

    var lab = getProperty(state.dataStore.graph, `lab-exams.${labId}`);

    return {
        personLabs: labExamEvents,
        labExam: lab,
        filters: state.filters,
    };
};

export default connect(mapStateToProps)(_personLabWithDoc);
