import React from 'react'


export const printIframe = id => {
    const iframe = document.frames ? document.frames[id] : document.getElementById(id)
    const iframeWindow  = iframe.contentWindow || iframe

    console.log('i frame',iframe)
    console.log('i frame content', iframe.contentWindow)

    iframe.focus();
    iframeWindow.print();
    return false;
}

export const Print = (id) => {
    var divContents = document.getElementById(id).innerHTML;
    var printWindow = window.open('', '','');
    printWindow.document.write('<html><head>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(divContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
}






const Iframe = ({props}) =>{
    return(
        <div dangerouslySetInnerHTML={{__html: props.iframe ? props.iframe : ''}}/>


    )
}


