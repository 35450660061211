import React, { Component } from 'react';
import { getProperty } from 'dot-prop';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';

import { ChannelController } from 'Mib/lib/websockets/channels';
import * as _Routes from 'Mib/routes';
import { SendUnencryptedData } from 'Mib/lib/sendUnencrypted';
import { SocketConnect, SocketDisconnect } from 'Mib/lib/websockets/connectToSocket';

class Routes extends Component {
    constructor(props) {
        super();
        this.state = {
            activePersonId: props.authData.activePersonId,
            lang: props.lang,
        };
    }

    componentDidMount() {
        const { authData, lang, activePerson } = this.props;
        const isAuthenticated = authData.access_token !== undefined;
        if (isAuthenticated && !window.sockClient) {
            SocketConnect(authData, lang);
        } else {
            console.warn('Skipping socket connection because session is not authorized');
        }

        if (activePerson && activePerson.dob != null && activePerson.gender != null) {
            if (activePerson.i == null || activePerson.g == null) {
                const yearOfBirth = moment(activePerson.dob).year();
                SendUnencryptedData(authData.activePersonId, yearOfBirth, activePerson.gender);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { activePersonId, lang } = this.state;
        const isAuthenticated = prevProps.authData && prevProps.authData.access_token !== undefined;
        const userChanged = prevProps.authData && prevProps.authData.activePersonId !== activePersonId;
        const langChanged = prevProps.lang && prevProps.lang !== lang;

        if (isAuthenticated && !window.sockClient) {
            SocketConnect(prevProps.authData, prevProps.lang);
        } else if ((isAuthenticated && userChanged) || langChanged) {
            this.setState({ activePersonId: prevProps.authData.activePersonId, lang: prevProps.lang });
            SocketDisconnect();
            SocketConnect(prevProps.authData, prevProps.lang);
        }
    }

    render() {
        const { authData } = this.props;
        const isAuthenticated = authData.access_token !== undefined;
        return (
            <>
                <ChannelController />
                <Switch>
                    <Redirect from="/" exact to="/dashboard" />

                    <AuthenticatedRoute
                        path="/dashboard"
                        component={_Routes.DashboardPage}
                        isAuthenticated={isAuthenticated}
                    />
                    {/* <AuthenticatedRoute path="/emergency-data" component={_Routes.EmergencyDataPage} isAuthenticated={isAuthenticated}/> */}
                    <AuthenticatedRoute
                        path="/health-folder"
                        component={_Routes.HealthFolderPage}
                        isAuthenticated={isAuthenticated}
                    />
                    <AuthenticatedRoute
                        path="/documents"
                        component={_Routes.DocumentPage}
                        isAuthenticated={isAuthenticated}
                    />
                    <AuthenticatedRoute
                        path="/health-indicators"
                        component={_Routes.HealthIndicatorsPage}
                        isAuthenticated={isAuthenticated}
                    />

                    <AuthenticatedRoute
                        path="/activities/:type?"
                        component={_Routes.ActivitiesPage}
                        isAuthenticated={isAuthenticated}
                    />
                    <AuthenticatedRoute
                        path="/profile/:mode?/:id?"
                        component={_Routes.PersonsPage}
                        isAuthenticated={isAuthenticated}
                    />
                    <AuthenticatedRoute
                        path="/connections/:mode?"
                        component={_Routes.ConnectionsPage}
                        isAuthenticated={isAuthenticated}
                    />
                    <AuthenticatedRoute
                        path="/user-settings"
                        component={_Routes.UserSettingsPage}
                        isAuthenticated={isAuthenticated}
                    />
                    <AuthenticatedRoute
                        path="/interact-with-medi"
                        component={_Routes.InteractiveModeIndexPage}
                        isAuthenticated={isAuthenticated}
                    />
                    {authData.provider_type && authData.provider_type.type === 'Promoter' ? (
                        <AuthenticatedRoute
                            path="/promoter-admin"
                            component={_Routes.PromotedAdminPage}
                            isAuthenticated={isAuthenticated}
                        />
                    ) : null}
                    {/* <AuthenticatedRoute path="/synchronizations/:mode?" component={_Routes.ConnectionsPage} isAuthenticated={isAuthenticated}/> */}
                </Switch>

                {/* <GlobalModal signalType="test" title={"hello"}>blablalasd asjd </GlobalModal> */}
            </>
        );
    }
}

const mapStateToProps = ({ authentication, appState, dataStore }) => ({
    authData: authentication,
    lang: appState.lang,
    activePerson: getProperty(dataStore, `graph.persons.${authentication.activePersonId}`),
});

export default withRouter(connect(mapStateToProps)(Routes));

function AuthenticatedRoute({ component: Component, isAuthenticated, ...rest }) {
    function Empty() {
        return null;
    }
    Component = Component || Empty;

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login', search: `?next=${window.location.pathname}` }} />
                )
            }
        />
    );
}
