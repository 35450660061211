import React from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import sortOn from 'sort-on';
import moment from 'moment';

import { SimpleMetricComponent } from 'Mib/components/metric_panels/filtered_metric';
import ReactHighChartsManaged from 'Mib/components/generic/react_high_charts_managed';
import BaseContainer from 'Mib/components/containers/base';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { store } from 'Mib/datastore/store';

import { MeasurementInfoPanel } from './measurements_info';
import NoDataMessage from './no_data_message';

class _DashTemperatureChart extends SimpleMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.dataPropName = 'temperatures';
        this.config = {
            descriptor: 'result',
            name: T.translate('measurements.singular.temperature'),
            dateDescriptor: 'date',
            onGraphPointClicked: this.open.bind(this),
            valueOffset: 10,
            merge_config: (props, cfg) => ({
                chart: {
                    height: 200,
                },
                series: [
                    {
                        ...cfg.series[0],
                        showInLegend: false,
                        color: props.color,
                    },
                ],
                title: { floating: true, text: '' },
                xAxis: {
                    title: {
                        text: '',
                    },
                },
            }),
        };
    }

    componentWillReceiveProps(newProps) {
        this.forceRefresh = newProps.color != this.props.color;
        super.componentWillReceiveProps(newProps);
    }

    render() {
        let { chart_config } = this.state;
        let { temperatures, loading, measures } = this.props;
        let lastMeasurement = temperatures && sortOn(Object.values(temperatures), '-date')[0];
        let measureDate = moment(getProperty(lastMeasurement, 'date')).format('L');
        let measureTime = moment(getProperty(lastMeasurement, 'date')).format('HH:mm');
        let measureUnitAbbrev = getProperty(lastMeasurement, 'measureUnit.abbreviation');
        let value = lastMeasurement ? lastMeasurement.result + measureUnitAbbrev : undefined;
        return (
            <>
                <MeasurementInfoPanel
                    style={{ borderTopColor: this.props.color, borderTopWidth: '2px' }}
                    title={T.translate('measurements.my.my-temperature-graph')}
                    date={measureDate}
                    time={measureTime}
                    value={value}
                    altText={T.translate('no-data')}
                    loading={loading}
                    color={this.props.color}
                    link="/health-folder/temperatures"
                />
                <BaseContainer loading={loading} className="dash-chart-container">
                    {loading ? (
                        <div style={{ width: '100%', height: '200px' }} />
                    ) : temperatures ? (
                        <ReactHighChartsManaged config={chart_config} />
                    ) : (
                        <NoDataMessage create={() => this.open(null, 'temperatures')} />
                    )}
                </BaseContainer>
            </>
        );
    }

    open(id = undefined, type = undefined) {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'MEASUREMENT_MODAL',
                showTime: true,
                id: id,
                type: type,
            },
        });
    }
}

const mapStateToProps = (state) => {
    const allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    const temperatureMeasureUnits = {};
    if (allmeasureUnits)
        Object.entries(allmeasureUnits).forEach(([key, unit]) => {
            // console.log('measureUnitType',unit)
            if (unit.measureUnitType && unit.measureUnitType.name == 'TEMPERATURE') {
                temperatureMeasureUnits[unit.id] = unit;
            }
        });

    return {
        temperatures: state.dataStore.graph.temperatures,
        measures: temperatureMeasureUnits,
        filters: state.filters,
    };
};

export const DashTemperatureChart = subscribeWithLoader(_DashTemperatureChart, mapStateToProps, ['temperatures']);

// export default connect(mapStateToProps)(_DashHeightChartSub);
