import React, { Component, Suspense } from 'react';
import { Input, Icon, Popup, Transition, Label, Segment } from 'semantic-ui-react';

import T from 'i18n-react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { TextInput, MibButton } from 'Mib/components/inputs';

import 'react-datepicker/dist/react-datepicker.css';
import { deltaDate } from './calendarTools';

const DatePicker = React.lazy(() => import('react-datepicker'));

class _AdvancedRangeCalendar extends Component {
    constructor() {
        super();
        this.state = {
            controlsVisible: false,
        };
    }

    onStartChanged = (date, e) => {
        this.setState({ startDate: date });
        const retValue = date != null ? { value: date, moment: date } : null; // props. onChange function  always expects a value property
        // let retValue = date != null ? Object.assign({}, { value: date.format(), moment: date }) : null; //props. onChange function  always expects a value property
        this.props.setDates({ dateFrom: date });
        if (this.props.onStartChanged) this.props.onStartChanged(e, retValue);
    };

    onEndChanged = (date, e) => {
        this.setState({ endDate: date });
        const retValue = date != null ? { value: date, moment: date } : null; // props. onChange function  always expects a value property
        // let retValue = date != null ? Object.assign({}, { value: date.format(), moment: date }) : null; //props. onChange function  always expects a value property
        this.props.setDates({ dateTo: date });
        if (this.props.onEndChanged) this.props.onEndChanged(e, retValue);
    };

    toggleControls = () => {
        this.setState({ controlsVisible: !this.state.controlsVisible });
    };

    lastWeekPicked = (e) => {
        const today = new Date();
        // let today = moment();
        // this.onStartChanged(moment(today).subtract(1, 'week'), e);
        this.onStartChanged(deltaDate(today, -7, 0, 0), e);
        // today.setDate(today.getDate() - 1 * 7);
        this.onEndChanged(today, e);
    };

    lastMonthPicked = (e) => {
        const today = new Date();
        // let today = moment();
        // this.onStartChanged(moment(today).subtract(1, 'month'), e);
        this.onStartChanged(deltaDate(today, 0, -1, 0), e);
        this.onEndChanged(today, e);
    };

    lastYearPicked = (e) => {
        const today = new Date();
        // let today = moment();
        // this.onStartChanged(moment(today).subtract(1, 'year'), e);
        this.onStartChanged(deltaDate(today, 0, 0, -1), e);
        this.onEndChanged(today, e);
    };

    allPicked = (e) => {
        this.onStartChanged(null, e);
        this.onEndChanged(null, e);
        this.setState({ controlsVisible: false });
    };

    renderLabel() {
        let {
            filters: { startDate, endDate },
        } = this.props;
        const { tagFilters } = this.props;
        const { controlsVisible } = this.state;
        startDate = this.state.startDate || startDate;
        endDate = this.state.endDate || endDate;
        const hasTagFilters = tagFilters && tagFilters.length !== 0;
        return (
            <div className={classnames('advanced-controls-header', { open: controlsVisible })}>
                <span className="date-display">
                    <Icon name="calendar" />
                    {startDate && endDate ? (
                        <>
                            <label>{T.translate('meta.from')}:</label>
                            <span className="value">{startDate.format('L')}</span>
                            <label>{T.translate('meta.to')}:</label>
                            <span className="value">{endDate && endDate.format('L')}</span>
                        </>
                    ) : (
                        <label>{T.translate('meta.all-measurements')}</label>
                    )}
                </span>
                {hasTagFilters && (
                    <span className="tags-display">
                        <Icon name="tags" />
                        {tagFilters.map((tag, index) =>
                            index < 2 ? (
                                <Label tag key={tag.id}>
                                    {tag.name}
                                </Label>
                            ) : null
                        )}
                        {tagFilters && tagFilters.length >= 3 && '...'}
                    </span>
                )}
                <Icon name="chevron down" className="controls-arrow" />
                <Popup
                    trigger={
                        <Icon
                            name="close"
                            className="clear-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.allPicked(e);
                            }}
                        />
                    }
                    content={T.translate('meta.clear-filters')}
                />
            </div>
        );
    }

    render() {
        let {
            filters: { startDate, endDate },
        } = this.props;
        const { fromLabel, toLabel } = this.props;
        const { controlsVisible } = this.state;

        startDate = this.state.startDate || startDate;
        endDate = this.state.endDate || endDate;

        return (
            <Suspense fallback={TextInput}>
                <div className="advanced-datepicker" onClick={this.toggleControls}>
                    {/* from: {startDate && startDate.format("L")} to: {endDate && endDate.format("L")} */}
                    {this.renderLabel()}
                    {controlsVisible ? <div className="fader" onClick={this.toggleControls} /> : null}
                    <Transition visible={controlsVisible} animation="slide down" duration={500}>
                        <Segment
                            className="advanced-datepicker-controls"
                            // style={{ display: controlsVisible ? "flex" : "none", width: "auto" }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Icon className="x-button" name="close" onClick={() => this.toggleControls()} />
                            <div className="advanced-datepicker-column">
                                <label>
                                    <span className="InputLabel">{fromLabel}:</span>
                                </label>
                                <Input className="defaultCss">
                                    <DatePicker
                                        selected={startDate}
                                        selectsStart
                                        inline
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={this.onStartChanged}
                                        dateFormat="dd/MM/yyyy"
                                        todayButton={T.translate('meta.dates.today')}
                                        maxDate={new Date()}
                                        // maxDate={moment()}
                                    />
                                </Input>
                            </div>
                            <div className="advanced-datepicker-column">
                                <label>
                                    <span className="InputLabel">{toLabel}:</span>
                                </label>
                                <Input className="defaultCss">
                                    <DatePicker
                                        selected={endDate}
                                        selectsEnd
                                        inline
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={this.onEndChanged}
                                        dateFormat="dd/MM/yyyy"
                                        todayButton={T.translate('meta.dates.today')}
                                        highlightDates={[new Date()]}
                                        // highlightDates={[moment()]}
                                        maxDate={new Date()}
                                        // maxDate={moment()}
                                    />
                                </Input>
                            </div>
                            <div className="advanced-datepicker-column easy-buttons">
                                <MibButton
                                    label={T.translate('meta.dates.last-week')}
                                    color="blue"
                                    basic
                                    onClick={this.lastWeekPicked}
                                />
                                <MibButton
                                    label={T.translate('meta.dates.last-month')}
                                    color="blue"
                                    basic
                                    onClick={this.lastMonthPicked}
                                />
                                <MibButton
                                    label={T.translate('meta.dates.last-year')}
                                    color="blue"
                                    basic
                                    onClick={this.lastYearPicked}
                                />
                                <spacer style={{ flexGrow: 1 }} />
                                <MibButton
                                    label={T.translate('meta.dates.all')}
                                    color="olive"
                                    basic
                                    onClick={this.allPicked}
                                />
                                {/* <MibButton
                                label={T.translate("meta.apply")}
                                color="olive"
                                basic
                                onClick={() => this.setState({ controlsVisible: false })}
                            /> */}
                            </div>
                        </Segment>
                    </Transition>
                </div>
            </Suspense>
        );
    }
}

const mapStateToProps = ({ filters }) => ({ filters });

const mapDispatchToProps = (dispatch) => ({
    setDates: ({ dateFrom, dateTo }) => dispatch({ type: 'SET_DATE_FILTERS', payload: { dateFrom, dateTo } }),
});

export const AdvancedRangeCalendar = connect(mapStateToProps, mapDispatchToProps)(_AdvancedRangeCalendar);
