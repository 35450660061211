// eslint-disable-next-line max-classes-per-file
import React from 'react';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import { fetchAction } from '../actions/backend';

export const subscribe = (Component, measurements = []) => {
    return class extends Component {
        // componentWillMount() {
        componentDidMount() {
            measurements.forEach((m) => {
                m == 'documents' ? fetchAction('documents', { query: '?orderBy=date&sortedBy=desc' }) : fetchAction(m);
            });
        }

        render() {
            return <Component {...this.props} />;
        }
    };
};

export const subscribeWithLoader = (Component, mapStateToProps, measurements = [], withRef = false) => {
    class Wrapped extends Component {
        // componentWillMount() {
        componentDidMount() {
            measurements.forEach((m) => {
                fetchAction(m);
                // const isPaginated = m.includes("page");
                // if (!isPaginated) {
                //     fetchAction(m);
                // } else {
                //     fetchAction(m).then(_ => fetchAction(
                //         m.replace(/[\?|\&]page=\d+/, "")
                //         .replace(/[\?|\&]perPage=\d+/, ""), {force: true}
                //
                //      ));
                // }
            });
        }

        render() {
            const loading = measurements
                .map((i) => i.split('?')[0])
                .some((name) => getProperty(this.props, `appState.lastSync.${name}`) === undefined);
            const syncing = measurements
                .map((i) => i.split('?')[0])
                .some((name) => this.props.appState.syncing.includes(name));
            return <Component {...this.props} loading={loading} syncing={syncing} />;
        }
    }

    const enhanceProps = (state) => {
        const componentProps = mapStateToProps(state);
        return {
            appState: state.appState,
            ...componentProps,
        };
    };

    if (withRef) {
        return connect(enhanceProps, null, null, { forwardRef: true })(Wrapped);
    }
    return connect(enhanceProps)(Wrapped);
};
