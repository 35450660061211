/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Segment, Message, Transition, Icon } from 'semantic-ui-react';
import T from 'i18n-react';
import SHA256 from 'crypto-js/sha256';

import { authBackendURL } from 'Mib/config';
import { notify } from 'Mib/actions';
import { TextInput, MibButton } from 'Mib/components/inputs';

require('./login.scss');

const PageHeader = (
    <Grid verticalAlign="middle">
        <Grid.Column mobile={16} tablet={8} computer={8}>
            <Link to="/">
                <img src="/assets/img/logo_mib_beta.png" alt="logo medinfobook" />
            </Link>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
            <img src="/assets/img/logo_cs.png" alt="Logo cs" style={{ float: 'right', marginRight: 10 }} />
        </Grid.Column>
    </Grid>
);

export class PasswordResetPage extends Component {
    constructor() {
        super();
        this.state = { loading: false, error: false };
    }

    componentDidMount() {
        this.emailInput.focus();
    }

    async submit() {
        const { email } = this.state;
        this.setState({ loading: true, error: false, success: false });
        let headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Locale': 'el',
        };
        try {
            let result = await fetch(`${authBackendURL}reset-individual-password-email`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ data: { email: email } }),
            });
        } catch (e) {
            this.setState({ loading: false, error: T.translate('meta.password-reset.password-reset-failed') });
            return false;
            // TODO: rollbar
        }

        if (!result.ok) {
            switch (result.status) {
                case 404:
                    this.setState({ loading: false, error: T.translate('meta.password-reset.password-reset-404') });
                    this.emailInput.focus();
                    break;
                default:
                    this.setState({ loading: false, error: T.translate('meta.password-reset.password-reset-failed') });
                // TODO: rollbar
            }
            return false;
        }

        this.setState({ loading: false, success: true });
        // this.props.history.push({ pathname: "/dashboard" });
    }

    render() {
        const { success, error, loading, email } = this.state;
        return (
            <div className="login-background">
                {PageHeader}
                {success ? (
                    <div style={{ maxWidth: '800px', width: '100%', margin: 'auto' }}>
                        <Message success>
                            <Message.Header style={{ marginBottom: '1.2em' }}>
                                <Icon name="mail" />
                                {T.translate('meta.password-reset.email-success-title')}
                            </Message.Header>
                            {T.translate('meta.password-reset.email-success-body')}
                            <p style={{ color: 'rgba(0,0,0,0.67)', marginTop: '1em' }}>
                                {T.translate('meta.password-reset.resend-email-1')}{' '}
                                <a onClick={this.submit.bind(this)} style={{ cursor: 'pointer' }}>
                                    {T.translate('meta.password-reset.resend-email-2')}
                                </a>
                            </p>
                        </Message>
                    </div>
                ) : (
                    <div className="login-panel-container">
                        <Segment className="login-panel">
                            <div className="login-title">{T.translate('meta.password-reset.reset-title')}</div>
                            <TextInput
                                type="email"
                                className="login-input"
                                ref={(i) => (this.emailInput = i)}
                                value={email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                placeholder={T.translate('meta.registration.email')}
                                label={T.translate('meta.registration.email')}
                                onKeyDown={(e) => e.key == 'Enter' && this.submit()}
                            />
                            <Message info>
                                <Icon name="info circle" />
                                {T.translate('meta.password-reset.password-reset-info')}
                            </Message>
                            <MibButton
                                fluid
                                type="info"
                                className="login-button"
                                loading={loading}
                                disabled={loading}
                                onClick={this.submit.bind(this)}
                                label={T.translate('meta.password-reset.reset')}
                            />
                            <Transition.Group animation="drop" duration={500}>
                                {error ? (
                                    <Message negative style={{ marginTop: '10px' }}>
                                        <Message.Header>{T.translate('meta.password-reset.error-title')}</Message.Header>
                                        <p>{error}</p>
                                    </Message>
                                ) : null}
                            </Transition.Group>
                        </Segment>
                        <MibButton
                            color="teal"
                            className="register-button"
                            label={
                                <span>
                                    <Icon name="chevron left" style={{ float: 'left' }} />
                                    {T.translate('meta.return')}
                                </span>
                            }
                            onClick={() => this.props.history.push({ pathname: '/login' })}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export class PasswordResetForm extends Component {
    constructor() {
        super();
        this.state = { mismatch: true };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            match: {
                params: { token },
            },
        } = nextProps;
        return prevState.token != token ? { ...prevState, token } : null;
    }

    onPasswordChanged(field, e) {
        let { pw1, pw2 } = this.state;
        if (field == 'pw1') {
            pw1 = e.target.value;
        } else {
            pw2 = e.target.value;
        }

        let mismatch = pw1 != pw2 || pw1 == '';

        this.setState({ [field]: e.target.value, mismatch, error: null });
    }

    async submit() {
        let { pw1, mismatch, token } = this.state;
        if (mismatch || token == null) {
            this.pw1.focus();
            this.setState({
                error:
                    pw1 == null || pw1.length == 0
                        ? T.translate('meta.password-reset.empty-password-not-allowed')
                        : T.translate('meta.password-reset.password-mismatch'),
            });
            return;
        }

        // TODO: password validation rules (length, complexity, ...)
        const passHash = SHA256(pw1).toString();
        const data = { data: { reset_code: token, password: passHash } };
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Locale': 'el',
        };

        try {
            let result = await fetch(`${authBackendURL}reset-password`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
            });
        } catch (e) {
            this.setState({
                pw1: '',
                pw2: '',
                mismatch: true,
                error: T.translate('meta.password-reset.password-change-failed'),
            });
            this.pw1.focus();
        }

        if (!result.ok) {
            // TODO use server errors
            this.setState({
                pw1: '',
                pw2: '',
                mismatch: true,
                error: T.translate('meta.password-reset.password-change-failed'),
            });
            this.pw1.focus();
        } else {
            this.props.history.replace('/login');
            notify({
                success: true,
                header: T.translate('meta.password-reset.password-changed-successfully-title'),
                content: T.translate('meta.password-reset.password-changed-successfully-content'),
            });
        }
    }

    render() {
        const { success, error, loading, pw1, pw2, showPass, mismatch } = this.state;
        return (
            <div className="login-background">
                {PageHeader}
                <div className="login-panel-container">
                    <Segment className="login-panel">
                        <div className="login-title">{T.translate('meta.password-reset.reset-form-title')}</div>
                        <TextInput
                            type={showPass ? 'text' : 'password'}
                            className="login-input"
                            ref={(i) => (this.pw1 = i)}
                            value={pw1}
                            onChange={this.onPasswordChanged.bind(this, 'pw1')}
                            placeholder={`${T.translate('meta.password-reset.new')} ${T.translate(
                                'meta.registration.password'
                            )}`}
                            onKeyDown={(e) => e.key == 'Enter' && this.submit()}
                            icon={
                                <Icon
                                    name={showPass ? 'hide' : 'unhide'}
                                    onClick={() => this.setState({ showPass: !showPass })}
                                    link
                                />
                            }
                        />
                        <TextInput
                            type="password"
                            className="login-input"
                            ref={(i) => (this.pw2 = i)}
                            value={pw2}
                            onChange={this.onPasswordChanged.bind(this, 'pw2')}
                            placeholder={T.translate('meta.registration.password-confirmation')}
                            onKeyDown={(e) => e.key == 'Enter' && this.submit()}
                            error={mismatch}
                            icon={<Icon name={mismatch ? 'remove' : 'checkmark'} color={mismatch ? 'red' : 'green'} />}
                        />
                        <MibButton
                            fluid
                            type="info"
                            className="login-button"
                            loading={loading}
                            disabled={loading}
                            onClick={this.submit.bind(this)}
                            label={T.translate('meta.password-reset.change-password')}
                        />
                        <Transition.Group animation="drop" duration={500}>
                            {error ? (
                                <Message negative style={{ marginTop: '10px' }}>
                                    <Message.Header>{T.translate('meta.password-reset.error-title')}</Message.Header>
                                    <p>{error}</p>
                                </Message>
                            ) : null}
                        </Transition.Group>
                    </Segment>
                </div>
            </div>
        );
    }
}
