import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import { Icon, Menu } from 'semantic-ui-react';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import Weight from 'Mib/components/metric_panels/weight';
import Height from 'Mib/components/metric_panels/height';
import TagPanel from 'Mib/components/containers/tag_panel';
import Oximetry from 'Mib/components/metric_panels/oximetry';
import Pressure from 'Mib/components/metric_panels/pressure';
import Pulses from 'Mib/components/metric_panels/pulses';
import Waist from 'Mib/components/metric_panels/waist';
import Head from 'Mib/components/metric_panels/head';
import Temperature from 'Mib/components/metric_panels/temperature';
import Document from 'Mib/components/metric_panels/person_document';
import AllMeasurements from 'Mib/components/metric_panels/all_measurements';
import WithSideMenu from 'Mib/components/containers/with_side_menu';
import { MenuPanel } from 'Mib/components/containers';
import { AllergyPanel } from 'Mib/routes/profile/emergency_data/allergy_panel';
import { MedicinePanel } from 'Mib/routes/profile/emergency_data/medicine_panel';
import { AllergyForm } from 'Mib/routes/profile/emergency_data/allergy_form';
import Gluccose from 'Mib/components/metric_panels/gluccose';
import { VaccinePanel } from 'Mib/routes/health_folder/vaccines/vaccine_panel';
import { MedicineForm } from 'Mib/routes/profile/emergency_data/medicine_form';
import { SingleUseMedicineForm } from 'Mib/routes/profile/emergency_data/single_use_medicine_form';

// import HealthFolderMenu from "./health_folder_menu_panel";
import ChildMenu from './child_menu_panel';
import HealthFolderMenu from './measurement_menu_panel';
import LabExamsMenu from './lab_exams_menu_panel';
import MedicationsMenu from './medication_menu_panel';

import { LabPage } from '../labs/index';
import { SingleUseMedicinePanel } from '../profile/emergency_data/single_use_dug_panel';
import SenseReportPanel from './sense_report/sense_report_panel';

require('./health_folder.scss');

function AllergyMenu({ history, openAllergyForm }) {
    const menuTitle = (
        <Menu.Item as={NavLink} to="/health-folder/allergies" exact className="menu-title emergency-title">
            <Icon className="icon-allergy" />
            <span className="title">{T.translate('measurements.plural.allergies')}</span>
        </Menu.Item>
    );
    return (
        <MenuPanel className="red profile-menu mib-menu" inverted title={menuTitle} openAllergyForm={openAllergyForm} />
    );
}

function VaccineMenu({ history }) {
    const menuTitle = (
        <Menu.Item as={NavLink} to="/health-folder/vaccines" exact className="menu-title emergency-title">
            <Icon className="icon-injecting-syringe-svgrepo-com" size="small" />
            <span className="title">{T.translate('measurements.plural.vaccines')}</span>
        </Menu.Item>
    );
    return <MenuPanel className="orange profile-menu mib-menu" inverted title={menuTitle} />;
}

function SenseReportMenu({ history }) {
    const menuTitle = (
        <Menu.Item as={NavLink} to="/health-folder/sense" exact className="menu-title emergency-title">
            <Icon className="icon-icon_activities" size="small" />
            <span className="title">{T.translate('sense.title')}</span>
        </Menu.Item>
    );
    return <MenuPanel className="purple profile-menu mib-menu" inverted title={menuTitle} />;
}

function TagPanelWithRoutes({ match, ...props }) {
    return <Route path={`${match.url}/:type?`} render={() => <TagPanel {...props} />} />;
}

class _HealthFolderPage extends Component {
    constructor({ person }) {
        super();
        let m = moment(person.dob, 'YYYY.MM.DD');
        let personAge = moment().diff(m, 'years', false);
        personAge != null || personAge > 18 ? (this.state = { child: false }) : (this.state = { child: true });
    }

    openAllergyForm(...args) {
        this.allergyForm.open(...args);
    }

    openMedicineForm(...args) {
        this.medicineForm.open(...args);
    }

    openSingleUseMedicineForm(...args) {
        this.singleUseMedicineForm.open(...args);
    }

    render() {
        let { personSettings, match, history, tagFilters, loading, syncing, ...rest } = this.props;
        let weightTarget = getProperty(personSettings, 'weight_target');

        const footer = weightTarget
            ? `${T.translate('persons.weightGoal')}: ${weightTarget}kg`
            : `${T.translate('persons.weightGoal')}: `;
        const childProps = { tagFilters, loading, syncing };
        const menuItems = [
            HealthFolderMenu,
            LabExamsMenu,
            AllergyMenu,
            MedicationsMenu,
            VaccineMenu,
            TagPanelWithRoutes,
            SenseReportMenu,
        ];
        this.state.child ? menuItems.splice(2, 0, ChildMenu) : null;
        return (
            <div className="content-container">
                <WithSideMenu match={match} history={history} menuItems={menuItems}>
                    <Route exact path={`${match.url}/`} component={AllMeasurements} />
                    <Route path={`${match.url}/weights/:action?`} render={() => <Weight {...childProps} />} />

                    <Route path={`${match.url}/heights/:action?`} render={() => <Height {...childProps} />} />
                    <Route path={`${match.url}/pressures/:action?`} render={() => <Pressure {...childProps} />} />
                    <Route path={`${match.url}/pulses/:action?`} render={() => <Pulses {...childProps} />} />
                    <Route path={`${match.url}/temperatures/:action?`} render={() => <Temperature {...childProps} />} />
                    <Route path={`${match.url}/oximetries/:action?`} render={() => <Oximetry {...childProps} />} />
                    <Route path={`${match.url}/waists/:action?`} render={() => <Waist {...childProps} />} />
                    <Route path={`${match.url}/heads/:action?`} render={() => <Head {...childProps} />} />

                    <Route path={`${match.url}/gluccosses/:action?`} render={() => <Gluccose {...childProps} />} />

                    <Route
                        path={`${match.url}/allergies`}
                        render={() => (
                            <AllergyPanel
                                {...childProps}
                                defaultShowAll
                                openAllergyForm={this.openAllergyForm.bind(this)}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/vaccines`}
                        render={() => <VaccinePanel {...childProps} defaultShowAll />}
                    />
                    <Route path={`${match.url}/medicine`} />

                    <Route
                        path={`${match.url}/medicine/medications`}
                        render={() => (
                            <MedicinePanel
                                {...childProps}
                                defaultShowAll
                                showExtraInfo
                                openMedicineForm={this.openMedicineForm.bind(this)}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/medicine/single-drug-use`}
                        render={() => (
                            <SingleUseMedicinePanel
                                {...childProps}
                                defaultShowAll
                                showExtraInfo
                                openMedicineForm={this.openSingleUseMedicineForm.bind(this)}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/labs`}
                        render={(props) => (
                            <LabPage changeParentState={this.hidePrimaryMenu} {...props} {...childProps} />
                        )}
                    />

                    <Route
                        path={`${match.url}/sense`}
                        render={() => (
                            <SenseReportPanel
                                {...childProps}
                                defaultShowAll
                                // openAllergyForm={this.openAllergyForm.bind(this)}
                            />
                        )}
                    />

                    <Route path={`${match.url}/documents`} render={() => <Document {...childProps} />} />

                    {this.state.child ? (
                        <Route path={`${match.url}/documents`} render={() => <Document {...childProps} />} />
                    ) : null}
                </WithSideMenu>
                <AllergyForm allergies={this.props.allergies} ref={(i) => (this.allergyForm = i)} />
                <MedicineForm ref={(i) => (this.medicineForm = i)} />
                <SingleUseMedicineForm ref={(i) => (this.singleUseMedicineForm = i)} />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication: { activePersonId }, dataStore, userSettings, appState }) => {
    const personSpecificSettings = getProperty(dataStore.graph, `person-settings.${activePersonId}`) || {};
    const person = getProperty(dataStore.graph, `persons.${activePersonId}`) || {};
    return {
        personSettings: personSpecificSettings,
        tagFilters: appState.tagFilters,
        allergies: getProperty(dataStore, 'graph.allergies'),
        person: person,
    };
};

export const HealthFolderPage = subscribeWithLoader(_HealthFolderPage, mapStateToProps, [
    'lab-exams?',
    'person-settings',
    'person-info',
    'allergies?',
]);
