require('./providersCss.scss');

import { Icon } from 'semantic-ui-react';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import WithSideMenu from 'Mib/components/containers/with_side_menu';

import HealthProviders from './providers/health_providers';
import AccessProvision from './providers/access_provision';
import ConnectMedicalLab from './providers/connect_medical_lab';
import ProvidersMenu from './providers/providers_menu';
import ConnectionsMenu from './devices_apps/connections_menu';
import { DevicesPage } from './devices_apps/index';

const TabTitleIcon = ({ icon, title }) => (
    <div className="mainDivProviderTab">
        <Icon className="tabIconStyle" name={icon} />
        <br />
        <label className="tabLabelStyle">{title}</label>
    </div>
);

export class ConnectionsPage extends Component {
    componentWillMount() {
        this._getDefaultView();
    }

    componentWillReceiveProps(newProps) {
        this._getDefaultView(newProps);
    }

    _getDefaultView(props = this.props) {
        const { match, location, history } = props;
        if (match.params.mode == null) {
            history.replace('/connections/health-providers');
        }
    }

    render() {
        const { history, match } = this.props;
        return (
            <WithSideMenu histroy={history} match={match} menuItems={[ProvidersMenu, ConnectionsMenu]}>
                <Route path="/connections/health-providers" component={HealthProviders} />
                <Route path="/connections/access-provision" component={AccessProvision} />
                <Route path="/connections/lab-connections" component={ConnectMedicalLab} />
                <Route path="/connections/devices_apps/:mode?/:action?" exact component={DevicesPage} />
            </WithSideMenu>
        );
    }
}
