import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Label, Icon } from 'semantic-ui-react';
import { getProperty } from 'dot-prop';

import BaseContainer from './base';

const routes = {
    person_documents: 'documents',
    height_to_waist: 'waists',
    allergies: 'personAllergies',
    medications: 'personDrugs',
};

class TagPanel extends Component {
    // componentWillMount() {
    //     const type = getProperty(this, 'props.match.params.type');
    //     if (type in this.props) {
    //         this.filterTags(Object.values(this.props[type] || {}));
    //     } else {
    //         this.filterTags();
    //     }
    // }

    // static getDerivedStateFromProps(props, state) {
    //     // Any time the current user changes,
    //     // Reset any parts of state that are tied to that user.
    //     // In this simple example, that's just the email.
    //     // if (props.match.params !== state.match.params) {
    //     //     return {
    //     //         prevPropsUserID: props.userID,
    //     //         email: props.defaultEmail,
    //     //     };
    //     // }

    //     const { type: newType } = props.match.params;
    //     const { type: oldType } = this.props.match.params;

    //     if (newType === 'labs') {
    //         var newlabId = props.location.pathname.split('/')[3];
    //         var oldlabId = this.props.location.pathname.split('/')[3];
    //         if (newlabId != oldlabId && oldlabId) {
    //             this.props.setTags([]);
    //         }
    //     } else {
    //         if (newType !== oldType && oldType != undefined) {
    //             this.props.setTags([]);
    //         }
    //     }

    //     // const { type: oldType } = this.props.match.params;
    //     if (newType === 'labs') {
    //         var labId = props.location.pathname.split('/')[3];
    //         var labExamEventData =
    //             props.labExamEvents &&
    //             Object.values(props.labExamEvents).filter((labEvent) => {
    //                 return labEvent.labExam.id == labId;
    //             });

    //         this.filterTags(labExamEventData);
    //     } else {
    //         let metype = this.mapRoutesToProps(newType);
    //         if (metype in this.props) {
    //             this.filterTags(Object.values(this.props[metype] || {}));
    //         } else {
    //             this.filterTags();
    //         }
    //     }

    //     return null;
    // }

    mapRoutesToProps(routeName) {
        return routes[routeName] !== undefined ? routes[routeName] : routeName;
    }

    componentDidUpdate(prevProps) {
        const { params } = this.props.match;
        const { location } = this.props;

        if (params !== undefined) {
            if (params !== prevProps.match.params) {
                const newProps = this.props;
                const { type: newType } = newProps.match.params;
                const { type: oldType } = prevProps.match.params;

                if (newType === 'labs') {
                    const newlabId = newProps.location.pathname.split('/')[3];
                    const oldlabId = location.pathname.split('/')[3];
                    if (newlabId !== oldlabId && oldlabId) this.props.setTags([]);

                    const labExamEventData =
                        newProps.labExamEvents &&
                        Object.values(newProps.labExamEvents).filter((labEvent) => {
                            return labEvent.labExam.id === newlabId;
                        });

                    this.filterTags(labExamEventData);
                } else {
                    if (newType !== oldType && oldType !== undefined) this.props.setTags([]);

                    const metype = this.mapRoutesToProps(newType);
                    if (metype in this.props) {
                        this.filterTags(Object.values(this.props[metype] || {}));
                    } else {
                        this.filterTags();
                    }
                }
            }
        } else {
            const type = getProperty(this, 'props.match.params.type');
            if (type in this.props) {
                this.filterTags(Object.values(this.props[type] || {}));
            } else {
                this.filterTags();
            }
        }
    }

    // componentWillReceiveProps(newProps) {
    //     const { type: newType } = newProps.match.params;
    //     const { type: oldType } = this.props.match.params;

    //     if (newType === 'labs') {
    //         var newlabId = newProps.location.pathname.split('/')[3];
    //         var oldlabId = this.props.location.pathname.split('/')[3];
    //         if (newlabId != oldlabId && oldlabId) {
    //             this.props.setTags([]);
    //         }
    //     } else {
    //         if (newType !== oldType && oldType != undefined) {
    //             this.props.setTags([]);
    //         }
    //     }

    //     // const { type: oldType } = this.props.match.params;
    //     if (newType === 'labs') {
    //         var labId = newProps.location.pathname.split('/')[3];
    //         var labExamEventData =
    //             newProps.labExamEvents &&
    //             Object.values(newProps.labExamEvents).filter((labEvent) => {
    //                 return labEvent.labExam.id == labId;
    //             });

    //         this.filterTags(labExamEventData);
    //     } else {
    //         let metype = this.mapRoutesToProps(newType);
    //         if (metype in this.props) {
    //             this.filterTags(Object.values(this.props[metype] || {}));
    //         } else {
    //             this.filterTags();
    //         }
    //     }
    // }

    filterTags(data) {
        let newData = data;
        if (data === undefined) {
            const {
                heads = {},
                personDrugs = {},
                personSingleUseDrug = {},
                personAllergies = {},
                weights = {},
                waists = {},
                heights = {},
                documents = {},
                oximetries = {},
                pressures = {},
                labExamEvents = {},
            } = this.props;
            newData = [
                Object.values(weights),
                Object.values(heights),
                Object.values(oximetries),
                Object.values(pressures),
                Object.values(labExamEvents),
                Object.values(documents),
                Object.values(waists),
                Object.values(personAllergies),
                Object.values(personDrugs),
                Object.values(personSingleUseDrug),
                Object.values(heads),
            ];
        }
        let used_tags = [];

        newData.forEach((item) => {
            const tags = item.tags;
            tags &&
                tags.forEach((tag) => {
                    const existing = used_tags.find((used_tag) => used_tag.id == tag.id);
                    if (existing) {
                        existing.count++;
                    } else {
                        used_tags.push({ id: tag.id, name: tag.tag, count: 1 });
                    }
                });
        });

        this.setState({ tags: used_tags });
    }

    render() {
        let tags = this.state ? this.state.tags : [];
        let { selected } = this.props;
        return tags.length ? (
            <BaseContainer style={{ marginTop: '20px' }}>
                {tags.map((tag) => {
                    const isSelected = selected.some((item) => item.id === tag.id);
                    return (
                        <Label
                            tag
                            key={tag.name}
                            style={{
                                cursor: 'pointer',
                                marginBottom: '5px',
                                marginRight: '3px',
                                backgroundColor: isSelected ? '#2eaadc' : '#78cef1',
                                borderColor: '#2eaadc',
                                color: 'white',
                            }}
                            onClick={isSelected ? this.onTagRemoved.bind(this, tag) : this.onTagAdded.bind(this, tag)}
                        >
                            {tag.name}
                            <span style={{ color: 'yellow', fontWeight: 'bold', marginLeft: '20px' }}>{tag.count}</span>
                            {isSelected && (
                                <Icon
                                    name="close"
                                    onClick={this.onTagRemoved.bind(this, tag)}
                                    style={{ position: 'absolute', right: '3px' }}
                                />
                            )}
                            {/* <Label color="teal" circular style={{ marginLeft: "10px" }}>
                            {tag.count}
                        </Label> */}
                        </Label>
                    );
                })}
            </BaseContainer>
        ) : null;
    }

    onTagAdded(tag, e) {
        const { selected } = this.props;
        const isSelected = selected.some((item) => item.id === tag.id);
        !isSelected && this.props.setTags([...selected, tag]);
    }

    onTagRemoved(tag, e) {
        e.stopPropagation();
        let { selected } = this.props;
        this.props.setTags(selected.filter((item) => item.id !== tag.id));
    }
}

const mapStateToProps = ({ dataStore, appState, filters }) => ({
    weights: getProperty(dataStore, 'graph.weights'),
    heights: getProperty(dataStore, 'graph.heights'),
    pressures: getProperty(dataStore, 'graph.pressures'),
    oximetries: getProperty(dataStore, 'graph.oximetries'),
    labExamEvents: getProperty(dataStore, 'graph.lab-exam-events'),
    documents: getProperty(dataStore, 'graph.documents'),
    waists: getProperty(dataStore, 'graph.waists'),
    heads: getProperty(dataStore, 'graph.head-circumferences'),
    personAllergies: getProperty(dataStore, 'graph.person-allergies'),
    personDrugs: getProperty(dataStore, 'graph.person-drugs'),
    personSingleUseDrug: getProperty(dataStore, 'graph.person-drug-usages'),
    selected: getProperty(filters, 'tags') || [],
});

const mapDispatchToProps = (dispatch) => ({
    setTags: (tags) => dispatch({ type: 'SET_TAG_FILTERS', payload: tags }),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagPanel));
// export default withRouter(TagPanel);
