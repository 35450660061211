import React, { Component } from 'react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu, Tab } from 'semantic-ui-react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { MenuPanel } from 'Mib/components/containers';
import BaseContainer from 'Mib/components/containers/base';

export default class ChildFolderMenu extends Component {
    constructor(props) {
        super();
        this.state = {
            visible: 0,
        };
        this.menuItems = [];

        this.generateMenuItems(props);
    }

    generateMenuItems(props) {
        const { match } = props;

        this.menuItems = [
            {
                path: '/weights',
                key: 1,
                label: (
                    <span>
                        <Icon className="icon icon-scale" />
                        {T.translate('measurements.singular.weight')}
                    </span>
                ),
                as: NavLink,
                to: match.url + '/weights',
            },
            {
                path: '/heights',
                key: 2,
                label: (
                    <span>
                        <Icon className="icon-height" />
                        {T.translate('measurements.singular.height')}
                    </span>
                ),

                as: NavLink,
                to: match.url + '/heights',
            },

            {
                path: '/heads',
                key: 7,
                label: (
                    <span>
                        <Icon className="icon-waist" />
                        {T.translate('measurements.singular.head')}
                    </span>
                ),
                // target: match.url + "/heads",
                // icon: "icon-waist",
                as: NavLink,
                to: match.url + '/heads',
            },
        ];
    }
    componentWillReceiveProps(newProps) {
        this.generateMenuItems(newProps);
    }

    render() {
        const { match, labsClicked, loading } = this.props;

        const { menuItems } = this;
        const menuTitle = (
            <Menu.Item onClick={() => {}} className="menu-title">
                <Icon name="file text" className="left" />
                <span className="title">{T.translate('routes.child')}</span>
            </Menu.Item>
        );
        // setTimeout(() => this.setState({ visible: !this.state.visible }), 100);
        return <MenuPanel className="purple  mib-menu" inverted title={menuTitle} items={this.menuItems} />;
    }
}
