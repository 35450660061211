/* eslint-disable import/prefer-default-export */
import { store } from 'Mib/datastore/store';
import { Socket } from 'Mib/vendor/phoenix';
import { websocketUrl as WEBSOCKET_URL } from 'Mib/config';

// Internal Phoenix events
const CHANNEL_EVENTS = ['phx_close', 'phx_error', 'phx_join', 'phx_reply', 'phx_leave', 'join', /chan_reply_\d*/];

export class PhoenixSocketClient {
    init(person_guid, user_id, token, lang) {
        this.channels = {};

        // Connect to the sockets
        // ;debugger
        this.personSock = this._makeConection(
            'person-socket',
            token,
            {
                person_guid,
            },
            lang
        );
        // this.userSock = this._makeConection("socket", token, { user_id }, lang);
        this.personSock.connect();
        // this.userSock.connect();

        // Create the channels
        const personChannel = this.personSock.channel(`person:${person_guid}`, {});
        personChannel.join().receive('ok', console.log).receive('error', console.log);

        // let userChannel = this.userSock.channel(`user:${user_id}`, {});
        // userChannel
        //     .join()
        //     .receive("ok", console.log)
        //     .receive("error", console.log);

        this.channels.person = personChannel;
        // this.channels["user"] = userChannel;

        // Register handlers
        // userChannel.onMessage = this.messageHandler.bind(this, "user");
        personChannel.onMessage = this.messageHandler.bind(this, 'person');
    }

    messageHandler(channel, event, payload, ref) {
        if (this._filterMessage(channel, event, payload, ref)) {
            // console.info('Websocket message - Channel:', channel, 'Topic:', event, 'Payload:', payload )
            console.log('Websocket message - Channel:', channel, 'Topic:', event, 'Payload:', payload);
            store.dispatch({
                type: 'ADD_SOCKET_MESSAGE',
                message: { channel, payload, topic: event },
            });
        }
        return payload;
    }

    // eslint-disable-next-line no-underscore-dangle
    _filterMessage(channel, event, payload, ref) {
        if (CHANNEL_EVENTS.some((regex) => event.search(regex) === 0)) {
            return false;
        }
        return true;
    }

    logger(...a) {
        // console.log("PhoenixSocketClient: ", ...a);
    }

    // eslint-disable-next-line no-underscore-dangle
    _makeConection(endpoint, token, extra_args, lang) {
        const socket = new Socket(`${WEBSOCKET_URL}/${endpoint}`, {
            params: {
                method: '2',
                token,
                lang,
                ...extra_args,
            },
            transport: WebSocket,
            logger: this.logger.bind(this),
        });
        window.phcli = Socket;
        return socket;
    }
}
