import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import React, { Component } from "react";
import GeneralMetricContainer from "Mib/components/metric_panels/general_metric";
import { openMeasurementModal } from "Mib/actions";
import { subscribe } from "../../lib/subscribe";
import { withRouter } from "react-router-dom";

import T from "i18n-react";
import { Create, Update } from "../../actions";
import { fetchAction } from "../../actions/backend";
import moment from "moment";

import MeasurementFormModal from "../modals/measurement_form_modal";
import { FilteredMetricComponent } from "./filtered_metric";

class _WaistContainer extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {}, unitId: null };

        this.open = this.open.bind(this);
        this.dataPropName = "waists";
        this.config = {
            descriptor: "result",
            name: T.translate("measurements.singular.waist"),
            dateDescriptor: "date",
            valueOffset: 1,
            onGraphPointClicked: this.open,
        };
    }

    render() {
        let { waists, measures, children, ...rest } = this.props;
        let { chart_config, waists: filteredWaists } = this.state;

        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate("measurements.singular.waist")}
                dataType="waists"
                measureUnits={measures}
                chart_config={chart_config}
                data={filteredWaists}
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
            />
        );
    }

    open(id = undefined, type = undefined) {
        const { unitId } = this.state;
        openMeasurementModal({id, type, unitId})
    }
}

const mapStateToProps = state => {
    var allmeasureUnits = state.dataStore.graph["measure-units"] && state.dataStore.graph["measure-units"];
    var waistMeasureUnits = {};
    allmeasureUnits &&
        Object.entries(allmeasureUnits).forEach(([key, unit]) => {
            if (unit.measureUnitType && unit.measureUnitType.name == "HEIGHT") {
                waistMeasureUnits[unit.id] = unit;
            }
        });

    return {
        waists: state.dataStore.graph.waists,
        measures: waistMeasureUnits,
        filters: state.filters,
    };
};

const _WaistContainerSub = subscribe(_WaistContainer, ["waists", "measure-units?with=measureUnitType"]);

export default withRouter(connect(mapStateToProps)(_WaistContainerSub));
