import React, { Component } from "react";
import { connect } from "react-redux";
import { Transition, Message, Icon } from "semantic-ui-react";
import { removeMessage } from "../../actions/notifications";

require("./notifier.scss");

class Notifier extends Component {
    render() {
        const { messages = [] } = this.props;
        return (
            <section className="notification-container">
                <Transition.Group animation="drop">
                    {messages.map(msg => (
                        <Message {...msg} icon={this.getIconName(msg)} key={msg._id} onClick={this.onMessageClicked.bind(this, msg)} onDismiss={_=>{}}>
                        </Message>
                    ))}
                </Transition.Group>
            </section>
        );
    }

    getIconName(msg){
        if (msg.warning) {
            return 'warning sign'
        } else if (msg.error) {
            return 'warning circle'
        } else if (msg.info) {
            return 'info circle'
        } else if (msg.success) {
            return 'check circle outline'
        } else {
            return ''
        }
    }

    onMessageClicked(msg, e) {
        removeMessage(msg);
    }
}

const mapStateToProps = ({ appState: { messages } }) => ({
    messages: messages,
});

export default connect(mapStateToProps)(Notifier);
