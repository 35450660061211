import React from 'react';
import T from 'i18n-react';
import { Link } from 'react-router-dom';

import BaseContainer from 'Mib/components/containers/base';

require('./measurement_info.scss');

// eslint-disable-next-line import/prefer-default-export
export function MeasurementInfoPanel({
    title,
    footer,
    iconName,
    date,
    time,
    subTitle,
    value,
    editAction,
    onClick,
    altText,
    loading,
    color,
    link,
    ...rest
}) {
    // loading = loading === undefined ? value === undefined && !altText : loading;
    return (
        <BaseContainer className="measure-info-container" {...rest} loading={loading} onClick={onClick}>
            <Link to={link || '/'}>
                {' '}
                <h3 className="measure-info-title">{title}</h3>
            </Link>
            <div className="measure-info-inner">
                {value !== undefined ? (
                    <>
                        <div className="measure-info-type dash">{T.translate('meta.last-measurement')}:</div>
                        <div className="measure-info-value dash">{value}</div>
                        <div className="measure-info-subtitle dash">
                            <div>{date}</div>
                            <div>{time}</div>
                        </div>
                    </>
                ) : (
                    // null
                    <div style={{ fontSize: '1.5em' }} className="measure-info-value">
                        {altText}
                    </div>
                )}
            </div>
        </BaseContainer>
    );
}
