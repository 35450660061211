/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import T from 'i18n-react';

import { PersonDrug, Drug } from 'Mib/lib/models';
import { FormModal } from 'Mib/components/modals';
import { TextInput, MibButton } from 'Mib/components/inputs';
import ModelForm from 'Mib/components/forms/model_form';
import { Create, Update, Delete } from 'Mib/actions';
import { CalendarInput, RadioButtonGroup, TextAreaInput, RemoteInput, TagRemoteInput } from 'Mib/components/inputs';

const DrugRemoteInput = RemoteInput({ modelName: 'drugs', endPoint: 'drugs', Model: Drug });

export class MedicineForm extends Component {
    constructor() {
        super();
        this.state = { model: {}, loading: false };
        this.radioItems = [
            { key: 0, label: T.translate('medications.sources.fromDoctor'), value: '0' },
            { key: 1, label: T.translate('medications.sources.fromPharmacist'), value: '1' },
            { key: 2, label: T.translate('medications.sources.fromPersonalKnowledge'), value: '2' },
            { key: 3, label: T.translate('medications.sources.fromFriend'), value: '3' },
            { key: 4, label: T.translate('medications.sources.other'), value: '4' },
        ];
        // TODO
        // this.frequencyItems = [
        //     { key: 1, text: T.translate('medications.frequencies.hours'), value: '0' },
        //     { key: 3, text: T.translate('medications.frequencies.days'), value: '1' },
        //     { key: 5, text: T.translate('medications.frequencies.weeks'), value: '2' },
        //     { key: 7, text: T.translate('medications.frequencies.months'), value: '3' },
        //     { key: 9, text: T.translate('medications.frequencies.years'), value: '4' },
        // ];
    }

    onModelChange(model) {
        // if (parseInt(model.frequency) < 2) {
        //     this.frequencyItems = [
        //         { key: 0, text: T.translate('medications.frequencies.hour'), value: '0' },
        //         { key: 2, text: T.translate('medications.frequencies.day'), value: '1' },
        //         { key: 4, text: T.translate('medications.frequencies.week'), value: '2' },
        //         { key: 6, text: T.translate('medications.frequencies.month'), value: '3' },
        //         { key: 8, text: T.translate('medications.frequencies.year'), value: '4' },
        //     ];
        // } else {
        //     this.frequencyItems = [
        //         { key: 1, text: T.translate('medications.frequencies.hours'), value: '0' },
        //         { key: 3, text: T.translate('medications.frequencies.days'), value: '1' },
        //         { key: 5, text: T.translate('medications.frequencies.weeks'), value: '2' },
        //         { key: 7, text: T.translate('medications.frequencies.months'), value: '3' },
        //         { key: 9, text: T.translate('medications.frequencies.years'), value: '4' },
        //     ];
        // }
        this.setState({ model: model });
    }

    open(medication) {
        const model = PersonDrug();
        if (medication) Object.assign(model, medication);

        this.setState({ model: model, action: medication === undefined ? 'create' : 'edit' });
        this.modal.open();
    }

    close() {
        this.setState({ model: {}, errors: [], action: null, loading: false });
        this.modal.close();
    }

    save() {
        // If we are already saving, don't do it again
        if (this.saving) return null;
        this.saving = true;
        this.setState({ loading: true });
        if (this.state.action === 'edit') {
            Update(this.state.model)
                .then((res) => {
                    this.saving = false;
                    this.setState({ loading: false });
                    this.close();
                })
                .catch((e) => {
                    this.saving = false;
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        } else {
            console.log('this.state.model', this.state.model);
            Create(this.state.model)
                .then((res) => {
                    this.saving = false;
                    this.setState({ loading: false });
                    this.close();
                })
                .catch((e) => {
                    this.saving = false;
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        }

        return null;
    }

    delete() {
        Delete(this.state.model).then((res) => {
            this.setState({ loading: false });
            this.close();
        });
    }

    render() {
        const { action, loading, model, errors } = this.state;
        const formItems = [
            { Element: DrugRemoteInput, fieldName: 'drug', label: 'medications.drug', attrib: 'id' },
            { Element: CalendarInput, fieldName: 'start', label: 'medications.startDate' },
            { Element: CalendarInput, fieldName: 'end', label: 'medications.endDate' },
            { Element: TagRemoteInput, label: 'measurements.tags', fieldName: 'tags', attrib: 'tag', multiple: true },
            { Element: RadioButtonGroup, fieldName: 'source', options: this.radioItems, label: 'allergies.source' },
            { Element: TextAreaInput, label: 'measurements.comments', fieldName: 'comments' },
            // { Element: TextInput, label: 'medications.dosage', fieldName: 'dosage' },
            // { Element: TextInput, label: 'medications.frequency', fieldName: 'frequency' },
            // TODO
            // {
            //     Element: DropDownInput,
            //     fieldName: 'frequency_type',
            //     options: this.frequencyItems,
            //     label: 'allergies.sensitivity',
            // },

            // {Element: TextAreaInput, fieldName: "allergy", label: "comments"}
        ];
        return (
            <FormModal ref={(i) => (this.modal = i)} title={T.translate('medications.create')} loading={loading}>
                <ModelForm
                    style={{ padding: '10%' }}
                    model={model}
                    validator={PersonDrug.Validator}
                    onChange={this.onModelChange.bind(this)}
                    // onChange={(model) => this.onModelChange.bind(this, model)}
                    // onChange={(model) => this.setState({ model: model })}
                    items={formItems}
                    errors={errors}
                    onValidate={(errors) => this.setState({ errors })}
                />
                <div className="mib-modal-footer controls">
                    {action === 'edit' && (
                        <MibButton
                            label={T.translate('buttons.delete')}
                            type="danger"
                            onClick={this.delete.bind(this)}
                        />
                    )}
                    <MibButton label={T.translate('buttons.close')} type="warning" onClick={this.close.bind(this)} />
                    <MibButton label={T.translate('buttons.save')} type="success" onClick={this.save.bind(this)} />
                </div>
            </FormModal>
        );
    }
}
