// import 'babel-polyfill';
import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import T from 'i18n-react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/el';
import 'moment/locale/en-ca';
import { getProperty } from 'dot-prop';

import { deepMerge } from 'Mib/lib/utils';
import Content from 'Mib/content';
import history from 'Mib/lib/history';
import { LoginPage, PasswordResetPage, PasswordResetForm } from 'Mib/routes';
import { syncAllAction } from 'Mib/actions';
import Notifier from 'Mib/components/generic/notifier';
import { GlobalMFModal, GlobalModal, GlobalDocModal, GlobalLabExamDocModal } from 'Mib/components/modals/global_modal';
import SurveyContainer from 'Mib/components/medi/surveys/survey_container';

// import { store } from 'Mib/datastore/newStore';
import { store } from 'Mib/datastore/store';

const TopNav = React.lazy(() => import('Mib/components/generic/top_nav'));
const SideBar = React.lazy(() => import('Mib/components/generic/side_bar'));
const PromotedAdminPage = React.lazy(() => import('Mib/routes/promoter_admin'));
const RegistrationPage = React.lazy(() => import('Mib/routes/registration/index'));

// Initialize translations
const lang = localStorage.getItem('lang') || 'el';

const mibTransData = require(`../public/locales/${lang}.json`);
let mibDoctorsTransData = {};
try {
    mibDoctorsTransData = require(`../public/locales/${lang}.json`);
} catch (e) {
    mibDoctorsTransData = {};
}

// let mibDoctorsTransData = {}hohfdfohdfgferrhodoho;

deepMerge(mibTransData, mibDoctorsTransData);
T.setTexts(mibTransData, {
    notFound: (key) => {
        console.warn(`Translation for ${key} not found!`);
        return key;
    },
});

require('./main.scss');

require('/public/fontello-embedded.css');
require('/public/assets/icons/mib_mono/style.css');
require('/public/assets/icons/mib_lab/style.css');
require('/public/assets/icons/mib_color/style.css');

moment.locale(lang);

window.__COMMITHASH = __COMMITHASH;
console.info(`Welcome to MedinfoBook. Current version: ${__GITVERSION}, revision hash: ${__COMMITHASH}`)

class MainApp extends Component {
    constructor() {
        super();
        this.state = this.getCurrentStateFromStore();
    }

    static getDerivedStateFromProps() {
        // Store prevId in state so we can compare when props change.
        // Clear out previously-loaded data (so we don't render stale stuff).
        const {
            location: { pathname },
        } = history;
        if (!pathname.match(/(login|registration|password-reset)/)) {
            console.log('syncing');
            syncAllAction();
        }
        // No state update necessary
        return null;
    }

    // console.log();

    componentDidMount() {
        this.unsubscribeStore = store.subscribe(this.updateStateFromStore.bind(this));
    }

    componentWillUnmount() {
        this.unsubscribeStore();
    }

    getCurrentStateFromStore() {
        return {
            is_promoter: getProperty(store.getState(), 'authentication.provider_type.type') === 'Promoter',
        };
    }

    updateStateFromStore() {
        const currentState = this.getCurrentStateFromStore();
        if (this.state.is_promoter !== currentState.is_promoter) {
            this.setState(currentState);
        }
    }

    // componentWillMount() {
    //     const {
    //         location: { pathname },
    //     } = history;
    //     if (!pathname.match(/(login|registration|password-reset)/)) {
    //         console.log('syncing');
    //         syncAllAction();
    //     }
    // }

    render() {
        const { is_promoter } = this.state;
        return (
            <Provider store={store}>
                <Suspense fallback="loading...">
                    <Router history={history}>
                        {is_promoter ? (
                            <Route render={() => <PromotedAdminPage />} />
                        ) : (
                            <Switch>
                                <Route path="/login" component={LoginPage} />
                                <Route path="/registration" component={RegistrationPage} />
                                <Route path="/password-reset" exact component={PasswordResetPage} />
                                <Route path="/password-reset/:token" component={PasswordResetForm} />
                                <Route
                                    render={() => (
                                        <>
                                            <TopNav toggleSideBar={() => this.sideBar.toggle()} />
                                            <div className="app-container">
                                                <SideBar ref={(i) => (this.sideBar = i)} />
                                                <Content />
                                            </div>
                                        </>
                                    )}
                                />
                            </Switch>
                        )}
                    </Router>
                    <Notifier />
                    <GlobalMFModal signalType="MEASUREMENT_MODAL" />
                    <GlobalModal signalType="TEXT_MODAL" />

                    <GlobalLabExamDocModal signalType="IN_VIVO_MODAL" />
                    <GlobalDocModal signalType="DOCUMENT_MODAL" />

                    <SurveyContainer signalType="CHECKUP-SURVEY" />
                </Suspense>
            </Provider>
        );
    }
}
// const mapStateToProps = ({ authentication }) => ({
//     authData: authentication,
// });

ReactDOM.render(<MainApp />, document.getElementById('root'));
// ReactDOM.render(<MainApp />, document.getElementById('app'));
