import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { Fetch } from 'Mib/lib/fetch';
import MediaQuery from 'react-responsive';
import { CalendarInput } from 'Mib/components/inputs';
import T from 'i18n-react';
import BaseContainer from 'Mib/components/containers/base';
import { logoutAction } from 'Mib/actions';
import { Print } from './codePrint';
import { CodeTable } from './codeTable';

require('./promoterPageCss.scss');

function MibIcon({ history }) {
    return (
        <div>
            <MediaQuery minWidth={992}>
                <img
                    style={{ width: '250px', height: '60px' }}
                    src="/assets/img/logo_mib_BIG.png"
                    alt="logo medinfobook"
                    className="logo-image"
                />
            </MediaQuery>
            <MediaQuery maxWidth={991}>
                <img src="/assets/img/logo_mib_mob.png" alt="logo medinfobook" className="logo-image-small" />
            </MediaQuery>
        </div>
    );
}

export class PromotedAdminPage extends Component {
    constructor() {
        super();
        this.state = {
            promoCode: null,
            expireDate: moment().add(366, 'days'),
        };
    }

    async generateCode() {
        console.log('IN GET CODE');
        let tokenAccess = await Fetch(
            'promoter-generate-bindcode',
            {
                method: 'POST',
                body: JSON.stringify({ data: { attributes: { expire_date: moment(this.state.expireDate) } } }),
            },
            'auth'
        ).then((results) => {
            return results['bind-code'];
        });
        this.setState({ promoCode: tokenAccess });

        console.log(tokenAccess);
    }

    printAction() {
        Print('codeDiv');
        //printIframe('codeTable')
    }

    dateChange(name, e, v) {
        this.setState({ expireDate: moment(v.value), promoCode: null });
    }

    render() {
        let now = moment().format('LLL');
        let expireAt = this.state.expireDate;

        return (
            <div className="promoter-container">
                <header style={{ padding: '1%' }}>
                    <div className="promoter-top-nav">
                        <MibIcon />
                        <Icon name="sign out" size="huge" className="logOutButton" onClick={logoutAction} />
                    </div>
                </header>

                <div className="bodyContainer">
                    <BaseContainer className="baseContainer">
                        {this.state.promoCode && (
                            <div id="codeBox" className="codeDiv">
                                <Icon
                                    name="print"
                                    size="huge"
                                    className="printButton"
                                    onClick={this.printAction.bind(this)}
                                />
                                <CodeTable today={now} code={this.state.promoCode} expireDay={expireAt} />
                            </div>
                        )}
                    </BaseContainer>

                    <div className="datePickerContainer">
                        <h2 className="h2Text">{T.translate('promoter.contractEndDate')}:</h2>
                        <CalendarInput
                            value={this.state.expireDate || ''}
                            onChange={(e, v) => this.dateChange('expireDate', e, v)}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>

                    <div className="buttonContainer">
                        <div className="codeBtn" onClick={this.generateCode.bind(this)}>
                            {T.translate('promoter.codeGenerateButton')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PromotedAdminPage;
