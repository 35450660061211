import React from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import { useSelector } from 'react-redux';

import { Message, Table } from 'semantic-ui-react';
import { ContentTitle } from '../../components/generic/content_title';
import { ExamRow } from './ExamRow';

import('./examsByAgeCss.scss');

export default function ExamsByAgeTable({ clearFilters }) {
    const ageExams = useSelector((state) => getProperty(state.live_data, 'lab-suggestions'));

    return (
        <div>
            <ContentTitle
                icon="mib-mono-icon icon-excersise"
                title={T.translate('routes.health-indicators.examsByAge')}
            />
            {ageExams ? (
                <Table className="tableCss" celled compact>
                    <Table.Header>
                        <Table.Row className="tableRow">
                            <Table.HeaderCell singleLine>{T.translate('routes.examsByAge.examType')}</Table.HeaderCell>
                            <Table.HeaderCell>{T.translate('routes.examsByAge.examFrequency')}</Table.HeaderCell>
                            <Table.HeaderCell>{T.translate('routes.examsByAge.lastEntry')}</Table.HeaderCell>
                            <Table.HeaderCell>{T.translate('routes.examsByAge.nextEntry')}</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {ageExams
                            .sort((a, b) => a.mib_id - b.mib_id)
                            .map((exam) => (
                                <ExamRow exam={exam} />
                            ))}
                    </Table.Body>
                </Table>
            ) : (
                <Message
                    icon="info circle"
                    header={T.translate('routes.examsByAge.no-measurements')}
                    onClick={clearFilters}
                    // content={T.translate("measurements.clear-filters-prompt")}
                    info
                    color="violet"
                    style={{ marginBottom: '180px', marginTop: '30px', cursor: 'pointer' }}
                />
            )}
        </div>
    );
}
