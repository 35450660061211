import React, { Component } from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';

import { Activity } from 'Mib/lib/models';
import { Create, Update, Delete } from 'Mib/actions';
import { FormModal } from 'Mib/components/modals';
import ModelForm from 'Mib/components/forms/model_form';
import { TextInput, DropDownInput, MibButton, CalendarInput } from 'Mib/components/inputs';

//TODO activity types from redux
const activityTypeUrls = {
    0: 'other',
    1: 'walking',
    2: 'running',
    3: 'biking',
    4: 'ski',
    // "5": "withings",
};

export class ActivityForm extends Component {
    constructor() {
        super();

        let validatorDeepCopy = JSON.parse(JSON.stringify(Activity.Validator));
        this.state = { model: {}, loading: false, validator: validatorDeepCopy };
    }

    getFormItems() {
        const large = { width: '60%', display: 'inline-block', marginRight: '5%', verticalAlign: 'top' };
        const small = { width: '20%', display: 'inline-block' };
        const { measureUnits } = this.props;
        const distanceMU = Object.values(measureUnits)
            .filter((mu) => getProperty(mu, 'measureUnitType.id') == '7')
            .map((mu) => ({ key: mu.id, text: mu.abbreviation, value: mu.id }));
        const velocityMU = Object.values(measureUnits)
            .filter((mu) => getProperty(mu, 'measureUnitType.id') == '8')
            .map((mu) => ({ key: mu.id, text: mu.abbreviation, value: mu.id }));
        const caloriesMU = Object.values(measureUnits)
            .filter((mu) => getProperty(mu, 'measureUnitType.id') == '9')
            .map((mu) => ({ key: mu.id, text: mu.abbreviation, value: mu.id }));
        const formItems = [
            {
                Element: DropDownInput,
                fieldName: 'activityType',
                label: 'activities.activity-type',
                options: Object.entries(activityTypeUrls).map((item) => ({
                    key: item[0],
                    value: item[0],
                    text: `${T.translate('activities.' + item[1])}`,
                })),
                attrib: 'id',
            },
            { Element: CalendarInput, fieldName: 'start', label: 'activities.start_date', time: true },
            { Element: CalendarInput, fieldName: 'end', label: 'activities.end_date', time: true },
            { Element: TextInput, fieldName: 'distance', label: 'activities.distance', fieldStyle: large },
            {
                Element: DropDownInput,
                fieldName: 'distanceUnit',
                label: 'activities.measure-unit',
                options: distanceMU,
                attrib: 'id',
                fieldStyle: small,
            },
            { Element: TextInput, fieldName: 'velocity', label: 'activities.velocity', fieldStyle: large },
            {
                Element: DropDownInput,
                fieldName: 'velocityUnit',
                label: 'activities.measure-unit',
                options: velocityMU,
                attrib: 'id',
                fieldStyle: small,
            },
            { Element: TextInput, fieldName: 'steps', label: 'activities.steps' },
            { Element: TextInput, fieldName: 'calories', label: 'activities.calories', fieldStyle: large },
            {
                Element: DropDownInput,
                fieldName: 'calorieUnit',
                label: 'activities.measure-unit',
                options: caloriesMU,
                attrib: 'id',
                fieldStyle: small,
            },
        ];
        return formItems;
    }
    render() {
        return (
            <FormModal
                ref={(i) => (this.modal = i)}
                title={T.translate('activities.create')}
                loading={this.state.loading}
            >
                <ModelForm
                    style={{ paddingRight: '10%', paddingLeft: '10%', paddingTop: '30px' }}
                    model={this.state.model}
                    validator={this.state.validator}
                    onChange={this.onFormChanged.bind(this)}
                    items={this.getFormItems()}
                    onValidate={(errors) => this.setState({ errors })}
                    errors={this.state.errors}
                />
                <div className="mib-modal-footer controls">
                    {this.state.action === 'edit' ? (
                        <MibButton label={T.translate('delete')} type="danger" onClick={this.delete.bind(this)} />
                    ) : null}
                    <MibButton label={T.translate('buttons.close')} type="warning" onClick={this.close.bind(this)} />
                    <MibButton label={T.translate('buttons.save')} type="success" onClick={this.save.bind(this)} />
                </div>
            </FormModal>
        );
    }
    onFormChanged(model) {
        const { validator } = this.state;
        const attributes = [
            ['distance', 'distanceUnit'],
            ['calories', 'calorieUnit'],
            ['velocity', 'velocityUnit'],
        ];
        attributes.forEach(([attr, measure]) => {
            if (model[attr]) {
                if (!(measure in validator.properties)) {
                    validator.properties[measure] = {
                        required: true,
                        conform: (value) => value && value.id != null,
                        messages: {
                            conform: T.translate('errors.required'),
                        },
                    };
                }
            } else {
                if (model[measure] != null && model[attr] == null) {
                    if (!(attr in validator.properties)) {
                        validator.properties[attr] = {
                            required: true,
                            // conform: value => value && value.id != null,
                            messages: {
                                conform: T.translate('errors.required'),
                            },
                        };
                    }
                }
                // delete validator.properties[measure];
                // model[measure] = null;
            }
        });
        this.setState({ model: model, validator: validator });
    }
    open(activity, activityType) {
        const model = Activity({});
        activity && Object.assign(model, activity);
        if (activityType) model.activityType = activityType;
        this.setState({
            model: model,
            validator: JSON.parse(JSON.stringify(Activity.Validator)),
            action: activity === undefined ? 'create' : 'edit',
        });
        this.modal.open();
    }
    close() {
        this.setState({
            model: {},
            validator: JSON.parse(JSON.stringify(Activity.Validator)),
            errors: [],
            action: null,
            loading: false,
        });
        this.modal.close();
    }
    save() {
        const { errors } = this.state;
        if (Array.isArray(errors) && errors.length) {
            return;
        }
        this.setState({ loading: true });
        if (this.state.action === 'edit') {
            Update(this.state.model).then((res) => {
                this.setState({ loading: false });
                this.close();
            });
        } else {
            Create(this.state.model).then((res) => {
                this.setState({ loading: false });
                this.close();
            });
        }
    }
    delete() {
        Delete(this.state.model).then((res) => {
            this.setState({ loading: false });
            this.close();
        });
    }
}
