import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getProperty } from 'dot-prop';
import { generateChartData, generateConfigSeries } from '../../lib/charts';
import { deepMerge } from '../../lib/utils';

export class FilteredMetricComponent extends Component {
    constructor() {
        super();
        this.oldData = {};
    }
    componentWillMount() {
        const { [this.dataPropName]: data = {} } = this.props;

        // Get the measure unit from the data
        let unitId = this._getMeasureUnitId(data);
        unitId && this.setState({ unitId });

        const filteredData = this.filterData(data);
        this.setState({ [this.dataPropName]: filteredData });

        // Generate the chart configuration
        const chart_config = this._generateChartData(filteredData);
        chart_config.forceRefresh = true;
        this.setState({ chart_config: chart_config });

        if (this.onDataChanged && this.onDataChanged.length) this.onDataChanged.forEach((fn) => fn(data));

        return data;
    }

    componentDidMount() {
        this._checkCreateRoute();
    }
    componentWillReceiveProps(newProps) {
        const { filters, [this.dataPropName]: data } = newProps;
        const { filters: oldFilters, [this.dataPropName]: oldData } = this.props;

        // Check for updates
        let filtersChanged = this.filtersHaveChanged(oldFilters, filters);
        let dataChanged = this.dataHasChanged(oldData, data);

        if (filtersChanged || dataChanged || this.forceRefresh) {
            // Get the measure unit from the data
            let unitId = this._getMeasureUnitId(data);
            unitId && this.setState({ unitId });

            // Apply filters
            const filteredData = this.filterData(data, newProps);
            this.setState({ [this.dataPropName]: filteredData });
            // Generate the chart configuration
            const chart_config = this._generateChartData(filteredData, newProps);
            chart_config.forceRefresh = true;
            this.setState({ chart_config: chart_config });

            // Execute custom callbacks
            if (this.onDataChanged && this.onDataChanged.length) this.onDataChanged.forEach((fn) => fn(filteredData));
            this.forceRefresh = false;
            return filteredData;
        } else {
            return this.state[this.dataPropName];
        }
        this._checkCreateRoute(newProps);
        // Get the data from the inheriting component by calling
        // const data = super.componentWillReceiveProps(newProps);
    }

    _checkCreateRoute(props = this.props) {
        const action = getProperty(props, 'match.params.action');
        if (action === 'create') {
            setTimeout(() => this.open(undefined, this.dataPropName), 10);
        }
    }

    configUpdateUnitFunc(unitId) {
        this.setState({ unitId: unitId });
    }

    _getMeasureUnitId(data = {}) {
        let vals = Object.values(data);
        return vals.length && getProperty(vals, '0.measureUnit.id');
    }

    _generateChartData(data = {}, props = this.props) {
        const { merge_config = {}, ...chart_settings } = this.config;

        const chart_config = generateChartData(data, chart_settings, this.state.unitId);
        typeof merge_config === 'function'
            ? deepMerge(chart_config, merge_config(props, chart_config))
            : deepMerge(chart_config, merge_config);
        return chart_config;
    }

    filtersHaveChanged(oldFilters = {}, newFilters) {
        const { dateFrom, dateTo, tags = [] } = newFilters;
        const { dateFrom: oldDateFrom, dateTo: oldDateTo, tags: oldTags = [] } = oldFilters;
        return dateFrom != oldDateFrom || dateTo != oldDateTo || tags.length != oldTags.length;
    }

    dataHasChanged(
        oldData = {},
        newData = {},
        valueAttr = 'result',
        dateAttr = 'date',
        extraAtr = ['comments', 'tags']
    ) {
        // let res = this.oldData != newData
        // this.oldData = newData
        // return res
        // return !Object.keys(newData).every(id => id in oldData && oldData[id][valueAttr] === newData[id][valueAttr]);
        // console.log('solddata',this.oldData)
        const allAttr = [valueAttr, dateAttr, ...extraAtr];

        let log = false; //Object.keys(newData).length && (Object.values(newData)[0]._type !== 'pressures')
        let res = !Object.keys(newData).every(
            (id) =>
                this.oldData &&
                id in this.oldData &&
                allAttr.every((itemValue) => {
                    if (typeof this.oldData[id][itemValue] === 'object' && this.oldData[id][itemValue]) {
                        let hasEqualObjValues = Object.keys(newData[id][itemValue]).every((itmKey) => {
                            return (
                                newData[id][itemValue][itmKey].id ===
                                getProperty(this, `oldData.${id}.${itemValue}${itmKey}.id`)
                            );
                        });

                        let hasEqualLengths =
                            Object.keys(newData[id][itemValue]).length ==
                            Object.keys(this.oldData[id][itemValue]).length;

                        if (log) {
                            console.log('hasEqualObjValues: ', hasEqualObjValues);
                            console.log('hasEqualLengths: ', hasEqualLengths);
                        }

                        return hasEqualObjValues && hasEqualLengths;
                    } else {
                        let hasEqualValues = this.oldData[id][itemValue] === newData[id][itemValue];
                        if (log) {
                            console.log('hasEqualValues: ', hasEqualValues);
                        }
                        return hasEqualValues;
                    }
                })
        );

        res |= Object.keys(newData).length != Object.keys(this.oldData).length;
        this.oldData = {};
        Object.keys(newData).forEach((id) =>
            allAttr.forEach((val) => {
                this.oldData[id] = { ...this.oldData[id], [val]: newData[id][val] };
            })
        );

        return res;
    }

    filterData(data = {}, props = this.props, valueAttr = 'result', dateAttr = 'date') {
        const {
            filters: { dateFrom, dateTo, tags },
        } = props;
        let filtered = {};
        Object.values(data)
            .filter((o) => {
                let res =
                    (dateFrom == null || moment(o[dateAttr]) >= dateFrom) &&
                    (dateTo == null || moment(o[dateAttr]) <= dateTo);
                if (tags && tags.length) {
                    res &= o.tags.some((tag) => tags.some((t) => t.id === tag.id));
                }
                return res;
            })
            .forEach((o) => (filtered[o.id] = o));
        return filtered;
    }
}

export class SimpleMetricComponent extends FilteredMetricComponent {
    filtersHaveChanged() {
        return false;
    }
    filterData(data = {}) {
        return data;
    }
}
