export const calculateStorageSize = (documentsSize, capacity) => {
    if (documentsSize && capacity) return Math.round((documentsSize / capacity) * 100);
    return 0;
};

export const calculateBarColor = (percent) => {
    if (percent <= 30) {
        return 'success';
    }
    if (percent > 30 && percent <= 70) {
        return 'warning';
    }
    return 'error';
};
