import React, { Component, Fragment } from 'react';
import T from 'i18n-react';
import { SearchInput, TextAreaInput } from 'Mib/components/inputs/form_inputs';
import { TextInput } from 'Mib/components/inputs/text';
import { CreateButton } from 'Mib/components/inputs/buttons';
import { ProviderCard } from './providerCard';
import { Fetch } from '../../../lib/fetch';
import { JsonApiDataStore } from 'Mib/vendor/jsonapi-datastore';
import Message from 'semantic-ui-react/dist/es/collections/Message/Message';
import moment from 'moment';
import { Label, Segment } from 'semantic-ui-react';
import { PermissionsCard } from './permissionsCard';
import { Provider } from 'Mib/lib/models';
import ProviderPermissionsCard from './provider_permissions_card';
import { WithPayment } from '../../../billing/withPaymentWrapper';
import BillingDimmer from '../../../billing/dimmer';
// import { getProperty } from 'dot-prop';
export default class AccessProvision extends Component {
    constructor() {
        super();
        this.state = {
            query: '',
            providers: [],
            refCode: '',
            notFind: false,
            done: false,
            permissionCard: false,
        };
    }

    createCustomProvider() {
        this.setState({ customProviderButton: true });
    }

    async searchProv(e) {
        this.setState({
            customProviderButton: false,
            notFind: false,
            providerCustom: null,
            providers: [],
        });
        let providers = await Fetch(`providers?search=${this.state.query}&type=3`, {}, 'auth').catch(console.log);
        const store = new JsonApiDataStore();
        !providers.data.length
            ? this.setState({
                  notFind: true,
                  providerCustom: Provider(this.state.query),
              })
            : store.sync(providers);
        this.setState({ providers: store.findAll('providers') });
    }

    onCustomProviderChanged(e) {
        const { providerCustom } = this.state;
        providerCustom.name = e.target.value;
        this.setState({ providerCustom });
    }

    render() {
        return (
            <div>
                <WithPayment feature_id="premium.connections.doctors">
                    {(props) => (
                        <Fragment>
                            {!props.canViewFeature ? (
                                <BillingDimmer
                                    mediMsg={`${T.translate('billing.you-are-basic')}`}
                                    feature_id={props.feature_id}
                                />
                            ) : null}
                            <label className="titleLabel">{T.translate('providers.access-provision')}</label>

                            <Segment>
                                {/*<Label as='a' color='blue' ribbon>{moment().format('L, H:mm')}</Label>*/}
                                <div className="searcBarContainer">
                                    <div className="searchBarPosition">
                                        <SearchInput
                                            onKeyDown={(e) => {
                                                if (e.key == 'Enter') this.searchProv().bind(this);
                                            }}
                                            iconName="search"
                                            placeholder={T.translate('providers.doctorPlaceholder')}
                                            onChange={(e, v) => this.setState({ query: e.target.value })}
                                            onClick={this.searchProv.bind(this)}
                                        />
                                    </div>
                                    <Message
                                        icon="info circle"
                                        header={T.translate('providers.doctorSearch')}
                                        content={T.translate('providers.doctorSearchInfo')}
                                        info
                                    />
                                </div>

                                <div style={{ paddingTop: '15px' }}>
                                    {this.state.notFind ? (
                                        this.state.customProviderButton ? (
                                            <ProviderPermissionsCard
                                                addProviderFunctionality
                                                provider={this.state.providerCustom}
                                                searchToAdd
                                                actionButtons={false}
                                            />
                                        ) : (
                                            <div style={{ display: 'flex' }}>
                                                <CreateButton
                                                    onClick={this.createCustomProvider.bind(this)}
                                                    label={T.translate('providers.createCustom')}
                                                />
                                                <TextInput
                                                    type="text"
                                                    className="login-input"
                                                    value={this.state.providerCustom.name}
                                                    fieldStyle={{ flex: 1 }}
                                                    label={T.translate('providers.name')}
                                                    onChange={this.onCustomProviderChanged.bind(this)}
                                                    placeholder={T.translate('meta.login.email')}
                                                />
                                            </div>
                                        )
                                    ) : null}
                                </div>

                                {this.state.providers.map(
                                    (item) =>
                                        !this.state.notFind && (
                                            <ProviderPermissionsCard
                                                addProviderFunctionality
                                                key={item.id}
                                                provider={item}
                                                searchToAdd
                                                actionButtons={false}
                                            />
                                        )
                                )}
                            </Segment>
                        </Fragment>
                    )}
                </WithPayment>
            </div>
        );
    }

    async searchConnectProvider() {
        let catchErrors = [];
        catchErrors = [{ detail: T.translate('providers.connectDoctorFail') }];
        this.setState({ notFind: true, done: false, errors: catchErrors });
    }
}

// const mapStateToProps = ({dataStore}) => {
//     return{
//         providers: getProperty(dataStore,"graph.providers"),
//         personProviders: getProperty(dataStore,"graph.person-providers")
//     }
// }

// export const AccessProvision = subscribeWithLoader(_AccessProvision,mapStateToProps,["permisssions"]);
