import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import GeneralMetricContainer from 'Mib/components/metric_panels/general_metric';
import { openMeasurementModal } from 'Mib/actions';
import { subscribe } from '../../lib/subscribe';
import { withRouter } from 'react-router-dom';

import T from 'i18n-react';
import { Create, Update } from '../../actions';
import { fetchAction } from '../../actions/backend';
import moment from 'moment';

import MeasurementFormModal from '../modals/measurement_form_modal';
import { FilteredMetricComponent } from './filtered_metric';
import filterByMeasureSystem from 'Mib/lib/measure_units';

class _TemperatureContainer extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.open = this.open.bind(this);
        this.dataPropName = 'temperatures';
        this.config = {
            descriptor: 'result',
            name: T.translate('measurements.singular.temperature'),
            dateDescriptor: 'date',
            valueOffset: 1,
            onGraphPointClicked: this.open,
        };
    }

    render() {
        let { temperatures, measures, children, ...rest } = this.props;

        let { chart_config, temperatures: filteredTemps } = this.state;
        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate('measurements.singular.temperature')}
                measureUnits={measures}
                data={filteredTemps}
                dataType="temperatures"
                chart_config={chart_config}
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
            />
        );
    }

    open(id = undefined, type = undefined) {
        const { unitId } = this.state;
        openMeasurementModal({ id, type, unitId, showTime: true });
    }
}

const mapStateToProps = (state) => {
    var allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    var temperatureMeasureUnits = {};
    var systemMeasureUnits = filterByMeasureSystem(allmeasureUnits);
    systemMeasureUnits &&
        Object.entries(systemMeasureUnits).forEach(([key, unit]) => {
            if (unit.measureUnitType && unit.measureUnitType.name == 'TEMPERATURE') {
                temperatureMeasureUnits[unit.id] = unit;
            }
        });
    // console.log('measureUnitType',temperatureMeasureUnits)
    return {
        temperatures: state.dataStore.graph.temperatures,
        measures: temperatureMeasureUnits,
        filters: state.filters,
    };
};

const _TemperatureContainerSub = subscribe(_TemperatureContainer, [
    'temperatures',
    'measure-units?with=measureUnitType',
]);

export default withRouter(connect(mapStateToProps)(_TemperatureContainerSub));
