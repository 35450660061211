/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { PersonalData } from './persons/persons_page';
import { PersonsList } from './persons/persons_menu';
import EmergencyMenu from './emergency_data/emergency_menu';
import { EmergencyDataPage } from './emergency_data';
import WithSideMenu from 'Mib/components/containers/with_side_menu';

require('./profile.scss');

export class PersonsPage extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        this._getDefaultView();
    }

    componentWillReceiveProps(newProps) {
        this._getDefaultView(newProps);
    }

    _getDefaultView(props = this.props) {
        const { match, location, history } = props;
        if (match.params.mode == null) {
            history.replace('/profile/emergency_data');
        }
    }

    render() {
        const { match, location, history } = this.props;
        const { fader } = this.state;
        return (
            <WithSideMenu history={history} menuItems={[EmergencyMenu, PersonsList]}>
                <Route path="/profile/persons/:id?" component={PersonalData} />
                <Route path="/profile/emergency_data/:category?" component={EmergencyDataPage} />
            </WithSideMenu>
        );
    }
}
