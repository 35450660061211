import React, { Component } from "react";
import { Segment, Container, Progress } from "semantic-ui-react";
import classnames from "classnames";

import { Loader } from "../generic/loader";

require("./base.scss");

export default class BaseContainer extends Component {
    constructor() {
        super();
    }
    render() {
        let { color, title, className, syncing, containerStyle, ...rest } = this.props;
        return (
            <Container style={containerStyle}>
                <Segment className={classnames("mib-container", className)} color={color} title={title} {...rest}>
                    {syncing && <Loader style={{ position: "absolute", top: "0px", right: "0px" }} />}
                    {this.props.children}
                </Segment>
            </Container>
        );
    }
}
