import React from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { QuickAddPanel } from 'Mib/routes/dashboard/panels/quickadd_panel';
import { getUserMeasureUnit } from './getQuickAddMeasureUnit';

class _DashWeightChart extends QuickAddPanel {
    constructor(props) {
        super();
        this.state = { value: null, muId: '1' };
        this.link = '/health-folder/weights';
        this.title = T.translate('measurements.add.add-weight');
        this.dataType = 'weights';
        this.maximums = {
            1: 200,
            3: 440,
            8: 200000,
        };
        this.steps = {
            1: 0.1,
            3: 0.1,
            8: 100,
        };
    }
}

const mapStateToProps = (state) => {
    const userId = state.dataStore && getProperty(state, 'authentication.id');
    const userSettings = getProperty(state, `dataStore.graph.user-settings.${userId}`);
    const defaultMeasureUnit = userSettings && getUserMeasureUnit(userSettings, 'weight');

    return {
        data: state.dataStore.graph.weights,
        filters: state.filters,
        measureUnitFromSettings: defaultMeasureUnit,
    };
};

export const DashWeightQuickAdd = subscribeWithLoader(_DashWeightChart, mapStateToProps, [
    'weights',
    'measure-units?with=measureUnitType',
]);
