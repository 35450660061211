import React, { Component, Suspense } from 'react';

import { Icon, TransitionablePortal, Dimmer } from 'semantic-ui-react';
const BaseContainer = React.lazy(() => import('Mib/components/containers/base'));

require('./form_modal.scss');

export class FormModal extends Component {
    constructor() {
        super();
        this.state = { open: false };
    }

    open = (contact) => {
        this.setState({ open: true });
    };

    close = () => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;
        const { title, children, loading } = this.props;
        return (
            <Suspense fallback="loading...">
                <TransitionablePortal
                    closeOnDocumentClick={true}
                    onOpen={() => this.setState({ open: true })}
                    onClose={this.close}
                    open={open}
                >
                    <BaseContainer className="mib-modal modal">
                        <div className="mib-modal-title">
                            {title}
                            <Icon name="x" className="close-button" onClick={this.close} />
                        </div>
                        {children}
                    </BaseContainer>
                </TransitionablePortal>
                <Dimmer active={open} onClickOutside={this.close} page />
            </Suspense>
        );
    }
}

export default FormModal;
