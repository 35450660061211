


export  const ConvertOptionsWithKey  = ( options ) =>
{

    const optionsWithKey =  Object.values(options).map(option => {
           return({
                'key': option.id,
                'text': option.name,
                'value': option.id
            });
        });
        return optionsWithKey
}

