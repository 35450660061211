import { getProperty } from 'dot-prop';
import { store } from '../datastore/store';
import { JsonApiDataStoreModel } from '../vendor/jsonapi-datastore';

const relationshipNames = {
    measureUnit: 'measure-units',
    distanceUnit: 'measure-units',
    velocityUnit: 'measure-units',
    calorieUnit: 'measure-units',
    weightUnit: 'measure-units',
    heightUnit: 'measure-units',
    pressureUnit: 'measure-units',
    temperatureUnit: 'measure-units',
    oximetryUnit: 'measure-units',
    labUnit: 'measure-units',
    distanceUnit: 'measure-units',
    allergy: 'allergies',
    tags: 'tags',
    category: 'categories',
    activityType: 'activity-types',
    'activity-type': 'activity-types',
    allergy: 'allergies',
    drug: 'drugs',
    vaccine: 'vaccines',
};
export const relToResource = (relName) => {
    if (relationshipNames[relName] !== undefined) {
        return relationshipNames[relName];
    }
    return relName;
};

// Receive an array of tag names and return an array of tac instances
export const convertTags = (tags = []) => {
    const state = store.getState();
    const existing = Object.values(getProperty(state, 'dataStore.graph.tags') || {});

    const tagsArray = tags.map((tag) => {
        const existingTag = existing.find((t) => t.tag === tag);
        if (existingTag) return existingTag;

        const tagObj = new JsonApiDataStoreModel('tags');
        tagObj.setAttribute('tag', tag);
        return tagObj;
    });
    return tagsArray;
};

export const humanFileSize = (bytes, si) => {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};

export const mapEntityToBackendModels = (entity) => {
    console.log('in mapEntityToBackendModels', entity);
    const entityMappings = {
        allergies: 'person-allergies',
        drugs: 'person-drugs',
    };
    return entityMappings[entity] || entity;
};

export const mapEntityToFrontEndModels = (entity) => {
    const entityMappings = {
        allergies: 'asyncedUserAllergy',
    };
    return entityMappings[entity] || entity;
};
