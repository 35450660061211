import React from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import sortOn from 'sort-on';
import moment from 'moment';

import { SimpleMetricComponent } from 'Mib/components/metric_panels/filtered_metric';
import ReactHighChartsManaged from 'Mib/components/generic/react_high_charts_managed';
import BaseContainer from 'Mib/components/containers/base';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { store } from 'Mib/datastore/store';

import { MeasurementInfoPanel } from './measurements_info';
import NoDataMessage from './no_data_message';

class _DashWeightChart extends SimpleMetricComponent {
    constructor(props) {
        super();

        this.state = { chart_config: {} };
        const color = props.color ? props.color : '#21ba45';
        this.dataPropName = 'weights';
        this.config = {
            descriptor: 'result',
            name: T.translate('measurements.singular.weight'),
            dateDescriptor: 'date',
            onGraphPointClicked: this.open.bind(this),
            valueOffset: 10,
            merge_config: (props, cfg) => ({
                chart: {
                    height: 200,
                },
                series: [
                    {
                        ...cfg.series[0],
                        showInLegend: false,
                        color: props.color,
                    },
                ],

                xAxis: {
                    title: {
                        text: '',
                    },
                },
                yAxis: {
                    plotLines: [
                        {
                            color: 'rgba(0, 0, 0, 0.4)',
                            dashStyle: 'dash',
                            width: 2,
                            value: props.weightTarget,
                            label: {
                                align: 'right',
                                style: {
                                    fontStyle: 'italic',
                                    color: '#21ba45',
                                },
                                text: T.translate('persons.weightGoal'),
                                x: -10,
                            },
                            zIndex: 3,
                        },
                    ],
                    min: props.weightTarget ? Math.min(props.weightTarget, cfg.yAxis.min) : cfg.yAxis.min,
                    max: props.weightTarget ? Math.max(props.weightTarget, cfg.yAxis.max) : cfg.yAxis.max,
                },
                title: { floating: true, text: '' },
            }),
        };
    }

    componentWillReceiveProps(newProps) {
        this.forceRefresh = newProps.color != this.props.color;
        super.componentWillReceiveProps(newProps);
    }

    render() {
        let { chart_config } = this.state;
        let { weights, loading, measures } = this.props;
        const lastMeasurement = weights && sortOn(Object.values(weights), '-date')[0];
        const measureDate = moment(getProperty(lastMeasurement, 'date')).format('L');
        const measureTime = moment(getProperty(lastMeasurement, 'date')).format('HH:mm');
        const measureUnitAbbrev = getProperty(lastMeasurement, 'measureUnit.abbreviation');
        const value = lastMeasurement ? lastMeasurement.result + measureUnitAbbrev : undefined;
        return (
            <>
                <MeasurementInfoPanel
                    style={{ borderTopColor: this.props.color, borderTopWidth: '2px' }}
                    title={T.translate('measurements.my.my-weight-graph')}
                    date={measureDate}
                    time={measureTime}
                    value={value}
                    altText={T.translate('no-data')}
                    loading={loading}
                    color={this.props.color}
                    link="/health-folder/weights"
                />
                <BaseContainer loading={loading} className="dash-chart-container">
                    {loading ? (
                        <div style={{ width: '100%', height: '200px' }} />
                    ) : weights ? (
                        <ReactHighChartsManaged config={chart_config} />
                    ) : (
                        <NoDataMessage create={() => this.open(null, 'weights')} />
                    )}
                </BaseContainer>
            </>
        );
    }

    open(id = undefined, type = undefined) {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'MEASUREMENT_MODAL',
                unitId: this.state.unitId,
                id: id,
                type: type,
            },
        });
    }
}

const mapStateToProps = (state) => {
    const allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    const weightMeasureUnits = {};
    if (allmeasureUnits)
        Object.entries(allmeasureUnits).forEach(([key, unit]) => {
            // console.log('measureUnitType',unit)
            if (unit.measureUnitType && unit.measureUnitType.name == 'WEIGHT') {
                weightMeasureUnits[unit.id] = unit;
            }
        });

    return {
        weights: state.dataStore.graph.weights,
        measures: weightMeasureUnits,
        weightTarget: state.dataStore.graph.meta && state.dataStore.graph.meta.weightTarget,
        filters: state.filters,
    };
};

export const DashWeightChart = subscribeWithLoader(_DashWeightChart, mapStateToProps, ['weights']);
