import React, { Component } from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, Radio, Popup } from 'semantic-ui-react';
import { VaccineForm } from 'Mib/routes/health_folder/vaccines/vaccine_form';
import { MenuPanel } from 'Mib/components/containers';
import { subscribeWithLoader } from 'Mib/lib/subscribe';

class _VaccinePanel extends Component {
    constructor(props) {
        super();
        const { defaultShowAll } = props;
        this.openVaccineForm = this.openVaccineForm.bind(this);
        this.state = { showAll: defaultShowAll || false };
    }
    filterData(data = [], props = this.props, valueAttr = 'result', dateAttr = 'date') {
        const {
            filters: { tags },
        } = props;
        let filtered = data.filter((o) => {
            let res = true;

            if (tags && tags.length && o.tags) {
                res = o.tags.some((tag) => tags.some((t) => t.id === tag.id));
            }
            return res;
        });
        return filtered;
    }
    render() {
        let { personVaccines, vaccines, history, ...rest } = this.props;
        let filteredVaccines = personVaccines && this.filterData(Object.values(personVaccines));
        const items =
            filteredVaccines &&
            filteredVaccines.map((vaccine) => ({
                label: (
                    <div>
                        {vaccine.vaccine_name || vaccine.vaccine.name}
                        <span style={{ float: 'right' }}>{moment(vaccine.date).format('L')}</span>
                    </div>
                ),
                key: vaccine.id,
                onClick: () => this.openVaccineForm(vaccine),
            }));
        const itemsIfEmpty = [
            {
                key: 0,
                label: (
                    <Menu.Item onClick={() => this.openVaccineForm()} icon="plus square outline">
                        {T.translate('vaccines.no-data')}
                    </Menu.Item>
                ),
            },
        ];
        return (
            <React.Fragment>
                <VaccineForm vaccines={vaccines} ref={(i) => (this.vaccineForm = i)} />
                <MenuPanel
                    className="orange"
                    inverted
                    loading={this.props.loading}
                    syncing={this.props.syncing}
                    title={this.renderHeader()}
                    {...rest}
                    footer={
                        <Menu.Item onClick={() => this.openVaccineForm()} className="menu-footer">
                            {T.translate('vaccines.create-new')}
                            <Icon className="add circle" />
                        </Menu.Item>
                    }
                    items={(items && items.length) || this.props.loading ? items : itemsIfEmpty}
                />
            </React.Fragment>
        );
    }

    openVaccineForm(vaccine) {
        this.vaccineForm.open(vaccine);
    }

    renderHeader() {
        let title = {
            label: `${T.translate('measurements.plural.vaccines')}`,
            onClick: (_) => this.props.history.push('/vaccines'),
            icon: 'icon-injecting-syringe-svgrepo-com',
        };
        return (
            <Menu.Item onClick={title.onClick} className="menu-title">
                <Icon className={title.icon} style={{ fontSize: '1.3em', marginLeft: 0 }} />
                <span className="title">{title.label}</span>
            </Menu.Item>
        );
    }
}

const mapStateToProps = ({ dataStore, filters }) => {
    return {
        personVaccines: getProperty(dataStore, 'graph.person-vaccines'),
        vaccines: getProperty(dataStore, 'graph.vaccines'),
        filters: filters,
    };
};

export const VaccinePanel = withRouter(
    subscribeWithLoader(_VaccinePanel, mapStateToProps, ['vaccines?', 'person-vaccines'])
);
// const VaccinePanelTemp = subscribe(_VaccinePanel,['person-vaccines']);

// export const VaccinePanel = connect(mapStateToProps)(VaccinePanelTemp);
