import React from "react";
import { Table } from "semantic-ui-react";
import T from "i18n-react";


export const CodeTable = ({today, code ,expireDay}) =>{

    return(
        <div id='codeDiv'  style={{width:'100%', height:'170px'}} className='codeDiv' >

        <Table  id='codeTable' style={{fontSize:'1.3em', margin:'0', minHeight:'170px'}} celled inverted selectable>
            <Table.Body >
                <Table.Row>
                    <Table.Cell>{T.translate("promoter.generateDate")}:</Table.Cell>
                    <Table.Cell>{today}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>{T.translate("promoter.promoteCode")}:</Table.Cell>
                    <Table.Cell>{code}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>{T.translate("promoter.contractEndDate")}:</Table.Cell>
                    <Table.Cell>{expireDay.format('LL')}</Table.Cell>
                </Table.Row>

            </Table.Body>

        </Table>
        </div>
    )
}