import React, { Component, Fragment, Suspense } from 'react';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import { Confirm } from 'semantic-ui-react';

import { DropDownInput, MibButton, TextInput } from 'Mib/components/inputs';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { relToResource } from 'Mib/lib/conversions';
import { Measurement } from 'Mib/lib/models';
import { store } from 'Mib/datastore/store';
import { Fetch } from 'Mib/lib/fetch';
import { serializeWithTags } from 'Mib/lib/json-api';
import { notify } from 'Mib/actions/notifications';
import { emailHash } from 'Mib/actions/registration';
import { JsonApiDataStoreModel } from 'Mib/vendor/jsonapi-datastore';
import { deleteUserAction } from '../../actions/deleteUser';
import { calculateBarColor, calculateStorageSize } from '../../actions/calculate';
import filterByMeasureSystem from '../../lib/measure_units';
// import { fetchFeatures } from '../../billing/actions';

const ModelForm = React.lazy(() => import('Mib/components/forms/model_form'));
const BaseContainer = React.lazy(() => import('Mib/components/containers/base'));

class _UserSettingsPage extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            error: false,
            usrSettingsModel: {},
            emailModel: {},
            promoCodeModel: {},
            errors: [],
            open: false,
        };
    }

    componentWillMount() {
        this.props.userSettings !== undefined
            ? this.setState({ usrSettingsModel: this.props.userSettings.makeDeepCopy() })
            : null;
    }

    componentWillReceiveProps(newProps) {
        newProps.userSettings !== undefined
            ? this.setState({ usrSettingsModel: newProps.userSettings.makeDeepCopy() })
            : null;
    }

    handleConfirm() {
        this.setState({ open: false });
        deleteUserAction();
    }

    handleCancel() {
        this.setState({ open: false });
    }

    onDeleteAccount() {
        this.setState({ open: true });
    }

    onEmailChanged(field, e, v) {
        const value = v ? v.value : e.target.value;
        const { model, onChange } = this.props;
        model.email = value;
        onChange(model);
    }

    onPromoCodeChanged(field, e, v) {
        const value = v ? v.value : e.target.value;
        const { model, onChange } = this.props;

        console.log('salalala', value, model);

        model.promoCode = value;
        onChange(model);
    }

    /***********************  attentio "this" is in the context of BaseForm!!!!!!!!!!!!!! ****************************************/
    onFormChanged(field, e, v) {
        const value = v ? v.value : e.target.value;
        const localField = v.fieldName;

        const { model, onChange, validator, onValidate } = this.props;
        // let model = userSettings;
        const { dataStore } = store.getState();

        const { id } = model;
        // let relationship = getProperty(dataStore, `graph.user-settings.${id}.${field}.${value}`);
        const relationship = getProperty(dataStore, `graph.${relToResource(localField)}.${value}`);

        if (relationship != null) {
            model.setRelationship(localField, relationship);
        } else {
            console.warn(`No relationship was found for model ${model._type} and field ${localField}`);
        }

        onChange(model);
        // this.setState({usrSettingsModel:model});
        // let valid = revalidator.validate(model, validator);
        // let errors = valid.errors.map(err => ({ property: err.property, message: err.message }));
        // console.log("Validation errors:", errors);
        // this.setState({ errors: errors });
        // onValidate && onValidate(errors);
    }

    emailSave() {
        this.setState({ loading: true });
        const emailModel = new JsonApiDataStoreModel();
        emailModel.setAttribute('email', emailHash(this.state.emailModel.email));
        // emailModel.email = this.state.emailModel.email;
        const payload = emailModel.serialize();
        Fetch(
            'user/email',
            {
                method: 'POST',
                body: JSON.stringify(payload),
            },
            'auth'
        )
            .then((results) => {
                store.dispatch({ type: 'SET_DATA', payload: results });
                notify({
                    success: true,
                    header: T.translate('success.update.header'),
                    content: T.translate('success.update.content'),
                });
            })
            .catch(() =>
                notify({
                    error: true,
                    header: T.translate('errors.update.header'),
                    content: T.translate('errors.update.content'),
                })
            )
            .then(() => {
                this.setState({ loading: false });
                this.close();
            });
    }

    promoCodeSave() {
        this.setState({ loading: true });

        const payload = { promoter_code: this.state.promoCodeModel.promoCode };
        Fetch(
            'promoters',
            {
                method: 'POST',
                body: JSON.stringify(payload),
            },
            'auth'
        )
            .then((results) => {
                store.dispatch({ type: 'SET_DATA', payload: results });
                notify({
                    success: true,
                    header: T.translate('success.update.header'),
                    content: T.translate('success.promoCode'),
                });
            })
            .catch(() =>
                notify({
                    error: true,
                    header: T.translate('errors.update.header'),
                    content: T.translate('errors.promoCode'),
                })
            )
            .then(() => {
                // const features = fetchFeatures();
                this.setState({ loading: false });
                this.setState({ promoCodeModel: { promoCode: '' } });
                this.close();
            });
    }

    save() {
        this.setState({ loading: true });

        const payload = serializeWithTags(this.state.usrSettingsModel);
        Fetch(
            'user-settings',
            {
                method: 'PATCH',
                body: JSON.stringify(payload),
            },
            'api'
        )
            .then((results) => {
                store.dispatch({ type: 'SET_DATA', payload: results });
                notify({
                    success: true,
                    header: T.translate('success.update.header'),
                    content: T.translate('success.update.content'),
                });
            })
            .catch(() =>
                notify({
                    error: true,
                    header: T.translate('errors.update.header'),
                    content: T.translate('errors.update.content'),
                })
            )
            .then(() => {
                this.setState({ loading: false });
                this.close();
            });

        // Update(this.state.usrSettingsModel).then(res => {this.setState({loading: false}); this.close()});
    }

    render() {
        const { weightUnits, heightUnits, pressureUnits, temperatureUnits, oximetryUnits, labUnits, distanceUnits } =
            this.props;

        // const values = this.state && this.state.usrSettingsModel;
        const weightUnitOptions = Object.values(weightUnits || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        const heightUnitOptions = Object.values(heightUnits || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        const pressureUnitOptions = Object.values(pressureUnits || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        const temperatureUnitOptionsAll = Object.values(temperatureUnits || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));
        const temperatureUnitOptions = temperatureUnitOptionsAll.filter((f) => f.key !== 30);

        const oximetryUnitOptions = Object.values(oximetryUnits || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        const labUnitOptions = Object.values(labUnits || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        const distanceUnitOptions = Object.values(distanceUnits || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        const formItems = [
            {
                Element: DropDownInput,
                label: 'measurements.measure-units.weight',
                onChange: this.onFormChanged,
                fieldName: 'weightUnit',
                options: weightUnitOptions,
                attrib: 'id',
            },
            {
                Element: DropDownInput,
                label: 'measurements.measure-units.height',
                onChange: this.onFormChanged,
                fieldName: 'heightUnit',
                options: heightUnitOptions,
                attrib: 'id',
            },
            {
                Element: DropDownInput,
                label: 'measurements.measure-units.pressure',
                onChange: this.onFormChanged,
                fieldName: 'pressureUnit',
                options: pressureUnitOptions,
                attrib: 'id',
            },
            {
                Element: DropDownInput,
                label: 'measurements.measure-units.temperature',
                onChange: this.onFormChanged,
                fieldName: 'temperatureUnit',
                options: temperatureUnitOptions,
                attrib: 'id',
            },
            {
                Element: DropDownInput,
                label: 'measurements.measure-units.oximetry',
                onChange: this.onFormChanged,
                fieldName: 'oximetryUnit',
                options: oximetryUnitOptions,
                attrib: 'id',
            },
            {
                Element: DropDownInput,
                label: 'measurements.measure-units.lab',
                onChange: this.onFormChanged,
                fieldName: 'labUnit',
                options: labUnitOptions,
                attrib: 'id',
            },
            {
                Element: DropDownInput,
                label: 'measurements.measure-units.distance',
                onChange: this.onFormChanged,
                fieldName: 'distanceUnit',
                options: distanceUnitOptions,
                attrib: 'id',
            },

            // {Element: TextInput, label: "measurements.result", fieldName:"id"},
        ];
        const emailItem = [{ Element: TextInput, label: 'email', fieldName: 'email', onChange: this.onEmailChanged }];

        const promoCodeItem = [
            { Element: TextInput, label: 'Promo code', fieldName: 'promoCode', onChange: this.onPromoCodeChanged },
        ];

        // Messages and notifications segment
        const Notifications = () => (
            <>
                <div style={{ textAlign: 'right', paddingRight: '2em' }}>
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            store.dispatch({
                                type: 'SEND_SIGNAL',
                                payload: {
                                    signalType: 'TEXT_MODAL',
                                    title: T.translate('legal.terms-title'),
                                    content: (
                                        <iframe
                                            style={{ height: 'calc(100vh - 200px)', width: '100%' }}
                                            src="/terms.html"
                                            title="terms"
                                        />
                                    ),
                                },
                            });
                        }}
                    >
                        {T.translate('legal.terms-title')}
                    </a>
                </div>
                <div style={{ textAlign: 'right', paddingRight: '2em' }}>
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            store.dispatch({
                                type: 'SEND_SIGNAL',
                                payload: {
                                    signalType: 'TEXT_MODAL',
                                    title: T.translate('legal.policy-title'),
                                    content: (
                                        <iframe
                                            style={{ height: 'calc(100vh - 200px)', width: '100%' }}
                                            src="/policy.html"
                                            title="policy"
                                        />
                                    ),
                                },
                            });
                        }}
                    >
                        {T.translate('legal.policy-title')}
                    </a>
                </div>
                <div style={{ textAlign: 'right', paddingRight: '2em' }}>
                    <span style={{ color: 'rgb(110, 146, 181)' }}>{`${T.translate(
                        'App version'
                    )}: ${__GITVERSION}`}</span>
                </div>
            </>
        );

        const storageSize = this.props.userSettings && this.props.userSettings.storageSize;
        const maxStorageSize = this.props.userSettings && this.props.userSettings.maxStorageSize;
        // let maxStorageSize = 15;

        const percent = calculateStorageSize(storageSize, maxStorageSize);
        const barColor = calculateBarColor(percent);

        return (
            <Suspense fallback="loading...">
                <div className="content-container">
                    <BaseContainer style={{ backgroundColor: 'transparent', border: 'none', boxShadow: '0 0' }}>
                        <Notifications />
                    </BaseContainer>

                    <BaseContainer>
                        <h1>{T.translate('meta.email-reset')}</h1>
                        <ModelForm
                            model={this.state.emailModel}
                            onChange={(model) => this.setState({ emailModel: model })}
                            items={emailItem}
                        />
                        <div style={{ display: 'flex', paddingTop: '30px', justifyContent: 'space-around' }}>
                            <MibButton
                                type="success"
                                label={T.translate('meta.save')}
                                onClick={() => this.emailSave()}
                            />
                        </div>
                    </BaseContainer>
                    <BaseContainer style={{ padding: '30px', marginTop: '30px' }}>
                        <h1>{T.translate('measurements.measure-units.title')}</h1>

                        <ModelForm
                            model={this.state.usrSettingsModel}
                            validator={Measurement.Validator}
                            onChange={(model) => this.setState({ usrSettingsModel: model })}
                            items={formItems}
                        />

                        <div style={{ display: 'flex', paddingTop: '30px', justifyContent: 'space-around' }}>
                            <MibButton type="success" label={T.translate('meta.save')} onClick={() => this.save()} />
                        </div>
                    </BaseContainer>

                    <BaseContainer style={{ padding: '30px', marginTop: '30px' }}>
                        <h1>{T.translate('meta.cloud-storage')}</h1>
                        {/*<h3>{T.translate("deleteAccountContent")}</h3>*/}
                        <div className={`ui ${barColor} progress`} data-percent={percent}>
                            <div className="bar" style={{ width: `${percent}%` }}>
                                <div className="progress">{percent}%</div>
                            </div>
                            <div className="label">Max storage capacity {maxStorageSize} Mb </div>
                        </div>
                    </BaseContainer>

                    <BaseContainer style={{ padding: '30px', marginTop: '30px' }}>
                        <h1>{T.translate('meta.premium')}</h1>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <MibButton
                                type="warning"
                                label={T.translate('meta.learn-more')}
                                target="_blank"
                                href="https://www.medinfobook.gr/#benefits"
                            />
                        </div>
                    </BaseContainer>

                    <BaseContainer style={{ padding: '30px', marginTop: '30px' }}>
                        <h1>{T.translate('meta.premiumPromoCode')}</h1>
                        <ModelForm
                            model={this.state.promoCodeModel}
                            onChange={(model) => this.setState({ promoCode: model })}
                            items={promoCodeItem}
                        />
                        <div style={{ display: 'flex', paddingTop: '30px', justifyContent: 'space-around' }}>
                            <MibButton
                                type="success"
                                label={T.translate('meta.save')}
                                onClick={() => this.promoCodeSave()}
                            />
                        </div>
                    </BaseContainer>

                    <BaseContainer style={{ padding: '30px', marginTop: '30px' }}>
                        <h1>{T.translate('deleteAccount')}</h1>
                        {/*<h3>{T.translate("deleteAccountContent")}</h3>*/}
                        <div style={{ display: 'flex', paddingTop: '30px', justifyContent: 'space-around' }}>
                            <MibButton
                                type="danger"
                                label={T.translate('deleteAccount')}
                                onClick={() => this.onDeleteAccount()}
                            />
                            <Confirm
                                open={this.state.open}
                                header={T.translate('attention')}
                                content={T.translate('deleteAccountContent')}
                                cancelButton={T.translate('cancel')}
                                confirmButton={T.translate('delete')}
                                onCancel={() => this.handleCancel()}
                                onConfirm={() => this.handleConfirm()}
                            />
                        </div>
                    </BaseContainer>
                </div>
            </Suspense>
        );
    }
}

const mapStateToProps = ({ dataStore, authentication: { id } }) => {
    const allmeasureUnits = dataStore.graph['measure-units'] && dataStore.graph['measure-units'];
    const systemMeasureUnits = filterByMeasureSystem(allmeasureUnits);
    const weightMeasureUnits = {};
    const oximetryUnits = {};
    const pressureUnits = {};
    const temperatureUnits = {};
    const distanceUnits = {};
    const heightUnits = {};
    const labUnits = {};

    if (systemMeasureUnits)
        Object.entries(systemMeasureUnits).forEach(([key, unit]) => {
            switch (unit.measureUnitType && unit.measureUnitType.name) {
                case 'WEIGHT':
                    weightMeasureUnits[unit.id] = unit;
                    break;
                case 'HEIGHT':
                    heightUnits[unit.id] = unit;
                    break;
                case 'PRESSURE':
                    pressureUnits[unit.id] = unit;
                    break;
                case 'TEMPERATURE':
                    temperatureUnits[unit.id] = unit;
                    break;
                case 'OXIMETRY':
                    oximetryUnits[unit.id] = unit;
                    break;
                case 'LAB':
                    labUnits[unit.id] = unit;
                    break;
                case 'DISTANCE':
                    distanceUnits[unit.id] = unit;
                    break;
                default:
                    break;
            }
        });
    return {
        userSettings: getProperty(dataStore, `graph.user-settings.${id}`),
        weightUnits: weightMeasureUnits,
        heightUnits: heightUnits,
        pressureUnits: pressureUnits,
        oximetryUnits: oximetryUnits,
        temperatureUnits: temperatureUnits,
        labUnits: labUnits,
        distanceUnits: distanceUnits,
    };
};

export const UserSettingsPage = subscribeWithLoader(_UserSettingsPage, mapStateToProps, ['user-settings']);
