export const labIcons = {
    0: 'mib-lab miblab-urine-general',
    1: 'mib-lab miblab-sugar',
    2: 'mib-lab miblab-tri-heart',
    3: 'mib-lab miblab-chol1',
    4: 'mib-lab miblab-Fe',
    5: 'mib-lab miblab-urea',
    6: 'mib-lab miblab-crea',
    7: 'mib-lab miblab-hdl',
    8: 'mib-lab miblab-ldl',
    9: 'mib-lab miblab-diabetes',
}

export const categoryIcons = {
    1: 'mib-lab miblab-heart',
    'other': 'mib-lab miblab-body',
}
