import React, { Component } from "react";
import BaseContainer from "Mib/components/containers/base";
import ReactHighchartsManaged from "Mib/components/generic/react_high_charts_managed";
import {DashOximetryChart, DashTemperatureChart} from "Mib/routes/dashboard/panels";


export const DoubleCharts =(props)=>{

    let {chartATitle , chartBTitle , chartAConfigs , chartBConfigs, chartALimit, chartBLimit , loading} = props

    return(
        <div style={{display:'flex' }}>
            <div style={{width:'50%', padding: '3px'}}>
            <BaseContainer title={chartATitle} loading={loading}>
               <DashTemperatureChart/>
            </BaseContainer>
            </div>
            <div style={{width:'50%', padding: '3px'}}>
            <BaseContainer title={chartBTitle} loading={loading}>
                <DashOximetryChart/>
            </BaseContainer>
            </div>

        </div>
    )
 }