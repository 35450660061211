import * as Store from './store';
import update from 'immutability-helper';
import { JsonApiDataStore } from '../vendor/jsonapi-datastore';

/**
 * Handles authentication and session data
 */
export const LoginReducer = (store = { token: null }, action) => {
    switch (action.type) {
        // Save the username, userid and auth token when login succeeds
        case 'SET_AUTH_DATA':
            const { access_token, id, provider_type, provider_name } = action.payload;
            window.sessionStorage.setItem(
                'authData',
                JSON.stringify({
                    ...store,
                    access_token: access_token,
                    id: id,
                    provider_type: provider_type,
                    provider_name: provider_name,
                })
            );
            return update(store, {
                access_token: { $set: access_token },
                id: { $set: id },
                provider_type: { $set: provider_type },
                provider_name: { $set: provider_name },
            });
        // Clear all auth data
        case 'CLEAR_AUTH_DATA':
            return update(store, { $set: {} });
        case 'SET_ACTIVE_PERSON':
            window.sessionStorage.setItem('authData', JSON.stringify({ ...store, activePersonId: action.payload }));
            return update(store, { activePersonId: { $set: action.payload } });
        default:
            return store;
    }
};

export const BillingReducer = (features = {}, action) => {
    let newFeatures;

    switch (action.type) {
        case 'SET_FEATURES':
            newFeatures = [...action.payload];
            return newFeatures;

        default:
            return features;
    }
};

export const DataStoreReducer = (store = new JsonApiDataStore(), action) => {
    let newStore;

    switch (action.type) {
        // Save an object or collection
        case 'SET_DATA':
        case 'SET_MODEL':
            let model_ = action.payload;
            const { replace } = action;
            let meta = store.syncWithMeta(model_, replace);
            if (meta) {
                newStore = Object.assign({}, { graph: store._brotherGraph });
                newStore.__proto__ = store.__proto__;

                if (meta.meta) {
                    newStore.graph.meta = Object.assign(newStore.graph.meta || {}, meta.meta);
                }
                return newStore;
            } else {
                return store;
            }
        case 'SET_WT':
            return {
                graph: {
                    ...store.graph,
                    meta: {
                        ...store.graph.meta,
                        weightTarget: action.payload,
                    },
                },
            };

        case 'DELETE_ITEM':
            const object = action.payload;
            // let model = object._type == null ? object.data.type : object._type;
            // obj = store.find(model, action.payload.id);
            let model_type = object._type;
            store.destroy(object);
            newStore = {
                ...store,
                graph: {
                    ...store.graph,
                    [model_type]: {
                        ...store.graph[model_type],
                    },
                },
            };
            newStore.__proto__ = store.__proto__;
            return newStore;
        case 'CLEAR_PERSONAL_DATA':
            newStore = {
                ...store,
                graph: {
                    persons: action.purge ? {} : { ...store.graph.persons },
                    permissions: action.purge ? {} : { ...(store.graph.permissions || {}) },
                    categories: { ...store.graph.categories },
                    'measure-units': { ...store.graph['measure-units'] },
                    'user-settings': { ...store.graph['user-settings'] },
                },
            };
            newStore.__proto__ = store.__proto__;
            return newStore;

        default:
            return store;
    }
};

export const AppStateReducer = (
    store = { syncing: [], lastSync: {}, messages: [], tagFilters: [], signals: [], lang: 'el' },
    action
) => {
    let data_type;
    switch (action.type) {
        // Add an item to the list of syncing data types
        case 'START_SYNCING':
            // Remove query strings
            data_type = action.payload.split('?')[0];
            return update(store, { syncing: { $push: [data_type] } });
        // Remove an item from the list of syncing data types
        case 'STOP_SYNCING':
            // Remove query strings
            data_type = action.payload.split('?')[0];
            if (store.syncing.includes(data_type)) {
                return {
                    ...store,
                    syncing: store.syncing.filter((item) => item !== data_type),
                    lastSync: { ...store.lastSync, [data_type]: Date.now() },
                };
            } else {
                return store;
            }
        case 'CLEAR_SYNC_CACHE':
            return {
                ...store,
                // syncing: [],
                lastSync: {},
            };
        case 'ADD_MESSAGE':
            var { messages } = store;
            return {
                ...store,
                messages: [...messages, { ...action.payload }],
            };
        case 'REMOVE_MESSAGE':
            var { messages } = store;
            return {
                ...store,
                messages: messages.filter((item) => item._id !== action.payload),
            };
        case 'RESET_PERSONS':
            return {
                ...store,
                resetPersons: action.payload,
            };
        // case "SEND_SIGNAL":
        //     var { signals } = store;
        //     var { signalType, ...rest } = action.payload;
        //     if (signals.some(s => s.signalType == signalType)) {
        //         return store;
        //     } else {
        //         return {
        //             ...store,
        //             signals: [...signals, action.payload],
        //         };
        //     }
        //
        // case "SIGNAL_PROCCESSED":
        //     var { signals } = store;
        //     var { signalType } = action.payload;
        //     return {
        //         ...store,
        //         signals: signals.filter(s => s.signalType !== signalType),
        //     };
        case 'SET_LANG':
            var lang = action.payload;
            if (lang != 'el' && lang != 'en') return store;
            return { ...store, lang: lang };
        default:
            return store;
    }
};

export const SignalsReducer = (store = { signals: [] }, action) => {
    let data_type;
    switch (action.type) {
        case 'SEND_SIGNAL':
            var { signals } = store;
            var { signalType, ...rest } = action.payload;
            if (signals.some((s) => s.signalType == signalType)) {
                return store;
            } else {
                return {
                    ...store,
                    signals: [...signals, action.payload],
                };
            }
        case 'SIGNAL_PROCCESSED':
            var { signals } = store;
            var { signalType } = action.payload;
            return {
                ...store,
                signals: signals.filter((s) => s.signalType !== signalType),
            };
        default:
            return store;
    }
};

export const FiltersReducer = (filters = {}, action) => {
    switch (action.type) {
        case 'SET_TAG_FILTERS':
            return { ...filters, tags: [...action.payload] };
        case 'SET_DATE_FILTERS':
            return {
                ...filters,
                dateFrom: action.payload.dateFrom || filters.dateFrom,
                dateTo: action.payload.dateTo || filters.dateTo,
            };
        case 'CLEAR_FILTERS':
            return {};
        default:
            return filters;
    }
};

export const UserSettingsReducer = (store = {}, action) => {
    switch (action.type) {
        case 'SET_USER_SETTINGS':
            return update(store, { $set: action.payload });
        default:
            return store;
    }
};

export const MediMessageReducer = (store = [], action) => {
    switch (action.type) {
        case 'ADD_MEDI_MESSAGE':
            return store.concat([action.payload]);
        case 'REMOVE_MEDI_MESSAGE':
            return store.filter((msg) => msg.id !== action.payload.id);
        case 'MARK_READ_MEDI_MESSAGE':
            action.payload.read = true;

        case 'UPDATE_MEDI_MESSAGE':
            const existing_msg = store.find((msg) => msg.id === action.payload.id);
            if (existing_msg != null) {
                const new_msg = Object.assign({}, existing_msg, action.payload);
                return store.map((msg) => (msg == existing_msg ? new_msg : msg));
            }
        default:
            return store;
    }
};

export const NotificationMessageReducer = (store = [], action) => {
    switch (action.type) {
        case 'ADD_OR_UPDATE_NOTIFICATION_MESSAGE':
            // const hoho = store.some(msg => msg.id == action.payload.id)
            // const existing_message = store.find(msg => msg.id == action.payload.id)
            // if(existing_message!=null)
            // {
            //     console.log("NOW UPDATING......")
            //     const newMsgDeep = Object.assign({},existing_message)
            //     return store.map(msg => msg.id == newMsgDeep.id ? newMsgDeep : msg)
            // }
            // console.log("NOW ADDING.......")
            return [...store, { ...action.payload }];

        case 'UPDATE_NOTIFICATION_MESSAGE':
            const existing_msg = store.find((msg) => msg.id == action.payload.id);
            if (existing_msg != null) {
                const new_msg = Object.assign({}, existing_msg, action.payload);
                return store.map((msg) => (msg.id == new_msg.id ? new_msg : msg));
            }
        case 'CLEAR_NOTIFICATION_MESSAGES':
            return [];

        default:
            return store;
    }
};

export const LiveDataReducer = (store = [], action) => {
    switch (action.type) {
        case 'ADD_LIVE_DATA':
            let dataStructure = {
                ...store,
                [action.topic]: [...action.payload],
            };

            console.log('IN ADD LIVE DATA PAYLOAD', action);
            return dataStructure;

        case 'UPDATE_LIVE_DATA':
            const existing_msg = store.find((msg) => msg.id == action.payload.id);
            if (existing_msg != null) {
                const new_data = Object.assign({}, existing_msg, action.payload);
                return store.map((msg) => (msg.id == new_data.id ? new_data : msg));
            }
            break;
        case 'CLEAR_LIVE_DATA':
            return [];

        default:
            return store;
    }
};

export const SenseReportReducer = (store = [], action) => {
    switch (action.type) {
        case 'ADD_SENSE_REPORT':
            return [...store, { ...action.payload }];

        case 'CLEAR_SENSE_REPORT':
            return [];

        default:
            return store;
    }
};
