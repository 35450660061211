import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import T from 'i18n-react';
import { ContentTitle } from 'Mib/components/generic';
import { getConnections } from 'Mib/actions';
import { WithPayment } from '../../../billing/withPaymentWrapper';

import Med4UPanel from './med4u';
import WithingsPanel from './withings';
import BillingDimmer from '../../../billing/dimmer';

require('./connections.scss');

let _panels = [WithingsPanel, Med4UPanel];

class _DevicesPage extends Component {
    componentWillMount() {
        this.setState({ loadingConnections: true });
        getConnections().then((res) => this.setState({ loadingConnections: false }));
    }

    render() {
        const { location, match, history } = this.props;
        const { loadingConnections } = this.state;
        const { mode } = match.params;
        let panels = _panels;
        if (mode == 'withings') panels = [WithingsPanel];
        if (mode == 'med4u') panels = [Med4UPanel];
        return (
            <div className="connections-container">
                <WithPayment feature_id="premium.connections.syncs">
                    {(props) => (
                        <Fragment>
                            {!props.canViewFeature ? (
                                <BillingDimmer
                                    mediMsg={`${T.translate('billing.you-are-basic')}`}
                                    feature_id={props.feature_id}
                                />
                            ) : null}
                            <ContentTitle icon="share alternate" title={T.translate('connections.title')} />
                            {panels.map((Panel, index) => (
                                <Panel
                                    location={location}
                                    match={match}
                                    history={history}
                                    key={index}
                                    loadingConnections={loadingConnections}
                                />
                            ))}
                        </Fragment>
                    )}
                </WithPayment>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export const DevicesPage = connect(mapStateToProps)(_DevicesPage);
