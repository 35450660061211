import React, { Component } from "react";
import { Form, Dropdown } from "semantic-ui-react";
import classnames from "classnames";

export class DropDownInput extends Component {
    constructor() {
        super();

        this.hoho = new Promise(resolve => {
            this.resolve = resolve;
        });

        this.onMeChange = this.onMeChange.bind(this);
    }
    componentDidMount() {
        this.resolve();
    }

    onMeChange(e = null) {
        let { onChange, multiple } = this.props;
        if (!multiple) {
            let customClickFunction = () => {
           // setTimeout(this.dropdown.close(),30000);
                   this.hoho.then(()=>{
           //   // e.preventDefault()
           //   // e.stopPropagation()
                   this.dropdown.setState({open:false})

                   this.dropdown.close()

            // this.dropdown.handleClose()
           //  //

           })
         return onChange;
        }

            return customClickFunction();
        }
        return onChange;
    }
    render() {
        let {
            label,
            required,
            value,
            options,
            className,
            errors,
            onChange,
            placeholder,
            inline = false,
            fieldStyle,
            onMySearchChange,
            ...other
        } = this.props;
        const hasErrors = Array.isArray(errors) && errors.length;

        label = required ? label + "*" : label;

     

        return (
            <Form.Field inline={inline} style={{ position: "relative", ...fieldStyle }}>

                    {/*<span className="InputLabel">{label}</span>*/}
                    <Dropdown
                        className={classnames("defaultCss", "material", { error: hasErrors }, className)}
                        selection
                        onChange={onChange}
                        onSearchChange={onMySearchChange}
                        value={value}
                        placeholder={placeholder || label || "-----------"}
                        options={options}
                        ref={i => (this.dropdown = i)}
                        {...other}
                    />

                <div
                    className={classnames("funky-placeholder", {
                        animePlaceHolderDatePicker: value != undefined && value != "",
                    })}
                >
                    {label}
                </div>
                <div style={{ maxHeight: hasErrors ? "400px" : "0px", transition: "max-height 0.5s" }}>
                    {hasErrors ? errors.map(err => <p className="input-error-message">{err.message}</p>) : null}
                </div>
            </Form.Field>
        );
    }

    focus() {
        // if (this.dropdown.searchRef) {
        //     this.dropdown.searchRef.focus();
        // }
    }
}
