import React from 'react';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import { backendURL } from '../config';
import { store } from '../datastore/store';

import el from '../../public/locales/el.json';
import en from '../../public/locales/en.json';

export const getDocumentThumbnail = (doc) => {
    // console.log('in Doc thumbnail', doc);
    const iconStyle = { fontSize: '100px', lineHeight: '100px' };
    if ('file' in doc) {
        const fileEnding = doc.file.split('.').pop();
        if (!fileEnding) return <Icon name="file outline" />;

        if (fileEnding.match(/(jpe?g|png|gif)/)) {
            const state = store.getState();
            const activePersonId = getProperty(state, 'authentication.activePersonId');
            const access_token = getProperty(state, 'authentication.access_token');
            return (
                <img
                    style={{ maxWidth: '100px' }}
                    src={`${backendURL}persons/${activePersonId}/download-document/${doc.file}?X-Authorization=Bearer ${access_token}`}
                    // onClick={() =>
                    //     console.log(
                    //         'clicked on',
                    //         `${backendURL}persons/${activePersonId}/download-document/${doc.file}?X-Authorization=Bearer ${access_token}`
                    //     )
                    // }
                />
            );
        }
        if (fileEnding.match(/pdf/)) {
            return <Icon name="file pdf outline" style={iconStyle} />;
        }
        if (fileEnding.match(/(docx?|odt)/)) {
            return <Icon name="file word outline" style={iconStyle} />;
        }
        return <Icon name="file outline" style={iconStyle} />;
    }
};

export const getDocFileType = (doc) => {
    if ('file' in doc) {
        const fileEnding = doc.file.split('.').pop();
        if (!fileEnding) return <Icon name="file outline" />;

        if (fileEnding.match(/(jpe?g|png|gif)/)) {
            return 'img';
        }
        if (fileEnding.match(/pdf/)) {
            return 'pdf';
        }
        if (fileEnding.match(/txt/)) {
            return 'txt';
        }
        if (fileEnding.match(/(docx?|odt)/)) {
            return 'office';
        }
        return 'other';
    }
};

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const deepMerge = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                deepMerge(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return deepMerge(target, ...sources);
};

const isObject = (elem) => {
    return typeof elem === 'object' && !Array.isArray(elem);
};

const localeTexts = {
    el: el,
    en: en,
};
export const setLocale = (locale) => {
    if (!locale in localeTexts) throw 'Invalid Locale';
    localStorage.setItem('lang', locale);
    moment.locale(locale);
    store.dispatch({ type: 'SET_LANG', payload: locale });
    store.dispatch({ type: 'CLEAR_SYNC_CACHE' });
    T.setTexts(localeTexts[locale], {
        notFound: (key) => {
            console.warn(`Translation for ${key} not found!`);
            return key;
        },
    });
};
