let style = {
    container: {
        position: "relative",
        fontSize: "95%",
        fontWeight: 300,
        padding: "0px 0",
        width: "95%",
        margin: "0 auto",
    },
    containerBefore: {
        content: "",
        position: "absolute",
        top: 0,
        left: 30,
        height: "100%",
        width: 6,
        background: "white",
        // boxShadow: 'inset 0 20px 15px -15px #dbedf0',
    },
    containerAfter: {
        content: "",
        display: "table",
        clear: "both",
    },
    event: {
        position: "relative",
        margin: "25px 0",
        paddingLeft: 75,
        minHeight: "75px",
    },
    /**
     * Bubble Arrow
     **/
    eventAfter: {
        position: "absolute",
        top: "15px",
        left: "-10px",
        //   width: '10px',
        //   height: '10px',
        borderTop: "10px solid transparent",
        borderBottom: "10px solid transparent",
        borderRight: "10px solid white",
        filter: "drop-shadow( -3px 0 3px rgba(0,0,0,0.16) )",
    },
    eventType: {
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: "50%",
        width: 55,
        height: 55,
        marginLeft: 2,
        //background: '#B8E986',
        border: "4px solid white",
    },
    materialIcons: {
        display: "block",
        width: 32,
        height: 32,
        position: "relative",
        left: "50%",
        top: "50%",
        marginLeft: -8,
        marginTop: -8,
    },
    eventContainer: {
        position: "relative",
    },
    eventContainerBefore: {
        top: 24,
        left: "100%",
        borderColor: "transparent",
        borderLeftColor: "#ffffff",
    },
    time: {
        color: "#009EE0",
        marginBottom: 3,
        fontSize: "90%",
    },
    message: {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1)",
        marginTop: "1em",
        marginBottom: "1em",
        lineHeight: 1.6,
        padding: "0.5em 1em",
        borderRadius: "5px",
        marginRight: "10px",
    },
    messageAfter: {
        clear: "both",
        content: "",
        display: "table",
    },
    actionButtons: {
        float: "right",
        textAlign: "right",
        marginTop: -20,
    },
    card: {
        marginLeft: "15px",
        boxShadow: "rgba(0, 0, 0, 0.117647) 3px 3px 13px 2px",
        backgroundColor: "rgb(255, 255, 255)",
        borderRadius: "5px",
    },
    cardTitle: {
        backgroundColor: "#7BB1EA",
        backgroundColor: "white",
        borderRadius: "5px",
        padding: 10,
        color: "#fff",
    },
    measurementType: {
        color: "rgba(0,0,0,0.87)",
    },
    cardBody: {
        backgroundColor: "#ffffff",
        marginBottom: "1em",
        lineHeight: 1.6,
        padding: "1.5em 0.6em",
        minHeight: 40,
        color: "#9a9b9c",
        fontSize: "90%",
    },
    eventValue: {
        fontSize: "1.4em",
        lineHeight: "1.6em",
        //   color: '#1171b4',
        color: "#009EE0",
        fontWeight: "bold",
        textAlign: "right",
        paddingRight: "10px",
        //   whiteSpace: 'nowrap',
    },
    eventIcon: {
        filter: "contrast(100)",
        width: 40,
        height: 40,
        position: "absolute",
        top: -12,
        left: -12,
    },
};

let style2 = {
    container: {
        position: "relative",
        fontSize: "95%",
        fontWeight: 300,
        padding: "0px 0",
        width: "98%",
        margin: "0 auto",
    },
    containerBefore: {
        content: "",
        position: "absolute",
        top: 0,
        left: "155px",
        height: "100%",
        width: 6,
        background: "#e8e8e8",
        zIndex: 1,
        // boxShadow: 'inset 0 20px 15px -15px #dbedf0',
    },
    containerAfter: {
        content: "",
        display: "table",
        clear: "both",
    },
    event: {
        position: "relative",
        margin: "25px 0",
        paddingLeft: 140,
        minHeight: "75px",
        display: 'flex',
    },
    /**
     * Bubble Arrow
     **/
    eventAfter: {
        position: "absolute",
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '50%',
        border: '2px solid #2EAADC',
        background: 'white',
        width: '10px',
        height: '10px',
        left: '-22px',
        zIndex: '2',
        // top: "15px",
        // left: "-10px",
        // //   width: '10px',
        // //   height: '10px',
        // borderTop: "10px solid transparent",
        // borderBottom: "10px solid transparent",
        // borderRight: "10px solid white",
        // filter: "drop-shadow( -3px 0 3px rgba(0,0,0,0.16) )",
    },
    eventType: {
        position: "absolute",
        top: 0,
        left: 0,
        // borderRadius: '50%',
        width: 120,
        height: 30,
        marginLeft: 2,
        //background: '#B8E986',
        border: "4px solid white",
    },
    eventTypeAfter: {
        position: "absolute",
        top: 0,
        right: 0,
        // borderRadius: '50%',
        width: 30,
        height: 30,
        marginLeft: 2,
        //background: '#B8E986',
        border: "4px solid white",
    },
    materialIcons: {
        display: "block",
        width: 32,
        height: 32,
        position: "relative",
        left: "50%",
        top: "50%",
        marginLeft: -8,
        marginTop: -8,
    },
    eventContainer: {
        position: "relative",
    },
    eventContainerBefore: {
        top: 24,
        left: "100%",
        borderColor: "transparent",
        borderLeftColor: "#ffffff",
    },
    time: {
        color: "#009EE0",
        marginBottom: 3,
        fontSize: "90%",
    },
    message: {
        flexGrow: 1,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1)",
        marginTop: "1em",
        marginBottom: "1em",
        lineHeight: 1.6,
        padding: "0.5em 1em",
        borderRadius: "5px",
        marginRight: "10px",
    },
    messageAfter: {
        clear: "both",
        content: "",
        display: "table",
    },
    actionButtons: {
        float: "right",
        textAlign: "right",
        marginTop: -20,
    },
    card: {
        marginLeft: "35px",
        boxShadow: "rgba(0, 0, 0, 0.317647) 3px 3px 13px 2px",
        backgroundColor: "rgb(255, 255, 255)",
        borderRadius: "5px",
        flexGrow: 1,
    },
    cardTitle: {
        backgroundColor: "#7BB1EA",
        backgroundColor: "white",
        borderRadius: "5px",
        padding: 10,
        color: "#fff",
    },
    measurementType: {
        color: "rgba(0,0,0,0.87)",
    },
    measurementCategory: {
        color: "rgba(0,0,0,0.57)",
    },
    cardBody: {
        backgroundColor: "#ffffff",
        marginBottom: "1em",
        lineHeight: 1.6,
        padding: "1.5em 0.6em",
        minHeight: 40,
        color: "#9a9b9c",
        fontSize: "90%",
    },
    eventValue: {
        fontSize: "1.4em",
        lineHeight: "1.6em",
        //   color: '#1171b4',
        color: "#009EE0",
        fontWeight: "bold",
        textAlign: "right",
        paddingRight: "10px",
        //   whiteSpace: 'nowrap',
    },
    eventIcon: {
        filter: "contrast(100)",
        width: 40,
        height: 40,
        position: "absolute",
        top: -12,
        left: -12,
    },
};

export default style2;
