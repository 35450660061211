import React, { Component } from 'react';
import T from 'i18n-react';

import { Symptom, UserAllergy } from 'Mib/lib/models';
import {
    TextAreaInput,
    CalendarInput,
    DropDownInput,
    MibButton,
    RadioButtonGroup,
    RemoteInput,
    TagRemoteInput,
} from 'Mib/components/inputs';
import { FormModal } from 'Mib/components/modals';
import ModelForm from 'Mib/components/forms/model_form';
import { Create, Update, Delete } from 'Mib/actions';
import { ConvertOptionsWithKey } from 'Mib/lib/convertOptionsWithKey';

const SymptomRemoteInput = RemoteInput({
    modelName: 'symptoms',
    endPoint: 'symptoms',
    Model: Symptom,
});

export class AllergyForm extends Component {
    constructor() {
        super();
        this.state = { model: {}, loading: false, allergiesWithKey: {} };
        this.allergyLevels = [
            { key: 0, text: '', value: '' },
            { key: 1, text: '+', value: '+' },
            { key: 2, text: '++', value: '++' },
            { key: 3, text: '+++', value: '+++' },
            { key: 4, text: '++++', value: '++++' },
            { key: 5, text: '+++++', value: '+++++' },
        ];

        this.radioItems = [
            { key: 1, label: T.translate('fromExam'), value: '0' },
            { key: 2, label: T.translate('fromPersonalKnowledge'), value: '1' },
        ];
    }

    onSymptomsChange(props, event, symptom = nul) {
        let { model, onChange, validator, onValidate } = props;
        let newVal = {};
        newVal = symptom.value.map((item) => {
            return !isNaN(item) ? { id: item } : { name: item };
        });

        model.setRelationship('symptoms', newVal);
        onChange(model);
    }

    getSymptomsValue(model, props) {
        let symptom =
            model['symptoms'] &&
            Object.keys(model['symptoms']).length != 0 &&
            model['symptoms'].map((item) => {
                return item['id'] ? item['id'] : item['name'];
            });
        return symptom;
    }

    render() {
        let { action } = this.state;
        let allergiesWithKey = this.state.allergiesWithKey;
        const formItems = [
            {
                Element: DropDownInput,
                fieldName: 'allergy',
                options: allergiesWithKey,
                search: true,
                label: 'allergies.allergy',
                attrib: 'id',
                multiple: false,
            },
            {
                Element: SymptomRemoteInput,
                fieldName: 'symptoms',
                label: 'allergies.symptoms',
                onChange: this.onSymptomsChange.bind(this),
                getElementValue: this.getSymptomsValue.bind(this),
                attrib: 'id',
                allowAdditions: true,
                multiple: true,
            },
            {
                Element: CalendarInput,
                fieldName: 'start',
                label: 'allergies.startDate',
            },
            {
                Element: CalendarInput,
                fieldName: 'end',
                label: 'allergies.endDate',
            },
            {
                Element: DropDownInput,
                fieldName: 'allergy_level',
                options: this.allergyLevels,
                label: 'allergies.sensitivity',
            },
            {
                Element: RadioButtonGroup,
                fieldName: 'source',
                options: this.radioItems,
                label: 'allergies.source',
            },
            {
                Element: TagRemoteInput,
                label: 'measurements.tags',
                fieldName: 'tags',
                attrib: 'tag',
                multiple: true,
            },
            {
                Element: TextAreaInput,
                label: 'measurements.comments',
                fieldName: 'comments',
            },

            // {Element: TextAreaInput, fieldName: "allergy", label: "comments"}
        ];

        return (
            <FormModal
                ref={(i) => (this.modal = i)}
                title={T.translate('allergies.create')}
                loading={this.state.loading}
            >
                <ModelForm
                    style={{ padding: '10%' }}
                    model={this.state.model}
                    validator={UserAllergy.Validator}
                    onChange={(model) => this.setState({ model: model })}
                    items={formItems}
                    onValidate={(errors) => this.setState({ errors })}
                    errors={this.state.errors}
                />
                <div className="mib-modal-footer controls">
                    {action === 'edit' && (
                        <MibButton
                            label={T.translate('buttons.delete')}
                            type="danger"
                            onClick={this.delete.bind(this)}
                        />
                    )}
                    <MibButton label={T.translate('buttons.close')} type="warning" onClick={this.close.bind(this)} />
                    <MibButton label={T.translate('buttons.save')} type="success" onClick={this.save.bind(this)} />
                </div>
            </FormModal>
        );
    }
    open(allergy) {
        let model = UserAllergy();
        allergy && Object.assign(model, allergy);
        let allergies = this.props.allergies;
        let allergiesWithKey = ConvertOptionsWithKey(allergies);
        this.setState({
            model: model,
            action: allergy === undefined ? 'create' : 'edit',
            allergiesWithKey: allergiesWithKey,
        });
        this.modal.open();
    }
    close() {
        this.setState({ model: {}, errors: [], action: null, loading: false });
        this.modal.close();
    }
    save() {
        // If we are already saving, don't do it again
        if (this.saving) return null;
        this.saving = true;
        this.setState({ loading: true });
        if (this.state.action === 'edit') {
            Update(this.state.model)
                .then((res) => {
                    this.saving = false;
                    this.setState({ loading: false });
                    this.close();
                })
                .catch((e) => {
                    this.saving = false;
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        } else {
            Create(this.state.model)
                .then((res) => {
                    this.saving = false;
                    this.setState({ loading: false });
                    this.close();
                })
                .catch((e) => {
                    this.saving = false;
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        }
    }
    delete() {
        Delete(this.state.model).then((res) => {
            this.setState({ loading: false });
            this.close();
        });
    }
}
