import React from 'react';
import { Message } from 'semantic-ui-react';
import T from 'i18n-react';

export default function ({ create }) {
    return (
        <Message
            icon="info circle"
            header={T.translate('measurements.no-measurements')}
            onClick={create}
            content={T.translate('measurements.create-prompt')}
            info
            style={{ marginBottom: '90px', marginTop: '30px', cursor: 'pointer' }}
        />
    );
}
