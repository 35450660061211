import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import {
    TextInput,
    DropDownInput,
    CalendarInput,
    TagRemoteInput,
    RemoteInput,
    RadioButtonGroup,
    TextAreaInput,
} from 'Mib/components/inputs';
import { Drug, Allergy, Symptom } from 'Mib/lib/models';
import { store } from '../datastore/store';
import { NoteReminderInput, NoteToggleInput } from '../components/inputs/text';

const SymptomRemoteInput = RemoteInput({ modelName: 'symptoms', endPoint: 'symptoms', Model: Symptom });
const DrugRemoteInput = RemoteInput({ modelName: 'drugs', endPoint: 'drugs', Model: Drug });
const AllergyRemoteInput = RemoteInput({ modelName: 'allergies', endPoint: 'allergies', Model: Allergy });

export const openMeasurementModal = ({ id, type, unitId, showTime }) => {
    if (showTime === undefined && type != null && type != 'heights') {
        showTime = true;
    }
    store.dispatch({
        type: 'SEND_SIGNAL',
        payload: {
            signalType: 'MEASUREMENT_MODAL',
            unitId,
            id,
            type,
            showTime,
        },
    });
};

export const openLabExamEventModal = ({ id, type, unitId, showTime, lab_type }) => {
    if (showTime === undefined && type != null && type != 'heights') {
        showTime = true;
    }
    store.dispatch({
        type: 'SEND_SIGNAL',
        payload: {
            signalType: 'MEASUREMENT_MODAL',
            unitId,
            id,
            type,
            showTime,
            lab_type,
        },
    });
};

export const openAllergyModal = (id = null) => {
    const allergyLevels = [
        { key: 0, text: '', value: '' },
        { key: 1, text: '+', value: '+' },
        { key: 2, text: '++', value: '++' },
        { key: 3, text: '+++', value: '+++' },
        { key: 4, text: '++++', value: '++++' },
        { key: 5, text: '+++++', value: '+++++' },
    ];

    const radioItems = [
        { key: 1, label: T.translate('fromExam'), value: '0' },
        { key: 2, label: T.translate('fromPersonalKnowledge'), value: '1' },
    ];
    // {
    //     Element: DropDownInput,
    //     fieldName: "allergy",
    //     options: allergiesWithKey,
    //     search: true,
    //     label: "allergies.allergy",
    //     attrib: "id",
    //     multiple: false,
    // },
    const formItems = [
        { Element: AllergyRemoteInput, fieldName: 'allergy', label: 'allergies.allergy', attrib: 'id' },

        // {
        //     Element: SymptomRemoteInput,
        //     fieldName: "symptoms",
        //     label: "allergies.symptoms",
        //     attrib: "id",
        //     allowAdditions: false,
        //     multiple: true,
        // },
        { Element: CalendarInput, fieldName: 'start', label: 'allergies.startDate' },
        { Element: CalendarInput, fieldName: 'end', label: 'allergies.endDate' },
        {
            Element: DropDownInput,
            fieldName: 'allergy_level',
            options: allergyLevels,
            label: 'allergies.sensitivity',
        },
        { Element: RadioButtonGroup, fieldName: 'source', options: radioItems, label: 'allergies.source' },
        { Element: TagRemoteInput, label: 'measurements.tags', fieldName: 'tags', attrib: 'tag', multiple: true },
        { Element: TextAreaInput, label: 'measurements.comments', fieldName: 'comments' },

        // {Element: TextAreaInput, fieldName: "allergy", label: "comments"}
    ];

    store.dispatch({
        type: 'SEND_SIGNAL',
        payload: {
            signalType: 'MEASUREMENT_MODAL',
            showTime: true,
            formItems,
            skipUnits: true,
            id: id,
            type: 'person-allergies',
            modalTitle: T.translate('measurements.genitive.allergy'),
        },
    });
};

export const openDrugsModal = (id = null) => {
    const radioItems = [
        { key: 0, label: T.translate('medications.sources.fromDoctor'), value: '0' },
        { key: 1, label: T.translate('medications.sources.fromPharmacist'), value: '1' },
        { key: 2, label: T.translate('medications.sources.fromPersonalKnowledge'), value: '2' },
        { key: 3, label: T.translate('medications.sources.fromFriend'), value: '3' },
        { key: 4, label: T.translate('medications.sources.other'), value: '4' },
    ];
    const formItems = [
        { Element: DrugRemoteInput, fieldName: 'drug', label: 'medications.drug', attrib: 'id' },
        { Element: CalendarInput, fieldName: 'start', label: 'medications.startDate' },
        { Element: CalendarInput, fieldName: 'end', label: 'medications.endDate' },
        { Element: TagRemoteInput, label: 'measurements.tags', fieldName: 'tags', attrib: 'tag', multiple: true },
        { Element: RadioButtonGroup, fieldName: 'source', options: radioItems, label: 'allergies.source' },
        { Element: TextAreaInput, label: 'measurements.comments', fieldName: 'comments' },

        // {Element: TextAreaInput, fieldName: "allergy", label: "comments"}
    ];

    store.dispatch({
        type: 'SEND_SIGNAL',
        payload: {
            signalType: 'MEASUREMENT_MODAL',
            showTime: true,
            formItems,
            skipUnits: true,
            id: id,
            type: 'person-drugs',
            modalTitle: T.translate('measurements.genitive.drug'),
        },
    });
};

export const openPulsesModal = ({ id, _, unitId }) => {
    const state = store.getState();
    const measures = {};
    Object.entries(getProperty(state, 'dataStore.graph.measure-units') || {}).forEach(([key, unit]) => {
        if (unit.measureUnitType && unit.measureUnitType.name === 'PRESSURE') {
            measures[unit.id] = unit;
        }
    });
    const measureUnitOptions = Object.values(measures).map((mu) => ({
        key: mu.id,
        value: mu.id,
        text: mu.name,
    }));
    const formItems = [
        { Element: TextInput, label: 'measurements.plural.pulses', fieldName: 'pulses', replaceComma: true },
        { Element: TextInput, label: 'measurements.pressures.systolic', fieldName: 'systolic', replaceComma: true },
        { Element: TextInput, label: 'measurements.pressures.diastolic', fieldName: 'diastolic', replaceComma: true },
        {
            Element: DropDownInput,
            label: 'measurements.measure-unit',
            fieldName: 'measureUnit',
            options: measureUnitOptions,
            attrib: 'id',
        },
        {
            Element: CalendarInput,
            label: 'measurements.date',
            fieldName: 'date',
            time: true,
            showMonthDropdown: true,
            showYearDropdown: true,
        },
        { Element: TextInput, label: 'measurements.comments', fieldName: 'comments' },
        { Element: TagRemoteInput, label: 'measurements.tags', fieldName: 'tags', attrib: 'tag', multiple: true },
    ];
    store.dispatch({
        type: 'SEND_SIGNAL',
        payload: {
            signalType: 'MEASUREMENT_MODAL',
            unitId: unitId,
            showTime: true,
            formItems,
            id,
            type: 'pulses',
            modalTitle: T.translate('measurements.genitive.pulses'),
        },
    });
};

export const openPressuresModal = ({ type, id, unitId }) => {
    const state = store.getState();
    const measures = {};
    Object.entries(getProperty(state, 'dataStore.graph.measure-units') || {}).forEach(([key, unit]) => {
        if (unit.measureUnitType && unit.measureUnitType.name === 'PRESSURE') {
            measures[unit.id] = unit;
        }
    });
    const measureUnitOptions = Object.values(measures || {}).map((mu) => ({
        key: mu.id,
        value: mu.id,
        text: mu.abbreviation,
    }));

    const formItems = [
        { Element: TextInput, label: 'measurements.pressures.systolic', fieldName: 'systolic', replaceComma: true },
        { Element: TextInput, label: 'measurements.pressures.diastolic', fieldName: 'diastolic', replaceComma: true },
        { Element: TextInput, label: 'measurements.plural.pulses', fieldName: 'pulses', replaceComma: true },
        {
            Element: DropDownInput,
            label: 'measurements.measure-unit',
            fieldName: 'measureUnit',
            options: measureUnitOptions,
            attrib: 'id',
        },
        {
            Element: CalendarInput,
            label: 'measurements.date',
            fieldName: 'date',
            time: true,
            showMonthDropdown: true,
            showYearDropdown: true,
        },
        { Element: TextInput, label: 'measurements.comments', fieldName: 'comments' },
        { Element: TagRemoteInput, label: 'measurements.tags', fieldName: 'tags', attrib: 'tag', multiple: true },
    ];

    store.dispatch({
        type: 'SEND_SIGNAL',
        payload: {
            signalType: 'MEASUREMENT_MODAL',
            unitId: unitId,
            showTime: true,
            formItems,
            id,
            type,
        },
    });
};

export const openNoteModal = ({ start, end, id }) => {
    const state = store.getState();

    const formItems = [
        { Element: TextInput, label: 'notes.title', fieldName: 'note' },
        { Element: CalendarInput, label: 'date', fieldName: 'date', time: true, defaultValue: start && moment(start) },

        { Element: NoteToggleInput, label: 'notes.reminder', fieldName: 'reminder_flag' },

        { Element: NoteReminderInput, label: '', defaultValue: '1|h', fieldName: 'reminder' },
        { Element: TextInput, label: 'notes.comments', fieldName: 'comments' },

        // { Element: CalendarInput, label: "end", fieldName: "end", time: true, defaultValue: end && moment(end) },
    ];
    store.dispatch({
        type: 'SEND_SIGNAL',
        payload: {
            signalType: 'MEASUREMENT_MODAL',
            showTime: true,
            formItems,
            skipUnits: true,
            id: id,
            type: 'person-notes',
            modalTitle: T.translate('notes.genitive'),
        },
    });
};

export const openNotificationModal = (content, title) => {
    const state = store.getState();

    store.dispatch({
        type: 'SEND_SIGNAL',
        payload: {
            signalType: 'TEXT_MODAL',
            showTime: true,
            content,
            title,
            skipUnits: true,
            type: 'notification',
            modalTitle: T.translate('notes.genitive'),
        },
    });
};
