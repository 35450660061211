import { store } from "../datastore/store";

let messageIds = 0;

// notify({ info: true, header: "hello", content: "blblblbla" }, false);

export const notify = (msg, timeout = 4000) => {
    msg._id = messageIds++;
    store.dispatch({ type: "ADD_MESSAGE", payload: msg });

    if (timeout) {
        setTimeout(() => removeMessage(msg), timeout);
    }
};

export const removeMessage = msg => {
    store.dispatch({ type: "REMOVE_MESSAGE", payload: msg._id });
};
