import React from 'react';
import { Accordion, Segment } from 'semantic-ui-react';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import T from 'i18n-react';
import { store } from 'Mib/datastore/store';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import { AccordionItem } from 'Mib/routes/health_indicators/covid/accordionItem';

class _QuestionsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.generateQuestions = this.generateQuestions.bind(this);
        this.generateQuestions();
    }

    generateQuestions() {
        if (this.props.covid19 != undefined) {
            if (!this.haveAnswers()) {
                this.openPopUp();
            }
        }

        let questions =
            this.props.covid19 &&
            this.props.covid19.survey.surveyQuestions.sort((a, b) =>
                Number(a.question.id) < Number(b.question.id) ? -1 : 1
            );

        let currentSurveyId = this.props.covid19 && this.props.covid19.id;

        let currentSurvey = { id: currentSurveyId };
        this.surveyQuestions =
            questions &&
            questions.map((quest) => {
                return {
                    key: quest.id,
                    title: quest.question.text,
                    content: {
                        content: (
                            <AccordionItem
                                question={quest}
                                currentSurvey={currentSurvey}
                                questionHint={quest.question.hint}
                            />
                        ),
                    },
                };
            });
    }

    openPopUp() {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'CHECKUP-SURVEY',
                survey_id: 'covid19',
            },
        });
    }

    haveAnswers() {
        let haveData = this.props.covid19.answers.length != 0;
        return haveData;
    }

    render() {
        this.generateQuestions();

        return (
            <Segment>
                <Accordion defaultActiveIndex={[0]} exclusive={false} panels={this.surveyQuestions} />
            </Segment>
        );
    }
}

const mapStateToProps = ({ dataStore }) => {
    let personSurveys = getProperty(dataStore.graph, 'person-surveys') || [];
    let covid19 = Object.values(personSurveys).find((s) => s.survey.name === 'Covid19');

    return {
        covid19: covid19,
    };
};

// export const QuestionsContainer = subscribeWithLoader(
//     _QuestionsContainer,
//     mapStateToProps,
//     ["person-surveys-by-survey-id/covid19?"]
// );

export const QuestionsContainer = connect(mapStateToProps)(_QuestionsContainer);
