import React from 'react';
import { connect } from 'react-redux';

import { getProperty } from 'dot-prop';

import GeneralMetricContainer from 'Mib/components/metric_panels/general_metric';
import { store } from '../../datastore/store';
import { FilteredMetricComponent } from './filtered_metric';

class _personLabContainer extends FilteredMetricComponent {
    constructor(props) {
        super(props);
        this.state = { chart_config: {} };

        this.open = this.open.bind(this);
        const labName = (props.labExam && props.labExam.name) || '';
        this.dataPropName = 'personLabs';

        this.config = {
            descriptor: 'result',
            name: labName,
            dateDescriptor: 'date',
            valueOffset: 1,
            onGraphPointClicked: this.open,
            merge_config: ((props, conf) => {
                let _mua = Object.values(props.personLabs)[0];
                let mua = getProperty(_mua, 'measureUnit.abbreviation');
                let name = (props.labExam && props.labExam.name) || '';
                let code = (props.labExam && props.labExam.code) || '';
                return {
                    name: (props.labExam && props.labExam.name) || '',
                    yAxis: {
                        title: {
                            text: mua ? `${code} (${mua})` : `${code}`,
                        },
                    },
                    title: {
                        text: (props.labExam && props.labExam.name) || '',
                    },
                    series: [
                        {
                            ...conf.series[0],
                            name: (props.labExam && props.labExam.name) || '',
                            point: {
                                events: {
                                    click: (event) =>
                                        this.open(event.point.id, event.point.type, props.match.params.labId), // props.graphPointClick(event.point.id, event.point.type,props.labId),
                                },
                            },
                        },
                    ],
                };
            }).bind(this),
        };
    }

    render() {
        const { personLabs, measures, labExam, tagFilters, children, ...rest } = this.props;
        const { labId } = this.props.match.params;
        const { chart_config, personLabs: filteredLabs } = this.state;
        const labName = (labExam && labExam.name) || '';

        return (
            <GeneralMetricContainer
                {...rest}
                title="my lab exams"
                data={filteredLabs}
                measureUnits={measures}
                dataType="lab-exam-events"
                chart_config={chart_config}
                name={labName}
                labId={labId}
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
            />
        );
    }

    open(id = undefined, type = undefined, lab_type = null) {
        const { unitId } = this.state;
        console.log('open', unitId);
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'MEASUREMENT_MODAL',
                id,
                type,
                unitId,
                lab_type,
            },
        });
    }
}

const mapStateToProps = (state, props) => {
    const { labId } = props.match.params;

    const allLabExams = state.dataStore.graph['lab-exam-events'];
    const labExamEvents = {};
    if (allLabExams)
        Object.entries(allLabExams).forEach(([key, labExamEvent]) => {
            if (labExamEvent.labExam && labExamEvent.labExam.id === labId) {
                labExamEvents[labExamEvent.id] = labExamEvent;
            }
        });

    // let lab = state.dataStore.graph["lab-exams"] && state.dataStore.graph["lab-exams"][labId];
    const lab = getProperty(state.dataStore.graph, `lab-exams.${labId}`);

    // let measurUnitType =  Object.values(labExamEvents).length>0 && Object.values(labExamEvents)[0].labExam.measureUnitType.name;
    const measurUnitType = lab && lab.measure_unit_type_id;
    const allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];

    const labMeasureUnits = {};
    if (allmeasureUnits)
        Object.entries(allmeasureUnits).forEach(([key, unit]) => {
            if (unit.measureUnitType && unit.measureUnitType.id === measurUnitType) {
                labMeasureUnits[unit.id] = unit;
            }
        });

    // let lab = state.dataStore.graph["lab-exams"] && state.dataStore.graph["lab-exams"][labId];
    // console.log('measure lab unites', labMeasureUnits);

    return {
        personLabs: labExamEvents,
        measures: labMeasureUnits,
        labExam: lab,
        filters: state.filters,
    };
};

// const _personLabContainerSub = subscribe(_personLabContainer, ['lab-exams', 'measure-units'])

export default connect(mapStateToProps)(_personLabContainer);
