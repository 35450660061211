import React, { Component } from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, Radio, Popup } from 'semantic-ui-react';

import { MenuPanel } from 'Mib/components/containers';
import { subscribeWithLoader } from 'Mib/lib/subscribe';

class _AllergyPanel extends Component {
    constructor(props) {
        super();
        const { defaultShowAll } = props;
        this.state = { showAll: defaultShowAll || false };
    }

    filterData(data = {}, props = this.props, valueAttr = 'result', dateAttr = 'date') {
        const {
            filters: { tags },
        } = props;
        const filtered = data.filter((o) => {
            let res = true;

            if (tags && tags.length && o.tags) {
                res = o.tags.some((tag) => tags.some((t) => t.id === tag.id));
            }
            return res;
        });
        return filtered;
    }

    renderHeader() {
        const { showAll } = this.state;
        const titleLabel = showAll
            ? `${T.translate('allergies.all')} ${T.translate('measurements.plural.allergies')}`
            : `${T.translate('allergies.active')} ${T.translate('measurements.plural.allergies')}`;
        const title = {
            label: titleLabel,
            // onClick: _ => this.props.history.push("/allergies"),
            icon: 'icon-allergy',
        };
        return (
            <Menu.Item onClick={title.onClick} className="menu-title">
                <Icon name={title.icon} style={{ fontSize: '1.3em', marginLeft: 0 }} />
                <span className="title">{title.label}</span>
                <div className="right">
                    <Popup
                        trigger={
                            <Radio toggle checked={showAll} onChange={(e, v) => this.setState({ showAll: v.checked })} />
                        }
                        content={T.translate('allergies.showAll')}
                    />
                </div>
            </Menu.Item>
        );
    }

    render() {
        const { showAll } = this.state;
        let { allergies = {} } = this.props;
        const {
            //  history,
            openAllergyForm,
            loading,
            syncing,
            ...rest
        } = this.props;
        allergies = showAll
            ? Object.values(allergies)
            : Object.values(allergies).filter((allergy) => allergy.end === null);
        const filteredAllergies = this.filterData(allergies);
        const items = filteredAllergies.map((allergy) => ({
            label: allergy.allergy_name || allergy.allergy.name,
            key: allergy.id,
            onClick: () => openAllergyForm(allergy),
            icon: allergy.end ? 'time' : null,
            style: allergy.end ? { color: 'gray' } : null,
        }));
        const itemsIfEmpty = [
            {
                key: 0,
                label: <Menu.Item onClick={() => openAllergyForm()}>{T.translate('allergies.no-data')}</Menu.Item>,
            },
        ];
        return (
            <MenuPanel
                className="red"
                inverted
                loading={loading}
                syncing={syncing}
                title={this.renderHeader()}
                {...rest}
                footer={
                    <Menu.Item onClick={() => openAllergyForm()} className="menu-footer">
                        {T.translate('allergies.create-new')}
                        <Icon className="add circle" />
                    </Menu.Item>
                }
                items={items.length || loading ? items : itemsIfEmpty}
            />
        );
    }
}

const mapStateToProps = ({ dataStore, filters }) => {
    return {
        allergies: getProperty(dataStore, 'graph.person-allergies'),
        filters: filters,
    };
};

export const AllergyPanel = withRouter(subscribeWithLoader(_AllergyPanel, mapStateToProps, ['person-allergies']));
// const AllergyPanelTemp = subscribe(_AllergyPanel,['person-allergies']);

// export const AllergyPanel = connect(mapStateToProps)(AllergyPanelTemp);
