import React, { Component } from 'react';

const NumberOfSegments = {
    'mibcolor-news': 13,
    'mibcolor-oximetries': 7,
    'mibcolor-pulses': 0,
    'mibcolor-pressures': 8,
    'mibcolor-weights': 3,
    'mibcolor-temperatures': 4,
    'mibcolor-lab_exams': 4,
    'mibcolor-meds': 0,
    'mibcolor-cloud': 0,
    'mibcolor-algorithms': 0,
    'mibcolor-alcohol': 4,
    'mibcolor-allergies': 7,
    'mibcolor-stamina': 7,
    'mibcolor-health_cross': 0,
    'mibcolor-files': 2,
    'mibcolor-excersise': 0,
    'mibcolor-library': 15,
    'mibcolor-bmi': 26,
    'mibcolor-connections': 4,
    'mibcolor-devices': 13,
    'mibcolor-emergency': 2,
    'mibcolor-files-official': 5,
    'mibcolor-files-lab_exams': 14,
    'mibcolor-meds-alt': 4,
    'mibcolor-genetic': 3,
    'mibcolor-woman': 0,
    'mibcolor-health-check': 9,
    'mibcolor-smoking': 6,
    'mibcolor-profile': 4,
    'mibcolor-rowing': 0,
    'mibcolor-mountain-bike': 0,
    'mibcolor-files-personal': 10,
    'mibcolor-nursings': 3,
    'mibcolor-family': 7,
    'mibcolor-child': 0,
    'mibcolor-walking': 0,
    'mibcolor-biking': 0,
    'mibcolor-person': 4,
    'mibcolor-person-plus': 6,
    'mibcolor-providers': 0,
    'mibcolor-hospital': 9,
    'mibcolor-skiing': 0,
    'mibcolor-somatometrika': 6,
    'mibcolor-activities-other': 4,
    'mibcolor-running': 0,
    'mibcolor-sleeping': 8,
    'mibcolor-surgeon': 10,
    'mibcolor-waist_to_heist': 6,
    'mibcolor-heights': 9,
    'mibcolor-waists': 5,
    'mibcolor-somatometrics-alt': 3,
    'mibcolor-activities': 0,
    'mibcolor-health_folder': 0,
};
export default class I extends Component {
    getSegments(className) {
        const number = NumberOfSegments[className] || 0;
        const res = [];
        for (let i = 0; i < number; i++) {
            res.push(<span className={`path${i + 1}`} key={i} />);
        }
        return res;
    }

    render() {
        const { className, ...props } = this.props;
        return (
            <span className={`mib-icon ${className}`} {...props}>
                {this.getSegments(className)}
            </span>
        );
    }
}
