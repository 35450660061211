import sortOn from 'sort-on';
import moment from 'moment';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';

require('Mib/components/generic/charts.scss');

export const generateChartData = (
    data,
    { descriptor = 'result', name = '', dateDescriptor = 'date', valueOffset = 10, onGraphPointClicked, muAbbr },
    unitId = null
) => {
    let dates = [],
        values = [],
        ids = [],
        types = [];

    // Convert to array and sort by date
    let dataArr = sortOn(Object.values(data), dateDescriptor);

    // Convert to an array of objects for Highcharts
    const dataPoints = dataArr.map((d) => ({
        x: moment(d[dateDescriptor]).valueOf() + moment.parseZone(d[dateDescriptor]).utcOffset() * 60000,
        // x: moment(d[dateDescriptor]).format(),
        y: Number(d[descriptor]),
        id: d.id,
        type: d._type,
    }));

    //Get the measure unit
    let muAbbreviation = muAbbr || getProperty(Object.values(data), '0.measureUnit.abbreviation');

    return createConfig(dataPoints, name, valueOffset, onGraphPointClicked, muAbbreviation);
};

const createConfig = (data, name = '', valueOffset = 10, onGraphPointClicked, measureUnitName) => {
    // let high = Math.max(...data.map(i => i.y)) + valueOffset,
    //     low = Math.min(...data.map(i => i.y)) - valueOffset;
    let high = Math.max(...data.map((i) => i.y)),
        low = Math.min(...data.map((i) => i.y));
    high *= 1.1;
    low *= 0.9;
    let config = getInitialConfig(name, low, high, measureUnitName);

    config.series = [
        {
            name: name,
            data: data,
            showInLegend: true,
            cursor: 'pointer',
            allowPointSelect: true,
            boostThreshold: 1000000,
        },
    ];

    if (onGraphPointClicked != null) {
        config.series[0].point = {
            events: {
                click: (event) => onGraphPointClicked(event.point.id, event.point.type),
            },
        };
    }
    return config;
};

const getInitialConfig = (title, low, high, measureUnitAbbreviation = null) => ({
    credits: {
        enabled: false,
    },
    chart: {
        height: 300,
        width: null,
        zoomType: 'x',
    },
    title: {
        // text: 'Διάγραμμα Θερμοκρασίας Σώματος'
        text: title,
    },
    boost: {
        seriesThreshold: 1000000000,
    },
    xAxis: {
        type: 'datetime',
        title: {
            text: T.translate('measurements.date'),
        },
        dateTimeLabelFormats: {
            millisecond: '%H:%M',
            second: '%H:%M',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e. %b',
            week: '%e. %b',
            month: "%b '%y",
            year: '%Y',
        },
    },
    yAxis: {
        min: low,
        max: high,
        lineColor: '#FF0000',
        title: {
            text: measureUnitAbbreviation ? `${title} (${measureUnitAbbreviation})` : `${title}`,
        },
    },
    tooltip: {
        useHTML: true,
        headerFormat: "<div class='chart-tooltip-date'>{point.x:%e. %b %Y}</div>",
        pointFormat: '{point.x:%e. %b}: {point.y:.2f}',
        pointFormat: `
            <div class='chart-tooltip-content'>
                <div class='chart-tooltip-value'>
                    {point.y} ${measureUnitAbbreviation || ''}
                </div>
                <!-- TODO: add crud functionality
                <div class='chart-tooltip-controls'>
                    <div class="chart-tooltip-button edit" data-pointId={point.id} data-pointType={point.type}>
                        <i class="icon pencil"></i>
                    </div>
                    <div class="chart-tooltip-button delete">
                        <i class="icon trash"></i>
                    </div>
                </div>
                -->
            </div>
        `,
    },
    time: {
        // timezone: 'Europe/Athens',
        // getTimezoneOffset: (ts) => moment(ts).utcOffset(),
        timezoneOffset: 360,
    },
    plotOptions: {
        series: {
            turboThreshold: 10000000,
        },
        spline: {
            marker: {
                enabled: true,
            },
        },
    },
});
