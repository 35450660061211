import { Icon, Menu } from 'semantic-ui-react';
import React, { Component } from 'react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';

import { MenuPanel } from 'Mib/components/containers';

export default class HealthIndicatorsMenu extends Component {
    constructor(props) {
        super();
        this.generateMenuItems(props);
    }

    generateMenuItems(props) {
        const { match } = props;

        this.menuItems = [
            {
                key: 0,
                as: NavLink,
                to: match.url + '/bmi',
                label: (
                    <span>
                        <Icon className="calculator" />
                        {T.translate('routes.health-indicators.bmi')}
                    </span>
                ),
            },
            {
                key: 1,
                as: NavLink,
                to: match.url + '/waist_to_height',
                label: (
                    <span>
                        <Icon className="icon-height" />
                        {T.translate('routes.health-indicators.height-to-waist')}
                    </span>
                ),
            },

            {
                key: 4,
                as: NavLink,
                to: match.url + '/examsByAge',
                label: (
                    <span>
                        <span style={{ paddingLeft: '5px' }} className="mib-mono-icon mibmono-health_check" />

                        <span style={{ paddingRight: '5px' }}>
                            {' '}
                            {T.translate('routes.health-indicators.examsByAge')}
                        </span>
                    </span>
                ),
            },
        ];
    }

    render() {
        const { history } = this.props;
        const menuTitle = (
            <Menu.Item className="menu-title" as={NavLink} to="/health-indicators/bmi" exact>
                <Icon className="line chart" />
                {T.translate('routes.health-indicators.menu-title')}
            </Menu.Item>
        );

        return <MenuPanel className="red profile-menu mib-menu" inverted title={menuTitle} items={this.menuItems} />;
    }
}
