import React, { Component } from 'react';
import T from 'i18n-react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';

import BaseContainer from '../containers/base';
import TimelineEventList from '../generic/timeline/timeline_event_list';
import ReactHighchartsManaged from '../generic/react_high_charts_managed';
import { GeneralMetricControls } from './general_metric_controls';

require('./general_metric.scss');

class GeneralMetricContainer extends Component {
    constructor() {
        super();
        // this.toggleChange = this.toggleChange.bind(this);
        this.state = { loading: false, dateFrom: null, dateTo: null, unitId: null };
        // this.open = this.open.bind(this);
    }

    generateBaseChildren(allData = {}, type = 'graph') {
        const {
            chart_config,
            data,
            dataType,
            labId,
            title = '',
            loading,
            forceEventType,
            graphPointClick,
            filters,
            chartLimit,
            name,
            clearFilters,
        } = this.props;

        if (!loading && Object.keys(allData).length == 0) {
            if (Object.keys(filters).length) {
                return (
                    <Message
                        icon="info circle"
                        header={T.translate('measurements.no-measurements')}
                        onClick={clearFilters}
                        content={T.translate('measurements.clear-filters-prompt')}
                        info
                        color="violet"
                        style={{ marginBottom: '180px', marginTop: '30px', cursor: 'pointer' }}
                    />
                );
            }
            return graphPointClick ? (
                <Message
                    icon="info circle"
                    header={T.translate('measurements.no-measurements')}
                    onClick={() => graphPointClick(null, dataType, labId)}
                    content={T.translate('measurements.create-prompt')}
                    info
                    style={{ marginBottom: '180px', marginTop: '30px', cursor: 'pointer' }}
                />
            ) : (
                <Message
                    icon="info circle"
                    header={T.translate('measurements.no-measurements')}
                    content={T.translate('measurements.no-health-index-text')}
                    info
                    style={{ marginBottom: '180px', marginTop: '30px', cursor: 'pointer' }}
                />
            );
        }

        return (
            <>
                <BaseContainer title={title} loading={loading}>
                    <ReactHighchartsManaged config={chart_config} limit={chartLimit} />
                </BaseContainer>
                <BaseContainer title={title} loading={loading} style={{ marginTop: '10px' }}>
                    <TimelineEventList
                        data={data}
                        sortOn="date"
                        title={title}
                        timelinePointClick={graphPointClick || null}
                        forceEventType={forceEventType}
                    />
                </BaseContainer>
            </>
        );
    }

    render() {
        let {
            title,
            data,
            dataType,
            measureUnits,
            configUpdateUnitFunc,
            labId,
            tagFilters,
            graphPointClick,
            unitId,
            ...rest
        } = this.props;
        const { dateFrom, dateTo } = this.state;

        // console.log('props unitId', unitId);
        // console.log('state unitId', this.state.unitId);

        const measureUnitOptions = Object.values(this.props.measureUnits || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.abbreviation,
        }));

        const createFunc = graphPointClick != undefined ? (args) => graphPointClick(null, dataType, labId) : null;
        const content = this.generateBaseChildren(data);

        return (
            <>
                <GeneralMetricControls
                    onCreate={createFunc || null}
                    unitId={unitId}
                    measureUnitOptions={measureUnitOptions}
                    configUpdateUnitFunc={configUpdateUnitFunc}
                    resourceType={dataType}
                    startDate={dateFrom}
                    endDate={dateTo}
                    onStartChanged={(e, d) => this.setState({ dateFrom: d ? d.moment : null })}
                    onEndChanged={(e, d) => this.setState({ dateTo: d ? d.moment : null })}
                    tagFilters={tagFilters}
                    // toggleView={this.toggleChange}
                />
                {content}
            </>
        );
    }
}

const mapStateToProps = ({ filters }) => ({ filters });
const mapDispatchToProps = (dispatch) => ({ clearFilters: () => dispatch({ type: 'CLEAR_FILTERS' }) });

export default connect(mapStateToProps, mapDispatchToProps)(GeneralMetricContainer);
