import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import classnames from 'classnames';

import BaseContainer from './base';

require('./menu_panels.scss');

function RenderFooter({ footer = null }) {
    switch (typeof footer) {
        case 'string':
            return <Menu.Item className="menu-footer">{footer}</Menu.Item>;
        case 'object':
            return footer.hasOwnProperty('type') ? (
                footer
            ) : (
                <Menu.Item onClick={footer.onClick} className="menu-footer">
                    {footer.label}
                    <Icon name={footer.icon} />
                </Menu.Item>
            );
        case 'function':
            return <footer />;
        default:
            return null;
    }
}

function RenderTitle({ title = '', titleComponent }) {
    switch (typeof title) {
        case 'string':
            return <Menu.Item className="menu-title">{title}</Menu.Item>;
        case 'object':
            return title.hasOwnProperty('type') ? (
                title
            ) : (
                <Menu.Item onClick={title.onClick} className="menu-title">
                    {title.label}
                    <Icon name={title.icon} />
                </Menu.Item>
            );
        case 'function':
            return <title />;
        default:
            return null;
    }
}

export function MenuPanel({
    items = [],
    loading = false,
    className,
    style = {},
    containerStyle = {},
    collapse = false,
    title = '',
    titleComponent = '',
    footer = null,
}) {
    return (
        <BaseContainer
            className={classnames('menu-panel', className)}
            loading={loading}
            style={style}
            containerStyle={containerStyle}
        >
            <Menu vertical fluid className={collapse ? 'collapse' : ''}>
                <RenderTitle title={title} titleComponent={titleComponent} />
                {items.map(({ key, label, icon, ...rest }) => (
                    <Menu.Item key={key || label} {...rest}>
                        {label}
                        {icon && <Icon name={icon} />}
                    </Menu.Item>
                ))}

                {footer && <RenderFooter footer={footer} />}
            </Menu>
        </BaseContainer>
    );
}

export default MenuPanel;
