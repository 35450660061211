import React, { Component, Fragment } from "react";
import T from "i18n-react";
import {ConnectMedicalLabSteps} from "./connectMedicalLabsSteps";
import {WithPayment} from "../../../billing/withPaymentWrapper";
import BillingDimmer from "../../../billing/dimmer";

export default class ConnectMedicalLab extends Component {
    render() {
        return (
            <div>
                <WithPayment feature_id="premium.connections.doctors">
                    {(props) =>
                        <Fragment>
                            {!props.canViewFeature ? <BillingDimmer mediMsg={`${T.translate('billing.you-are-basic')}`} feature_id={props.feature_id}/> : null}
                            <label className="titleLabel">{T.translate("providers.connect-to-medical-lab")}</label>
                            <div style={{height: "35px"}}/>

                            <ConnectMedicalLabSteps/>
                        </Fragment>
                    }
                </WithPayment>
            </div>
        );
    }
}
