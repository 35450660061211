import T from 'i18n-react';
import React, { Component } from 'react';

import { Contact } from 'Mib/lib/models';
import { FormModal } from 'Mib/components/modals';
import { Create, Update, Delete } from 'Mib/actions';
import ModelForm from 'Mib/components/forms/model_form';
import { TextInput, DropDownInput, MibButton } from 'Mib/components/inputs';

export class ContactForm extends Component {
    constructor() {
        super();
        this.state = { model: {}, loading: false };
        this.formItems = [
            { Element: TextInput, fieldName: 'first_name', label: 'contacts.first_name' },
            { Element: TextInput, fieldName: 'last_name', label: 'contacts.last_name' },
            { Element: TextInput, fieldName: 'email', label: 'contacts.email' },
            { Element: TextInput, fieldName: 'phone1', label: 'contacts.phone1' },
            { Element: TextInput, fieldName: 'phone2', label: 'contacts.phone2' },
            {
                Element: DropDownInput,
                fieldName: 'priority',
                label: 'contacts.priority',
                options: [
                    { value: '1', text: 1 },
                    { value: '2', text: 2 },
                ],
            },
        ];
    }
    render() {
        return (
            <FormModal ref={(i) => (this.modal = i)} title={T.translate('contacts.create')} loading={this.state.loading}>
                <ModelForm
                    model={this.state.model}
                    validator={Contact.validator}
                    onChange={(model) => this.setState({ model: model })}
                    items={this.formItems}
                    errors={this.state.errors}
                    onValidate={(errors) => this.setState({ errors })}
                />
                <div className="mib-modal-footer controls">
                    {this.state.action === 'edit' ? (
                        <MibButton label={T.translate('delete')} type="danger" onClick={this.delete.bind(this)} />
                    ) : null}
                    <MibButton label={T.translate('buttons.close')} type="warning" onClick={this.close.bind(this)} />
                    <MibButton label={T.translate('buttons.save')} type="success" onClick={this.save.bind(this)} />
                </div>
            </FormModal>
        );
    }
    open(contact) {
        const model = Contact({});
        contact && Object.assign(model, contact);
        this.setState({ model: model, action: contact === undefined ? 'create' : 'edit' });
        this.modal.open();
    }
    close() {
        this.setState({ model: {}, errors: [], action: null, loading: false });
        this.modal.close();
    }
    save() {
        this.setState({ loading: true });
        if (this.state.action === 'edit') {
            Update(this.state.model, 'auth')
                .then((res) => {
                    this.setState({ loading: false });
                    this.close();
                })
                .catch((e) => {
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        } else {
            Create(this.state.model, 'auth')
                .then((res) => {
                    this.setState({ loading: false });
                    this.close();
                })
                .catch((e) => {
                    let errors = e.errors.map((item, key) => {
                        return { property: item.title, message: item.detail };
                    });

                    this.setState({ errors });
                });
        }
    }
    delete() {
        Delete(this.state.model, 'auth').then((res) => {
            this.setState({ loading: false });
            this.close();
        });
    }
}
