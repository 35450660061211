import React, { Component } from 'react';

import { Grid } from 'semantic-ui-react';
import T from 'i18n-react';
import moment from 'moment';
import { getProperty } from 'dot-prop';
import { connect } from 'react-redux';

import { ContentTitle } from 'Mib/components/generic/content_title';
import { subscribe } from 'Mib/lib/subscribe';
import { AllergyPanel } from 'Mib/routes/profile/emergency_data/allergy_panel';
import { PanelDropdownInput } from 'Mib/components/inputs/panel_inputs';

import { ContactsList } from './contacts_list';
import { MedicinePanel } from './medicine_panel';
import { MedicineForm } from './medicine_form';
import { ContactForm } from './contact_form';
import { AllergyForm } from './allergy_form';

require('./emergency_data.scss');

class _EmergencyDataPage extends Component {
    openContactForm(contact) {
        this.contactForm.open(contact);
    }

    openAllergyForm(allergy) {
        this.allergyForm.open(allergy);
    }

    openMedicineForm(medicine) {
        this.medicineForm.open(medicine);
    }

    render() {
        let {
            bloodType,
            gender,
            lastWeight,
            lastWeight2,
            lastHeight,
            lastHeight2,
            lastPressure,
            lastPressure2,
            lastWaist2,
            lastWaist,
            birthDate,
            fullName,
            allergies,
            personAllergies,
            appState,
            dominantHand,
        } = this.props;

        // Get the gender
        if (gender !== undefined) gender = T.translate(`genders.${gender}`);
        console.log('dominantHand', dominantHand);
        const handedness =
            dominantHand !== undefined ? T.translate(`handedness.${dominantHand}`) : T.translate('unknown');
        console.log('handedness', handedness);

        const lastWeightDate = lastWeight && moment(getProperty(lastWeight, 'date')).format('L LT');
        const lastHeightDate = lastHeight && moment(getProperty(lastHeight, 'date')).format('L');
        const lastPressureDate = lastPressure && moment(getProperty(lastPressure, 'date')).format('L LT');
        const lastWaistDate = lastWaist && moment(getProperty(lastWaist, 'date')).format('L LT');

        // Get the age
        let age;
        if (birthDate != null) {
            const ageYears = moment().diff(birthDate, 'years');
            age =
                ageYears > 2
                    ? `${ageYears} ${T.translate('years')}`
                    : `${moment().diff(birthDate, 'months')} ${T.translate('months')}`;
        } else {
            age = null;
        }

        return (
            <div className="content-container">
                <ContentTitle
                    title={T.translate('routes.emergency-data')}
                    icon="mibcolor-emergency"
                    style={{
                        color: '#da2727',
                        borderBottom: '1px solid #da2727',
                        width: '100%',
                    }}
                />
                <Grid>
                    <Grid.Column width={8}>
                        <AllergyPanel
                            openAllergyForm={this.openAllergyForm.bind(this)}
                            defaultShowAll
                            showExtraInfo
                            style={{ maxHeight: '300px', overflowY: 'auto' }}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MedicinePanel
                            openMedicineForm={this.openMedicineForm.bind(this)}
                            defaultShowAll
                            showExtraInfo
                            style={{ maxHeight: '300px', overflowY: 'auto' }}
                        />
                    </Grid.Column>
                </Grid>
                {/* <ContentTitle
                    title={T.translate("genetic")}
                    icon="mibcolor-genetic"
                    style={{ color: "#2eaadc", borderBottom: "1px solid #2eaadc", width: '100%' }}
                /> */}
                <ContentTitle
                    title={T.translate('routes.profile.genetic')}
                    icon="mibcolor-genetic"
                    style={{
                        color: '#2eaadc',
                        borderBottom: '1px solid black',
                        width: '100%',
                    }}
                />
                <div className="person-panel-form-container">
                    <PanelDropdownInput
                        value={1}
                        label={T.translate('persons.blood-type')}
                        options={[
                            {
                                key: 1,
                                text: bloodType ? bloodType : T.translate('unknown'),
                                value: 1,
                            },
                        ]}
                        radiusLarge={!bloodType && 110}
                        radiusSmall={!bloodType && 90}
                        lockable
                        hideLockButton
                    />
                    <PanelDropdownInput
                        value={1}
                        label={T.translate('persons.gender')}
                        onChange={(e, v) => onFormChanged('gender', e, v)}
                        options={[
                            {
                                key: 1,
                                text: gender,
                                value: 1,
                            },
                        ]}
                        radiusLarge={110}
                        radiusSmall={90}
                        lockable
                        hideLockButton
                    />
                    <PanelDropdownInput
                        value={1}
                        label={T.translate('age')}
                        options={[
                            {
                                key: 1,
                                text: age,
                                value: 1,
                            },
                        ]}
                        lockable
                        hideLockButton
                    />
                    <PanelDropdownInput
                        value={1}
                        label={T.translate('persons.handedness')}
                        options={[
                            {
                                key: 1,
                                text: handedness,
                                value: 1,
                            },
                        ]}
                        lockable
                        hideLockButton
                        radiusLarge={110}
                        radiusSmall={90}
                    />
                </div>

                <ContentTitle
                    title={T.translate('routes.profile.biometrics')}
                    icon="mibcolor-bmi"
                    style={{
                        color: '#2eaadc',
                        borderBottom: '1px solid black',
                        width: '100%',
                    }}
                />
                <div className="person-panel-form-container">
                    <PanelDropdownInput
                        value={1}
                        label={T.translate('measurements.singular.weight')}
                        date={lastWeightDate}
                        options={[
                            {
                                key: 1,
                                text: lastWeight2,
                                value: 1,
                            },
                        ]}
                        loading={appState.syncing.includes('weights')}
                        lockable
                        hideLockButton
                    />
                    <PanelDropdownInput
                        value={1}
                        label={T.translate('measurements.singular.height')}
                        date={lastHeightDate}
                        options={[
                            {
                                key: 1,
                                text: lastHeight2,
                                value: 1,
                            },
                        ]}
                        loading={appState.syncing.includes('heights')}
                        lockable
                        hideLockButton
                    />
                    <PanelDropdownInput
                        value={1}
                        label={T.translate('measurements.singular.pressure')}
                        date={lastPressureDate}
                        options={[
                            {
                                key: 1,
                                text: lastPressure2,
                                value: 1,
                            },
                        ]}
                        loading={appState.syncing.includes('pressures')}
                        radiusLarge={110}
                        radiusSmall={90}
                        lockable
                        hideLockButton
                    />
                    <PanelDropdownInput
                        value={1}
                        label={T.translate('measurements.singular.waist')}
                        date={lastWaistDate}
                        options={[
                            {
                                key: 1,
                                text: lastWaist2,
                                value: 1,
                            },
                        ]}
                        loading={appState.syncing.includes('waists')}
                        lockable
                        hideLockButton
                    />
                </div>
                <ContentTitle
                    title={T.translate('contacts.contacts')}
                    icon="mibcolor-person"
                    style={{
                        color: '#2eaadc',
                        borderBottom: '1px solid black',
                        width: '100%',
                    }}
                />
                <ContactsList openContactForm={this.openContactForm.bind(this)} />
                <ContactForm ref={(i) => (this.contactForm = i)} />
                <AllergyForm allergies={allergies} ref={(i) => (this.allergyForm = i)} />
                <MedicineForm ref={(i) => (this.medicineForm = i)} />
            </div>
        );
    }
}

const mapStateToProps = ({ authentication: { activePersonId }, dataStore, appState }) => {
    const weights = Object.values(getProperty(dataStore, 'graph.weights') || {});
    const lastWeight = weights && weights[weights.length - 1];
    const lastWeight2 = lastWeight && `${lastWeight.result}${getProperty(lastWeight, 'measureUnit.abbreviation', '')}`;
    const heights = Object.values(getProperty(dataStore, 'graph.heights') || {});
    const lastHeight = heights && heights[heights.length - 1];
    const lastHeight2 = lastHeight && `${lastHeight.result}${getProperty(lastHeight, 'measureUnit.abbreviation', '')}`;
    const pressures = Object.values(getProperty(dataStore, 'graph.pressures') || {}).filter((i) => i.systolic);
    const lastPressure = pressures && pressures[pressures.length - 1];
    const lastPressure2 =
        lastPressure &&
        `${lastPressure.systolic}/${lastPressure.diastolic} ${getProperty(
            lastPressure,
            'measureUnit.abbreviation',
            ''
        )}`;
    const waists = Object.values(getProperty(dataStore, 'graph.waists') || {});
    const lastWaist = waists && waists[waists.length - 1];
    const lastWaist2 = lastWaist && `${lastWaist.result}${getProperty(lastWaist, 'measureUnit.abbreviation', '')}`;
    const firstName = getProperty(dataStore, `graph.persons.${activePersonId}.first_name`) || '';
    const lastName = getProperty(dataStore, `graph.persons.${activePersonId}.last_name`) || '';

    return {
        bloodType: getProperty(dataStore, `graph.person-info.${activePersonId}.bloodType.type`),
        dominantHand: getProperty(dataStore, `graph.person-info.${activePersonId}.dominant_hand`),
        gender: getProperty(dataStore, `graph.persons.${activePersonId}.gender`),
        birthDate: getProperty(dataStore, `graph.persons.${activePersonId}.dob`),
        fullName: `${firstName} ${lastName}`,
        lastWeight,
        lastWeight2,
        lastHeight,
        lastHeight2,
        lastPressure,
        lastPressure2,
        lastWaist,
        lastWaist2,
        allergies: getProperty(dataStore, `graph.allergies`),
        personAllergies: getProperty(dataStore, `graph.person-allergies`),
        appState,
    };
};

const EmergencyDataPageTemp = subscribe(_EmergencyDataPage, [
    'weights',
    'heights',
    'pressures',
    'waists',
    'person-info',
    'allergies?',
    'person-allergies',
]);

export const EmergencyDataPage = connect(mapStateToProps)(EmergencyDataPageTemp);
