import { Icon } from 'semantic-ui-react';
import React, { Component } from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import BaseContainer from 'Mib/components/containers/base';
import { subscribeWithLoader } from 'Mib/lib/subscribe';

class _ContactsList extends Component {
    ContactRow(contact) {
        return (
            <BaseContainer color="blue" key={contact.id} onClick={() => this.props.openContactForm(contact)}>
                {contact['first_name']}&nbsp;{contact['last_name']}, &nbsp; {contact['email']}
                <span className="contact-phone">
                    {contact.phone1 || contact.phone2 ? <Icon name="phone" /> : null}
                    {contact.phone1}
                    {contact.phone2 ? `, ${contact.phone2}` : null}
                </span>
            </BaseContainer>
        );
    }

    render() {
        const { contacts = {}, openContactForm, loading } = this.props;
        return (
            <BaseContainer className="contacts-list" loading={loading} containerStyle={{ width: '100%' }}>
                <div className="contacts-title">
                    {T.translate('contacts.contacts')}
                    <Icon name="address card outline" />
                </div>
                {Object.values(contacts).map(this.ContactRow.bind(this))}
                <BaseContainer
                    className="contacts-footer bg-blue"
                    onClick={() => openContactForm()}
                    style={{ cursor: 'pointer' }}
                >
                    {T.translate('contacts.create')}
                    <Icon name="add user" />
                </BaseContainer>
            </BaseContainer>
        );
    }
}

const mapStateToProps = ({ dataStore }) => ({
    contacts: getProperty(dataStore, 'graph.contacts'),
});

export const ContactsList = subscribeWithLoader(_ContactsList, mapStateToProps, ['contacts']);
// export const ContactsList = connect(mapStateToProps)(_ContactsList);
