import React, {Component} from 'react'
import T from 'i18n-react'
import {Popup, Icon } from 'semantic-ui-react'

import { CreateButton } from "../inputs/buttons";

export default class GenericAddPopup extends Component {
    constructor(props) {
        super();
        this.state = { text: props.defaultText };
    }
    render() {
        const { text } = this.state;
        const {items, defaultText} = this.props;

        return (
            <Popup trigger={<CreateButton />} on="click" style={{ zIndex: 100 }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ display: "flex", width:"250px" ,justifyContent:"space-between"}}>
                        {items.map( (item, index) => (
                            <div
                                key={item.key != 'undefined' ? item.key : index}
                                className="measurement-button-tiny"
                                onClick={item.onClick}
                                onMouseEnter={() =>
                                    this.setState({
                                        text: item.hoverText,
                                    })
                                }
                                onMouseLeave={() =>
                                    this.setState({
                                        text: defaultText,
                                    })
                                }
                            >
                                <i className={`mib-mono-icon ${item.icon}`} />
                            </div>
                        ))}
                    </div>
                    <div style={{ color: "#009ee0", textAlign: "center", minHeight: '3em' }}>{text}</div>
                </div>
            </Popup>
        );
    }
}
