import { store } from 'Mib/datastore/store';

const mediMessageListener = (msg) => {
    const msgJson = JSON.parse(msg.payload.message);
    const medi_message = {
        id: msg.id,
        type: 'info',
        title: msgJson.title,
        body: msgJson.note,
    };
    store.dispatch({ type: 'ADD_MEDI_MESSAGE', payload: medi_message });
    return false;
};

const notificationListener = (msg) => {
    console.log('Notification message', msg);
    const msgJson = JSON.parse(msg.payload.message);
    const notification = {
        id: msgJson.id,
        type: 'info',
        title: msgJson.title,
        body: msgJson.note,
        due: msgJson.due,
    };
    store.dispatch({ type: 'ADD_OR_UPDATE_NOTIFICATION_MESSAGE', payload: notification });
    return false;
};

const socketAgeExamsListener = (data) => {
    console.log('LIVE SOCKET DATA AT LISTENER', data);
    const dataJson = JSON.parse(data.payload.message);

    // const socket_data ={
    //     examByAge: dataJson,
    // }

    store.dispatch({ type: 'ADD_LIVE_DATA', payload: dataJson, topic: data.topic });
    //  store.dispatch({ type: "POP_SOCKET_MESSAGE", message_id: socket_data.id })

    return false;
};

const socketDataListener = (data, dataFor) => {
    const socket_data = {
        id: data.id,
        type: 'data',
        title: data.title,
        body: data.body,
    };

    store.dispatch({ type: 'ADD_SOCKET_DATA', payload: socket_data, dataFor: dataFor });
};

const socketAnswerListener = (data, dataFor) => {
    const socket_data = {
        id: data.id,
        type: 'data',
        title: data.title,
        body: data.body,
    };

    store.dispatch({ type: 'ADD_SOCKET_ANSWER', payload: socket_data, dataFor: dataFor });
};

const socketQuestionListener = (data, dataFor) => {
    const socket_data = {
        id: data.id,
        type: 'data',
        title: data.title,
        body: data.body,
    };

    store.dispatch({ type: 'ADD_SOCKET_QUESTION', payload: socket_data, dataFor: dataFor });
};

export const socketSenseReport = (data) => {
    // to json exei timestamp & value
    const dataJson = JSON.parse(data.payload.message);

    return store.dispatch({ type: 'ADD_SENSE_REPORT', payload: dataJson, topic: data.topic });
};

const Listeners = [
    { channel: 'person', topic: 'phx_error', listener: mediMessageListener },
    { channel: 'person', topic: 'remind', listener: notificationListener },
    { channel: 'person', topic: 'socket_data', listener: socketDataListener },
    { channel: 'person', topic: 'socket_quest', listener: socketQuestionListener },
    { channel: 'person', topic: 'socket_answer', listener: socketAnswerListener },
    { channel: 'person', topic: 'lab-suggestions', listener: socketAgeExamsListener },
    { channel: 'person', topic: 'sense-report', listener: socketSenseReport },
];
export default Listeners;
