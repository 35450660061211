/* eslint-disable max-classes-per-file */
import React, { Component, Suspense } from 'react';
import classnames from 'classnames';
import T from 'i18n-react';
// import { getProperty } from 'dot-prop';
import { Icon, Segment, Input, Header, Popup } from 'semantic-ui-react';

// import BaseContainer from '../containers/base';
import MibSelect from 'Mib/components/inputs/select';

// import moment from 'moment';
import { formatDate } from '../../lib/dateTools';

const DatePicker = React.lazy(() => import('react-datepicker'));

require('./panel_inputs.scss');

export default class PanelInput extends Component {
    constructor(props = {}) {
        super();
        const { lockable = false } = props;
        this.state = {
            locked: lockable,
        };
        this.onChange = this.onChange.bind(this);
        this.onEditClicked = this.onEditClicked.bind(this);
    }

    onEditClicked(e) {
        const { lockable, focusable } = this.props;
        e.stopPropagation();
        if (lockable) {
            this.setState({ locked: !this.state.locked });
        }
        try {
            setTimeout(() => this.inputRef.focus(), 10);
        } catch (e) {
            console.log(e);
        }
    }

    // renderInput(type) {
    //   switch (type) {
    //       case 'date':
    //       return this._renderDateInput();
    //       break
    //     case "text":
    //     default:
    //       return this._renderTextInput();
    //       break;
    //   }
    // }

    onChange(e) {
        const { onChange = () => {} } = this.props;
        onChange(e);
    }

    renderInput() {
        const { label, value = '', type = 'text', placeholder = label } = this.props;
        const { locked } = this.state;
        return (
            <input
                disabled={locked}
                type={type}
                className={classnames('panel_input-editable panel_input-label', {
                    disabled: locked,
                })}
                onInput={this.onChange}
                value={value}
                placeholder={placeholder}
                ref={(i) => (this.inputRef = i)}
            />
        );
    }

    render() {
        const {
            style = {},
            type = 'text',
            label = '',
            encrypted,
            lockable,
            focusable,
            hideLockButton,
            date,
            radiusLarge,
            radiussmall,
            radiusSmall,
            ...rest
        } = this.props;
        const { locked } = this.state;

        const showPencil = (lockable || focusable) && !hideLockButton;
        return (
            <Segment className="panel_input" style={style} {...rest} onClick={() => this.inputRef.focus()}>
                {encrypted && (
                    <Popup
                        trigger={<Icon name="lock" className="panel_input-crypto_lock" />}
                        style={{ color: 'rgba(0, 0, 0, 0.67)' }}
                        content={T.translate('persons.encrypted-personal-info')}
                    />
                )}
                <div className="panel_input-title">{label}</div>
                <div className="panel_input-input_container">{this.renderInput()}</div>
                {showPencil && (
                    <div
                        className={classnames('panel_input-edit_button', {
                            locked: locked || focusable,
                        })}
                        onClick={this.onEditClicked}
                    >
                        <Icon name="edit outline" />
                    </div>
                )}
                {date && <div className="panel_input-date">{date}</div>}
            </Segment>
        );
    }
}

class InputLabel extends Component {
    focus() {
        this.props.onClick();
    }

    render() {
        const { val, onClick, placeholder } = this.props;
        return (
            <div className="panel_input-label" onClick={onClick}>
                {val || <span className="panel_input-placeholder">{placeholder}</span>}
            </div>
        );
    }
}

export class PanelDateInput extends PanelInput {
    constructor(props = {}) {
        super(props);
        this.state = { ...(this.state || {}), value: null };
        this.handleChange = this.handleChange.bind(this);
        this.inputRef = {
            focus: () => this.test.setOpen(true),
        };
    }

    renderInput() {
        let { value, label, placeholder = label, onChange, maxDate, ...other } = this.props;
        const { locked } = this.state;
        value = value ? new Date(value) : null;
        // value = value ? moment(value) : null;
        let stringVal = value ? formatDate(value) : '';
        // let stringVal = value ? value.format('L') : '';
        const hasErrors = false;
        // const dateFormat = getProperty(moment.localeData(), '_longDateFormat.L', 'dd/MM/yyyy');
        const maxDateProps = maxDate ? new Date() : '';

        return (
            <Suspense fallback={null}>
                <Input
                    className={classnames('panel_input-datepicker', {
                        error: hasErrors,
                        disabled: locked,
                    })}
                >
                    <DatePicker
                        disabled={locked}
                        selected={value}
                        innerRef={(i) => (this.test = i)}
                        onChange={this.handleChange}
                        maxDate={maxDateProps}
                        className={classnames({})}
                        customInput={<InputLabel val={stringVal} />}
                        dateFormat="dd/MM/yyyy"
                        // dateFormat={dateFormat}
                        // withPortal
                        placeholderText={placeholder}
                        {...other}
                    />
                </Input>
            </Suspense>
        );
    }

    handleChange(date, e) {
        // this.setState({
        //   value: date
        // });
        // this.value = date;
        let hoho = date != null ? Object.assign({}, { value: date }) : null; //props. onChange function  always expects a value property
        // let hoho = date != null ? Object.assign({}, { value: date.format() }) : null; //props. onChange function  always expects a value property
        this.props.onChange && this.props.onChange(e, hoho);
    }
}

function Circle({ radius, value, style = {}, onClick, className, ...rest }) {
    const quickInfoCircle = {
        cursor: 'pointer',
        width: radius || '90',
        height: radius || '90',
        backgroundColor: '#2EAADC',
        color: 'white',
        ...style,
    };
    return (
        <Segment
            onClick={onClick}
            style={quickInfoCircle}
            circular
            className={classnames('quick-info-circle', className)}
            {...rest}
        >
            <Header style={{ color: 'white' }} as="h2">
                {value}
            </Header>
        </Segment>
    );
}

export class PanelDropdownInput extends PanelInput {
    constructor(props) {
        super(props);
        this.state = { ...(this.state || {}), value: props.value, date: props.date };
    }

    handleChange(val) {
        const { onChange } = this.props;
        this.props.onChange(null, { value: val });
        // this.setState({ value: val });
    }

    renderInput() {
        const { options = [], radiusLarge = 90, radiusSmall = 70, value } = this.props;
        const { locked } = this.state;
        const OFFSET = '60px';
        return (
            <MibSelect
                disabled={locked}
                changeOnWheel={true}
                items={options}
                value={value}
                onChange={this.handleChange.bind(this)}
                itemStyle={{ background: 'pink' }}
                ref={(i) => (this.inputRef = i)}
                renderCenterItem={(item, onClick) => (
                    <Circle
                        radius={radiusLarge}
                        value={item.text}
                        onClick={onClick}
                        style={{ margin: 0, cursor: locked ? 'not-allowed' : 'pointer' }}
                    />
                )}
                renderItemBefore={(item, onClick, open, activeIndex, index) => (
                    <Circle
                        className={classnames('dropdown-circle', 'inactive', {
                            hidden: !open,
                        })}
                        radius={radiusSmall}
                        value={item.text}
                        onClick={onClick}
                        style={{
                            left: `${(radiusLarge - radiusSmall) / 2}px`,
                            top: `calc(${activeIndex - index} * ${OFFSET} + 20px)`,
                        }}
                    />
                )}
                renderItemAfter={(item, onClick, open, activeIndex, index) => (
                    <Circle
                        className={classnames('dropdown-circle', 'inactive', {
                            hidden: !open,
                        })}
                        radius={radiusSmall}
                        value={item.text}
                        onClick={onClick}
                        style={{
                            left: `${(radiusLarge - radiusSmall) / 2}px`,
                            top: `calc(${-(index + 1)} * ${OFFSET})`,
                        }}
                    />
                )}
            />
        );
    }
}

PanelInput.Date = PanelDateInput;
PanelInput.Dropdown = PanelDropdownInput;
