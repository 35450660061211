import React from 'react';
import T from 'i18n-react';
import { v4 as uuidv4 } from 'uuid';

import { store } from 'Mib/datastore/store';
import { MibButton } from 'Mib/components/inputs';
import BaseContainer from 'Mib/components/containers/base';
import { ContentTitle } from 'Mib/components/generic';

function CheckUpPanel() {
    const onOpenTutorial = () => {
        console.log('opening checkup panel');
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'CHECKUP-SURVEY',
                survey_id: 1,
            },
        });
    };

    const Button = <MibButton onClick={onOpenTutorial} type="success" label={T.translate('meta.start')} />;
    return (
        <BaseContainer className="connection-segment" color="blue">
            <div className="connection-container-inner">
                <img
                    src="/assets/img/connections/medi_checkUp.png"
                    alt="checkUp logo"
                    className="logo-image"
                    style={{ width: '15%' }}
                />
                <div className="connection-description">{T.translate('connections.checkUpTutorial')}</div>
                <div className="connection-controls">{Button}</div>
            </div>
        </BaseContainer>
    );
}

function TutorialPanel() {
    const onOpenTutorial = () => {
        console.log('opening tutorial panel');
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'CHECKUP-SURVEY',
                survey_id: 3,
            },
        });
    };

    const Button = <MibButton onClick={onOpenTutorial} type="success" label={T.translate('connections.activate')} />;
    return (
        <BaseContainer className="connection-segment" color="blue">
            <div className="connection-container-inner">
                <img
                    src="/assets/img/connections/medi_wizard.png"
                    alt="wizard logo"
                    className="logo-image"
                    style={{ width: '15%' }}
                />
                <div className="connection-description">{T.translate('connections.starterTutorial')}</div>
                <div className="connection-controls">{Button}</div>
            </div>
        </BaseContainer>
    );
}

function CovidPanel() {
    const onOpenTutorial = () => {
        console.log('opening covid panel');
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'CHECKUP-SURVEY',
                survey_id: 'covid19',
            },
        });
    };

    const Button = <MibButton onClick={onOpenTutorial} type="success" label={T.translate('connections.activate')} />;

    return (
        <BaseContainer className="connection-segment" color="blue">
            <div className="connection-container-inner">
                <img
                    src="/assets/img/connections/medi_checkUp.png"
                    alt="wizard logo"
                    className="logo-image"
                    style={{ width: '15%' }}
                />
                <div className="connection-description">{T.translate('connections.covidTutorial')}</div>
                <div className="connection-controls">{Button}</div>
            </div>
        </BaseContainer>
    );
}

function HeadachePanel() {
    const onOpenHeadache = () => {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'CHECKUP-SURVEY',
                survey_id: 5,
            },
        });
    };

    const Button = <MibButton onClick={onOpenHeadache} type="success" label={T.translate('connections.initiate')} />;

    return (
        <BaseContainer className="connection-segment" color="blue">
            <div className="connection-container-inner">
                <img
                    src="/assets/img/connections/medi_checkUp.png"
                    alt="wizard logo"
                    className="logo-image"
                    style={{ width: '15%' }}
                />
                <div className="connection-description">{T.translate('connections.headacheTutorial')}</div>
                <div className="connection-controls">{Button}</div>
            </div>
        </BaseContainer>
    );
}

function TutorialsPage({ location, match, history }) {
    const { mode } = match.params;
    const panels = [CheckUpPanel, CovidPanel, TutorialPanel, HeadachePanel];

    return (
        <div className="connections-container">
            <ContentTitle icon="magic" title={T.translate('routes.health-indicators.checkup-menu-title')} />
            {panels.map((Panel) => (
                <Panel location={location} match={match} history={history} key={uuidv4()} />
            ))}
        </div>
    );
}

export default TutorialsPage;
