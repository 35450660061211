import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';

import { subscribe } from 'Mib/lib/subscribe';
import LabExamIndexPage from 'Mib/routes/health_folder/lab_exam_index';
import PersonLab from 'Mib/components/metric_panels/person_labs';
import PersonLabWithDoc from 'Mib/components/metric_panels/person_labs_with_doc';

class _LabPage extends Component {
    isInVivo(id) {
        const labExam = Object.values(this.props.labExams).find((l) => l.id === id);
        if (labExam) {
            return labExam.type === 'in-vivo';
        }
        return false;
    }

    render() {
        let { userSettings, measures, personLabs, user, match, tagFilters, changeParentState, labExams, ...rest } =
            this.props;
        // console.log('what have props in lab index out of return', this.props);
        return (
            <div className="content-container">
                <Grid.Column width={8}>
                    <Route exact path={`${match.url}`} component={LabExamIndexPage} />
                    <Route
                        exact
                        path={`${match.url}/:labId`}
                        render={(props) => {
                            return this.isInVivo(props.match.params.labId) ? (
                                <PersonLabWithDoc {...props} tagFilters={tagFilters} />
                            ) : (
                                <PersonLab {...props} tagFilters={tagFilters} />
                            );
                        }}
                    />
                </Grid.Column>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication: { user_id }, dataStore, userSettings }) => {
    const allmeasureUnits = dataStore.graph['measure-units'] && dataStore.graph['measure-units'];
    const labMeasureUnits = {};
    if (allmeasureUnits)
        Object.entries(allmeasureUnits).forEach(([key, unit]) => {
            // console.log('measureUnitType',unit)
            if (unit.measureUnitType && unit.measureUnitType.name === 'LAB') {
                labMeasureUnits[unit.id] = unit;
            }
        });

    const userSpecificSettings = getProperty(userSettings, `${user_id}`) || {};
    const user = getProperty(dataStore, `user.${user_id}`) || {};
    return {
        userSettings: userSpecificSettings,
        user: user,
        measures: labMeasureUnits,
        labExams: getProperty(dataStore.graph, 'lab-exams', {}),
    };
};

const _LabPageSub = subscribe(_LabPage, [
    'lab-groups?with=labExams',
    'lab-exam-events',
    'measure-units?with=measureUnitType',
]);

export const LabPage = connect(mapStateToProps)(_LabPageSub);
