import React, { Component } from 'react';
import T from 'i18n-react';

import BaseForm from './base_form';

class ModelForm extends Component {
    render() {
        const { items, ...rest } = this.props;
        return (
            <BaseForm
                {...rest}
                ref={(bf) => {
                    this.bf = bf;
                }}
            >
                {items.map(({ Element, value, label, ...props }, index) => (
                    <Element key={index} label={T.translate(label) + ':'} {...props} />
                ))}
            </BaseForm>
        );
    }
}

export default ModelForm;
