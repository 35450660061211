import React, { Suspense, useEffect, useRef, useState } from 'react';

import T from 'i18n-react';
import { Label } from 'semantic-ui-react';
import moment from 'moment';
import { getProperty } from 'dot-prop';
import { v4 as uuidv4 } from 'uuid';

import { backendURL } from '../../../config';
import { store } from '../../../datastore/store';
import { getDocFileType, getDocumentThumbnail } from '../../../lib/utils';
import s from './styles';

const Timeline = React.lazy(() => import('./timeline'));
const TimelineEvent = React.lazy(() => import('./timeline_event'));
const FilePreviewModal = React.lazy(() => import('../../modals/filePreviewModal'));

function RenderEvents({
    timelinePointClick,
    typeInTags,
    forceEventType,
    sortOn,
    confirmDeletion,
    data,
    withoutHoverPopUp = false,
    getActivityText,
    getActivityIcon,
}) {
    const filePreviewModal = useRef(null);
    const [returnList, setReturnList] = useState([]);

    if (sortOn == null) console.log('no sort on');

    const openFilePreviewModal = (fileUrl, fileType, fileTitle) => {
        filePreviewModal.current.open(fileUrl, fileType, fileTitle);
    };

    const handleEventDetailClick = (fileUrl, fileType, fileTitle) => {
        openFilePreviewModal(fileUrl, fileType, fileTitle);
    };

    useEffect(() => {
        const innerEventArr = Array.isArray(data) ? data : Object.values(data);
        if (sortOn) innerEventArr.sort((a, b) => (a[sortOn] < b[sortOn] ? 1 : -1));
        const innerReturnList =
            innerEventArr &&
            innerEventArr.map((event, key) => {
                let eventType = forceEventType || event._type;

                if (eventType === 'pressures' && !event.systolic && !event.diastolic) eventType = 'pulses';

                const eventId = event.id;
                if (eventType === undefined) return null;

                const eventTags = event.tags;
                let eventTagString = '';
                if (eventTags) eventTags.forEach(({ tag }) => (eventTagString += ` ${tag}`));
                const eventTagss = eventTags
                    ? eventTags.map(({ tag }) => (
                          <Label horizontal color="blue" key={uuidv4()}>
                              {tag}
                          </Label>
                      ))
                    : [];

                const eventCategory = event.category ? event.category.name : null;
                let eventDetail;
                let eventIcon;
                let eventColor;
                // let eventLink;
                let eventTitle;
                let eventPage;
                let eventDate;
                let evLabTypeId;
                let tagContent;
                let skipComments;
                let localWithoutHoverPopUp = withoutHoverPopUp;
                let fileUrl;
                let fileTitle;

                switch (eventType) {
                    case 'weights':
                        eventTitle = (
                            <span>
                                <i className="icon icon-scale" />
                                {T.translate('measurements.singular.weight')}
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="/img/desktop/weight.svg" />;
                        eventColor = '#50E3C2';
                        eventPage = 'Weights';
                        eventDetail = `${event.result} ${event.measureUnit.abbreviation}`;
                        break;
                    case 'temperatures':
                        eventTitle = (
                            <span>
                                <i className="icon icon-thermometer" />
                                {T.translate('measurements.singular.temperature')}
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="img/desktop/temperatures.svg" />;
                        eventColor = '#e4114d';
                        eventPage = 'Temperatures';
                        eventDetail = `${event.result} ${event.measureUnit.abbreviation}`;
                        break;
                    case 'heights':
                        eventTitle = (
                            <span>
                                <i className="icon icon-height" />
                                {T.translate('measurements.singular.height')}
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'Heights';
                        eventDetail = `${event.result} ${event.measureUnit.abbreviation}`;
                        break;
                    case 'bmis':
                        eventTitle = (
                            <span>
                                <i className="icon calculator" />
                                {T.translate('measurements.singular.bmi')}
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'Bmis';
                        eventDetail = event.result;
                        localWithoutHoverPopUp = true;
                        break;

                    case 'wths':
                        eventTitle = (
                            <span>
                                <i className="icon calculator" />
                                {T.translate('measurements.singular.wth')}
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'Wths';
                        eventDetail = event.result;
                        localWithoutHoverPopUp = true;
                        break;
                    case 'waists':
                        eventTitle = (
                            <span>
                                <i className="icon calculator" />
                                {T.translate('measurements.singular.waist')}
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'Waists';
                        eventDetail = `${event.result} ${event.measureUnit.abbreviation}`;
                        break;
                    case 'head-circumferences':
                        eventTitle = (
                            <span>
                                <i className="icon calculator" />
                                {T.translate('measurements.singular.head')}
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'Heads';
                        eventDetail = `${event.result} ${event.measureUnit.abbreviation}`;
                        break;
                    case 'oximetries':
                        eventTitle = (
                            <span>
                                <i className="icon icon-oxygen" />
                                {T.translate('measurements.singular.oximetry')}
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'Oximetries';
                        eventDetail = `${event.result} ${event.measureUnit.abbreviation}`;
                        break;

                    case 'pressures':
                        eventTitle = (
                            <span>
                                <i className="icon icon-pressure" />
                                {T.translate('measurements.singular.pressure')}
                            </span>
                        );
                        eventColor = '#B8E986';
                        eventPage = 'pressures';
                        eventDetail = event.pulses
                            ? `${event.diastolic} / ${event.systolic} ${event.measureUnit.abbreviation} - ${event.pulses} bpm `
                            : `${event.diastolic} / ${event.systolic} ${event.measureUnit.abbreviation}`;
                        break;
                    case 'pulses':
                        eventTitle = (
                            <span>
                                <i className="icon icon-heartbeat" />
                                {T.translate('measurements.plural.pulses')}
                            </span>
                        );
                        eventColor = '#B8E986';
                        eventPage = 'pressures';
                        eventDetail =
                            event.diastolic || event.systolic
                                ? `${event.diastolic} / ${event.systolic} ${event.measureUnit.abbreviation} - ${event.pulses} bpm `
                                : `${event.pulses} bpm `;
                        break;
                    case 'lab-exam-events':
                        eventTitle = event.labExam.name;
                        evLabTypeId = event.labExam.id;
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'Heights';
                        eventDetail = `${event.result} ${event.measureUnit.abbreviation}`;
                        break;
                    case 'lab-exam-document':
                        eventTitle = event.labExam.name;
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'documents';
                        const stateE = store.getState();
                        const activePersonIdE = getProperty(stateE, 'authentication.activePersonId');
                        const access_tokenE = getProperty(stateE, 'authentication.access_token');
                        const thumbnailE = event.documents.length && getDocumentThumbnail(event.documents[0]);

                        fileUrl =
                            event.documents.length &&
                            `${backendURL}persons/${activePersonIdE}/download-document/${event.documents[0].file}?X-Authorization=Bearer ${access_tokenE}`;

                        eventDetail = eventDetail = fileUrl !== 0 ? <a href={fileUrl}>{thumbnailE}</a> : '';
                        eventType = 'lab-exam-events';
                        break;

                    case 'documents':
                        eventTitle = (
                            <span>
                                <i className="icon icon-scale" />
                                <span style={{ fontWeight: 'bold' }}>{event.title}</span>
                            </span>
                        );
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        eventPage = 'documents';
                        const state = store.getState();
                        const activePersonId = getProperty(state, 'authentication.activePersonId');

                        const access_token = getProperty(state, 'authentication.access_token');
                        let thumbnail = (
                            <img
                                style={{ maxWidth: '100px' }}
                                src={`${backendURL}persons/${activePersonId}/download-document/${event.file}?X-Authorization=Bearer ${access_token}`}
                            />
                        );
                        thumbnail = getDocumentThumbnail(event);
                        const type = getDocFileType(event);

                        fileUrl = `${backendURL}persons/${activePersonId}/download-document/${event.file}?X-Authorization=Bearer ${access_token}`;
                        eventDetail = (
                            <div
                                style={{ cursor: 'grab' }}
                                onClick={() => handleEventDetailClick(fileUrl, type, event.title)}
                            >
                                {thumbnail}
                            </div>
                        );
                        break;
                    case 'activities':
                        eventTitle = event.activityType && event.activityType.name;
                        eventIcon = <img style={s.eventIcon} src="img/desktop/height.svg" />;
                        eventColor = '#B8E986';
                        // eventPage = "Heights";
                        eventDetail = getActivityText(event);
                        eventDate = `${moment(event.start).format('L LT')} - ${moment(event.end).format('L LT')}`;
                        tagContent = (
                            <span>
                                <i className={`mib-mono-icon ${getActivityIcon(event)}`} />{' '}
                                {moment(event.start).format('L')}
                            </span>
                        );
                        skipComments = true;
                        break;
                    default:
                        eventTitle = eventType || '';
                        eventDetail = event.result || '';
                        return null;
                }

                return (
                    <div key={uuidv4()}>
                        <FilePreviewModal ref={filePreviewModal} />

                        <Suspense fallback={null}>
                            <TimelineEvent
                                confirmDeletion={confirmDeletion}
                                title={eventTitle}
                                tagContent={
                                    tagContent || (typeInTags ? eventTitle : eventDate || moment(event.date).format('L'))
                                }
                                type={eventType}
                                evId={eventId}
                                evLabTypeId={evLabTypeId}
                                container="card"
                                icon={eventIcon}
                                eventColor={eventColor}
                                createdAt={eventDate || moment(event.date).format('L')}
                                value={eventDetail}
                                skipComments={skipComments}
                                tags={eventTagss}
                                category={eventCategory}
                                timelinePointClick={timelinePointClick}
                                event={event}
                                withoutHoverPopUp={localWithoutHoverPopUp}
                            >
                                {event.comments || null}
                            </TimelineEvent>
                        </Suspense>
                    </div>
                );
            });

        if (innerReturnList && !innerReturnList.some((e) => e != null)) {
            innerReturnList.push(
                <div key={uuidv4()}>
                    <Suspense fallback={null}>
                        <TimelineEvent
                            title={T.translate('meta.welcome')}
                            container="card"
                            createdAt={moment().format('L')}
                            value="&nbsp;"
                            showCommentTitle={false}
                            withoutHoverPopUp
                        >
                            {T.translate('meta.welcome-text')}
                        </TimelineEvent>
                    </Suspense>
                </div>
            );
        }

        setReturnList(innerReturnList);
    }, [data]);

    return <div key={uuidv4()}>{returnList}</div>;
}

export default class TimelineEventList extends React.Component {
    getActivityText(activity) {
        const textArray = [];
        if (activity.distance) {
            textArray.push(`${activity.distance} ${getProperty(activity, 'distanceUnit.abbreviation') || ''}`);
        }
        if (activity.steps) {
            textArray.push(`${activity.steps} ${T.translate('activities.steps')}`);
        }
        if (activity.velocity) {
            textArray.push(`${activity.velocity} ${getProperty(activity, 'velocityUnit.abbreviation') || ''}`);
        }
        if (activity.calories) {
            textArray.push(`${activity.calories} ${getProperty(activity, 'calorieUnit.abbreviation') || ''}`);
        }
        return (
            <div className="activity-event-value">
                {' '}
                {textArray.map((e) => (
                    <div key={uuidv4()}>{e}</div>
                ))}
            </div>
        );
    }

    getActivityIcon(activity) {
        // const iconMap = {
        //     "0": "child",
        //     "1": "icon-walking",
        //     "2": "icon-running",
        //     "3": "bicycle",
        //     "4": "icon-skiing",
        //     "5": "icon-withings",
        // };

        const iconMap = {
            0: 'mibmono-excersise',
            1: 'mibmono-walking',
            2: 'mibmono-running',
            3: 'mibmono-bicycle',
            4: 'mibmono-skiing',
            5: 'icon-withings',
        };
        return getProperty(iconMap, activity.activityType.id) || 'question';
    }

    render() {
        const { timelinePointClick, typeInTags, forceEventType, sortOn, confirmDeletion, data } = this.props;
        return (
            <Suspense fallback={null}>
                <Timeline>
                    <RenderEvents
                        timelinePointClick={timelinePointClick}
                        typeInTags={typeInTags}
                        forceEventType={forceEventType}
                        sortOn={sortOn}
                        confirmDeletion={confirmDeletion}
                        data={data}
                        getActivityText={this.getActivityText}
                        getActivityIcon={this.getActivityIcon}
                    />
                </Timeline>
            </Suspense>
        );
    }
}
