import React, { Component } from 'react';
import { Message, Icon, Transition } from 'semantic-ui-react';
import T from 'i18n-react';

import { ContentTitle } from 'Mib/components/generic/content_title';
import BaseContainer from 'Mib/components/containers/base';
import PanelInput, { PanelDateInput, PanelDropdownInput } from 'Mib/components/inputs/panel_inputs';

import { TextInput, MibButton } from 'Mib/components/inputs';

class _PersonForm extends Component {
    constructor() {
        super();
        this.state = { pwInfoColor: 'olive', animateMessage: true };
    }

    onSaveClicked() {
        const { values } = this.props;
        const un = values.username;
        const pw = values.password;
        if (!un || !pw) {
            this.usernameInput.focus();
            this.setState({
                pwInfoColor: 'red',
                animateMessage: !this.state.animateMessage,
            });
            setTimeout(() => this.setState({ pwInfoColor: 'olive' }), 3000);
        } else {
            this.props.save();
            this.props.onFormChanged('username', { target: { value: '' } }, undefined, true);
            // We need this because onFormChanged can breack if run multiple times simultaneously
            setTimeout(() => this.props.onFormChanged('password', { target: { value: '' } }, undefined, true), 10);
        }
    }

    render() {
        // const persons = Object.values(this.props.persons || {});
        const { values, onFormChanged, save, cancel, title, errors = [] } = this.props;

        return (
            <>
                <ContentTitle
                    title={T.translate('routes.profile.genetic')}
                    icon="mibcolor-genetic"
                    style={{
                        color: '#2eaadc',
                        borderBottom: '1px solid black',
                        width: '100%',
                    }}
                />
                <div className="person-panel-form-container">
                    <PanelDropdownInput
                        value={values['blood-type'] || 0}
                        label={T.translate('persons.blood-type')}
                        onChange={(e, v) => onFormChanged('blood-type', e, v)}
                        options={Object.values(this.props.bloodTypes || {}).map((bt) => ({
                            key: bt.id,
                            text: bt.type,
                            value: bt.id,
                        }))}
                        changeOnWheel
                        focusable
                    />
                    <PanelDropdownInput
                        value={values.gender || 0}
                        label={T.translate('persons.gender')}
                        onChange={(e, v) => onFormChanged('gender', e, v)}
                        options={Object.values({
                            0: { id: '0', type: T.translate('genders.0') },
                            1: { id: '1', type: T.translate('genders.1') },
                            2: { id: '2', type: T.translate('genders.2') },
                        }).map((bt) => ({
                            key: bt.id,
                            text: bt.type,
                            value: bt.id,
                        }))}
                        radiusLarge={110}
                        radiusSmall={100}
                        changeOnWheel
                        encrypted
                        focusable
                    />
                    <PanelDateInput
                        value={values.dob}
                        label={T.translate('persons.dob')}
                        onChange={(e, v) => onFormChanged('dob', e, v)}
                        maxDate
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        encrypted
                        focusable
                    />
                    <PanelDropdownInput
                        value={values.dominant_hand || 2}
                        label={T.translate('persons.handedness')}
                        onChange={(e, v) => onFormChanged('dominant_hand', e, v)}
                        options={Object.values({
                            0: { id: '0', type: T.translate('handedness.0') },
                            1: { id: '1', type: T.translate('handedness.1') },
                            2: { id: '2', type: T.translate('handedness.2') },
                        }).map((bt) => ({
                            key: bt.id,
                            text: bt.type,
                            value: bt.id,
                        }))}
                        radiusLarge={110}
                        radiusSmall={100}
                        changeOnWheel
                        encrypted
                        focusable
                    />
                </div>
                <ContentTitle
                    title={T.translate('routes.profile.demographics')}
                    icon="mibcolor-profile"
                    style={{
                        color: '#2eaadc',
                        borderBottom: '1px solid black',
                        width: '100%',
                    }}
                />
                <div className="person-panel-form-container">
                    <PanelInput
                        value={values.first_name}
                        onChange={(e, v) => onFormChanged('first_name', e, v)}
                        errors={errors.filter((e) => e.property == 'first_name')}
                        label={T.translate('persons.first_name')}
                        encrypted
                        focusable
                    />
                    <PanelInput
                        value={values.last_name}
                        onChange={(e, v) => onFormChanged('last_name', e, v)}
                        errors={errors.filter((e) => e.property == 'last_name')}
                        label={T.translate('persons.last_name')}
                        encrypted
                        focusable
                    />
                    <PanelInput
                        value={values.email || ''}
                        label={T.translate('persons.email')}
                        onChange={(e, v) => onFormChanged('email', e, v)}
                        errors={errors.filter((e) => e.property == 'email')}
                        encrypted
                        focusable
                    />
                    <PanelInput
                        value={values.ssn || ''}
                        label={T.translate('persons.ssn')}
                        onChange={(e, v) => onFormChanged('ssn', e, v)}
                        encrypted
                        focusable
                    />
                    <PanelInput
                        value={values.phone || ''}
                        label={T.translate('persons.phone')}
                        onChange={(e, v) => onFormChanged('phone', e, v)}
                        encrypted
                        focusable
                    />
                    <PanelInput
                        value={values.mobile || ''}
                        label={T.translate('persons.mobile')}
                        onChange={(e, v) => onFormChanged('mobile', e, v)}
                        encrypted
                        focusable
                    />
                    <PanelInput
                        value={values.address || ''}
                        label={T.translate('persons.address')}
                        onChange={(e, v) => onFormChanged('address', e, v)}
                        encrypted
                        focusable
                    />
                    <PanelInput
                        value={values.zipcode || ''}
                        label={T.translate('persons.zipcode')}
                        onChange={(e, v) => onFormChanged('zipcode', e, v)}
                        encrypted
                        focusable
                    />
                </div>
                {/*
        <BaseContainer style={{ padding: "30px" }}>
          <h1>{T.translate(title)}</h1>
          <TextInput
            value={values.first_name || ""}
            label={T.translate("persons.first_name")}
            onChange={(e, v) => onFormChanged("first_name", e, v)}
            errors={errors.filter(e => e.property == "first_name")}
          />
          <TextInput
            value={values.last_name || ""}
            label={T.translate("persons.last_name")}
            onChange={(e, v) => onFormChanged("last_name", e, v)}
            errors={errors.filter(e => e.property == "last_name")}
          />
          <TextInput
            value={values.email || ""}
            label={T.translate("persons.email")}
            onChange={(e, v) => onFormChanged("email", e, v)}
            errors={errors.filter(e => e.property == "email")}
          />
          <CalendarInput
            value={values.dob || ""}
            label={T.translate("persons.dob")}
            onChange={(e, v) => onFormChanged("dob", e, v)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <TextInput
            value={values.ssn || ""}
            label={T.translate("persons.ssn")}
            onChange={(e, v) => onFormChanged("ssn", e, v)}
          />
          <TextInput
            value={values.phone || ""}
            label={T.translate("persons.phone")}
            onChange={(e, v) => onFormChanged("phone", e, v)}
          />
          <TextInput
            value={values.mobile || ""}
            label={T.translate("persons.mobile")}
            onChange={(e, v) => onFormChanged("mobile", e, v)}
          />
          <TextInput
            value={values.address || ""}
            label={T.translate("persons.address")}
            onChange={(e, v) => onFormChanged("address", e, v)}
          />
          <TextInput
            value={values.zipcode || ""}
            label={T.translate("persons.zipcode")}
            onChange={(e, v) => onFormChanged("zipcode", e, v)}
          />
          <DropDownInput
            value={values["blood-type"] || ""}
            label={T.translate("persons.blood-type")}
            onChange={(e, v) => onFormChanged("blood-type", e, v)}
            options={Object.values(this.props.bloodTypes || {}).map(bt => ({
              key: bt.id,
              text: bt.type,
              value: bt.id
            }))}
          />

          <DropDownInput
            value={values.gender || ""}
            label={T.translate("persons.gender")}
            onChange={(e, v) => onFormChanged("gender", e, v)}
            options={Object.values({
              1: { id: "1", type: T.translate("male") },
              2: { id: "2", type: T.translate("female") }
            }).map(bt => ({
              key: bt.id,
              text: bt.type,
              value: bt.id
            }))}
          />
        </BaseContainer>
*/}
                <BaseContainer style={{ marginTop: '30px', padding: '30px' }} containerStyle={{ width: '100%' }}>
                    <TextInput
                        value={values.username || ''}
                        ref={(i) => (this.usernameInput = i)}
                        label={T.translate('persons.username')}
                        onChange={(e, v) => onFormChanged('username', e, v)}
                    />
                    <TextInput
                        type="password"
                        value={values.password || ''}
                        ref={(i) => (this.passwordInput = i)}
                        label={T.translate('meta.login.password')}
                        onChange={(e, v) => onFormChanged('password', e, v)}
                    />
                    <Transition animation="pulse" duration={500} visible={this.state.animateMessage}>
                        <Message color={this.state.pwInfoColor} icon="info">
                            <Icon name="info circle" />
                            {T.translate('persons.must-login-to-edit')}
                        </Message>
                    </Transition>
                </BaseContainer>
                <BaseContainer style={{ marginTop: '30px' }} containerStyle={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <MibButton type="danger" label={T.translate('meta.cancel')} onClick={cancel} />
                        <MibButton
                            type="success"
                            label={T.translate('meta.save')}
                            onClick={this.onSaveClicked.bind(this)}
                        />
                    </div>
                </BaseContainer>
            </>
        );
    }
}

export const PersonForm = _PersonForm;
