import React, { Component, Fragment as F } from 'react';
import { Message, Icon, Transition } from 'semantic-ui-react';
import T from 'i18n-react';

import BaseContainer from 'Mib/components/containers/base';
import { TextInput, CalendarInput, MibButton, DropDownInput } from 'Mib/components/inputs';
import { WithPayment } from '../../../billing/withPaymentWrapper';
import BillingDimmer from '../../../billing/dimmer';

class _PersonForm extends Component {
    constructor() {
        super();
        this.state = { pwInfoColor: 'olive', animateMessage: true };
    }
    render() {
        // const persons = Object.values(this.props.persons || {});
        const { values, onFormChanged, save, cancel, title, errors = [] } = this.props;
        let hasErrors = errors.length > 0;

        return (
            <F>
                <WithPayment feature_id="premium.connections.syncs">
                    {(props) => (
                        <F>
                            {!props.canViewFeature ? (
                                <BillingDimmer
                                    mediMsg={`${T.translate('billing.you-are-basic')}`}
                                    feature_id={props.feature_id}
                                />
                            ) : null}

                            <BaseContainer style={{ padding: '30px' }}>
                                <h1>{T.translate(title)}</h1>
                                <TextInput
                                    value={values.first_name || ''}
                                    label={T.translate('persons.first_name')}
                                    onChange={(e, v) => onFormChanged('first_name', e, v)}
                                    errors={errors.filter((e) => e.property == 'first_name')}
                                />
                                <TextInput
                                    value={values.last_name || ''}
                                    label={T.translate('persons.last_name')}
                                    onChange={(e, v) => onFormChanged('last_name', e, v)}
                                    errors={errors.filter((e) => e.property == 'last_name')}
                                />
                                <TextInput
                                    value={values.email || ''}
                                    label={T.translate('persons.email')}
                                    onChange={(e, v) => onFormChanged('email', e, v)}
                                    errors={errors.filter((e) => e.property == 'email')}
                                />
                                <CalendarInput
                                    value={values.dob || ''}
                                    label={T.translate('persons.dob')}
                                    onChange={(e, v) => onFormChanged('dob', e, v)}
                                    showMonthDropdown
                                    maxDate
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                <TextInput
                                    value={values.ssn || ''}
                                    label={T.translate('persons.ssn')}
                                    onChange={(e, v) => onFormChanged('ssn', e, v)}
                                />
                                <TextInput
                                    value={values.phone || ''}
                                    label={T.translate('persons.phone')}
                                    onChange={(e, v) => onFormChanged('phone', e, v)}
                                />
                                <TextInput
                                    value={values.mobile || ''}
                                    label={T.translate('persons.mobile')}
                                    onChange={(e, v) => onFormChanged('mobile', e, v)}
                                />
                                <TextInput
                                    value={values.address || ''}
                                    label={T.translate('persons.address')}
                                    onChange={(e, v) => onFormChanged('address', e, v)}
                                />
                                <TextInput
                                    value={values.zipcode || ''}
                                    label={T.translate('persons.zipcode')}
                                    onChange={(e, v) => onFormChanged('zipcode', e, v)}
                                />
                                <DropDownInput
                                    value={values['blood-type'] || ''}
                                    label={T.translate('persons.blood-type')}
                                    onChange={(e, v) => onFormChanged('blood-type', e, v)}
                                    options={Object.values(this.props.bloodTypes || {}).map((bt) => ({
                                        key: bt.id,
                                        text: bt.type,
                                        value: bt.id,
                                    }))}
                                />

                                <DropDownInput
                                    value={values.gender || ''}
                                    label={T.translate('persons.gender')}
                                    onChange={(e, v) => onFormChanged('gender', e, v)}
                                    options={Object.values({
                                        1: { id: '1', type: T.translate('male') },
                                        2: { id: '2', type: T.translate('female') },
                                    }).map((bt) => ({
                                        key: bt.id,
                                        text: bt.type,
                                        value: bt.id,
                                    }))}
                                />
                            </BaseContainer>

                            <BaseContainer style={{ marginTop: '30px', padding: '30px' }}>
                                <TextInput
                                    value={values.username || ''}
                                    ref={(i) => (this.usernameInput = i)}
                                    label={T.translate('persons.username')}
                                    onChange={(e, v) => onFormChanged('username', e, v)}
                                    errors={errors.filter((e) => e.property == 'username')}
                                />
                                <TextInput
                                    type="password"
                                    value={values.password || ''}
                                    ref={(i) => (this.passwordInput = i)}
                                    label={T.translate('meta.login.password')}
                                    onChange={(e, v) => onFormChanged('password', e, v)}
                                    errors={errors.filter((e) => e.property == 'password')}
                                />
                                <Transition animation="pulse" duration={500} visible={this.state.animateMessage}>
                                    <Message color={this.state.pwInfoColor} icon="info">
                                        <Icon name="info circle" />
                                        {T.translate('persons.must-login-to-edit')}
                                    </Message>
                                </Transition>
                            </BaseContainer>
                            {!hasErrors && (
                                <BaseContainer style={{ marginTop: '30px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <MibButton type="danger" label={T.translate('meta.cancel')} onClick={cancel} />
                                        <MibButton
                                            type="success"
                                            label={T.translate('meta.save')}
                                            onClick={this.onSaveClicked.bind(this)}
                                        />
                                    </div>
                                </BaseContainer>
                            )}
                        </F>
                    )}
                </WithPayment>
            </F>
        );
    }

    onSaveClicked() {
        const { values } = this.props;
        const un = values.username;
        const pw = values.password;
        if (!un || !pw) {
            this.usernameInput.focus();
            this.setState({ pwInfoColor: 'red', animateMessage: !this.state.animateMessage });
            setTimeout(() => this.setState({ pwInfoColor: 'olive' }), 3000);
        } else {
            this.props.save();
            this.props.onFormChanged('username', { target: { value: '' } });
            // We need this because onFormChanged can breack if run multiple times simultaneously
            setTimeout(() => this.props.onFormChanged('password', { target: { value: '' } }), 10);
        }
    }
}

export const PersonForm = _PersonForm;
