import React from 'react';
import { connect } from 'react-redux';
import T from 'i18n-react';
import { withRouter } from 'react-router-dom';

import GeneralMetricContainer from 'Mib/components/metric_panels/general_metric';
import { openMeasurementModal } from 'Mib/actions';
import filterByMeasureSystem from 'Mib/lib/measure_units';
import { subscribe } from '../../lib/subscribe';
import { FilteredMetricComponent } from './filtered_metric';

class _HeadContainer extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {}, unitId: null };

        this.open = this.open.bind(this);
        this.dataPropName = 'heads';
        this.config = {
            descriptor: 'result',
            name: T.translate('measurements.singular.head'),
            dateDescriptor: 'date',
            valueOffset: 1,
            onGraphPointClicked: this.open,
        };
    }

    render() {
        const { heads, measures, children, ...rest } = this.props;
        const { chart_config, heads: filteredHeads } = this.state;

        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate('measurements.singular.head')}
                dataType="heads"
                measureUnits={measures}
                chart_config={chart_config}
                data={filteredHeads}
                graphPointClick={this.open}
                unitId={this.state.unitId}
                configUpdateUnitFunc={this.configUpdateUnitFunc.bind(this)}
            />
        );
    }

    open(id = undefined, type = undefined) {
        const { unitId } = this.state;
        openMeasurementModal({ id, type, unitId });
    }
}

const mapStateToProps = (state) => {
    const allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    const headMeasureUnits = {};
    const systemMeasureUnits = filterByMeasureSystem(allmeasureUnits);
    if (systemMeasureUnits)
        Object.entries(systemMeasureUnits).forEach(([key, unit]) => {
            if (unit.measureUnitType && unit.measureUnitType.name === 'HEIGHT') {
                headMeasureUnits[unit.id] = unit;
            }
        });

    return {
        heads: state.dataStore.graph['head-circumferences'],
        measures: headMeasureUnits,
        filters: state.filters,
    };
};

const _HeadContainerSub = subscribe(_HeadContainer, ['head-circumferences', 'measure-units?with=measureUnitType']);

export default withRouter(connect(mapStateToProps)(_HeadContainerSub));
