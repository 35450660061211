import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import Fab from './fab';
import { Transition, Grid } from 'semantic-ui-react';

require('./side_menu.scss');

export default class SideMenu extends Component {
    constructor() {
        super();
        this.state = { visible: false };
    }

    render() {
        const { visible } = this.state;
        const { history, children } = this.props;
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <Grid.Column width={4}>{children}</Grid.Column>
                </MediaQuery>
                <MediaQuery maxWidth={1200}>
                    <Fab
                        title="hello"
                        icon="list layout"
                        x="5px"
                        y="5px"
                        background="#d92e74"
                        onClick={() => {
                            this.setState({ visible: !visible });
                            this.props.onToggle(!visible);
                        }}
                    />
                    <Transition.Group animation="slide right">
                        {visible ? (
                            <div
                                className="sub-menu-container"
                                onClick={() => {
                                    this.setState({ visible: !visible });
                                    this.props.onToggle(!visible);
                                }}
                            >
                                {children}
                            </div>
                        ) : null}
                    </Transition.Group>
                </MediaQuery>
            </>
        );
    }
}
