import React, { Component } from 'react';
import T from 'i18n-react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import classnames from 'classnames';

import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { MenuPanel } from 'Mib/components/containers';
import { shouldRenderFeature } from 'Mib/lib/feature_toggle';

// generateLabExamItem(lab, key) {
const LabExamRow = (lab, key, match) => {
    // const icon =
    //     lab.id in labIcons ? (
    //         <span className={labIcons[lab.id] + " lab-exam-icon"} />
    //     ) : (
    //         <Icon className="icon-microscope" />
    //     );
    const icon = lab.icon ? (
        <span className={`${lab.icon} mib-lab lab-exam-icon`} />
    ) : (
        <Icon className="icon-microscope" />
    );
    return {
        key: key,
        label: (
            <>
                {icon}
                {lab.name}
            </>
        ),
        to: `${match.url}/labs/${lab.id}`,
        as: NavLink,
    };
};

class LabExamMenu extends Component {
    constructor(props) {
        super();
        this.labMenuItems = [];
        this.fuzzyOptions = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ['label'],
        };
        this.state = {
            labMenuItems: this.labMenuItems,
            expandedCategories: [],
            viewingSearchResults: false,
        };
    }

    generateCategoryTitle(group, key, expanded = false) {
        // FEATURE TOGGLE
        const isVisible = shouldRenderFeature('lab_categories');

        const icon = group.icon ? `mib-lab ${group.icon}` : 'mib-lab miblab-body';
        return {
            key: key,
            label: (
                <>
                    <span className={`${icon} category-icon`} />
                    <span style={{ flex: 1 }}>{group.name}</span> <Icon name="angle down" />
                </>
            ),
            onClick: this.toggleCategoryExpanded.bind(this, group.id),
            as: 'div',
            className: classnames('lab-panel-category', { expanded: expanded }),
            style: { display: isVisible ? 'flex' : 'none' },
        };
    }

    generateMenuItems(props) {
        const { expandedCategories, viewingSearchResults } = this.state;
        const { labs = {}, labGroups = {}, match } = props;

        // Get an array of all lab-exam ids to later detect which do not belong in a group
        let labIds = Object.values(labs).map((l) => l.id);
        const menuItems = [];

        Object.values(labGroups)
            .filter((lg) => lg.labExams.length > 0)
            .forEach((group, index) => {
                // FEATURE TOGGLE
                const isExpanded = shouldRenderFeature('lab_categories') ? expandedCategories.includes(group.id) : true;
                // const isExpanded = expandedCategories.includes(group.id);

                // Create title
                menuItems.push(this.generateCategoryTitle(group, index, isExpanded));
                // Add exams
                group.labExams.forEach((lab, ind2) => {
                    labIds = labIds.filter((id) => id != lab.id);
                    if (isExpanded || viewingSearchResults) {
                        menuItems.push(LabExamRow(lab, `${index}-${ind2}`, match));
                    }
                });
            });
        // Labs that don't belong in a group
        const others = Object.values(labs).filter((lab) => labIds.includes(lab.id));
        if (others.length) {
            // Create title
            const isExpanded = shouldRenderFeature('lab_categories') ? expandedCategories.includes('other') : true;
            // const isExpanded = expandedCategories.includes("other");

            menuItems.push(this.generateCategoryTitle({ name: T.translate('other'), id: 'other' }, 999, isExpanded));
            // Add exams
            if (isExpanded || viewingSearchResults) {
                others.forEach((lab, ind2) => {
                    menuItems.push(LabExamRow(lab, `999-${ind2}`, match));
                });
            }
        }
        return menuItems;
        //             .sort((a, b) => a.position - b.position)) ||
    }

    toggleCategoryExpanded(id) {
        let { expandedCategories } = this.state;
        if (expandedCategories.includes(id)) {
            expandedCategories = expandedCategories.filter((i) => i != id);
        } else {
            expandedCategories.push(id);
        }
        this.setState({ expandedCategories });
    }

    render() {
        const { loading } = this.props;

        const labMenuItems = this.generateMenuItems(this.props);
        const menuTitle = (
            <Menu.Item
                as={NavLink}
                to="/health-folder/labs/"
                // isActive={this.isActive.bind(this)}
                className="menu-title emergency-title"
            >
                <Icon className="icon-microscope" />
                <span className="title">{T.translate('labs')}</span>
                <Icon name="angle down" />
            </Menu.Item>
        );
        return (
            <MenuPanel
                className="teal mib-menu lab-exams-menu"
                inverted
                title={menuTitle}
                items={labMenuItems}
                collapse
                loading={loading}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        labs: state.dataStore.graph['lab-exams'],
        labGroups: state.dataStore.graph['lab-groups'],
    };
};

export default subscribeWithLoader(LabExamMenu, mapStateToProps, ['lab-exams?']);
