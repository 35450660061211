import React, { Component } from 'react';
// import { connect } from 'react-redux';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import sortOn from 'sort-on';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';

import Knob from 'Mib/vendor/canvas-knob';
import BaseContainer from 'Mib/components/containers/base';
import { MibButton } from 'Mib/components/inputs/buttons';
import { Measurement } from 'Mib/lib/models';
import { Create } from 'Mib/actions/backend';
import { notify } from 'Mib/actions/notifications';
import QuickAddSelect from 'Mib/components/inputs/quickAddSelect';

import { MeasurementInfoPanel } from './measurements_info';

// import Select from 'Mib/components/inputs/select';
// import { getUserMeasureUnit } from './getQuickAddMeasureUnit';
// import mapStateToProps from 'react-redux/lib/connect/mapStateToProps';

window.moment = moment;

class _QuickAddPanel extends Component {
    save() {
        let { value, muId } = this.state;
        if (value == null) {
            let { data } = this.props;
            const lastMeasurement = data && sortOn(Object.values(data), '-date')[0];
            value = lastMeasurement.result;
        }
        const model = Measurement(this.dataType, Number(value), Number(muId));
        Create(model)
            .then(() => console.log('asdasdasd'))
            .catch((e) =>
                notify({
                    error: true,
                    header: T.translate('errors.create.header'),
                    content: e.errors[0] ? e.errors[0].detail : T.translate('errors.create.content'),
                })
            );

        // console.log('salalalalalal' , e.errors[0].detail) )
    }

    render() {
        let { chart_config } = this.state;
        let { data, loading, color, measureUnitFromSettings } = this.props;

        const lastMeasurement = data !== undefined ? data && sortOn(Object.values(data), '-date')[0] : null;
        const measureDate = moment(getProperty(lastMeasurement, 'date')).format('L');
        const measureTime = moment(getProperty(lastMeasurement, 'date')).format('HH:mm');
        const measureUnitAbbrev = getProperty(lastMeasurement, 'measureUnit.abbreviation');
        const value = lastMeasurement ? lastMeasurement.result + measureUnitAbbrev : undefined;
        const selectedMeasureUnit = measureUnitFromSettings && {
            value: measureUnitFromSettings.id,
            text: measureUnitFromSettings.abbreviation,
            key: measureUnitFromSettings.id,
        };

        return (
            <>
                <MeasurementInfoPanel
                    style={{ borderTopColor: this.props.color, borderTopWidth: '2px' }}
                    title={this.title}
                    date={measureDate}
                    time={measureTime}
                    value={value}
                    altText={T.translate('no-data')}
                    loading={loading}
                    color={this.props.color}
                    link={this.link}
                />
                <BaseContainer
                    // loading={loading}
                    className="dash-chart-container"
                    style={{
                        borderRadius: '0 0 .28571429rem .28571429rem',
                        height: 'calc(200px + 1rem)',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <div style={{ flex: 1, alignSelf: 'flex-start' }}>
                            <h3 style={{ color: 'rgba(0,0,0, 0.49)', paddingTop: '10px' }}>
                                {T.translate('quick-add')}
                            </h3>
                        </div>
                        <Knob
                            value={Number(this.state.value || getProperty(lastMeasurement, 'result') || 0)}
                            min={this.minimums ? this.minimums[this.state.muId] : 0}
                            max={this.maximums[this.state.muId]}
                            height={160}
                            width={160}
                            step={this.steps[this.state.muId]}
                            bgColor="#ccc"
                            fgColor={color || '#b5cc18'}
                            onChange={(value) => this.setState({ value })}
                        />
                        <div style={{ flex: 1 }}>
                            {/* <Dropdown
                                placeholder="units"
                                options={measureUnitOptions}
                                selection
                                onChange={(e, v) => this.setState({ muId: v.value })}
                                // onChange={console.log}
                                value={this.state.muId}
                                style={{ marginLeft: '1em', minWidth: "30px", color: color || 'black'}}
                            /> */}

                            <QuickAddSelect
                                value={this.state.muId}
                                item={selectedMeasureUnit}
                                style={{ marginLeft: '1.5em' }}
                                itemStyle={{ color: this.props.color }}
                                onChange={(val) => this.setState({ muId: val })}
                            />

                            {/* <Select */}
                            {/*     value={this.state.muId} */}
                            {/*     items={measureUnitOptions} */}
                            {/*     style={{ marginLeft: "1.5em" }} */}
                            {/*     itemStyle={{ color: this.props.color }} */}
                            {/*     onChange={val => this.setState({ muId: val })} */}
                            {/* /> */}
                        </div>
                    </div>
                    <MibButton
                        label={
                            <>
                                <Icon name="add" />
                                {T.translate('create')}
                            </>
                        }
                        style={{ backgroundColor: '#b5cc18', color: 'white' }}
                        onClick={this.save.bind(this)}
                    />
                </BaseContainer>
            </>
        );
    }
}
export const QuickAddPanel = _QuickAddPanel;

// const mapStateToProps =  state => {
//
//     console.log('THIS IS STATE', state)
//     let userId = state.dataStore && getProperty(state, "authentication.id")
//     let userSettings =  getProperty(state, `dataStore.graph.user-settings.${userId}`);
//     let defaultMeasureUnit = userSettings && getUserMeasureUnit(userSettings, 'weight')
//
//
//     return {
//         measureUnitFromSettings: defaultMeasureUnit,
//     }
// };
// export const QuickAddPanel = connect(mapStateToProps)(_QuickAddPanel) ;

// const mapStateToProps = state => {
//     var allmeasureUnits = state.dataStore.graph["measure-units"];
//     var weightMeasureUnits = {};
//     allmeasureUnits &&
//         Object.entries(allmeasureUnits).forEach(([key, unit]) => {
//             // console.log('measureUnitType',unit)
//             if (unit.measureUnitType && unit.measureUnitType.name == "WEIGHT") {
//                 weightMeasureUnits[unit.id] = unit;
//             }
//         });
//
//     return {
//         weights: state.dataStore.graph.weights,
//         measures: weightMeasureUnits,
//         weightTarget: state.dataStore.graph.meta && state.dataStore.graph.meta.weightTarget,
//         filters: state.filters,
//     };
// };
//
// export const DashWeightQuickAdd = subscribeWithLoader(_DashWeightChart, mapStateToProps, ["weights","measure-units?with=measureUnitType"]);
