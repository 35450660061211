import React, { Component } from 'react';
import T from 'i18n-react';
import { connect } from 'react-redux';
import { getProperty } from 'dot-prop';
import { Icon, Segment, Table, Button, Checkbox, Popup } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

import { notify } from 'Mib/actions/notifications';
import { Fetch } from 'Mib/lib/fetch';
import { store } from 'Mib/datastore/store';

import { fetchAction } from 'Mib/actions/backend';
import { RangeCalendarInput } from '../../../components/inputs/calendars/rangeCalendarInput';

class _PermissionsCard extends Component {
    constructor({ permissions, categories }) {
        super();
        const perValues = {};
        let start;
        let end;
        if (
            permissions &&
            Object.values(permissions).length === 1 &&
            Object.values(permissions)[0].category_id === null
        ) {
            // let allSelect = {};
            /**** why did we do this???????? *****/
            // Object.values(categories).forEach(item => perValues[item.id] = 3);
        } else if (permissions) {
            Object.values(permissions).forEach((item) => {
                perValues[item.category_id] = item.type;
            });

            if (Object.values(permissions).length > 0) {
                start = Object.values(permissions)[0].start;
                end = Object.values(permissions)[0].end;
            }
        }

        this.state = {
            permissionsValues: perValues,
            start: start ? new Date(start) : null,
            end: end ? new Date(end) : null,
        };
    }

    onSelectAll(e, v) {
        if (this.props.readOnly) return;
        const value = getProperty(v.checked, 'value');

        const allSelect = {};
        if (value) {
            Object.values(this.props.categories).forEach((item) => (allSelect[item.id] = 3));
        }
        this.setState({ permissionsValues: allSelect });
    }

    onDateChanged(field, e, date) {
        this.setState({ [field]: date && date.moment });
    }

    onFormChanged = (field, ReadWrite, e, data) => {
        console.group('onFormChanged...');
        console.log('field', field);
        console.log('ReadWrite', ReadWrite);
        console.log('data', data);

        // 1=Read 2=Write 3=All
        if (this.props.readOnly) return;

        const oldValue = this.state.permissionsValues[field] || 0;

        const value = data.checked;
        // const value = getProperty(v.checked, 'value') || e.target.value;

        let newValue;
        if (value) {
            console.log('never here');
            newValue = oldValue + ReadWrite;
        } else {
            console.log('comes here');
            newValue = oldValue - ReadWrite;
        }

        console.log('newValue', newValue);
        this.setState({ permissionsValues: { ...this.state.permissionsValues, [field]: newValue } });
        console.groupEnd();
    };

    savePermissions() {
        if (!this.props.bindCode && !this.props.providerGuid) {
            notify({
                success: false,
                header: T.translate('did not create bind with provider'),
                content: T.translate('did not create bind with provider '),
            });
        }
        // If type = 0 (we removed the permission on the specific category we should not send it at all to the server because the server translates  0 as admin provilliges )

        let permArray = Object.entries(this.state.permissionsValues).map(([perKey, perVal]) => {
            if (perVal === 0) return;
            return { attributes: { category_id: perKey, type: perVal, start: this.state.start, end: this.state.end } };
        });
        permArray = permArray.filter((obj) => obj); // getting nulls out of the array, nulls are where there were 0 values
        const payload = { data: permArray };

        const state = store.getState();
        const activePersonId = getProperty(state, 'authentication.activePersonId');
        const url = this.props.bindCode
            ? `persons/${activePersonId}/permissions/bind-code/${this.props.bindCode}`
            : `persons/${activePersonId}/permissions/${this.props.providerGuid}`;
        Fetch(
            url,
            {
                method: 'POST',
                body: JSON.stringify(payload),
            },
            'auth'
        )
            .then((results) => {
                store.dispatch({ type: 'SET_DATA', payload: results });
                notify({
                    success: true,
                    header: T.translate('success.update.header'),
                    content: T.translate('success.update.content'),
                });
                fetchAction('person-providers', { query: '?all&type=3', force: true });
            })
            .catch(() =>
                notify({
                    error: true,
                    header: T.translate('errors.update.header'),
                    content: T.translate('errors.update.content'),
                })
            )
            .then((res) => {
                this.setState({ loading: false });
            });
    }

    CategoriesToEntities(category) {
        const categoriesMappings = {
            1: ['weights', 'heights', 'temperatures'],
            2: ['pressures', 'oximetries'],
            3: ['allergies'],
            4: ['drugs'],
            5: ['lab-exam-events'],
            6: ['activities'],
            8: ['medact-events'],
            9: ['vaccines'],
        };

        return (
            categoriesMappings[category] &&
            categoriesMappings[category].map((val, key) => {
                return <span key={uuidv4()}>{`${T.translate(`measurements.plural.${val}`)} `}</span>;
            })
        ); // T.translate(val) + ' '}) ;
    }

    render() {
        const { categories, readOnly, toggleProviderPermissions } = this.props;
        const { permissionsValues, start, end } = this.state;
        return (
            <Segment className="providerSegmentSize">
                <Table style={{ height: '100%' }} celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            {Object.values(categories).map((item) => (
                                <Popup
                                    trigger={
                                        <Table.HeaderCell style={{ textAlign: 'center' }} key={item.id}>
                                            {item.name}
                                        </Table.HeaderCell>
                                    }
                                    key={uuidv4()}
                                >
                                    <Popup.Header> {this.CategoriesToEntities(item.id)} </Popup.Header>
                                </Popup>
                            ))}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row warning>
                            <Table.Cell style={{ textAlign: 'center', fontWeight: 'bold' }} warning>
                                {T.translate('providers.read')}
                            </Table.Cell>
                            {Object.values(categories).map((item) => (
                                <Table.Cell key={item.id} textAlign="center">
                                    <Checkbox
                                        toggle
                                        checked={permissionsValues[item.id] === 1 || permissionsValues[item.id] === 3}
                                        onChange={(e, data) => this.onFormChanged(item.id, 1, e, data)}
                                        key={item.id}
                                    />
                                </Table.Cell>
                            ))}
                        </Table.Row>
                        <Table.Row positive>
                            <Table.Cell style={{ textAlign: 'center', fontWeight: 'bold' }} positive>
                                {T.translate('providers.write')}
                            </Table.Cell>
                            {Object.values(categories).map((item) => (
                                <Table.Cell key={item.id} textAlign="center">
                                    <Checkbox
                                        toggle
                                        checked={permissionsValues[item.id] === 2 || permissionsValues[item.id] === 3}
                                        onChange={(e, data) => this.onFormChanged(item.id, 2, e, data)}
                                        key={item.id}
                                    />
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan="16">
                                <div className="ui labeled button mini" tabIndex="0">
                                    {/* !this.props.readOnly?
                                    (<React.Fragment>
                                        <div className="ui basic green button" >
                                        <Checkbox onChange={this.onSelectAll.bind(this)} checked={Object.values(this.props.categories).every( cat => this.state.permissionsValues[cat.id] == 3)}/>
                                        </div>
                                        <a style={{whiteSpace:'nowrap'}} className="ui basic  green label">
                                            {T.translate("providers.selectAll")}
                                        </a> </React.Fragment>) : null */}

                                    {/* <div style={{paddingLeft:'100px'}}> */}

                                    {/* </div> */}

                                    <div style={{ flex: 1, float: 'right' }}>
                                        {!readOnly ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ fontSize: '15px', paddingRight: '10px' }}>{`${T.translate(
                                                    'providers.connectRange'
                                                )}:`}</span>
                                                <RangeCalendarInput
                                                    onStartChanged={(e, retVal) =>
                                                        this.onDateChanged('start', e, retVal)
                                                    }
                                                    onEndChanged={(e, retVal) => this.onDateChanged('end', e, retVal)}
                                                    startDate={start}
                                                    endDate={end}
                                                    fromLabel={T.translate('from')}
                                                    toLabel={T.translate('to')}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div style={{ flex: 1, float: 'right' }}>
                                    {!readOnly ? (
                                        <Button
                                            onClick={() => this.savePermissions()}
                                            floated="right"
                                            animated="fade"
                                            color="green"
                                            icon
                                            labelPosition="left"
                                            size="small"
                                        >
                                            <Icon name="save" /> {T.translate('save')}
                                        </Button>
                                    ) : null}
                                    <Button
                                        onClick={toggleProviderPermissions}
                                        floated="right"
                                        animated="fade"
                                        color="red"
                                        icon
                                        labelPosition="left"
                                        size="small"
                                    >
                                        {' '}
                                        <Icon name="close" /> {T.translate('buttons.close')}{' '}
                                    </Button>
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                {/* <Label attached='bottom right'>{this.props.city}</Label> */}
            </Segment>
        );
    }
}

const mapStateToProps = ({ dataStore }) => {
    return {
        categories: getProperty(dataStore, 'graph.categories') || {},
    };
};

export const PermissionsCard = connect(mapStateToProps)(_PermissionsCard);
