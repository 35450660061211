import React, { Component } from "react";
import T from "i18n-react";


export default class CovidIndexPage extends Component {


    render() {
        return(
            <h1>SALLALALLALAL PAP PARAP PAP</h1>
        )
    }
}