import React from 'react';

import T from 'i18n-react';
import { connect } from 'react-redux';

import GeneralMetricContainer from 'Mib/components/metric_panels/general_metric';

import { subscribe } from '../../lib/subscribe';
import { FilteredMetricComponent } from './filtered_metric';

class _BmiContainer extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.dataPropName = 'bmis';
        this.config = {
            descriptor: 'result',
            name: T.translate('BMI'),
            dateDescriptor: 'date',
            valueOffset: 10,
        };
    }

    render() {
        let { bmis, children, ...rest } = this.props;
        let { chart_config, bmis: filteredBmis } = this.state;

        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate('BMI')}
                data={filteredBmis}
                chart_config={chart_config}
                dataType="bmis"
                name="bmis"
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        bmis: state.dataStore.graph.bmis,
        filters: state.filters,
    };
};

const _BmiContainerSub = subscribe(_BmiContainer, ['bmis']);

export default connect(mapStateToProps)(_BmiContainerSub);
