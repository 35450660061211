import React, { Component, Fragment as F } from 'react';
import T from 'i18n-react';
import { Message } from 'semantic-ui-react';
import { SearchInput, TextAreaInput, TextInput } from 'Mib/components/inputs/form_inputs';
import ProviderRow from './provider_row';
import ProviderPermissionsCard from './provider_permissions_card';

export default class HealthProvidersActive extends Component {
    constructor() {
        super();
        this.state = {
            searchInput: '',
        };
    }

    onChangeSearch(e) {
        this.setState({ searchInput: e.target.value });
    }

    render() {
        const { activeProviders } = this.props;
        return (
            <div>
                {activeProviders && activeProviders.length ? (
                    this.props.activeProviders.map((prov, key) => (
                        <ProviderPermissionsCard actionButtons={true} key={key} provider={prov} type="active" />
                    ))
                ) : (
                    <Message
                        icon="info"
                        style={{ margin: '10px', width: 'auto' }}
                        content={T.translate('providers.not-found.active')}
                    />
                )}
            </div>
        );
    }
}
