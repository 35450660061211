import { store } from 'Mib/datastore/store';
import { getProperty } from 'dot-prop';
import { syncAllAction } from './backend';

export const startBackendSyncAction = (dataType) => {
    store.dispatch({ type: 'START_SYNCING', payload: dataType });
};

export const stopBackendSyncAction = (dataType) => {
    store.dispatch({ type: 'STOP_SYNCING', payload: dataType });
};

export const setActivePersonAction = (id) => {
    const state = store.getState();
    // const activePersonId = getProperty(state, 'authentication.activePersonId');
    // if (id == activePersonId) return;
    store.dispatch({ type: 'SET_ACTIVE_PERSON', payload: id });
    store.dispatch({ type: 'CLEAR_PERSONAL_DATA' });
    store.dispatch({ type: 'CLEAR_SYNC_CACHE' });
    store.dispatch({ type: 'CLEAR_NOTIFICATION_MESSAGES' });
    store.dispatch({ type: 'CLEAR_LIVE_DATA' });
    syncAllAction();
};
