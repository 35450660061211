import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import React, { Component } from "react";
import GeneralMetricContainer from "Mib/components/metric_panels/general_metric";
import { store } from "../../datastore/store";
import { subscribe } from "../../lib/subscribe";

import T from "i18n-react";
import { Create, Update } from "../../actions";
import { fetchAction } from "../../actions/backend";
import moment from "moment";

import MeasurementFormModal from "../modals/measurement_form_modal";
import { FilteredMetricComponent } from "./filtered_metric";

class _WthContainer extends FilteredMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.dataPropName = "wths";
        this.config = {
            descriptor: "result",
            name: T.translate("WTH"),
            dateDescriptor: "date",
            valueOffset: 10,
        };
    }

    render() {
        let { wths, children, ...rest } = this.props;
        let { chart_config, wths: filteredWths } = this.state;

        return (
            <GeneralMetricContainer
                {...rest}
                title={T.translate("WTH")}
                data={filteredWths}
                chart_config={chart_config}
                dataType="wths"
                name="wths"
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        wths: state.dataStore.graph.wths,
        filters: state.filters,
    };
};

const _WthContainerSub = subscribe(_WthContainer, ["wths"]);

export default connect(mapStateToProps)(_WthContainerSub);
