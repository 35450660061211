// export const isValidDate = (date) => {
//     if (Object.prototype.toString.call(date) === '[object Date]') {
//         // it is a date
//         if (Number.isNaN(date)) {
//             // d.getTime() or d.valueOf() will also work
//             // date object is not valid
//             return false;
//         }
//         return true;
//     }
//     // not a date object
//     return false;
// };

export const isValidDate = (dateCandidate) => new Date(dateCandidate).getMonth();

export const deltaDate = (input, days, months, years) => {
    return new Date(
        input.getFullYear() + years,
        input.getMonth() + months,
        Math.min(
            input.getDate() + days,
            new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
        )
    );
};
