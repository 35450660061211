import React, { Component } from 'react';
import { Route, Prompt } from 'react-router-dom';
import { getProperty } from 'dot-prop';
import T from 'i18n-react';
import revalidator from 'revalidator';
import moment from 'moment';

import { Fetch } from 'Mib/lib/fetch';
import { store } from 'Mib/datastore/store';
import { PersonUpdateAction } from 'Mib/actions';
import { fetchAction } from 'Mib/actions/backend';
import { notify } from 'Mib/actions/notifications';
import { serializeWithTags } from 'Mib/lib/json-api';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { JsonApiDataStoreModel } from 'Mib/vendor/jsonapi-datastore';
import { PersonForm } from './person_form';
import { PersonForm as PersonFormOld } from './person_form_old';
import { BarLoader, Loader } from '../../../components/generic/loader';
// import { startBackendSyncAction, stopBackendSyncAction } from 'Mib/actions/app_state';

const formValidator = {
    properties: {
        password: {
            required: true,
            message: 'Υποχρεωτικό πεδίο',
        },
        username: {
            required: true,
            message: 'Υποχρεωτικό πεδίο',
        },
        first_name: {
            required: true,
            minLength: 2,
            message: 'Υποχρεωτικό πεδίο',
        },
        last_name: {
            required: true,
            minLength: 2,
            message: 'Υποχρεωτικό πεδίο',
        },
    },
};

class _PersonsPage extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            error: false,
            formValues: {},
            errors: [],
            attrValues: {},
            params_id: null,
        };

        this.onFormChanged = this.onFormChanged.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount() {
        // console.group('in componentDidMount');
        // Initialize the formValues and redirect to the active person if no id param has been passed in the url
        const { activePersonId, match } = this.props;
        const id = getProperty(this, 'props.match.params.id');
        // console.log('id', id);
        const attribs = getProperty(this, `props.attribs`);
        if (id === 'create') {
            this.setState({ formValues: {}, attrValues: {}, params_id: id, loading: false });
        } else {
            const person = getProperty(this, `props.persons.${activePersonId}`);
            this.setState({ formValues: { ...person }, attrValues: attribs, params_id: id });
            this.getPersonSettingAndInfo(activePersonId);
        }
        // console.groupEnd();
    }

    // componentWillReceiveProps(newProps) {
    //     console.group('in componentWillReceiveProps');
    //     // Check if the url has changed so that we can change the active person
    //     const oldId = getProperty(this, 'props.match.params.id');
    //     // console.log('oldId', oldId);
    //     // console.log('props.match.params', newProps.match.params);
    //     let newId = getProperty(newProps, 'match.params.id');
    //     if (oldId === newId) {
    //         console.log('ids match');
    //         console.log('oldId', oldId);
    //         console.log('newId', newId);
    //         console.groupEnd();

    //         return;
    //     }
    //     console.log('HEHEHEHEHEHRE');

    //     if (newId == null) newId = getProperty(this, 'props.activePersonId');

    //     const newPersonSettings = getProperty(newProps, `person-settings.${newId}`);
    //     const newPerson = getProperty(newProps, `persons.${newId}`);
    //     // Get the gender
    //     // if (gender !== undefined) gender = T.translate(`genders.${gender}`);
    //     if (newId === 'create') {
    //         this.setState({ formValues: {}, attrValues: {} });
    //     } else {
    //         // TODO: this gets executed twice
    //         console.log('does it reach here?');

    //         this.setState({ formValues: { ...newPerson } });
    //         this.getPersonSettingAndInfo(newId);
    //         console.log('newPersonSettings', newPersonSettings);
    //         this.setState({ attrValues: { ...newPersonSettings } });
    //         console.groupEnd();
    //     }
    // }

    static getDerivedStateFromProps(newProps, oldState) {
        const { params_id } = oldState;
        // console.group('getDerivedStateFromProps');
        // console.log('params_id', params_id);

        let newId = getProperty(newProps, 'match.params.id');
        // console.log('newId', newId);
        // if (params_id == newId) {
        //     console.log('ids match');
        //     console.groupEnd();
        //     return { loading: false, error: false, formValues: {}, errors: [], attrValues: {}, params_id: null };
        // }

        if (params_id != newId) {
            if (newId == null) newId = getProperty(this, 'props.activePersonId');

            const newPersonSettings = getProperty(newProps, `person-settings.${newId}`);
            const newPerson = getProperty(newProps, `persons.${newId}`);
            // Get the gender
            // if (gender !== undefined) gender = T.translate(`genders.${gender}`);
            if (newId === 'create') return { formValues: {}, attrValues: {} };

            // console.log('does it reach here?');
            // console.log('newPersonSettings', newPersonSettings);
            // console.groupEnd();
            // TODO: this gets executed twice
            return { formValues: { ...newPerson }, attrValues: { ...newPersonSettings } };
            // this.getPersonSettingAndInfo(newId);
        }
        // console.groupEnd();
        return {};
    }

    async getPersonSettingAndInfo(personId) {
        // startBackendSyncAction("person-settings");
        // const personAttr = await Fetch(`/persons/${personId}/person-settings`, {}, "api").catch(e => {
        //     console.log("Error while syncing: ", e);
        //     stopBackendSyncAction("person-settings");
        // });
        // store.dispatch({ type: "SET_DATA", payload: personAttr });
        console.log('geting persons settings and info');
        await Promise.all([
            fetchAction(`person-settings/${personId}`, {
                url: `persons/${personId}/person-settings`,
                query: '',
                force: true,
            }),
            fetchAction(`person-info/${personId}`, {
                url: `persons/${personId}/person-info`,
                query: '',
                force: true,
            }),
            // fetchAction(`persons/${personId}`, { url: `persons/${personId}`, query: "", force:true }),
        ]);
        // const personInfo = await Fetch(`/persons/${personId}/person-info`, {}, "api").catch(e => {
        //     console.log("Error while syncing: ", e);
        //     stopBackendSyncAction("person-settings");
        // });
        // store.dispatch({ type: "SET_DATA", payload: personInfo });
        // stopBackendSyncAction("person-settings");
        const personAttrStore = store.getState().dataStore.graph['person-settings'][`${personId}`];
        // const personStore = store.getState().dataStore.graph['persons'][`${personId}`];
        this.setState({
            attrValues: { ...personAttrStore },
            formValues: {
                ...this.state.formValues,
                'blood-type': getProperty(store.getState(), `dataStore.graph.person-info.${personId}.bloodType.id`),
                dominant_hand: getProperty(store.getState(), `dataStore.graph.person-info.${personId}.dominant_hand`),
            },
            loading: false,
        });
    }

    postPersonSettingAndInfo() {
        // let [model, endpoint] = getMeta(data);
        // const payload = serializeWithTags(data);
        const payload = this.state.attrValues;
        const personId = payload.id;
        const state = store.getState();
        const personAttribNames = ['weight_target', 'dashboard'];
        const personAttrib = {};
        const pSmodel = new JsonApiDataStoreModel('person-settings');
        personAttribNames.forEach((item) => {
            if (item in payload) {
                personAttrib[item] = payload[item];
                pSmodel.setAttribute(item, payload[item]);
            }
        });
        const measureUnit = state.dataStore.find('measure-units', 1);
        // let measureUnit = store.getState().dataStore.find('measure-units', 1);
        pSmodel.setRelationship('weightUnit', measureUnit);

        const payloadtosend = serializeWithTags(pSmodel);

        return Fetch(
            `persons/${personId}/person-settings`,
            { method: 'PATCH', body: JSON.stringify(payloadtosend) },
            'api'
        )
            .then((results) => {
                store.dispatch({ type: 'SET_DATA', payload: results });
                notify({
                    success: true,
                    header: T.translate('success.create.header'),
                    content: T.translate('success.create.content'),
                });
            })
            .catch(() =>
                notify({
                    error: true,
                    header: T.translate('errors.create.header'),
                    content: T.translate('errors.create.content'),
                })
            );
    }

    onAttrChanged(field, e, v) {
        const value = getProperty(v, 'value') || e.target.value;
        const { attrValues } = this.state;
        const newValues = {
            ...attrValues,
            [field]: value,
        };
        this.setState({
            attrValues: newValues,
            formHasChanged: true,
        });
        // let errors = revalidator.validate(newValues, attrValidator).errors;
        // this.setState({errors: errors})
        // console.log(errors)
    }

    onFormChanged(field, e, v, dont_flag_changed = false) {
        const value = getProperty(v, 'value') || e.target.value;
        const { formValues } = this.state;
        const oldValue = formValues[field];

        if (value === oldValue) return null;

        const newValues = {
            ...formValues,
            [field]: value,
        };

        this.setState({
            formValues: newValues,
            formHasChanged: !dont_flag_changed,
        });

        const { errors } = revalidator.validate(newValues, formValidator);
        this.setState({ errors: errors });
    }

    cancelChanges() {
        const id = getProperty(this, 'props.match.params.id');
        // const person = getProperty(this, `props.persons.${id}`);

        if (id) {
            // this.setState({ formValues: { ...person } });
            this.getPersonSettingAndInfo(id);
        } else if (id === 'create') {
            this.setState({ formValues: {}, formHasChanged: false });
        } else {
            this.handle404();
        }
    }

    saveChanges() {
        // const id = getProperty(this, 'props.match.params.id');
        // const person = getProperty(this, `props.persons.${id}`);
        this.setState({ formHasChanged: false });

        PersonUpdateAction(this.state.formValues);
    }

    handle404() {}

    render() {
        const { formHasChanged = false, formValues, errors, loading } = this.state;
        const {
            match,
            bloodTypes,
            //  history,
            //  persons
        } = this.props;
        let {
            birthDate,
            gender,
            // bloodType,
            // lastWeight,
            // lastHeight,
            // lastPressure,
            // lastWaist,
            // fullName,
            // allergies,
            // personAllergies,
            // appState,
            // personProfile,
        } = this.props;

        // Get the gender
        if (gender !== undefined) gender = T.translate(`genders.${gender}`);

        // Get the age
        let age;
        if (birthDate != null) {
            const ageYears = moment().diff(birthDate, 'years');
            age =
                ageYears > 2
                    ? `${ageYears} ${T.translate('years')}`
                    : `${moment().diff(birthDate, 'months')} ${T.translate('months')}`;
        } else {
            age = null;
        }
        const create = getProperty(match, 'params.id');
        this.title = create === 'create' ? 'persons.create-person' : 'persons.person-info';

        return loading ? (
            <div
                style={{
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                }}
            >
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <BarLoader />
                    </div>
                    <span>{`${T.translate('loadingData')}...`}</span>
                </div>
            </div>
        ) : (
            <>
                <Route
                    path="/profile/persons/create"
                    render={() => (
                        <PersonFormOld
                            values={formValues}
                            onFormChanged={this.onFormChanged}
                            cancel={this.cancelChanges}
                            save={this.saveChanges}
                            title={this.title}
                            errors={errors}
                            bloodTypes={bloodTypes}
                        />
                    )}
                />
                <Route
                    path="/profile/persons/"
                    exact
                    render={() => (
                        <>
                            <PersonForm
                                values={formValues}
                                onFormChanged={this.onFormChanged}
                                cancel={this.cancelChanges}
                                save={this.saveChanges}
                                title={this.title}
                                errors={errors}
                                bloodTypes={bloodTypes}
                            />
                            <Prompt when={formHasChanged} message={T.translate('persons.form-changed-confirmation')} />
                        </>
                    )}
                />
            </>
        );
    }
}

const mapStateToProps = ({
    authentication: { activePersonId },
    dataStore,
    //  appState
}) => {
    const weights = Object.values(getProperty(dataStore, 'graph.weights') || {});
    const lastWeight = weights && weights[weights.length - 1];
    const heights = Object.values(getProperty(dataStore, 'graph.heights') || {});
    const lastHeight = heights && heights[heights.length - 1];
    const waists = Object.values(getProperty(dataStore, 'graph.waists') || {});
    const lastWaist = waists && waists[waists.length - 1];
    const pressures = Object.values(getProperty(dataStore, 'graph.pressures') || {}).filter(
        (p) => p.systolic != null && p.diastolic != null
    );
    const lastPressure = pressures && pressures[pressures.length - 1];
    const firstName = getProperty(dataStore, `graph.persons.${activePersonId}.first_name`) || '';
    const lastName = getProperty(dataStore, `graph.persons.${activePersonId}.last_name`) || '';

    return {
        personProfile: getProperty(dataStore, `graph.persons.${activePersonId}`),
        persons: getProperty(dataStore, 'graph.persons'),
        bloodType: getProperty(dataStore, `graph.person-info.${activePersonId}.bloodType.type`),
        dominantHand: getProperty(dataStore, `graph.person-info.${activePersonId}.dominant_hand`),
        bloodTypes: getProperty(dataStore, 'graph.blood-types'),
        gender: getProperty(dataStore, `graph.persons.${activePersonId}.gender`),
        birthDate: getProperty(dataStore, `graph.persons.${activePersonId}.dob`),
        fullName: `${firstName} ${lastName}`,
        lastWeight,
        lastHeight,
        lastPressure,
        lastWaist,
        allergies: getProperty(dataStore, `graph.allergies`),
        personAllergies: getProperty(dataStore, `graph.person-allergies`),
        // appState,
        activePersonId,
    };
};

export const PersonalData = subscribeWithLoader(_PersonsPage, mapStateToProps, [
    'weights',
    'heights',
    'pressures',
    'waists',
    'person-info',
    'blood-types?',
    'allergies?',
    'person-allergies',
]);
