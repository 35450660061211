import React from 'react';

require('./loader.scss');

export function Loader(props) {
    return (
        <div className="loader" {...props}>
            <div className="circle">&nbsp;</div>
            <div className="circle">&nbsp;</div>
            <div className="circle">&nbsp;</div>
            <div className="circle">&nbsp;</div>
        </div>
    );
}

export function BarLoader(props) {
    return (
        <div className="bar-loader" {...props}>
            <div className="bar-loader-line line1" />
            <div className="bar-loader-line line2" />
            <div className="bar-loader-line line3" />
            <div className="bar-loader-line line4" />
            <div className="bar-loader-line line5" />
        </div>
    );
}
