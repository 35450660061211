import React from 'react';
import T from 'i18n-react';
import { getProperty } from 'dot-prop';
import sortOn from 'sort-on';
import moment from 'moment';

import { SimpleMetricComponent } from 'Mib/components/metric_panels/filtered_metric';
import ReactHighChartsManaged from 'Mib/components/generic/react_high_charts_managed';
import BaseContainer from 'Mib/components/containers/base';
import { TextInput, DropDownInput, MibButton, CalendarInput, TagRemoteInput } from 'Mib/components/inputs';
import { subscribeWithLoader } from 'Mib/lib/subscribe';
import { generateChartData } from 'Mib/lib/charts';
import { deepMerge } from 'Mib/lib/utils';
import { store } from 'Mib/datastore/store';

import { MeasurementInfoPanel } from './measurements_info';
import NoDataMessage from './no_data_message';

class _DashPressureChart extends SimpleMetricComponent {
    constructor() {
        super();
        this.state = { chart_config: {} };

        this.dataPropName = 'pressures';
        this.config = {
            descriptor: 'systolic',
            name: T.translate('measurements.singular.pressure'),
            dateDescriptor: 'date',
            onGraphPointClicked: this.open.bind(this),
            valueOffset: 3,
            merge_config: (props, cfg) => ({
                chart: {
                    height: 200,
                },
                title: { floating: true, text: '' },
                xAxis: {
                    title: {
                        text: '',
                    },
                },
            }),
        };
    }

    componentWillReceiveProps(newProps) {
        this.forceRefresh = newProps.color != this.props.color;
        super.componentWillReceiveProps(newProps);
    }

    // eslint-disable-next-line no-underscore-dangle
    _generateChartData(data = {}, props = this.props) {
        const { merge_config = {}, ...chart_settings } = this.config;
        const dataWithPressure = {};
        Object.values(data).forEach((p) => {
            if (p.systolic != 0 && p.systolic != null) dataWithPressure[p.id] = p;
        });
        const chart_config = generateChartData(dataWithPressure, chart_settings);
        const chart_config2 = generateChartData(dataWithPressure, {
            ...chart_settings,
            descriptor: 'diastolic',
        });
        chart_config.series[0].name = T.translate('measurements.pressures.systolic');
        chart_config2.series[0].name = T.translate('measurements.pressures.diastolic');
        chart_config.series[0].color = '#db8929';
        chart_config2.series[0].color = '#db2828';
        chart_config.series.push(chart_config2.series[0]);
        typeof merge_config === 'function'
            ? deepMerge(chart_config, merge_config(props, chart_config))
            : deepMerge(chart_config, merge_config);
        let [min, max] = this.getMinMax(dataWithPressure);
        chart_config.yAxis.min = min;
        chart_config.yAxis.max = max;
        return chart_config;
    }

    getMinMax(data) {
        let min, max;
        Object.values(data).forEach((p) => {
            if (p.systolic > max || max == null) max = p.systolic;
            if (p.diastolic < min || min == null) min = p.diastolic;
        });
        return [min, max];
    }

    render() {
        let { chart_config } = this.state;
        let { pressures = {}, loading } = this.props;
        const filteredPressures = {};
        Object.values(pressures).forEach((p) => {
            if (p.systolic != 0 && p.systolic != null) filteredPressures[p.id] = p;
        });
        const lastMeasurement = filteredPressures && sortOn(Object.values(filteredPressures), '-date')[0];
        const measureDate = moment(getProperty(lastMeasurement, 'date')).format('L');
        const measureTime = moment(getProperty(lastMeasurement, 'date')).format('HH:mm');
        const measureUnitAbbrev = getProperty(lastMeasurement, 'measureUnit.abbreviation');
        const value = lastMeasurement
            ? `${lastMeasurement.systolic}/${lastMeasurement.diastolic} ${measureUnitAbbrev}`
            : undefined;

        return (
            <>
                <MeasurementInfoPanel
                    style={{ borderTopColor: this.props.color, borderTopWidth: '2px' }}
                    title={T.translate('measurements.my.my-pressure-graph')}
                    date={measureDate}
                    time={measureTime}
                    value={value}
                    altText={T.translate('no-data')}
                    loading={loading}
                    color="red"
                    link="/health-folder/pressures"
                />
                <BaseContainer loading={loading} className="dash-chart-container">
                    {loading ? (
                        <div style={{ width: '100%', height: '200px' }} />
                    ) : Object.keys(filteredPressures).length ? (
                        <ReactHighChartsManaged config={chart_config} limit={20} />
                    ) : (
                        <NoDataMessage create={() => this.open(null, 'pressures')} />
                    )}
                </BaseContainer>
            </>
        );
    }

    open(id = undefined, type = undefined) {
        let { pressures, measures } = this.props;
        const measureUnitOptions = Object.values(this.props.measures || {}).map((mu) => ({
            key: mu.id,
            value: mu.id,
            text: mu.name,
        }));

        let formItems = [
            { Element: TextInput, label: 'systolic', fieldName: 'systolic' },
            { Element: TextInput, label: 'diastolic', fieldName: 'diastolic' },
            { Element: TextInput, label: 'pulses', fieldName: 'pulses' },
            {
                Element: DropDownInput,
                label: 'measurements.measure-unit',
                fieldName: 'measureUnit',
                options: measureUnitOptions,
                attrib: 'id',
            },
            { Element: CalendarInput, label: 'measurements.date', fieldName: 'date', time: true },
            { Element: TagRemoteInput, label: 'measurements.tags', fieldName: 'tags', attrib: 'tag', multiple: true },
        ];

        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'MEASUREMENT_MODAL',
                unitId: this.state.unitId,
                showTime: true,
                formItems,
                id,
                type,
            },
        });
    }
}

const mapStateToProps = (state) => {
    const allmeasureUnits = state.dataStore.graph['measure-units'] && state.dataStore.graph['measure-units'];
    const weightMeasureUnits = {};
    if (allmeasureUnits)
        Object.entries(allmeasureUnits).forEach(([key, unit]) => {
            // console.log('measureUnitType',unit)
            if (unit.measureUnitType && unit.measureUnitType.name == 'PRESSURE') {
                weightMeasureUnits[unit.id] = unit;
            }
        });

    return {
        pressures: state.dataStore.graph.pressures,
        measures: weightMeasureUnits,
        filters: state.filters,
    };
};

export const DashPressureChart = subscribeWithLoader(_DashPressureChart, mapStateToProps, ['pressures']);
