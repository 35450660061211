import React from 'react';
import { connect } from 'react-redux';

import T from 'i18n-react';

import { store } from 'Mib/datastore/store';
import { subscribe } from 'Mib/lib/subscribe';
import { ContentTitle } from 'Mib/components/generic';
import { CreateButton } from 'Mib/components/inputs/buttons';
import BaseContainer from 'Mib/components/containers/base';
import TimelineEventList from 'Mib/components/generic/timeline/timeline_event_list';
// import { getProperty } from 'dot-prop';

import { FilteredMetricComponent } from './filtered_metric';

function DocumentFile() {
    return (
        <>
            <label htmlFor="documentUpload"> {this.props.value} </label>
            <input
                className="documentUpload"
                type="file"
                name="documents"
                text={this.props.value}
                onChange={(event) => this.props.onChange(event)}
            />
        </>
    );
}

class _PersonDocContainer extends FilteredMetricComponent {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.dataPropName = 'documents';
        this.onDataChanged = [];

        this.config = {};
        this.state = { chart_config: {} };
    }

    componentWillReceiveProps(newProps) {
        this.forceRefresh = true;
        super.componentWillReceiveProps(newProps);
    }

    render() {
        const { documents, categories, children, tagFilters, ...rest } = this.props;

        const { chart_config, documents: filteredDocuments, fileUploading } = this.state;

        return (
            <>
                <ContentTitle icon="file text outline" title={T.translate('measurements.my.my-documents')} />
                <BaseContainer>
                    <CreateButton onClick={() => this.open(null, 'documents')} />
                    <TimelineEventList
                        confirmDeletion
                        data={filteredDocuments}
                        sortOn="date"
                        timelinePointClick={this.open}
                        documentsDownloadClick={this.documentsDownloadClick}
                    />
                </BaseContainer>
            </>
        );
    }

    // async documentsDownloadClick(event,file) {
    //   event.stopPropagation();
    //   event.preventDefault();
    //   const state = store.getState();

    //   const activePersonId = getProperty(state, "authentication.activePersonId");
    //   let url = `persons/${activePersonId}/download-document/${file}`;
    //   let lolo = await  Fetch(url,null,null,true).then(function(resp) {
    //        return resp.blob();
    //       }).then(function(blob) {
    //         FileSaver.saveAs(blob, `${file}`);
    //       });

    // }

    open(id = undefined, type = undefined) {
        const { documents, categories, children, tagFilters, ...rest } = this.props;
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'DOCUMENT_MODAL',
                id,
                type,
            },
        });
    }
}

const mapStateToProps = (state) => {
    return {
        documents: state.dataStore.graph.documents,
        categories: state.dataStore.graph.categories,
        filters: state.filters,
    };
};

const _PersonDocContainerSub = subscribe(_PersonDocContainer, ['documents?orderBy=date&sortedBy=desc']);

export default connect(mapStateToProps)(_PersonDocContainerSub);
