import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { getProperty } from 'dot-prop';
import revalidator from 'revalidator';
import { store } from '../../datastore/store';
import { relToResource, convertTags } from '../../lib/conversions';

class BaseForm extends Component {
    constructor() {
        super();
        this.state = { errors: [] };
    }

    componentWillReceiveProps(newProps) {
        this.setState({ errors: newProps.errors });
    }

    componentDidMount() {
        // console.log(this.form.props.children[0])

        this.firstNode.hoho.then(() => this.firstNode.focus());
        // setTimeout(_=>this.firstNode.focus(),10)
        // this.form.props.children[0].focus();
    }

    // onChange: child.props.onChange!=undefined ? () =>{ child.props.onChange(this,this.props) }: this.onFormChanged.bind(this, child.props.fieldName),

    onFormChanged(field, e, v) {
        // ;debugger
        const value = v ? v.value : e.target.value;

        const { model, onChange, validator, onValidate } = this.props;
        const { dataStore } = store.getState();
        if (field === 'tags' && Array.isArray(value)) {
            const tags = convertTags(value);
            model.setRelationship('tags', tags);
        } else if (model._relationships && model._relationships.includes(field) && Array.isArray(value)) {
            const newVal = value.map((item) => {
                return { id: item };
            });
            model.setRelationship(field, newVal);
        } else if (model._relationships && model._relationships.includes(field)) {
            // We handle both the id as value and the entire object
            if ('options' in v && v.options.find && v.options.every((opt) => 'item' in opt)) {
                const relItem = getProperty(
                    v.options.find((i) => i.value === value),
                    'item'
                ); // use dp because find may return undefined
                model.setRelationship(field, relItem);
            } else if (typeof value === 'object') {
                model.setRelationship(field, value);
            } else {
                const relationship = getProperty(dataStore, `graph.${relToResource(field)}.${value}`);
                if (relationship != null) {
                    model.setRelationship(field, relationship);
                } else {
                    console.warn(`No relationship was found for model ${model._type} and field ${field}`);
                }
            }
        } else if (model.event && model.event._relationships.includes(field)) {
            const relationship = getProperty(dataStore, `graph.${relToResource(field)}.${value}`);
            if (relationship != null) {
                model.event.setRelationship(field, relationship);
            }
        } else {
            model[field] = value;
        }

        onChange(model);

        const valid = revalidator.validate(model, validator);
        // console.log('thew validator is ',model,validator,valid)
        const errors = valid.errors.map((err) => ({ property: err.property, message: err.message }));

        this.setState({ errors: errors });
        if (onValidate) onValidate(errors);
    }

    getElementValue(model, childProps) {
        const field = childProps.fieldName;
        const { defaultValue } = childProps;
        if ('attrib' in childProps) {
            if (model && Array.isArray(model[field])) {
                return model[field].map((item) => item[childProps.attrib]);
            }
            return model && model[field] && model[field][childProps.attrib] ? model[field][childProps.attrib] : null;
        }
        return model[field] != null ? model[field] : (model[field] = defaultValue);
    }

    render() {
        const { style } = this.props;
        const { validator } = this.props;
        return (
            <Form
                style={style}
                className="base-form"
                ref={(form) => {
                    this.form = form;
                }}
            >
                {React.Children.map(this.props.children, (child, i) => {
                    if (i == 0) {
                        const required = getProperty(validator, `properties.${child.props.fieldName}.required`);

                        return React.cloneElement(child, {
                            value: child.props.getElementValue
                                ? child.props.getElementValue(this.props.model, child.props)
                                : this.getElementValue(this.props.model, child.props),
                            errors:
                                this.state.errors &&
                                this.state.errors.filter((i) => i.property == child.props.fieldName),
                            onChange: child.props.onChange
                                ? child.props.onChange.bind(this, this.props)
                                : this.onFormChanged.bind(this, child.props.fieldName),
                            onMySearchChange: child.props.onMySearchChange
                                ? child.props.onMySearchChange.bind(this, this.props)
                                : null,
                            required: required,
                            ref: (firstNode) => {
                                this.firstNode = firstNode;
                            },
                        });
                    }

                    const required = getProperty(validator, `properties.${child.props.fieldName}.required`);
                    return React.cloneElement(child, {
                        value: child.props.getElementValue
                            ? child.props.getElementValue(this.props.model, child.props)
                            : this.getElementValue(this.props.model, child.props),
                        errors:
                            this.state.errors && this.state.errors.filter((i) => i.property == child.props.fieldName),
                        onChange: child.props.onChange
                            ? child.props.onChange.bind(this, this.props)
                            : this.onFormChanged.bind(this, child.props.fieldName),
                        onMySearchChange: child.props.onSearchChange
                            ? child.props.onSearchChange.bind(this, child.props)
                            : null,

                        required: required,
                    });
                })}
            </Form>
        );
    }
}

export default BaseForm;
