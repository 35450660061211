import { store } from 'Mib/datastore/store';
import { fetchFeatures } from './actions';

/* *
 * Checks if `featureName` should be accessible for the current user and returns true or false
 * */

const ShouldDisplayFeature = (feature_id) => {
    const redux = store;

    const { features = [] } = redux.getState();

    const feature = features.length > 0 && features.find((f) => f.feature_id === feature_id);
    return feature != null && feature.enabled;
};

const fetchFeaturesFirst = async () => {
    await fetchFeatures;
};

export default ShouldDisplayFeature;
