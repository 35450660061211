import React, { Component } from "react";
import { Checkbox, Form, Radio } from "semantic-ui-react";


export class RadioButtonGroup extends Component {
    constructor() {
        super();
    }
    render() {
        let { label, options, value, checked, inline = false, onChange, ...other } = this.props;
        return (
            <Form.Group inline={inline}>
                <span className="InputLabel">{label}</span>
                <div style={{display:'flex', flexDirection: 'column'}}>
                {options.map(item => {
                    return (
                        <Form.Field
                            style={{ paddingTop: "10px" }}
                            control={Radio}
                            onChange={onChange}
                            key={item.key}
                            label={item.label}
                            value={item.value}
                            checked={value === item.value}
                        />
                    );
                })}
            </div>
            </Form.Group>
        );
    }
}

export class RadioButtonInput extends Component {
    constructor() {
        super();
    }
    render() {
        let { label, radioLabel, checked, onChange, inline = false, ...other } = this.props;
        return (
            <Form.Field inline={inline}>
                <span className="InputLabel">{label}</span>
                <label>
                    <Radio className="defaultCss" onChange={onChange} checked={checked} {...other} />
                    <span className="InputLabel">{radioLabel}</span>
                </label>
            </Form.Field>
        );
    }
}

export class CheckBoxInput extends Component {
    constructor() {
        super();
    }
    render() {
        let { label, value, onChange, inline = false, ...other } = this.props;

        return (
            <Form.Field inline={inline}>
                <label>
                    <span className="InputLabel">{label}</span>
                    <Checkbox className="defaultCss" onChange={onChange} value={value} {...other} />
                </label>
            </Form.Field>
        );
    }
}
