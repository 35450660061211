import React, { Component } from 'react';
import T from 'i18n-react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

export default class CloseSurveyMsg extends Component {
    constructor() {
        super();
        this.state = {
            closeMsgOpen: false,
        };
    }

    saveAndClose = () => {
        this.resolve();
        this.setState({ closeMsgOpen: false });
    };

    open = () => {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            this.setState({ closeMsgOpen: true });
        });
    };

    close = () => {
        this.setState({ closeMsgOpen: false });
        this.reject();
    };

    render() {
        return (
            <Modal basic open={this.state.closeMsgOpen}>
                <Header icon="archive" content={T.translate('surveys.close-guide')} />
                <Modal.Content>
                    <p>{T.translate('surveys.confirmation-msg')}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.close} basic color="red" inverted>
                        <Icon name="remove" /> {T.translate('no')}
                    </Button>
                    <Button onClick={this.saveAndClose} color="green" inverted>
                        <Icon name="checkmark" /> {T.translate('yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
