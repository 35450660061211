import React from 'react';
import {Icon} from 'semantic-ui-react';
import MibIcon from './Icons'


const renderIcon = (icon) => {
    if (icon.startsWith('mibcolor')){
        return <MibIcon className={icon}/>
    }else if(icon.startsWith('mib-mono-icon')){
        return <i className={icon}/>
    } else {
        return <Icon className={icon} />
    }

}
export const ContentTitle = ({ icon, title, style={} }) => (
    <h3 className="content-title ui container" style={style}>
        {icon && renderIcon(icon)}
        {"  "}
        {title}
    </h3>
);
