/* eslint-disable import/prefer-default-export */
import React from 'react';
// import T from 'i18n-react';

import { MediBase64 } from 'Mib/routes/dashboard/medi_svg';
// import { Button } from 'semantic-ui-react';

require('./mediCss.scss');

export function MediWithMsg({ mediMsg }) {
    return (
        <div className="mediContainer">
            <div className="bubble">
                <div className="inner">
                    {/* <div style={{ textAlign: "center", fontSize: '1.3em', fontWeight: "bold", color: "#2eaadc", paddingBottom: '5px' }}/> */}
                    <span style={{ color: 'rgba(0,0,0,0.7)', fontSize: '1.2em' }}>{mediMsg}</span>
                    <div style={{ paddingTop: '5%' }}>
                        {/* <Button  href inverted color='blue'> */}
                        {/*    <a href={path} target="_blank">{T.translate('billing.push-me')}</a> */}
                        {/* </Button> */}
                    </div>
                </div>
            </div>

            <div className="mediContainer">
                <svg width="100%" height="100%" version="1.1" id="medi-svg">
                    <image
                        y="0"
                        x="0"
                        id="image314"
                        xlinkHref={MediBase64}
                        preserveAspectRatio="yes"
                        height="100%"
                        width="100%"
                    />
                </svg>

                {/* <div className="medi-background" /> */}
                <div className="medi-shadow" />
            </div>
        </div>
    );
}
