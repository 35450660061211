import React, { Component, Suspense } from 'react';
import { Segment, Message, Icon, Checkbox, Flag } from 'semantic-ui-react';
import T from 'i18n-react';
import classnames from 'classnames';
import revalidator from 'revalidator';

import { store } from 'Mib/datastore/store';
import { setLocale } from 'Mib/lib/utils';
import { notify } from 'Mib/actions';
import { TextInput, MibButton } from 'Mib/components/inputs';
import { CreateRegistrationData, registrationAction } from 'Mib/actions/registration';

const LoginAndRegisterTemplate = React.lazy(() => import('../loginAndRegisterTemplate/loginAndRegisterTemplate'));

require('./registration.scss');

function SelectLocale({ reload }) {
    return (
        <div style={{ display: 'inline-flex', justifyContent: 'space-around', padding: '.92857143em 1.14285714em' }}>
            <Flag
                style={{ cursor: 'pointer' }}
                name="gr"
                size="large"
                onClick={() => {
                    setLocale('el');
                    reload();
                }}
            />
            <Flag
                style={{ cursor: 'pointer' }}
                name="gb"
                onClick={() => {
                    setLocale('en');
                    reload();
                }}
            />
        </div>
    );
}

const getRegistrationValidator = () => ({
    properties: {
        first_name: {
            type: 'string',
            minLength: 2,
            messages: {
                minLength: T.translate('meta.registration.errors.min-length', {
                    len: 2,
                }),
            },
        },
        last_name: {
            type: 'string',
            minLength: 2,
            messages: {
                minLength: T.translate('meta.registration.errors.min-length', {
                    len: 2,
                }),
            },
        },
        email: {
            type: 'string',
            // pattern: /^[\w\d-_\.\%\+]*@[\w\d-_\.\%\+]*\.[\w\d*]/,
            format: 'email',
            required: true,
            messages: {
                required: T.translate('meta.registration.errors.required'),
                format: T.translate('meta.registration.errors.email-format'),
            },
        },
        password1: {
            type: 'string',
            required: true,
            minLength: 8,
            pattern: /(?=^.{6,10})(?=.*[0-9])(?=.*[a-z]).*$/,
            messages: {
                required: T.translate('meta.registration.errors.required'),
                minLength: T.translate('meta.registration.errors.min-length', {
                    len: 8,
                }),
                pattern: T.translate('meta.registration.errors.password-complexity', {
                    len: 8,
                }),
            },
        },
        password2: {
            type: 'string',
            required: true,
            messages: {
                required: T.translate('meta.registration.errors.required'),
            },
        },
    },
});

function PasswordStrength({ strength }) {
    const colorMap = {
        [-1]: 'rgba(230,0,0,0.34)',
        0: 'rgba(230,0,0,0.34)',
        1: 'rgba(230,0,0,0.34)',
        2: 'rgba(217, 84, 9, 0.34)',
        3: 'rgba(247, 210, 52, 0.34)',
        4: 'rgba(39, 230, 0, 0.34)',
    };
    return (
        <div
            style={{
                width: '100%',
                marginTop: '5px',
                height: '8px',
                background: 'rgba(0,0,0,0.34)',
            }}
        >
            <div
                style={{
                    width: `calc( ${strength} * 25%)`,
                    height: '8px',
                    background: colorMap[strength],
                }}
            />
        </div>
    );
}

class RegistrationPage extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            error: false,
            errors: [],
            formValues: { first_name: '', last_name: '', email: '' },
            agreeToTerms: false,
            over18: false,
            is_premium: false,
            pwStrength: 0,
            reload: false,
        };
    }

    onFormChanged(field, e) {
        console.log('field', field);
        console.log('e', e);
        const { errors } = this.state;
        const formValues = {
            ...this.state.formValues,
            [field]: e.target.value,
        };
        const { password1, password2 } = formValues;
        const pwStrength = typeof zxcvbn !== 'undefined' ? zxcvbn(password1 || '').score : 0;
        this.setState({
            formValues,
            pwStrength,
            errors: errors.filter((e) => e.property != field && `user.${e.property} != field`),
        });
        // this.setState({formValues})
    }

    async register() {
        const { formValues, is_premium } = this.state;
        const { password1, password2 } = formValues;
        const validation = revalidator.validate(formValues, getRegistrationValidator());
        if (!this.state.over18) {
            this.setState({ over18Error: true });
            return;
        }
        if (password1 !== password2) {
            validation.valid = false;
            validation.errors.push({
                property: 'password2',
                message: T.translate('meta.registration.errors.passwords-must-match'),
            });
        }
        if (!validation.valid) {
            this.setState({ ...validation });
            // TODO
            // notify({
            //         error: true,
            //         header: T.translate("success.create.header"),
            //         content: T.translate("meta.registration.title"),
            //     });
            return;
        }
        if (!this.state.agreeToTerms1) {
            this.setState({ agreeError1: true });
            this.agree1.inputRef.focus();
            return;
        }
        if (!this.state.agreeToTerms2) {
            this.setState({ agreeError2: true });
            this.agree2.inputRef.focus();
            return;
        }
        this.setState({ loading: true });
        const values = {
            password: password1,
            is_premium,
            ...formValues,
        };
        // if (formValues.promoter_code) values.promoter_id = 1;
        const data = CreateRegistrationData(values);
        registrationAction(data).then(async (res) => {
            this.setState({ loading: false });
            if (res.ok) {
                notify({
                    success: true,
                    header: T.translate('success.create.header'),
                    content: T.translate('meta.registration.title'),
                });
                this.props.history.push('/');
            } else {
                if (res.status == 422) {
                    const errors = await res.json();
                    const err = errors.errors.map((e) => ({
                        property: e.title,
                        message: e.detail,
                    }));
                    this.setState({ errors: [...this.state.errors, ...err] });
                }
                notify({
                    error: true,
                    header: T.translate('errors.update.header'),
                    content: T.translate('errors.update.content'),
                });
            }
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <Suspense fallback="loading...">
                <LoginAndRegisterTemplate>
                    <div className="registration-panel-container">
                        <SelectLocale reload={() => this.setState({ reload: !this.state.reload })} />
                        <Segment className="registration-panel">
                            <div className="registration-title">{T.translate('meta.registration.title')}</div>
                            <TextInput
                                type="text"
                                className="registration-input"
                                value={this.state.formValues.first_name || ''}
                                onChange={(e) => this.onFormChanged('first_name', e)}
                                placeholder={T.translate('meta.registration.first_name')}
                                label={T.translate('meta.registration.first_name')}
                                errors={errors.filter((e) => e.property === 'first_name')}
                            />
                            <TextInput
                                type="text"
                                className="registration-input"
                                value={this.state.formValues.last_name || ''}
                                onChange={(e) => this.onFormChanged('last_name', e)}
                                placeholder={T.translate('meta.registration.last_name')}
                                label={T.translate('meta.registration.last_name')}
                                errors={errors.filter((e) => e.property === 'last_name')}
                            />
                            <TextInput
                                type="email"
                                className="registration-input"
                                value={this.state.formValues.email || ''}
                                onChange={(e) => this.onFormChanged('email', e)}
                                placeholder={T.translate('meta.registration.email')}
                                label={T.translate('meta.registration.email')}
                                errors={errors.filter((e) => e.property === 'email' || e.property === 'user.email')}
                            />
                            <TextInput
                                type="password"
                                className="registration-input"
                                value={this.state.formValues.password1 || ''}
                                onChange={(e) => this.onFormChanged('password1', e)}
                                placeholder={T.translate('meta.registration.password')}
                                label={T.translate('meta.registration.password')}
                                errors={errors.filter((e) => e.property === 'password1')}
                            />
                            <PasswordStrength strength={this.state.pwStrength} />
                            <TextInput
                                type="password"
                                className="registration-input"
                                value={this.state.formValues.password2 || ''}
                                onChange={(e) => this.onFormChanged('password2', e)}
                                placeholder={T.translate('meta.registration.password-confirmation')}
                                label={T.translate('meta.registration.password-confirmation')}
                                errors={errors.filter((e) => e.property === 'password2')}
                            />
                            <TextInput
                                type="text"
                                className="registration-input"
                                value={this.state.formValues.promoter_code || ''}
                                onChange={(e) => this.onFormChanged('promoter_code', e)}
                                placeholder={T.translate('meta.registration.promoter-code')}
                                label={T.translate('meta.registration.promoter-code')}
                                errors={errors.filter((e) => e.property === 'providers.promoter')}
                            />
                            <div
                                className={classnames('field', { error: this.state.over18Error })}
                                style={{ margin: '20px 0 10px' }}
                            >
                                <Checkbox
                                    ref={(i) => (this.over18 = i)}
                                    checked={this.state.over18}
                                    onChange={(e, v) =>
                                        this.setState({
                                            over18: !this.state.over18,
                                            over18Error: false,
                                        })
                                    }
                                    label={T.translate('legal.over18')}
                                />
                            </div>
                            <div
                                className={classnames('field', { error: this.state.agreeError1 })}
                                style={{ margin: '20px 0 10px' }}
                            >
                                <Checkbox
                                    ref={(i) => (this.agree1 = i)}
                                    checked={this.state.agreeToTerms1}
                                    onChange={(e, v) =>
                                        this.setState({
                                            agreeToTerms1: !this.state.agreeToTerms1,
                                            agreeError1: false,
                                        })
                                    }
                                    label={T.translate('legal.registration1', {
                                        terms_link: (
                                            <a
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    store.dispatch({
                                                        type: 'SEND_SIGNAL',
                                                        payload: {
                                                            signalType: 'TEXT_MODAL',
                                                            title: T.translate('legal.terms-title'),
                                                            content: (
                                                                <iframe
                                                                    style={{
                                                                        height: 'calc(100vh - 200px)',
                                                                        width: '100%',
                                                                        borderColor: '#f9f9f9',
                                                                    }}
                                                                    src="/terms.html"
                                                                    title="termsInRegistration"
                                                                />
                                                            ),
                                                        },
                                                    });
                                                }}
                                            >
                                                {T.translate('legal.terms-of-use')}
                                            </a>
                                        ),
                                        polcy_link: (
                                            <a
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    store.dispatch({
                                                        type: 'SEND_SIGNAL',
                                                        payload: {
                                                            signalType: 'TEXT_MODAL',
                                                            title: T.translate('legal.policy-title'),
                                                            content: (
                                                                <iframe
                                                                    style={{
                                                                        height: 'calc(100vh - 200px)',
                                                                        width: '100%',
                                                                        borderColor: '#f9f9f9',
                                                                    }}
                                                                    src="/policy.html"
                                                                    title="policyInRegistration"
                                                                />
                                                            ),
                                                        },
                                                    });
                                                }}
                                            >
                                                {T.translate('legal.policy')}
                                            </a>
                                        ),
                                    })}
                                />
                            </div>
                            <div
                                className={classnames('field', { error: this.state.agreeError2 })}
                                style={{ margin: '20px 0 10px' }}
                            >
                                <Checkbox
                                    ref={(i) => (this.agree2 = i)}
                                    checked={this.state.agreeToTerms2}
                                    onChange={(e, v) =>
                                        this.setState({
                                            agreeToTerms2: !this.state.agreeToTerms2,
                                            agreeError2: false,
                                        })
                                    }
                                    label={T.translate('legal.registration2')}
                                />
                            </div>

                            <Checkbox
                                ref={(i) => (this.is_premium = i)}
                                checked={this.state.is_premium}
                                onChange={(e, v) =>
                                    this.setState({
                                        is_premium: !this.state.is_premium,
                                    })
                                }
                                label={T.translate('legal.register-trial')}
                            />

                            <MibButton
                                fluid
                                type="info"
                                disabled={
                                    this.state.over18 != true ||
                                    this.state.agreeToTerms1 != true ||
                                    this.state.agreeToTerms2 != true
                                }
                                loading={this.state.loading}
                                style={{ marginTop: '10px' }}
                                onClick={this.register.bind(this)}
                                label={T.translate('meta.registration.register')}
                            />
                        </Segment>
                        <MibButton
                            color="teal"
                            label={T.translate('meta.registration.login')}
                            onClick={() => this.props.history.push({ pathname: '/login' })}
                            style={{ minHeight: '50px', width: '440px' }}
                        />
                        <span style={{ padding: '5%', height: 'auto', width: '100%', maxWidth: '800px' }}>
                            <Message info>
                                <Icon name="info circle" style={{ fontSize: '1.4em' }} />
                                {T.translate('legal.registration-note')}
                            </Message>
                        </span>
                    </div>
                </LoginAndRegisterTemplate>
            </Suspense>
        );
    }
}

export default RegistrationPage;

// const mapStateToProps = ({ authentication }) => ({
//     authData: authentication,
// });
//
// export const LoginPage = connect(mapStateToProps)(_LoginPage);
