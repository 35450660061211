import React, { Component, Fragment as F } from "react";
import {
    Checkbox,
    Form,
    Input,
    Icon,
    Select,
    TextArea,
    Dropdown,
    Radio,
    Popup,
    Message,
    Transition,
    Label,
    Segment,
} from "semantic-ui-react";

require("./form_inputs.scss");

export class TagInput extends Component {
    constructor() {
        super();
    }
    render() {
        let { label, options, onChange, onAddItem, ...other } = this.props;

        return (
            <Form.Field inline>
                <label>
                    <span className="InputLabel">{label}</span>
                    <Dropdown
                        className="defaultCss"
                        style={{ width: 550 }}
                        selection
                        search
                        multiple
                        allowAdditions
                        onAddItem={onAddItem}
                        onChange={onChange}
                        options={options}
                        {...other}
                    />
                </label>
            </Form.Field>
        );
    }
}

require("./form_inputs.scss");

export class TextAreaInput extends Component {
    constructor() {
        super();
    }
    render() {
        let { label, value, onChange, inline = false, style, ...other } = this.props;

        let styleProp = Object.assign({ width: 250 }, style);

        return (
            <Form.Field inline={inline}>
                <label>
                    <span className="InputLabel">{label}</span>
                    <TextArea
                        className="defaultCss textarea"
                        style={styleProp}
                        onChange={onChange}
                        value={value}
                        {...other}
                    />
                </label>
            </Form.Field>
        );
    }
}

export class SearchInput extends Component {
    constructor() {
        super();
    }
    render() {
        let { value, onChange,onClick,placeholder,iconName='search', ...other  } = this.props;

        return (

            <Input
                style={{ width: "100%" }}
                className="defaultCss"
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                {...other}
                icon={{onClick: onClick , name:`${iconName}`, circular: true, link: true }}
            />
        );
    }
}
