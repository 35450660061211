import { Icon, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import React from 'react';
import T from 'i18n-react';

import { MenuPanel } from 'Mib/components/containers';

// const iconMap = {
//     "0": "child",
//     "1": "icon-walking",
//     "2": "icon-running",
//     "3": "bicycle",
//     "4": "icon-skiing",
//     "5": "icon-withings",
// };
const iconMap = {
    0: 'mibmono-excersise',
    1: 'mibmono-walking',
    2: 'mibmono-running',
    3: 'mibmono-bicycle',
    4: 'mibmono-skiing',
    5: 'icon-withings',
};

const ActivitiesMenu = ({ match, history, types = {}, createActivity }) => {
    const menuItems = Object.entries(types).map(([id, name]) => ({
        path: `/${name}`,
        key: id,
        text: `activities.${name}`,
        as: NavLink,
        to: `/activities/${name}`,
        label: (
            <span>
                <i className={`mib-mono-icon  ${iconMap[id]}`} />
                <span style={{ padding: '3%' }}>{T.translate(`activities.${name}`)}</span>
            </span>
        ),
    }));

    return (
        <MenuPanel
            title={
                <Menu.Item className="menu-title" as={NavLink} to="/activities" exact>
                    <i className="mib-mono-icon mibmono-activities" />
                    {T.translate('activities.all')}
                </Menu.Item>
            }
            // className="bg-blue"
            className="mib-menu violet activity-menu"
            items={menuItems}
            footer={
                <Menu.Item onClick={createActivity} className="menu-footer">
                    {T.translate('activities.add')}
                    <Icon className="add circle" />
                </Menu.Item>
            }
        />
    );
};

export default ActivitiesMenu;
