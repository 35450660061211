import { store } from "Mib/datastore/store";
import {Fetch} from "../lib/fetch";


export const fetchFeatures = async () => {
    const features = await Fetch(`features`, { method: "GET" }, "api")
        .catch(e => {console.log("Error while syncing: ", e);
    });
    store.dispatch({ type: "SET_FEATURES", payload: features });

    return features;
}





