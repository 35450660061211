import { Header, Table } from 'semantic-ui-react';
import { MibButton } from '../../components/inputs/buttons';
import T from 'i18n-react';
import React, { Component } from 'react';
import moment from 'moment';
import { getProperty } from 'dot-prop';
import { store } from '../../datastore/store';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

class _ExamRow extends Component {
    constructor() {
        super();
    }

    render() {
        const { lab_exams, measure_units } = this.props;
        const { frequency, next_exam, last_exam, last_exam_date, mib_id } = this.props.exam;

        let frequency_ = frequency ? `${Math.floor(frequency / 30)} Μήνες` : '';
        let currentExam = lab_exams && Object.values(lab_exams).find((labExam) => Number(labExam.id) == mib_id);
        let currentMeasureUnit =
            currentExam &&
            Object.values(measure_units).find(
                (measureUnit) => Number(measureUnit.measureUnitType.id) == currentExam.measure_unit_type_id
            );
        let circleColor = this.colorByPercent(moment(last_exam_date).format('L'), frequency);

        //this.props.history.push({ pathname: "/login" })
        return (
            <Table.Row>
                <Table.Cell
                    onClick={() => this.props.history.replace({ pathname: `/health-folder/labs/${mib_id}` })}
                    className="examNameStyle"
                >
                    {currentExam.name}
                </Table.Cell>
                <Table.Cell singleLine>{frequency_}</Table.Cell>
                <Table.Cell singleLine>
                    {last_exam_date ? moment(last_exam_date).format('L') : T.translate('routes.examsByAge.noEntry')}
                </Table.Cell>
                <Table.Cell singleLine className="lastCellWithCircle">
                    <div style={{ transform: 'translate(0, 7px)' }}>
                        {next_exam ? moment(next_exam).format('L') : T.translate('routes.examsByAge.noEntry')}
                    </div>
                    <div className="outCircle">
                        <div className="innerCircle" style={{ backgroundColor: circleColor }} />
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <MibButton
                        type="info"
                        onClick={() => this.open(mib_id, currentMeasureUnit.id)}
                        label={T.translate('routes.examsByAge.addMeasurement')}
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    open(lab_type = null, measureUnit) {
        store.dispatch({
            type: 'SEND_SIGNAL',
            payload: {
                signalType: 'MEASUREMENT_MODAL',
                unitId: measureUnit,
                id: null,
                type: 'lab-exam-events',
                lab_type,
            },
        });
    }

    colorByPercent(lastExam, examFrequency) {
        let now = moment(new Date());
        let lastExamDate = moment(lastExam, 'DD/MM/YYYY');
        let examFreqDates = Number(examFrequency);
        let diffFromNow = moment.duration(now.diff(lastExamDate));
        let daysOfDiffFromNow = diffFromNow.asDays();
        let percent = (daysOfDiffFromNow / examFreqDates) * 100;

        if (percent < 40) {
            return '#81ff07';
        } else if (percent < 70 && percent > 41) {
            return '#ffff00';
        } else {
            return '#ff102c';
        }
    }
}

const mapStateToProps = ({ dataStore }) => {
    return {
        lab_exams: getProperty(dataStore, 'graph.lab-exams'),
        measure_units: getProperty(dataStore, 'graph.measure-units'),
    };
};

export const ExamRow = withRouter(connect(mapStateToProps)(_ExamRow));
