import CryptoJS from 'crypto-js';
import SHA256 from 'crypto-js/sha256';
import AES from 'crypto-js/aes';
import { authBackendURL } from '../config';

const registrationURL = `${authBackendURL}register-individual`;
// window.CJ = CryptoJS

export const CreateRegistrationData = ({ password, email, trial, ...rest }, personData = false) => {
    const passHash = SHA256(password).toString();
    // const params = CS.lib.CipherParams.create({})
    // params.salt = CS.enc.Hex.parse('hoho')
    // window.CS = CS;
    // var Ckey = CS.enc.Hex.parse('36ebe205bcdfc499a25e6923f4450fa8');

    // var username_iv = CryptoJS.PBKDF2(password, username + "hoho");
    // var username_passkey = CryptoJS.PBKDF2(password, username);

    const encryptedEmail = !personData
        ? emailHash(email)
        : email != null
        ? AES.encrypt(email, password).toString()
        : null;
    // console.log('encrypteds email',en)
    const encryptedData = {
        password: passHash,
        // username: AES.encrypt(username, username_passkey, { iv: username_iv }).toString(),
        email: encryptedEmail,
    };

    const dontEncrypt = ['promoter_id', 'promoter_code', 'is_premium'];

    Object.entries(rest).forEach(([key, value]) => {
        if (dontEncrypt.includes(key)) {
            encryptedData[key] = value;
            return null;
        } else {
            let val = AES.encrypt(value, password);

            encryptedData[key] = value != null ? val.toString() : null;
            return val;
        }
    });

    // console.log(username, password);
    // Object.entries(encryptedData).forEach(([key, value]) => {
    //     if (key != "username") {
    //         console.log(key, AES.decrypt(value, password).toString(CryptoJS.enc.Utf8));
    //     } else {
    //         var username_iv = CryptoJS.PBKDF2(password, username + "hoho");
    //         var username_passkey = CryptoJS.PBKDF2(password, username);
    //         console.log(key, AES.decrypt(value, username_passkey, { iv: username_iv }).toString(CryptoJS.enc.Utf8));
    //     }
    // });
    return encryptedData;
};

export const registrationAction = async (data) => {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    let options = {
        method: 'POST',
        body: JSON.stringify({ data: { attributes: data } }),
        headers: headers,
    };
    const result = await fetch(registrationURL, options);
    return result;
};
// Object.values(encryptedData).forEach(i=>{
//     console.log(AES.decrypt(i, password).toString(CryptoJS.enc.Utf8))
// })

export const emailHash = (userEmail) => {
    // The key must be exactly 32 chars long
    const passphrase = CryptoJS.enc.Utf8.parse(userEmail.substring(0, 32).padEnd(32, '0'));
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(userEmail), passphrase, {
        // iv: CryptoJS.enc.Hex.parse("b2308e3a13740a0524ac382ec837911f"),
        iv: CryptoJS.enc.Utf8.parse(userEmail.substring(0, 16).padEnd(16, '0')),
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};
