/* eslint-disable no-underscore-dangle */
/**
 * @class JsonApiDataStoreModel
 */

const sortOn = require('sort-on');

// ('use strict');

const _createClass = (function () {
    function defineProperties(target, props) {
        for (let i = 0; i < props.length; i++) {
            const descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps) defineProperties(Constructor.prototype, protoProps);
        if (staticProps) defineProperties(Constructor, staticProps);
        return Constructor;
    };
})();

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}

const JsonApiDataStoreModel = (function () {
    /**
     * @method constructor
     * @param {string} type The type of the model.
     * @param {string} id The id of the model.
     */

    function JsonApiDataStoreModel(type, id) {
        _classCallCheck(this, JsonApiDataStoreModel);
        this.id = id;
        this._type = type;
        this._attributes = [];
        this._relationships = [];
    }

    /**
     * @class JsonApiDataStore
     */

    /**
     * Serialize a model.
     * @method serialize
     * @param {object} opts The options for serialization.  Available properties:
     *
     *  - `{array=}` `attributes` The list of attributes to be serialized (default: all attributes).
     *  - `{array=}` `relationships` The list of relationships to be serialized (default: all relationships).
     * @return {object} JSONAPI-compliant object
     */

    _createClass(JsonApiDataStoreModel, [
        {
            key: 'serialize',
            value: function serialize(opts) {
                const self = this;
                const res = { data: { type: this._type } };

                const localOpts = opts || {};
                localOpts.attributes = localOpts.attributes || this._attributes;
                localOpts.relationships = localOpts.relationships || this._relationships;

                if (this.id !== undefined) res.data.id = this.id;
                if (localOpts.attributes.length !== 0) res.data.attributes = {};
                if (localOpts.relationships.length !== 0) res.data.relationships = {};

                localOpts.attributes.forEach(function (key) {
                    res.data.attributes[key] = self[key];
                });

                localOpts.relationships.forEach(function (key) {
                    function relationshipIdentifier(model) {
                        return { type: model._type, id: model.id };
                    }
                    if (!self[key]) {
                        res.data.relationships[key] = { data: null };
                    } else if (self[key].constructor === Array) {
                        res.data.relationships[key] = {
                            data: self[key].map(relationshipIdentifier),
                        };
                    } else {
                        res.data.relationships[key] = {
                            data: relationshipIdentifier(self[key]),
                        };
                    }
                });

                return res;
            },

            /**
             * Set/add an attribute to a model.
             * @method setAttribute
             * @param {string} attrName The name of the attribute.
             * @param {object} value The value of the attribute.
             */
        },
        {
            key: 'setAttribute',
            value: function setAttribute(attrName, value) {
                if (this[attrName] === undefined) this._attributes.push(attrName);

                this[attrName] = value;
            },

            /**
             * Set/add a relationships to a model.
             * @method setRelationship
             * @param {string} relName The name of the relationship.
             * @param {object} models The linked model(s).
             */
        },
        {
            key: 'makeDeepCopy',
            value: function makeDeepCopy() {
                const deepCopy = JSON.parse(JSON.stringify(this));
                deepCopy.__proto__ = this.__proto__;

                return deepCopy;
            },

            /**
             * Set/add a relationships to a model.
             * @method setRelationship
             * @param {string} relName The name of the relationship.
             * @param {object} models The linked model(s).
             */
        },
        {
            key: 'setRelationship',
            value: function setRelationship(relName, models) {
                if (this[relName] === undefined) this._relationships.push(relName);

                this[relName] = models;
            },
        },
    ]);

    return JsonApiDataStoreModel;
})();

const JsonApiDataStore = (function () {
    /**
     * @method constructor
     */

    function JsonApiDataStore() {
        _classCallCheck(this, JsonApiDataStore);

        this.graph = {};
        this._brotherGraph = {};
    }

    /**
     * Remove a model from the store.
     * @method destroy
     * @param {object} model The model to destroy.
     */

    _createClass(JsonApiDataStore, [
        {
            key: 'destroy',
            value: function destroy(model) {
                delete this.graph[model._type][model.id];
            },

            /**
             * Empty an entire collection
             * @method empty
             * @param {string} modelName The name of the collection to empty
             */
        },
        {
            key: 'createDeepCopy',
            value: function createDeepCopy(model = {}) {
                const newModel = { ...this.find(model.type, model.id) };

                newModel.__proto__ = new JsonApiDataStoreModel().__proto__;
                const brotherGraph = {
                    ...this.graph,
                    [model.type]: {
                        ...this.graph[model.type],
                        [model.id]: newModel,
                    },
                };
                this._brotherGraph = { ...brotherGraph };
            },
        },
        {
            key: 'empty',
            value: function empty(modelName) {
                if (this.graph[modelName]) {
                    this.graph[modelName] = {};
                }
            },

            /**
             * Retrieve a model by type and id. Constant-time lookup.
             * @method find
             * @param {string} type The type of the model.
             * @param {string} id The id of the model.
             * @return {object} The corresponding model if present, and null otherwise.
             */
        },
        {
            key: 'find',
            value: function find(type, id) {
                if (!this.graph[type] || !this.graph[type][id]) return null;
                return this.graph[type][id];
            },

            /**
             * Retrieve all models by type.
             * @method findAll
             * @param {string} type The type of the model.
             * @return {object} Array of the corresponding model if present, and empty array otherwise.
             */
        },
        {
            key: 'findAll',
            value: function findAll(type) {
                const self = this;

                if (!this.graph[type]) return [];
                return sortOn(
                    Object.keys(self.graph[type]).map(function (v) {
                        return self.graph[type][v];
                    }),
                    '-date'
                );
            },

            /**
             * Empty the store.
             * @method reset
             */
        },
        {
            key: 'reset',
            value: function reset() {
                this.graph = {};
            },
        },
        {
            key: 'initModel',
            value: function initModel(type, id) {
                this.graph[type] = this.graph[type] || {};
                this.graph[type][id] = this.graph[type][id] || new JsonApiDataStoreModel(type, id);
                return this.graph[type][id];
            },
        },
        {
            key: 'syncRecord',
            value: function syncRecord(rec) {
                const self = this;
                const model = this.initModel(rec.type, rec.id);
                function findOrInit(resource) {
                    // search if it already exists
                    if (!self.find(resource.type, resource.id)) {
                        const placeHolderModel = self.initModel(resource.type, resource.id);
                        placeHolderModel._placeHolder = true;
                    }

                    return self.graph[resource.type][resource.id];
                }

                delete model._placeHolder;

                Object.entries(rec.attributes).forEach(([key, val]) => {
                    if (model._attributes.indexOf(key) === -1) model._attributes.push(key);

                    model[key] = val;
                });

                if (rec.relationships)
                    Object.entries(rec.relationships).forEach(([key, rel]) => {
                        if (rel.data !== undefined) {
                            if (model._relationships.indexOf(key) === -1) model._relationships.push(key);

                            if (rel.data === null) {
                                model[key] = null;
                            } else if (rel.data.constructor === Array) {
                                model[key] = rel.data.map(findOrInit);
                            } else {
                                model[key] = findOrInit(rel.data);
                            }
                        }

                        if (rel.links) console.log('Warning: Links not implemented yet.');
                    });

                this.createDeepCopy(model.serialize().data);
                return model;
            },

            /**
             * Sync a JSONAPI-compliant payload with the store and return any metadata included in the payload
             * @method syncWithMeta
             * @param {object} data The JSONAPI payload
             * @return {object} The model/array of models corresponding to the payload's primary resource(s) and any metadata.
             */
        },
        {
            key: 'syncWithMeta',
            value: function syncWithMeta(payload, replace = false) {
                const primary = payload.data;
                const syncRecord = this.syncRecord.bind(this);

                if (!primary || (Array.isArray(primary) && primary.length === 0)) return null;
                if (payload.included) payload.included.map(syncRecord);
                if (replace && primary.constructor === Array) {
                    if (primary.length > 0) this.graph[primary[0].type] = {};
                }

                let data;
                if (primary.constructor === Array) {
                    data = primary.map(syncRecord);
                } else {
                    data = syncRecord(primary);
                }
                // const data = primary.constructor === Array ? primary.map(syncRecord) : syncRecord(primary);

                return {
                    data: data,
                    meta: 'meta' in payload ? payload.meta : null,
                };
            },

            /**
             * Sync a JSONAPI-compliant payload with the store.
             * @method sync
             * @param {object} data The JSONAPI payload
             * @return {object} The model/array of models corresponding to the payload's primary resource(s).
             */
        },
        {
            key: 'sync',
            value: function sync(payload) {
                return this.syncWithMeta(payload).data;
            },
        },
    ]);

    return JsonApiDataStore;
})();

// if ('undefined' !== typeof module) {
//   module.exports = {
//     JsonApiDataStore: JsonApiDataStore,
//     JsonApiDataStoreModel: JsonApiDataStoreModel
//   };
// }

export { JsonApiDataStoreModel, JsonApiDataStore };
