import React, { Component } from "react";
import classnames from "classnames";

require("./select.scss");

let OFFSET = "1.2em";

export default class MibSelect extends Component {
  constructor() {
    super();
    this.state = { open: false };
    this.onWheel = this.onWheel.bind(this);
  }

  render() {
    const { open } = this.state;
    const { items, value, style, itemStyle, disabled } = this.props;
    let [arrangedItems, activeIndex] = this.arrangeStuff(
      items,
      items&&items.findIndex(i => i.value == value)
    );
    const activeItem = arrangedItems[activeIndex] || {
      text: "",
      key: 189237678
    };

    return (
      <div
        className={classnames("mib-select-container", { disabled: disabled })}
        style={style}
        onWheel={this.onWheel}
      >
        {arrangedItems
          .slice(0, activeIndex)
          .map((item, index) =>
            this._renderItemBefore(item, open, activeIndex, index)
          )}
        {this._renderCenterItem(activeItem)}
        {arrangedItems
          .slice(activeIndex + 1)
          .map((item, index) =>
            this._renderItemAfter(item, open, activeIndex, index)
          )}
      </div>
    );
  }

  onWheel(e) {
    const { open } = this.state;
    const { items, value, changeOnWheel } = this.props;
    if (!changeOnWheel || !open) return;

    e.preventDefault();
    const directionDown = e.deltaY > 0;

    let index = items.findIndex(i => i.value == value);
    if (index == -1) return;
    let newIndex;
    if (directionDown) {
      if (index == items.length - 1) {
        newIndex = 0;
      } else {
        newIndex = index + 1;
      }
    } else {
      if (index == 0) {
        newIndex = items.length - 1;
      } else {
        newIndex = index - 1;
      }
    }
    this.props.onChange(items[newIndex].value);
  }

  _renderCenterItem(item) {
    const { itemStyle } = this.props;
    return this.props.renderCenterItem ? (
      this.props.renderCenterItem(
        item,
        this.onCenterItemClicked.bind(this, item.value)
      )
    ) : (
      <div
        className="mib-select-item active"
        onClick={this.onCenterItemClicked.bind(this, item.value)}
        style={itemStyle}
      >
        {item.text}
      </div>
    );
  }

  _renderItemBefore(item, open, activeIndex, index) {
    const { itemStyle } = this.props;
    return this.props.renderItemBefore ? (
      this.props.renderItemBefore(
        item,
        this.onItemClicked.bind(this, item.value),
        open,
        activeIndex,
        index
      )
    ) : (
      <div
        onClick={this.onItemClicked.bind(this, item.value)}
        className={classnames("mib-select-item inactive", {
          hidden: !open
        })}
        key={item.key != null ? item.key : item.value}
        style={{
          top: `calc(${activeIndex - index} * ${OFFSET})`,
          ...itemStyle
        }}
      >
        {item.text}
      </div>
    );
  }

  _renderItemAfter(item, open, activeIndex, index) {
    const { itemStyle } = this.props;
    return this.props.renderItemAfter ? (
      this.props.renderItemAfter(
        item,
        this.onItemClicked.bind(this, item.value),
        open,
        activeIndex,
        index
      )
    ) : (
      <div
        onClick={this.onItemClicked.bind(this, item.value)}
        className={classnames("mib-select-item inactive after", {
          hidden: !open
        })}
        key={item.key != null ? item.key : item.value}
        style={{ top: `calc(${-(index + 1)} * ${OFFSET})`, ...itemStyle }}
      >
        {item.text}
      </div>
    );
  }

  onItemClicked(index) {
    this.props.onChange(index);
    this.toggle(false);
  }

  onCenterItemClicked(activeIndex) {
    const { disabled } = this.props;
    if (disabled) return null;
    if (this.state.open) {
      this.onItemClicked(activeIndex);
    } else {
      this.toggle(true);
    }
  }

  focus(){
      return this.toggle()
  }

  toggle(state) {
    this.setState({ open: state != undefined ? state : !this.state.open });
  }

  arrangeStuff(stuff, activeIndex) {
    const newList = [...stuff];
    let len = stuff.length;
    let middle = Math.floor(len / 2);
    if (activeIndex == -1) return [newList, middle];
    while (activeIndex != middle) {
      newList.push(newList.shift());
      activeIndex = activeIndex > 0 ? activeIndex - 1 : len - 1;
    }
    return [newList, middle];
  }
}
